import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { ILogoProps } from './interfaces'

const Logo: React.FC<ILogoProps> = ({ url, className, color }) => {
  const currentColor = color ?? EColor.WHITE

  /* eslint-disable max-len */
  return (
    <CommonLink url={url} className={className} spanIfEmpty>
      <svg width="130" height="28" viewBox="0 0 130 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.316 5.05247C125.173 5.05247 126.752 5.83207 128.051 7.39129C129.35 8.95051 130 10.8322 130 13.0362C130 15.2403 129.35 17.122 128.051 18.6812C126.752 20.2404 125.173 21.02 123.316 21.02C121.66 21.02 120.192 20.3511 118.912 19.0132V25.0109C118.912 26.0759 118.049 26.9392 116.984 26.9392H115.336C115.158 26.9392 115.014 26.7954 115.014 26.6179V19.0132C113.734 20.3511 112.257 21.02 110.582 21.02C108.753 21.02 107.189 20.2404 105.89 18.6812C104.581 17.1316 103.926 15.2499 103.926 13.0362C103.926 10.8322 104.576 8.95051 105.875 7.39129C107.175 5.83207 108.744 5.05247 110.582 5.05247C112.257 5.05247 113.734 5.72139 115.014 7.05923V2.98983C115.014 1.92486 115.878 1.06152 116.943 1.06152H118.591C118.768 1.06152 118.912 1.20541 118.912 1.38291V7.05923C120.192 5.72139 121.66 5.05247 123.316 5.05247ZM108.691 16.3423C109.422 17.276 110.327 17.7428 111.405 17.7428C112.473 17.7428 113.383 17.276 114.134 16.3423C114.875 15.4087 115.245 14.3067 115.245 13.0362C115.245 11.7658 114.875 10.6589 114.134 9.71568C113.383 8.7917 112.473 8.32971 111.405 8.32971C110.327 8.32971 109.422 8.7917 108.691 9.71568C107.94 10.6589 107.565 11.7658 107.565 13.0362C107.565 14.3163 107.94 15.4184 108.691 16.3423ZM119.807 16.3423C120.539 17.276 121.444 17.7428 122.522 17.7428C123.59 17.7428 124.499 17.276 125.25 16.3423C125.991 15.4087 126.362 14.3067 126.362 13.0362C126.362 11.7658 125.991 10.6589 125.25 9.71568C124.499 8.7917 123.59 8.32971 122.522 8.32971C121.444 8.32971 120.539 8.7917 119.807 9.71568C119.057 10.6589 118.681 11.7658 118.681 13.0362C118.681 14.3163 119.057 15.4184 119.807 16.3423Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.1 7.39129C98.6948 5.83207 97.0008 5.05247 95.0181 5.05247C93.0835 5.05247 91.4377 5.78395 90.0806 7.24692C90.0806 6.36187 89.3631 5.64439 88.4781 5.64439H86.4895C86.312 5.64439 86.1681 5.78828 86.1681 5.96578V25.0109C86.1681 26.0759 87.0315 26.9392 88.0964 26.9392H89.7592C89.9367 26.9392 90.0806 26.7954 90.0806 26.6179V18.8255C91.4377 20.2885 93.0835 21.02 95.0181 21.02C97.0008 21.02 98.6948 20.2404 100.1 18.6812C101.496 17.1316 102.193 15.2499 102.193 13.0362C102.193 10.8225 101.496 8.94088 100.1 7.39129ZM94.1808 17.7428C92.968 17.7428 91.9382 17.2808 91.0912 16.3568C90.2346 15.4424 89.8063 14.3356 89.8063 13.0362C89.8063 11.7465 90.2346 10.6348 91.0912 9.70124C91.9478 8.78689 92.9777 8.32971 94.1808 8.32971C95.3839 8.32971 96.4185 8.78689 97.2848 9.70124C98.1414 10.6348 98.5697 11.7465 98.5697 13.0362C98.5697 14.3356 98.1414 15.4424 97.2848 16.3568C96.4185 17.2808 95.3839 17.7428 94.1808 17.7428Z"
          fill={currentColor}
        />
        <path
          d="M0 20.1067C0 20.2842 0.143889 20.4281 0.321385 20.4281H3.59109C3.76859 20.4281 3.91248 20.2842 3.91248 20.1067V8.92163H10.6546V20.1067C10.6546 20.2842 10.7985 20.4281 10.976 20.4281H14.2313C14.4088 20.4281 14.5527 20.2842 14.5527 20.1067V5.96577C14.5527 5.78828 14.4088 5.64439 14.2313 5.64439H0.321384C0.143889 5.64439 0 5.78828 0 5.96577V20.1067Z"
          fill={currentColor}
        />
        <path
          d="M47.0005 5.64439C46.868 5.64439 46.7491 5.72567 46.701 5.84909L41.3617 19.5519C41.1975 19.9734 41.5084 20.4281 41.9607 20.4281H44.5414C44.804 20.4281 45.0402 20.2683 45.138 20.0246L49.4805 9.19594L53.8089 20.0239C53.9065 20.268 54.1429 20.4281 54.4058 20.4281H56.986C57.4383 20.4281 57.7491 19.9734 57.5849 19.5519L52.2456 5.84909C52.1975 5.72567 52.0786 5.64439 51.9462 5.64439H47.0005Z"
          fill={currentColor}
        />
        <path
          d="M78.1838 16.5156C78.6746 16.0247 79.2762 15.7793 79.9884 15.7793C80.7006 15.7793 81.307 16.0247 81.8075 16.5156C82.308 17.0161 82.5582 17.6176 82.5582 18.3202C82.5582 19.0325 82.308 19.6388 81.8075 20.1393C81.307 20.6494 80.7006 20.9045 79.9884 20.9045C79.2666 20.9045 78.665 20.6494 78.1838 20.1393C77.6833 19.6388 77.433 19.0325 77.433 18.3202C77.433 17.6176 77.6833 17.0161 78.1838 16.5156Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.5921 16.9631C68.9665 17.4829 68.1773 17.7428 67.2244 17.7428C66.1753 17.7428 65.2417 17.3915 64.4236 16.6888C63.5863 15.9862 63.0617 15.1056 62.85 14.0468H74.7136C74.8911 14.0468 75.0354 13.9026 75.0313 13.7252C74.9746 11.2155 74.2492 9.16191 72.8549 7.56454C71.4016 5.88982 69.5248 5.05247 67.2244 5.05247C64.9819 5.05247 63.0713 5.83207 61.4929 7.39129C59.9144 8.95051 59.1252 10.8322 59.1252 13.0362C59.1252 15.2403 59.9144 17.122 61.4929 18.6812C63.0713 20.2404 64.9819 21.02 67.2244 21.02C68.3602 21.02 69.3948 20.8227 70.3284 20.4281C71.2428 20.0431 71.9598 19.6052 72.4796 19.1143C72.8978 18.7207 73.2775 18.2572 73.6189 17.7239C73.7171 17.5705 73.6601 17.3677 73.4997 17.2813L72.0892 16.5208C71.2906 16.0901 70.2811 16.3726 69.5921 16.9631ZM67.2244 8.32971C66.3005 8.32971 65.4679 8.5992 64.7268 9.13819C63.9953 9.6868 63.4467 10.3942 63.081 11.2605H71.007C70.7086 10.3942 70.2177 9.6868 69.5344 9.13819C68.8318 8.5992 68.0618 8.32971 67.2244 8.32971Z"
          fill={currentColor}
        />
        <path
          d="M20.5725 8.10153C18.4273 10.2241 17.3547 12.7855 17.3547 15.7858C17.3547 17.365 17.6518 18.8225 18.2461 20.1585C18.2967 20.2724 18.4105 20.3442 18.5351 20.3442H21.7036C21.9669 20.3442 22.1218 20.0421 21.9841 19.8176C21.2546 18.6283 20.8899 17.2843 20.8899 15.7858C20.8899 13.6879 21.6155 11.874 23.0668 10.3439C24.518 8.82969 26.2564 8.07256 28.2817 8.07256C30.3071 8.07256 32.0454 8.82969 33.4967 10.3439C34.9479 11.874 35.6736 13.6879 35.6736 15.7858C35.6736 17.2843 35.3088 18.6283 34.5793 19.8176C34.4416 20.0421 34.5965 20.3442 34.8599 20.3442H38.1891C38.3137 20.3442 38.4275 20.2724 38.4781 20.1585C39.0724 18.8225 39.3695 17.365 39.3695 15.7858C39.3695 12.7855 38.2969 10.2241 36.1517 8.10153C34.0195 5.97901 31.423 4.91775 28.3621 4.91775C25.3143 4.91775 22.7178 5.97901 20.5725 8.10153Z"
          fill={currentColor}
        />
      </svg>
    </CommonLink>
  )
}

export default Logo
