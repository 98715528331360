import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { getInitialState } from '@/redux/helpers'
import { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { ERequestStatus } from '@/types'
import { clearRequestStatuses, requestFailed, requestFinished, requestStarted, setInitialStatus } from './actions'
import { IClearRequestStatusesPayload, IRequestStatusPayload, IRequestStatusState } from './interfaces'

const initialState: IRequestStatusState = {
  requestsStatuses: {},
}

const setStatus = (payload: IRequestStatusPayload, status: ERequestStatus) => (state: IRequestStatusState) => {
  const { name, meta = null, data = null } = payload
  if (!state.requestsStatuses[name]) state.requestsStatuses[name] = { status: ERequestStatus.INITIAL }

  state.requestsStatuses[name]!.data = data
  state.requestsStatuses[name]!.status = status
  state.requestsStatuses[name]!.meta = meta
}

const reducerName = 'requestStatus'
const reducer = createReducer(getInitialState(reducerName, initialState), {
  [requestStarted.type]: (state, { payload }: PayloadAction<IRequestStatusPayload>) => {
    setStatus(payload, ERequestStatus.LOADING)(state)
  },
  [requestFinished.type]: (state, { payload }: PayloadAction<IRequestStatusPayload>) => {
    setStatus(payload, ERequestStatus.LOADED)(state)
  },
  [requestFailed.type]: (state, { payload }: PayloadAction<IRequestStatusPayload>) => {
    setStatus(payload, ERequestStatus.FAILURE)(state)
  },
  [setInitialStatus.type]: (state, { payload }: PayloadAction<IRequestStatusPayload>) => {
    setStatus(payload, ERequestStatus.INITIAL)(state)
  },
  [clearRequestStatuses.type]: (state, { payload }: PayloadAction<IClearRequestStatusesPayload>) => {
    payload.names.forEach(name => setStatus({ name }, ERequestStatus.INITIAL)(state))
  },
})

reducerManager.add(reducerName, reducer)

export const getRequest = (name: string) => (state: RootState) => state[reducerName].requestsStatuses[name]

export const getRequestStatus = (name: string) => (state: RootState) => getRequest(name)(state)?.status

export const isRequestFailed = (name: string) => (state: RootState) => getRequestStatus(name)(state) === ERequestStatus.FAILURE

export const isRequestInProcess = (name: string) => (state: RootState) => getRequestStatus(name)(state) === ERequestStatus.LOADING

export const isRequestEnded = (name: string) => (state: RootState) => getRequestStatus(name)(state) === ERequestStatus.LOADED

export const getRequestMeta = (name: string) => (state: RootState) => getRequest(name)(state)?.meta || {}

export const getRequestData =
  <GData>(name: string, defaultValue?: GData) =>
  (state: RootState): GData =>
    getRequest(name)(state)?.data || defaultValue || {}

export const getRequestState = (name?: string) => (state: RootState) => {
  if (!name) {
    return {
      isLoading: false,
      isLoaded: false,
      isFailure: false,
    }
  }

  const status = getRequestStatus(name)(state)

  return {
    isLoading: status === ERequestStatus.LOADING,
    isLoaded: status === ERequestStatus.LOADED,
    isFailure: status === ERequestStatus.FAILURE,
  }
}
