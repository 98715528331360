import React, { FC, memo } from 'react'
import { ChangeOrgButton, Logo } from '@/components/ui'
import {
  AdditionalMenuLinks,
  HeaderActions,
  MobileNavigationContainer,
  NavLinks,
  RegionChooserBottom,
  RegionChooserTop,
  ScrollableHeader,
} from './components'
import './styles.scss'

const HeaderContent: FC = memo(() => (
  <>
    <div className="header-top text_small">
      <div className="page-wrapper">
        <div className="header-top__region header-top__region_tablet">
          <RegionChooserTop />
        </div>

        <HeaderActions />
      </div>
    </div>
    <div className="header-bottom">
      <div className="page-wrapper">
        <nav className="header-bottom-navigation">
          <Logo url="/" className="header-logo" />
          <MobileNavigationContainer menuButtonTextId="header.menu.title">
            <NavLinks />
            <AdditionalMenuLinks />
          </MobileNavigationContainer>
          <ChangeOrgButton className="headerChangeOrgButton" buttonClassName="headerChangeOrgButton__button" />
        </nav>
      </div>
    </div>
  </>
))

const HeaderTablet: FC = () => (
  <>
    <div className="header-tablet-litter" />
    <ScrollableHeader>
      <HeaderContent />
    </ScrollableHeader>
    <RegionChooserBottom />
  </>
)

export default HeaderTablet
