import React, { FC, memo } from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { injectMessages } from '@/logic/translation/utils'
import { getRegionAccusativeDeclination } from '@/utils/regions'
import { InjectHtml, TextWithIcon } from '..'
import { IAgriculturalProductDeliveryRegionNoticeProps } from './interfaces'
import messages from './messages.json'

injectMessages(messages)

const AgriculturalProductDeliveryRegionNotice: FC<IAgriculturalProductDeliveryRegionNoticeProps> = props => {
  const { chosenRegion, supplierRegionCode, iconSize, className, iconClassName } = props

  const deliveryRegion = supplierRegionCode === chosenRegion?.code ? undefined : chosenRegion
  if (!deliveryRegion) return null

  return (
    <TextWithIcon
      className={classNames(className, 'text_wrap')}
      iconProps={{ name: EIconName.Delivery, style: { color: EColor.GREEN, fontSize: iconSize }, className: iconClassName }}
      text={
        <InjectHtml
          textId="agriculturalProductDeliveryRegion.deliveryInformation"
          textValues={{ name: getRegionAccusativeDeclination(deliveryRegion.name) }}
        />
      }
    />
  )
}

export default memo(AgriculturalProductDeliveryRegionNotice)
