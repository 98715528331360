/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateOnboardingStatusDataCC = {
    id: string;
    status: UpdateOnboardingStatusDataCC.status;
};

export namespace UpdateOnboardingStatusDataCC {

    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
        DELETED = 'DELETED',
    }


}

