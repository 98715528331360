import { getAnalyticsClientId } from '@/logic/app/reducer'
import { getJwt } from '@/logic/auth/reducer'
import { AuthCookiesService, ClientIdCookieService } from '@/services'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { IAjaxOptions, IPreparedAjaxOptions } from './interfaces'

const ajaxOptions = (props: IAjaxOptions): IPreparedAjaxOptions => {
  const { url, method = 'POST', headers = {}, body, isJson = true, isInternalApi = true, shouldBeAuthorized = true, state, signal } = props
  const options: IPreparedAjaxOptions = {
    url,
    method,
    headers,
    signal,
  }

  if (body && isJson) options.body = JSON.stringify(body)
  else if (body) options.body = body

  if (isJson && !headers['Content-Type']) {
    options.headers['Content-Type'] = 'application/json'
  }

  let jwt: string | undefined
  let clientId: string | undefined
  if (ExecutionEnvironment.isSsr && isInternalApi && state) {
    jwt = getJwt()(state)
    clientId = getAnalyticsClientId()(state)
  } else if (isInternalApi) {
    jwt = AuthCookiesService.getJwt()
    clientId = ClientIdCookieService.get()
  }

  if (shouldBeAuthorized && jwt) options.headers['Authorization'] = `Bearer ${jwt}`
  if (clientId) options.headers['X-Client-Id'] = clientId

  return options
}

export default ajaxOptions
