import { TMessages } from '@/types/TMessages'

export const messages = {
  requestPrice: 'Все цены',
  leasingAvailable: 'Доступно в лизинг',
  buy: 'Купить',

  chipNames: {
    discount: { floating: 'Скидка до {discountPercent}%', fixed: 'Скидка' },
    installment: 'Рассрочка до\u00A012 мес.',
    postponement: 'Постоплата',
  },
} satisfies TMessages
