import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { TSwiperArrowModifier } from '@/components/ui/SwiperArrow/interfaces'
import { SwiperArrow } from '..'
import { IHorizontalScrollableBlockProps } from './interfaces'
import useSlider from './useSlider'
import './styles.scss'

const modifier: TSwiperArrowModifier = 'swiper-gray'

function HorizontalScrollableBlock(props: PropsWithChildren<IHorizontalScrollableBlockProps>) {
  const { total, activeIndex, className, children, totalWidth, refObject } = props

  const { isPrevAvailable, isNextAvailable, goNext, goPrev } = useSlider({
    refObject,
    total,
    totalWidth,
    activeIndex,
  })

  return (
    <>
      <div
        className={classNames('horizontal-scrollable-block-container', {
          'horizontal-scrollable-block-container_was-scrolled': isPrevAvailable,
        })}
      >
        <div className={classNames('horizontal-scrollable-block', className)}>{children}</div>
      </div>

      <div className="horizontal-scrollable-block-arrows">
        {isPrevAvailable && (
          <SwiperArrow
            direction="left"
            onClick={goPrev}
            modifier={modifier}
            className="horizontal-scrollable-block__arrow horizontal-scrollable-block__arrow_left"
          />
        )}
        {isNextAvailable && (
          <SwiperArrow
            direction="right"
            onClick={goNext}
            modifier={modifier}
            className="horizontal-scrollable-block__arrow horizontal-scrollable-block__arrow_right"
          />
        )}
      </div>
    </>
  )
}

export default HorizontalScrollableBlock
