import React, { LegacyRef, useCallback, useEffect, useRef } from 'react'
import Draggable from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Button, ErrorBoundary, InjectHtml } from '@/components/ui'
import AppError from '@/pages/errors/AppError'
import { RootState } from '@/redux/interfaces'
import { addNewEpicsLazily } from '@/utils/addNewEpicsLazily'
import { bindKey } from '@/utils/events'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { clearModalOptions, closeModal } from './actions'
import { KnownModals } from './constants'
import modalEpics from './epics'
import { IModalProps } from './interfaces'
import { getContentOptions, getModalOptions } from './reducer'
import './styles.scss'

addNewEpicsLazily([modalEpics])

const mapState = (state: RootState) => ({
  options: getModalOptions()(state),
  contentOptions: getContentOptions()(state),
})

const animationDuration = 250

const Modal: React.FC<IModalProps> = () => {
  const { options, contentOptions } = useSelector(mapState)

  const { isMobile, isTablet } = useDeviceType()

  const { isOpened, header, headerId, dialogId, shouldCloseOnMissingClick, headerIcon, onClickCloseIcon } = options
  const dispatch = useDispatch()
  const contentPortalRef = useRef<HTMLDivElement>()
  const exitIcon = options?.exitIcon ?? { name: EIconName.Exit }

  const closeDialog = useCallback(() => {
    dispatch(closeModal())
    if (options?.onCloseAction) dispatch(options.onCloseAction())
    if (options?.onCloseModal) options?.onCloseModal()
  }, [dispatch, options])

  const onCloseDialog = useCallback(() => {
    if (options.onConfirm) {
      options.onConfirm()
    } else closeDialog()
  }, [closeDialog, options])

  useEffect(() => {
    document.body.style.overflow = isOpened ? 'hidden' : ''

    let timer: NodeJS.Timeout
    if (!isOpened) {
      timer = setTimeout(() => dispatch(clearModalOptions()), animationDuration)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
    //eslint-disable-next-line
  }, [isOpened])

  useEffect(() => {
    const subscription = bindKey('Escape', onCloseDialog)

    return () => {
      subscription.unsubscribe()
    }
  }, [onCloseDialog])

  const Content = KnownModals[dialogId]
  const isHeaderShown = headerId || header

  return (
    <CSSTransition in={isOpened} timeout={animationDuration} className="modal fading-enter " classNames="fading" unmountOnExit>
      <div className={classNames('modal', options?.modalClassName)} data-modal-type={dialogId}>
        <div className="modal-overlay" onClick={shouldCloseOnMissingClick ? onCloseDialog : undefined} />
        <Draggable disabled={!options?.isDraggable || isMobile || isTablet}>
          <div className={`modal-content-wrapper ${options?.modalClassName ? `modal-content-wrapper__${options?.modalClassName}` : ''}`}>
            <div
              className={classNames('modal-content', contentOptions?.modalContentClassName, {
                'modal-content_draggable': options?.isDraggable,
              })}
            >
              {headerIcon}
              {isHeaderShown && (headerId ? <InjectHtml textId={headerId} TagName="h3" /> : header)}
              {Content && (
                <ErrorBoundary fallBack={error => <AppError errorText={error} />}>
                  <Content closeModal={onCloseDialog} options={contentOptions} contentPortalRef={contentPortalRef} />
                </ErrorBoundary>
              )}
              <Button
                className="modal-content__close-button"
                isUnstyled
                onClick={onClickCloseIcon || onCloseDialog}
                iconProps={exitIcon}
                textId={options.textId}
              >
                {options.buttonText}
              </Button>
            </div>
            <div className="modal-content-portal" ref={contentPortalRef as LegacyRef<HTMLDivElement>} />
          </div>
        </Draggable>
      </div>
    </CSSTransition>
  )
}

export default React.memo(Modal)
