import { combineEpics } from 'redux-observable'
import { EMPTY, fromEvent, merge, of } from 'rxjs'
import { filter, map, mergeMap, throttleTime } from 'rxjs/operators'
import { openModal } from '@/components/composed/Modal/actions'
import { getStore } from '@/redux/helpers'
import { isAuthenticated } from '../auth/reducer'
import { Epic } from '../interfaces'
import { onAppMountWithEpics, setUserLastActivityTime } from './actions'
import { getLocation } from './reducer'

let store: any
getStore().then(module => (store = module?.default))

const fromClicks = fromEvent(document, 'click')
const fromScroll = fromEvent(window, 'scroll')

const onAppMountDoTrackUserActivity: Epic<undefined, typeof onAppMountWithEpics> = action$ =>
  action$.pipe(
    filter(onAppMountWithEpics.match),
    mergeMap(() => merge(fromClicks!, fromScroll!).pipe(throttleTime(10000), map(setUserLastActivityTime))),
  )

const onAppMountDoOpenModalByHashForNotAuthorizedUser: Epic<undefined, typeof onAppMountWithEpics> = (action$, state$) =>
  action$.pipe(
    filter(onAppMountWithEpics.match),
    filter(() => {
      const { value: state } = state$

      return !isAuthenticated()(state) && Boolean(getLocation()(state).hash)
    }),
    mergeMap(() => {
      const { hash } = getLocation()(state$.value)

      switch (hash) {
        case '#registration':
          return of(openModal({ options: { dialogId: 'AuthModal' } }))
        default:
          return EMPTY
      }
    }),
  )

export default (<any>combineEpics)(onAppMountDoTrackUserActivity, onAppMountDoOpenModalByHashForNotAuthorizedUser)
