import React from 'react'
import { IAccreditationContext } from './interfaces'

export const AccreditationContext = React.createContext<IAccreditationContext>({} as IAccreditationContext)

export const mainStatuses = [
  'active',
  'expires',
  'expired',
  'processing',
  'rejected',
  'sign',
  'new',
  'waiting',
  'adminApproves',
  'approved',
] as const
