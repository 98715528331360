export enum EUserPermission {
  ADMIN = 'ADMIN',
  AGRARIAN = 'AGRARIAN',
  SUPPLIER = 'SUPPLIER',
  BUYER = 'BUYER',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
  LOGISTICS_OPERATOR = 'LOGISTICS_OPERATOR',
}

export type TUserPermissions = EUserPermission[]
