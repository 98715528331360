import React, { FC, memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { Button } from '@frontend/pole-ui/lib/components/Button'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { CompanySuggestion, FioSuggestion } from '@/api/kubik/dadata'
import { SuggestionCompany, SuggestionFio } from '@/components/composed/modals/AuthModal/components'
import { pushOpenModalAuthConfirmGtmEvent } from '@/components/composed/modals/AuthModal/components/gtmHelpers'
import {
  getSuggestionFioValue,
  getSuggestionValue,
  onSuggestionCompanySelected,
  onSuggestionsCompanyFetchRequested,
  onSuggestionsFioFetchRequested,
} from '@/components/composed/modals/AuthModal/components/helpers'
import authMessages from '@/components/composed/modals/AuthModal/messages.json'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { Autosuggest, TextInput } from '@/components/ui/ReactHookFormFields'
import { sendingSmsRequestFailed, sendRegistrationRequest } from '@/logic/auth/actions'
import { getError, getRootState, isAuthenticated, isFetching, isRegistrationSuccessful } from '@/logic/auth/reducer'
import { useData } from '@/logic/data'
import { injectMessages } from '@/logic/translation/utils'
import { RootState } from '@/redux/interfaces'
import { TLegalDocuments } from '@/types/legalDocuments'
import { makeAllFieldsTouched } from '@/utils/fields'
import { normalizePhoneNumber } from '@/utils/formatUtils'
import { formatPhone } from '@/utils/helpers'
import { useMount } from '@/utils/hooks'
import { autosuggestOrganizationProfileValidator } from '@/utils/validators'
import { RegistrationDocument } from './components/RegistrationDocument'
import { REGISTRATION_DOCUMENTS_DATA } from './components/RegistrationDocument/constants'
import { messages } from './messages'
import { IRegistrationFormProps, IRegistrationFormState } from './types'
import './styles.scss'

injectMessages(authMessages)

const mapState = (state: RootState) => ({
  isRegistrationSuccessful: isRegistrationSuccessful()(state),
  isAuthenticated: isAuthenticated()(state),
  isFetching: isFetching()(state),
  registrationError: getError()(state),
  rootState: getRootState()(state),
})

const RegistrationFormComponent: FC<IRegistrationFormProps> = ({ phoneNumber, goBack, afterRegistrationComplete }) => {
  const { data: documents = [] } = useData<TLegalDocuments>('legalDocuments')

  const { openAuthModal } = useAuthenticateUser()

  const dispatch = useDispatch()
  const state = useSelector(mapState)

  const form = useForm<IRegistrationFormState>({
    mode: 'onChange',
  })

  const onSubmit = (values: IRegistrationFormState) => {
    // Экшен для установки в стор состояния регистрации
    dispatch(sendingSmsRequestFailed({ shouldRegisterUser: true }))
    dispatch(
      sendRegistrationRequest({
        formValues: {
          ...values,
          email: values.email || undefined,
          phone: normalizePhoneNumber(phoneNumber),
        },
        isSendingAgain: true,
      }),
    )

    pushOpenModalAuthConfirmGtmEvent(state.rootState)
  }

  const handleSubmitAndTouchAllFields: Parameters<typeof form.handleSubmit>[0] = values => {
    onSubmit(values)
    makeAllFieldsTouched(form)
  }

  const handleSubmitErrorAndTouchAllFields: Parameters<typeof form.handleSubmit>[1] = () => {
    makeAllFieldsTouched(form)
  }

  useEffect(() => {
    if (state.isRegistrationSuccessful) {
      openAuthModal({
        initialValues: {
          ...form.getValues(),
          phone: normalizePhoneNumber(phoneNumber),
        },
        codeAlreadySent: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, state.isRegistrationSuccessful])

  useMount(() => {
    pushOpenModalAuthConfirmGtmEvent(state.rootState)
  })

  useEffect(() => {
    if (state.isAuthenticated) {
      afterRegistrationComplete?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isAuthenticated])

  return (
    <div className="registrationForm">
      <div className="registrationForm__header">
        <h4 className="registrationForm__headerTitle">
          {messages.header.title.part1} <span>{formatPhone(phoneNumber)}</span> {messages.header.title.part2}
        </h4>
        <p>{messages.header.text}</p>
      </div>

      <form onSubmit={form.handleSubmit(handleSubmitAndTouchAllFields, handleSubmitErrorAndTouchAllFields)}>
        <div className="registrationForm__fields">
          <Autosuggest<FioSuggestion, IRegistrationFormState>
            control={form.control}
            name="fullName"
            getSuggestionValue={getSuggestionFioValue}
            onSuggestionsFetchRequested={onSuggestionsFioFetchRequested()}
            suggestionComponent={SuggestionFio}
            onSuggestionSelected={async ({ suggestion }) => suggestion.value}
            allowRawValue
            customInputProps={{
              placeholder: messages.form.fullName,
              id: 'registrationFormFullName',
              name: 'fullName',
            }}
            rules={{
              required: messages.errors.required,
            }}
          />

          <Autosuggest<CompanySuggestion, IRegistrationFormState>
            name="organizationProfile"
            control={form.control}
            suggestionComponent={SuggestionCompany}
            getSuggestionValue={getSuggestionValue}
            onSuggestionsFetchRequested={onSuggestionsCompanyFetchRequested(form.setValue, true)}
            onSuggestionSelected={onSuggestionCompanySelected}
            customValidator={autosuggestOrganizationProfileValidator()}
            shouldAlwaysRenderSuggestions
            autosearchValue="test"
            shouldHighlightFirstSuggestion
            isRequired
            customInputProps={{
              placeholder: messages.form.organization,
              name: 'organizationProfile',
              id: `registrationFormOrganizationProfile`,
              bubblingTooltipProps: {
                children: (
                  <div className="row">
                    <Icon name={EIconName.QuestFill} style={{ color: EColor.GREEN }} />
                  </div>
                ),
                tooltipProps: {
                  placement: 'top-end',
                },
                content: (
                  <>
                    <h5 className="space-holder8">{messages.tooltip.title}</h5>
                    <p className="text_small">{messages.tooltip.text}</p>
                  </>
                ),
              },
            }}
            rules={{
              required: messages.errors.inn,
            }}
          />

          <TextInput control={form.control} name="email" type="email" id="authModalEmail" placeholder={messages.form.email} />
        </div>

        <div className="registrationForm__checkboxes">
          {REGISTRATION_DOCUMENTS_DATA.map(document => (
            <RegistrationDocument control={form.control} key={document.name} {...document} documents={documents} />
          ))}
        </div>

        {state.registrationError && (
          <p className="registrationForm__error">
            <span>{state.registrationError.text}</span>
          </p>
        )}

        <div className="registrationForm__buttons">
          <Button modifiers={['outline-gray', 'small']} onClick={goBack} isDisabled={state.isFetching}>
            {messages.button.goBack}
          </Button>

          <Button
            type="submit"
            className="registrationForm__submitButton"
            modifiers={['green', 'small']}
            isDisabled={state.isFetching}
            loaderProps={{ isVisible: state.isFetching }}
          >
            {messages.button.submit}
          </Button>
        </div>
      </form>
    </div>
  )
}

export const RegistrationForm = memo(RegistrationFormComponent)
