import { combineEpics } from 'redux-observable'
import { concat, from, of, throwError, timer } from 'rxjs'
import { catchError, delayWhen, filter, map, mergeMap, switchMap } from 'rxjs/operators'
import { isStatusOk } from '@/api/helpers'
import { closeModal } from '@/components/composed/Modal/actions'
import { getLocation, getLocationQuery } from '@/logic/app/reducer'
import { Epic } from '@/logic/interfaces'
import { fetchAgrarianOrder } from '@/logic/order/actions'
import { requestFailed, requestFinished, requestStarted } from '@/logic/requestStatus/actions'
import { OrderDocumentsService, OrganizationDocumentsService, SaleRequestDocumentsService } from '@/services/documents'
import { EDocumentEntityType, IDocument } from '@/types/Documents'
import { IUpdateDocumentPayload, IUploadDocumentPayload } from './components/UploadDocumentModal/interfaces'
import {
  deleteDocument,
  downloadDocument,
  fetchDocuments,
  reloadDocuments,
  setDocuments,
  setPending,
  updateDocument,
  uploadDocument,
} from './actions'
import { EntityVersionGetter } from './helpers'
import { IFetchDocumentsPayload, IFileDownloadPayload } from './interfaces'

const DocumentsServices = {
  [EDocumentEntityType.MY_DOCUMENTS]: new OrganizationDocumentsService(),
  [EDocumentEntityType.ORDERS]: new OrderDocumentsService(),
  [EDocumentEntityType.REQUESTS]: new SaleRequestDocumentsService(),
}

const onUploadDocumentDoMakeRequest: Epic<IUploadDocumentPayload, typeof uploadDocument> = (action$, state$) =>
  action$.pipe(
    filter(uploadDocument.match),
    mergeMap(({ payload }) => {
      const state = state$.value
      const { documentFile, documentName, documentType, entity, entityId } = payload
      const requestStatus = { name: `uploadDocument_${documentType}` }

      return concat(
        of(requestStarted(requestStatus)),
        from(
          DocumentsServices[entity].uploadDocument({
            id: entityId!,
            file: documentFile,
            version: EntityVersionGetter[entity]?.(state),
            name: documentName,
            type: documentType,
          }),
        ).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [requestFinished(requestStatus), closeModal(), fetchDocuments({ entity, entityId })]
          }),
          catchError(() => [closeModal(), requestFailed(requestStatus)]),
        ),
      )
    }),
  )

const onUpdateDocumentDoMakeRequest: Epic<IUpdateDocumentPayload, typeof updateDocument> = (action$, state$) =>
  action$.pipe(
    filter(updateDocument.match),
    mergeMap(({ payload }) => {
      const state = state$.value
      const { documentFile, documentName, document, entityId, entity, orderId } = payload
      const { type, id } = document
      const requestStatus = { name: `updateDocument_${type}` }

      return concat(
        of(requestStarted(requestStatus)),
        from(
          DocumentsServices[entity].updateDocument({
            documentId: id,
            id: entityId!,
            file: documentFile,
            version: EntityVersionGetter[entity]?.(state),
            name: documentName,
            type,
          }),
        ).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [
              requestFinished(requestStatus),
              closeModal(),
              fetchDocuments({ entityId, entity }),
              orderId ? fetchAgrarianOrder({ id: orderId!, shouldNotShowLoadingState: true }) : undefined,
            ]
          }),
          catchError(() => [closeModal(), requestFailed(requestStatus)]),
        ),
      )
    }),
  )

const onDownloadDocumentDoMakeRequest: Epic<IFileDownloadPayload, typeof downloadDocument> = action$ =>
  action$.pipe(
    filter(downloadDocument.match),
    mergeMap(({ payload }) => {
      const { url, fileName, type, entity, id } = payload
      const requestStatus = { name: `downloadDocument_${id}` }

      return concat(
        of(requestStarted(requestStatus)),
        from(DocumentsServices[entity].downloadDocument(url, fileName)).pipe(
          map(() => requestFinished(requestStatus)),
          catchError(() => of(requestFailed(requestStatus))),
        ),
      )
    }),
  )

const onFetchDocumentsDoMakeRequest: Epic<IFetchDocumentsPayload, typeof fetchDocuments> = action$ =>
  action$.pipe(
    filter(fetchDocuments.match),
    mergeMap(({ payload }) => {
      const { entity, entityId } = payload

      return from(DocumentsServices[entity].fetchDocuments(entityId!)).pipe(
        mergeMap(response => (isStatusOk(response) ? [setDocuments(response.data)] : throwError(null))),
        catchError(() => [setPending(false)]),
      )
    }),
  )

const onReloadDocumentsDoSetReloadingByInterval: Epic<IFetchDocumentsPayload, typeof reloadDocuments> = (action$, state$) =>
  action$.pipe(
    filter(reloadDocuments.match),
    delayWhen(() => timer(10000)),
    filter(({ payload }) => {
      const { value: state } = state$

      return (getLocation()(state$.value).pathname || '').includes(String(payload.entityId)) && getLocationQuery(state)?.tab === 'documents'
    }),
    switchMap(({ payload }) => [fetchDocuments({ ...payload, shouldNotShowLoadingState: true }), reloadDocuments(payload)]),
  )

const onDeleteDocumentDoMakeRequest: Epic<IDocument, typeof deleteDocument> = (action$, state$) =>
  action$.pipe(
    filter(deleteDocument.match),
    mergeMap(({ payload }) => {
      const state = state$.value
      const { id, entityId, entity, type } = payload
      const requestStatus = { name: `deleteDocument_${type}` }

      return concat(
        of(requestStarted(requestStatus)),
        from(
          DocumentsServices[entity].deleteDocument({ id: entityId!, documentId: id, version: EntityVersionGetter[entity]?.(state) }),
        ).pipe(
          mergeMap(response =>
            isStatusOk(response) ? [fetchDocuments({ entityId, entity }), requestFinished(requestStatus)] : throwError(null),
          ),
          catchError(() => of(requestFailed(requestStatus))),
        ),
      )
    }),
  )

export default (<any>combineEpics)(
  onUploadDocumentDoMakeRequest,
  onUpdateDocumentDoMakeRequest,
  onFetchDocumentsDoMakeRequest,
  onDeleteDocumentDoMakeRequest,
  onDownloadDocumentDoMakeRequest,
  onReloadDocumentsDoSetReloadingByInterval,
)
