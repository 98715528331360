import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetUserJwtQueryResponse, GetUserJwtQueryParams } from "../types/GetUserJwt";

export const getUserJwtQueryKey = (params: GetUserJwtQueryParams) =>     [{url:`api/ms-content-mobile-service-backend/api/v1/user/token`},...(params ? [params] : [])] as const;
      export function getUserJwtQueryOptions <TData = GetUserJwtQueryResponse, TError = unknown>(params: GetUserJwtQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getUserJwtQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `api/ms-content-mobile-service-backend/api/v1/user/token`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link api/ms-content-mobile-service-backend/api/v1/user/token
 */

export function useGetUserJwt <TData = GetUserJwtQueryResponse, TError = unknown>(params: GetUserJwtQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getUserJwtQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getUserJwtQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
