import React, { FC } from 'react'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Fading } from '../animations'
import { Button } from '..'
import { IClosableMessageProps } from './interfaces'
import './сlosableMessage.scss'

const ClosableMessage: FC<IClosableMessageProps> = ({ children, onClose, isVisible, className }) => (
  <Fading isVisible={isVisible}>
    <div className={classNames('closable-message', className)}>
      {children}
      {onClose && <Button className="closable-message__close-button" isUnstyled onClick={onClose} iconProps={{ name: EIconName.Exit }} />}
    </div>
  </Fading>
)

export default ClosableMessage
