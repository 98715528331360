import { createAsyncThunk } from '@reduxjs/toolkit'
import { isStatusOk } from '@/api/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { IThunkProps } from '@/redux/interfaces'
import { CatalogService } from '@/services/CatalogService'
import { IAsyncThunkFetchProductCatalogPayload } from '@/types/Catalog'
import { PRODUCTS_PER_PAGE } from './constants'
import type { IAsyncThunkFetchProductCatalogResponse } from './types'
import { mapCatalogPayloadToFetchRequestParams, mapFetchRequestResponseToStoreState } from './utils'

const catalogService = new CatalogService()

export const fetchAgriculturalProductCatalog = createAsyncThunk<
  IAsyncThunkFetchProductCatalogResponse,
  IAsyncThunkFetchProductCatalogPayload,
  IThunkProps
>('@@agriculturalProductsCatalog/FETCH_PRODUCT_CATALOG', async (payload, { rejectWithValue, getState }) => {
  const { apiUrl = '', signal } = payload

  const state = getState()

  const regions = getUserRegion()(state)?.code
  const params = mapCatalogPayloadToFetchRequestParams(payload, { perPage: PRODUCTS_PER_PAGE }, { regions })

  const response = await catalogService.fetchCatalog(params, state, { apiUrl, signal })

  if (isStatusOk(response)) return mapFetchRequestResponseToStoreState(response.data, payload)

  // todo: костыль кривой типизации `requestData`
  if ((response.meta.message as unknown) instanceof Error) {
    throw response.meta.message
  }

  return rejectWithValue(response)
})
