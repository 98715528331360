import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetTemplateDataByIdMutationRequest, GetTemplateDataByIdMutationResponse, GetTemplateDataByIdHeaderParams } from "../../types/GetTemplateDataById";

/**
     * @link /api/document/purchase-offer/v1/getTemplateDataById
     */
export async function getTemplateDataById (data: GetTemplateDataByIdMutationRequest, headers?: GetTemplateDataByIdHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetTemplateDataByIdMutationResponse>["data"]> {
    const { data: resData } = await client<GetTemplateDataByIdMutationResponse, GetTemplateDataByIdMutationRequest>({
        method: "post",
        url: `/api/document/purchase-offer/v1/getTemplateDataById`,
        data,
        headers: { ...headers, ...options.headers },
        ...options
    });
    
    return resData;
};