/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MonetaryAmountData } from './MonetaryAmountData';

export type CreateFundingRequestData = {
    productIds: Array<string>;
    skuId?: string;
    phone: string;
    email?: string;
    vtbClient?: boolean;
    loanAmount?: MonetaryAmountData;
    contactPersonPosition?: string;
    organizationInn?: string;
    organizationName?: string;
    city?: string;
    cropsArea?: number;
    desiredAdvancePaymentDate?: string;
    grossHarvest?: number;
    comment?: string;
    regionCode?: number;
    agreement?: boolean;
    fiasCode?: string;
    offerId?: string;
    fetilizerOrderId?: number;
    creationMethod?: CreateFundingRequestData.creationMethod;
    assignedOperatorId?: string;
};

export namespace CreateFundingRequestData {

    export enum creationMethod {
        IMPERSONATED = 'IMPERSONATED',
        BY_USER = 'BY_USER',
        BY_OPERATOR = 'BY_OPERATOR',
        AUTO_GENERATION_SBER = 'AUTO_GENERATION_SBER',
        BY_USER_MOBILE = 'BY_USER_MOBILE',
    }


}

