import { CanceledError } from 'axios'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { createAxiosClient } from './axiosFactory'

const { isSsr } = ExecutionEnvironment
const { NODE_ENV } = process.env
const IS_DEV = NODE_ENV !== 'production'
const ssrUrl =
  isSsr && IS_DEV
    ? `http://${process.env.CUSTOM_ENV}-ms-integration-dadata-backend.dev.poletech.io/api`
    : `http://ms-integration-dadata-backend/api`

const baseURL = isSsr ? ssrUrl : '/api/ms-integration-dadata-backend'
export const dadataAxiosClient = createAxiosClient({
  baseURL,
})

export * from './types'
export { CanceledError }
export default dadataAxiosClient
