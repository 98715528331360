import { createAction } from '@reduxjs/toolkit'
import { ICart } from '@/types/Cart'
import { loadData, loadingDataFinished } from '../data/actions'
import { IAddProductToCartPayload, IClearCartPayload, TChangeCartItemQuantityPayload, TDeleteICartItemPayload } from './interfaces'

export const fetchMultiCart = () => loadData({ name: 'multiCart', shouldLoadForcibly: true })
export const fetchCart = (cartId: string) => loadData({ name: 'cart', shouldLoadForcibly: true, params: { cartId } })
export const setCartData = (data: ICart) => loadingDataFinished({ name: 'cart', data })
export const addProductToCart = createAction<IAddProductToCartPayload>('@@cart/ADD_PRODUCT_TO_CART')
export const deleteCartItem = createAction<TDeleteICartItemPayload>('@@cart/DELETE_ITEM')
export const changeCartItemQuantity = createAction<TChangeCartItemQuantityPayload>('@@cart/CHANGE_ITEM_QUANTITY')
export const clearCart = createAction<IClearCartPayload>('@@cart/CLEAR_CART')
