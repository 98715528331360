import { IUserGtmInfoPayload } from '@/interfaces'
import { EFinancingProductId, ELoanProductName, ELoanProductOption } from '@/types'
import { TBooleanAsBinaryNumber } from '@/types/TBooleanAsBinary'

export interface IBaseGtmEventFinancing extends IUserGtmInfoPayload {
  event: string
  regionSelected?: string
  eventCategory: 'interactions' | 'conversions'
  eventAction: 'click' | 'input' | 'backgroundProcess' | 'viewing' | 'formSend'
  eventNonInteraction?: TBooleanAsBinaryNumber
  blockName: Maybe<string>
  eventLabel: Maybe<string>
  eventContext: Maybe<string>
  fundingProduct?: EFinancingProductId
  requestID?: Maybe<string>
  targetRequestID?: Maybe<string>
  pagePart?: string
  eventEcommerce?: string | null
  userSegment?: string | null
  ecommerce?: object | null
}

export interface ILeasingFormGtmEvent extends Partial<IBaseGtmEventFinancing> {
  firingOptions: string
}

export interface ILendingFinancingOfferGtmEvent extends Partial<IBaseGtmEventFinancing> {
  firingOptions: string
}

export interface IFinancingProductInfo {
  product: string
  productNameOnLending?: string
  bank: string
}

export enum EGtmBlock {
  PRODUCT = 'blockFinancialProduct',
  FORM = 'blockLoanApplicationForm',
  FILTER = 'blockFinancialProductFilter',
  SUCCESS = 'blockLoanApplicationFormSuccessPopup',
  FAQ = 'blockFinancialFAQ',
  RESPONSES = 'blockFinancialResponses',
}

export enum EGtmProductName {
  MARKETPLACE = 'MARKETPLACE',
}

export enum EGtmLabel {
  ACTION = 'financing-application-actions_button',
  BUTTON = 'financing-detail_button',
  TITLE = 'financing-detail_title',
  LOGO = 'financing-detail_logo-logo',
  INSTALLMENT = 'installment-product_button',
  RESET_FILTER = 'resetFilter',
  REGION_SELECTOR = 'regionSelector',
  INPUTS = 'inputs',
  OUTPUTS = 'outputs',
  IMPROVE = 'improve',
  JOURNAL = 'journal',
  LK = 'lk',
  ARROW_LEFT = 'arrowLeft',
  ARROW_RIGHT = 'arrowRight',
  CONTROL_GROUP = '01_control_group',
  EXPERIMENTAL_GROUP = '01_experimental_group',
}

export enum EGtmAction {
  CLICK = 'click',
  VIEWING = 'viewing',
  BACKGROUND = 'backgroundProcess',
  INPUT = 'input',
}

export interface IFinancingGtm {
  blockName: EGtmBlock
  fundingProduct?: Maybe<ELoanProductName | EGtmProductName | EFinancingProductId>
  eventLabel: Maybe<EGtmLabel | ELoanProductOption | string>
  eventAction?: EGtmAction
}
