import { createAsyncThunk } from '@reduxjs/toolkit'
import { isStatusOk } from '@/api/helpers'
import { requestData } from '@/api/request'
import { IThunkProps } from '@/redux/interfaces'
import { loadingDataStarted, NDataAction } from './actions'
import { apiUrls } from './apiUrls'
import { getTransformedResponse } from './helpers'
import { IFetchDataPayload, IGettingAsyncThunkParams, TGetAsyncThunkAction, TGettingAsyncThunkParams } from './interfaces'
import { isApiUrlName } from './typeguard'

export const fetchData = createAsyncThunk<NDataAction.ILoadingDataFinished, IFetchDataPayload, IThunkProps>(
  '@@data/FETCH_DATA',
  async (payload, thunkAPI) => {
    const { params = {}, apiUrl = '', name, shouldFetch, shouldAccumulate } = payload
    const { getState, rejectWithValue, dispatch } = thunkAPI
    const state = getState()
    if (shouldFetch !== undefined && !shouldFetch(payload, state)) return { name, data: undefined }

    dispatch(loadingDataStarted({ name }))

    const url = `${apiUrl}${apiUrls[name]?.(params, state)}`
    const response = await requestData<any>({ url, method: 'GET', state })
    const { data, meta } = response
    if (!isStatusOk(response)) return rejectWithValue({ name, meta })

    return { name, data: getTransformedResponse(name, data)(state), shouldAccumulate }
  },
)

export const getAsyncThunks = (...params: TGettingAsyncThunkParams): TGetAsyncThunkAction[] =>
  params.map(config => asyncThunkPayload => {
    const configResult: IGettingAsyncThunkParams = isApiUrlName(config) ? { name: config } : config
    const payload: IFetchDataPayload = {
      ...asyncThunkPayload,
      ...configResult,
    }

    if (!isApiUrlName(config)) {
      payload.params = {
        ...asyncThunkPayload.params,
        ...configResult.params,
      }
    }

    return fetchData(payload)
  })
