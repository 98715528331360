import React, { useState } from 'react'
import { useController } from 'react-hook-form'
import classNames from 'classnames'
import { Radio, RadioGroup } from 'rsuite'
import { IHookFormRadioButtonsGroupProps } from './types'

export const HookFormRadioButtonsGroup: React.FC<IHookFormRadioButtonsGroupProps> = ({ name, control, rules, options, className }) => {
  const { field } = useController({
    name,
    control,
    rules,
  })

  const [radioGroupKey, setRadioGroupKey] = useState<number>(0)

  const unselectRadioButton = (value: string) => {
    if (value === field.value) {
      field.onChange('')
      setRadioGroupKey(radioGroupKey + 1)
    }
  }

  const onRadioButtonChange = (value: string) => {
    field.onChange(value)
  }

  return (
    <div className="radio-buttons-group">
      <RadioGroup
        key={radioGroupKey}
        name={name}
        inline
        appearance="picker"
        onChange={onRadioButtonChange}
        className={className}
        value={field.value}
      >
        {options.map(({ value, name: optionName }, i) => (
          <Radio value={value} key={`${value}-${i}`} className={classNames({ 'rs-radio-checked': field.value === value })}>
            <span onClick={() => unselectRadioButton(value)}>{optionName}</span>
          </Radio>
        ))}
      </RadioGroup>
    </div>
  )
}
