import { combineEpics } from 'redux-observable'
import { BehaviorSubject, catchError, mergeMap } from 'rxjs'
import { Epic } from '../interfaces'
import agriculturalProductGtmEpics from './agriculturalProduct/epics'
import appGtmEpics from './app/epics'
import cartGtmEpics from './cart/epics'
import catalogGtmEpics from './catalog/epics'
import harvestGtmEpics from './harvest/epics'

const gtmEpics: Epic<undefined, undefined>[] = [appGtmEpics, cartGtmEpics, harvestGtmEpics, catalogGtmEpics, agriculturalProductGtmEpics]

const epic$ = new BehaviorSubject((<any>combineEpics)(...gtmEpics))

const gtmEpic: Epic<undefined, undefined> = (action$, state$) =>
  epic$.pipe(
    mergeMap(epic => epic(action$, state$, {})),
    catchError((error, source) => {
      // eslint-disable-next-line no-console
      if (console) console.error(error)

      return source
    }),
  )

export default gtmEpic
