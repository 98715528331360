import { ISkeletonLoaderProps } from '@frontend/pole-ui/lib/components/Skeleton'

export const wideArticlePreviewSkeleton: ISkeletonLoaderProps = {
  className: 'journal-articles-item wide-article-preview-link wide-article-preview',
  width: null,
  height: null,
  styleName: 'white',
  skeletons: [
    { className: 'wide-article-preview__image space-holder24-sm', height: null, width: null },
    {
      className: 'wide-article-preview-content',
      height: null,
      width: null,
      styleName: 'white',
      style: { flexDirection: 'column' },
      skeletons: [{ className: 'space-holder16 h2' }, { className: 'text_large' }],
    },
  ],
}
