import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DoCalculateMutationRequest, DoCalculateMutationResponse } from "../types/DoCalculate";

/**
 * @link /api/credit-flow-backend/v1/advance/do_calculate
 */

export function useDoCalculate <TData = DoCalculateMutationResponse, TError = unknown, TVariables = DoCalculateMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/credit-flow-backend/v1/advance/do_calculate`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
