import React, { FC, useState } from 'react'
import classNames from 'classnames'
import { Button } from '..'
import { ICollapsingBlock } from './interfaces'
import './styles.scss'

const CollapsingBlock: FC<ICollapsingBlock> = props => {
  const {
    title,
    titleId,
    children,
    headingClass = 'h5',
    className,
    contentClassName,
    animation,
    isOpenButtonOnLeft = false,
    expandedOnMount,
    onExpand,
    buttonClassName,
    toggleClassName,
    isIconButtonWhite,
  } = props

  const [isExpanded, setIsExpanded] = useState(Boolean(expandedOnMount))

  const onClick = () => {
    if (!isExpanded) onExpand?.()
    setIsExpanded(!isExpanded)
  }

  const toggle = <span className={toggleClassName} />

  return (
    <div className={classNames(`collapsing-block`, className, isExpanded && `${className}--active`)} {...animation}>
      <Button
        modifiers={['clickable', 'full-width']}
        textId={titleId}
        className={classNames(
          {
            'collapsing-block__button-left': isOpenButtonOnLeft,
            'collapsing-block__button': !isOpenButtonOnLeft,
            'collapsing-block__button_active': isExpanded && !isOpenButtonOnLeft,
            'collapsing-block__button-left_active': isExpanded && isOpenButtonOnLeft,
            'collapsing-block__button-white': isIconButtonWhite,
          },
          buttonClassName,
          headingClass,
        )}
        onClick={onClick}
        isUnstyled
      >
        {isOpenButtonOnLeft && toggle}
        {title}
        {!isOpenButtonOnLeft && toggle}
      </Button>
      <div className={classNames(contentClassName, 'collapsing-block-content', { 'collapsing-block-content--expanded': isExpanded })}>
        {children}
      </div>
    </div>
  )
}

export default CollapsingBlock
