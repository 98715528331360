import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddSealToPdfDocumentMutationRequest, AddSealToPdfDocumentMutationResponse } from "../types/AddSealToPdfDocument";

/**
 * @link /api/document/template/v1/update_add-seal-to-document
 */

export function useAddSealToPdfDocument <TData = AddSealToPdfDocumentMutationResponse, TError = unknown, TVariables = AddSealToPdfDocumentMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/document/template/v1/update_add-seal-to-document`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
