import { getCategoryFilters, getCategoryFiltersQueryKey } from '@/api/kubik/monolith'
import { isDesktop } from '@/logic/app/reducer'
import { hasParamInUrl } from '@/logic/data/helpers'
import { getAsyncThunks } from '@/logic/data/thunks'
import { betsVitrinaPrefetchSsrQueries } from '@/logic/index/bets/queries'
import { ssrPrefetchQueries } from '@/logic/index/ssrPrefetchQueries'
import {
  journalByRubricSsrQueries,
  journalByTagSsrQueries,
  journalIndexSsrQueries,
  journalNewsSsrQueries,
  journalPublicationSsrQueries,
} from '@/logic/journal/ssrQueries'
import MobileRedirect from '@/pages/MobileRedirect/MobileRedirect'
import { fetchPoolingLanding } from '@/pages/Pooling/thunks'
import {
  EAgriculturalProductsRoute,
  EAgroservicesRoute,
  ECartRoute,
  ECommonRoute,
  EFinancingOfferRoute,
  EHarvestSaleRoute,
  EInputsPromoLandingRoute,
  EJournalRoute,
  EPartnerLandingRoute,
  EPoolingRoute,
  EUserPermission,
  EUserProfileRoute,
} from '@/types'
import { ELegalDocumentsRoute } from '@/types/ELegalDocumentsRoute'
import { EOfferRequestsRoute } from '@/types/EOfferRequestsRoute'
import { EInfoportalAdministrationRoute } from '@/types/Infoportal'
import { fetchTemplates } from './logic/harvestSale/thunks'
import { fetchAgriculturalProduct } from './pages/AgriculturalProduct/thunks'
import { fetchAgriculturalProductCatalog } from './pages/AgriculturalProductCatalog/thunks'
import { vezdehodPromoLanding } from './pages/promo'
import { getFaqBreadcrumbs } from './pages/QuestionsAndAnswers/helpers'
import QuestionsAndAnswers from './pages/QuestionsAndAnswers/QuestionsAndAnswers'
import { EFaqRouteUrl } from './pages/QuestionsAndAnswers/types'
import { IRoute } from './interfaces'
import {
  AboutUs,
  AdvancePaymentOnFutureHarvest,
  AdvancePaymentOnFutureHarvestWithCalculator,
  AgriculturalMachinesAndEquipmentLeasing,
  AgriculturalProduct,
  AgriculturalProductCatalog,
  AgriculturalProducts,
  Agrocalc,
  Agroconsulting,
  Agroservices,
  AlfaLeasing,
  BusinessProcess,
  CenterInvestLoan,
  CulturesSaleLots,
  EfisZsn,
  Error404Page,
  Error500Page,
  ExperimentalFinancing,
  ExpressCredit,
  Faq,
  FaqSearch,
  FarmlandCropValuation,
  Feedback,
  FgisZerno,
  Financing,
  FinancingRedirect,
  FleetFinanceLeasing,
  HarvestSale,
  HarvestSaleLots,
  Impersonation,
  IndexPage,
  InfoportalAdministrationPage,
  Interleasing,
  InvestmentCredit,
  InvestSber,
  Journal,
  JournalNews,
  JournalPublication,
  JournalRedirectToLatestNews,
  LegalDocumentsPage,
  LombardCreditAlfaBank,
  Loyalty,
  MakingOrderDetails,
  MFOPage,
  MultiCart,
  OborotSber,
  OfferRequests,
  PartnerLanding,
  PartnerLandingRedirect,
  PayOnline,
  PoleDiff,
  PoleExpert,
  PolePlus,
  PoleScouting,
  Pooling,
  QrCode,
  Requisites,
  Revolving,
  SberAuthRedirect,
  SberLeasing,
  SberRassrochka,
  SeedingRateCalc,
  StoneXxiLeasing,
  TemporaryBasicAuthPage,
  TenderComparativeTable,
  TenderPublic,
  TenderPublicOfferForm,
  TransportAndSpecialEquipmentLeasing,
  UserProfile,
  YouDo,
} from './pages'

const fetchAgroserviceProducts = getAsyncThunks('agroserviceProducts')[0]!

export const routes: IRoute[] = [
  {
    key: 'index-page',
    path: ECommonRoute.main,
    exact: true,
    component: IndexPage,
    ssrPrefetchQueries,
    metaInfo: {
      titleId: 'metaInfo.indexPage.title',
    },
  },
  {
    key: 'agricultural-products',
    path: EAgriculturalProductsRoute.main,
    exact: true,
    component: AgriculturalProducts,
    ssrPrefetchQueries: () => [{ queryFn: getCategoryFilters, queryKey: getCategoryFiltersQueryKey() }],
    metaInfo: {
      titleId: 'metaInfo.agriculturalProductsPage.title',
    },
  },
  {
    key: 'agricultural-product-catalog',
    path: EAgriculturalProductsRoute.category,
    exact: true,
    component: AgriculturalProductCatalog,
    asyncThunk: [fetchAgriculturalProductCatalog],
  },
  {
    key: 'agricultural-product',
    path: EAgriculturalProductsRoute.product,
    exact: true,
    component: AgriculturalProduct,
    asyncThunk: [fetchAgriculturalProduct],
  },
  {
    key: 'user-profile',
    path: EUserProfileRoute.main,
    component: UserProfile,
    shouldBeAuthenticated: true,
    metaInfo: {
      titleId: 'metaInfo.userProfilePage.title',
      robots: 'noindex',
    },
    permissions: [EUserPermission.AGRARIAN, EUserPermission.BUYER, EUserPermission.SUPPLIER, EUserPermission.LOGISTICS_OPERATOR],
  },
  // DEPRECATED. Пожалуйста не используйте
  {
    key: 'harvestSaleLotsOld',
    path: `${EHarvestSaleRoute.main}/lots/:cultureId/`,
    exact: true,
    component: HarvestSaleLots,
    asyncThunk: [fetchTemplates, ...getAsyncThunks({ name: 'cultureIndicators', shouldFetch: hasParamInUrl('cultureId') }, 'cultures')],
  },
  {
    key: 'harvestSaleLots',
    path: `${EHarvestSaleRoute.main}/:cultureId/lots`,
    exact: true,
    component: HarvestSaleLots,
    asyncThunk: [fetchTemplates, ...getAsyncThunks({ name: 'cultureIndicators', shouldFetch: hasParamInUrl('cultureId') }, 'cultures')],
  },
  {
    key: 'CulturesSaleLots',
    path: `${EHarvestSaleRoute.main}/:cultureId`,
    exact: true,
    component: CulturesSaleLots,
    asyncThunk: [fetchTemplates, ...getAsyncThunks({ name: 'cultureIndicators', shouldFetch: hasParamInUrl('cultureId') }, 'cultures')],
  },
  {
    key: 'Pooling',
    path: EPoolingRoute.POOLING,
    exact: true,
    component: Pooling,
    asyncThunk: [fetchPoolingLanding],
  },
  {
    key: 'harvestSale',
    path: EHarvestSaleRoute.main,
    exact: true,
    component: HarvestSale,
    asyncThunk: getAsyncThunks('cultures', 'harvestSalePage'),
    metaInfo: {
      titleId: 'metaInfo.harvestSalePage.title',
    },
  },
  {
    key: 'offerRequests',
    path: EOfferRequestsRoute.main,
    exact: true,
    component: OfferRequests,
    ssrPrefetchQueries: betsVitrinaPrefetchSsrQueries,
    metaInfo: {
      titleId: 'metaInfo.offerRequestsPage.title',
    },
  },
  {
    key: 'qr-code',
    path: ECommonRoute.qrCode,
    exact: true,
    component: QrCode,
    metaInfo: {
      titleId: 'metaInfo.qrCode.title',
      robots: 'noindex',
    },
  },
  {
    key: 'pay-online',
    path: ECommonRoute.payOnline,
    exact: true,
    component: PayOnline,
    metaInfo: {
      titleId: 'metaInfo.payOnline.title',
      robots: 'noindex',
    },
  },
  {
    key: 'error-500',
    path: ECommonRoute.error500,
    exact: true,
    component: Error500Page,
    metaInfo: {
      titleId: 'metaInfo.500pagePage.title',
      robots: 'noindex',
    },
  },
  {
    key: 'error-404',
    path: ECommonRoute.error404,
    exact: true,
    component: Error404Page,
    metaInfo: {
      titleId: 'metaInfo.404pagePage.title',
      robots: 'noindex',
    },
  },
  {
    key: 'making-order-details',
    path: `${ECartRoute.makingOrderDetails}/:cartId`,
    exact: true,
    component: MakingOrderDetails,
    shouldBeAuthenticated: true,
    metaInfo: {
      titleId: 'metaInfo.orderDetailPage.title',
      robots: 'noindex',
    },
  },
  {
    key: 'multi-cart',
    path: ECartRoute.multiCart,
    exact: true,
    component: MultiCart,
    shouldBeAuthenticated: true,
    metaInfo: {
      titleId: 'metaInfo.multiCart.title',
      robots: 'noindex',
    },
  },
  {
    key: 'impersonation',
    path: ECommonRoute.impersonation,
    exact: true,
    component: Impersonation,
    shouldBeAuthenticated: true,
    permissions: [EUserPermission.ADMIN],
    metaInfo: {
      titleId: 'metaInfo.impersonationPage.title',
      robots: 'noindex',
    },
  },
  {
    key: 'temporaryBasicAuthPage',
    path: ECommonRoute.temporaryBasicAuthPage,
    exact: true,
    component: TemporaryBasicAuthPage,
    metaInfo: {
      titleId: 'metaInfo.temporaryBasicAuthPage.title',
      robots: 'noindex',
    },
  },
  {
    key: 'financing',
    path: EFinancingOfferRoute.main,
    exact: true,
    component: Financing,
    asyncThunk: getAsyncThunks('expressCreditWithRedirection'),
    metaInfo: {
      titleId: 'metaInfo.financing.title',
    },
  },
  {
    key: 'finances',
    path: EFinancingOfferRoute.experimentalMain,
    exact: true,
    component: ExperimentalFinancing,
    asyncThunk: getAsyncThunks('expressCreditWithRedirection'),
    metaInfo: {
      titleId: 'metaInfo.financing.title',
    },
  },
  {
    key: 'agroservices-calc',
    path: EAgroservicesRoute.agrocalc,
    exact: true,
    component: Agrocalc,
    metaInfo: {
      titleId: 'metaInfo.agroservices.agrocalc.title',
    },
  },
  {
    key: 'agroservices-seeding-rate',
    path: EAgroservicesRoute.seedingRateCalc,
    exact: true,
    component: SeedingRateCalc,
    metaInfo: {
      titleId: 'metaInfo.agroservices.seedingRate.title',
    },
  },
  {
    key: 'agroservices-fgis-zerno',
    path: EAgroservicesRoute.fgisZerno,
    exact: true,
    component: FgisZerno,
    metaInfo: {
      titleId: 'metaInfo.agroservices.fgis.title',
    },
  },
  {
    key: 'agroservices-poleexpert',
    path: EAgroservicesRoute.poleExpert,
    exact: true,
    component: PoleExpert,
    asyncThunk: fetchAgroserviceProducts,
    metaInfo: {
      titleId: 'agroserviceProductPage.title.poleExpert',
      descriptionId: 'poleExpertPage.header.text',
    },
  },
  {
    key: 'agroservices-polescouting',
    path: EAgroservicesRoute.poleScouting,
    exact: true,
    component: PoleScouting,
    asyncThunk: fetchAgroserviceProducts,
    metaInfo: {
      titleId: 'agroserviceProductPage.title.poleScouting',
      descriptionId: 'poleScoutingPage.header.text',
    },
  },
  {
    key: 'agroservices-polediff',
    path: EAgroservicesRoute.poleDiff,
    exact: true,
    component: PoleDiff,
    asyncThunk: getAsyncThunks('agroserviceProducts'),
    metaInfo: {
      titleId: 'agroserviceProductPage.title.poleDiff',
      descriptionId: 'poleDiffPage.header.text',
    },
  },
  {
    key: 'agronout-farmland-crop-valuation',
    path: EAgroservicesRoute.farmlandCropValuation,
    exact: true,
    component: FarmlandCropValuation,
    asyncThunk: fetchAgroserviceProducts,
    metaInfo: {
      titleId: 'agroserviceProductPage.title.farmlandCropValuation',
      descriptionId: 'farmlandCropValuation.header.text',
    },
  },
  {
    key: 'agroservices-poleplus',
    path: EAgroservicesRoute.polePlus,
    exact: true,
    component: PolePlus,
    asyncThunk: fetchAgroserviceProducts,
    metaInfo: {
      titleId: 'agroserviceProductPage.title.polePlus',
      descriptionId: 'polePlusPage.header.text',
    },
  },
  {
    key: 'express',
    path: EFinancingOfferRoute.expressCredit,
    exact: true,
    component: ExpressCredit,
    asyncThunk: getAsyncThunks('expressCreditWithRedirection'),
  },
  {
    key: 'financingRedirect',
    path: [
      EFinancingOfferRoute.deprecatedMain,
      EFinancingOfferRoute.experimentalOldUrl,
      EFinancingOfferRoute.expressCreditAlfaBank,
      EFinancingOfferRoute.revolvingTinkoff,
      EFinancingOfferRoute.baltiyskiyLeasing,
    ],
    exact: true,
    component: FinancingRedirect,
    metaInfo: {
      robots: 'noindex',
    },
  },
  {
    key: 'sberRassrochka',
    path: EFinancingOfferRoute.sberRassrochka,
    exact: true,
    component: SberRassrochka,
  },
  {
    key: 'investment',
    path: EFinancingOfferRoute.investmentCredit,
    exact: true,
    component: InvestmentCredit,
  },
  {
    key: 'investSber',
    path: EFinancingOfferRoute.investSber,
    exact: true,
    component: InvestSber,
  },
  {
    key: 'advancePaymentOnFutureHarvest',
    path: EFinancingOfferRoute.advancePaymentOnFutureHarvest,
    exact: true,
    component: AdvancePaymentOnFutureHarvest,
  },
  {
    key: 'advancePaymentOnFutureHarvestWithCalculator',
    path: EFinancingOfferRoute.advancePaymentOnFutureHarvestWithCalculator,
    exact: true,
    component: AdvancePaymentOnFutureHarvestWithCalculator,
  },
  {
    key: 'agriculturalMachinesAndEquipmentLeasing',
    path: EFinancingOfferRoute.agriculturalMachinesAndEquipmentLeasing,
    exact: true,
    component: AgriculturalMachinesAndEquipmentLeasing,
  },
  {
    key: 'alfabankLeasing',
    path: EFinancingOfferRoute.alfaLeasing,
    exact: true,
    component: AlfaLeasing,
  },
  {
    key: 'lombardCreditAlfaBank',
    path: EFinancingOfferRoute.lombardCreditAlfaBank,
    exact: true,
    component: LombardCreditAlfaBank,
  },
  {
    key: 'interleasing',
    path: EFinancingOfferRoute.interleasing,
    exact: true,
    component: Interleasing,
  },
  {
    key: 'sberLeasing',
    path: EFinancingOfferRoute.sberLeasing,
    exact: true,
    component: SberLeasing,
  },
  {
    key: 'stoneXxiLeasing',
    path: EFinancingOfferRoute.stoneXxiLeasing,
    exact: true,
    component: StoneXxiLeasing,
  },
  {
    key: 'agroconsulting',
    path: EAgroservicesRoute.agroconsulting,
    exact: true,
    component: Agroconsulting,
  },
  {
    key: 'revolving',
    path: EFinancingOfferRoute.revolving,
    exact: true,
    component: Revolving,
  },
  {
    key: 'oborotSber',
    path: EFinancingOfferRoute.oborotSber,
    exact: true,
    component: OborotSber,
  },
  {
    key: 'MFOPage',
    path: EFinancingOfferRoute.MFOPage,
    exact: true,
    component: MFOPage,
  },
  {
    key: 'transportAndSpecialEquipmentLeasing',
    path: EFinancingOfferRoute.transportAndSpecialEquipmentLeasing,
    exact: true,
    component: TransportAndSpecialEquipmentLeasing,
  },
  {
    key: 'CenterInvestLoan',
    path: EFinancingOfferRoute.centerInvestLoan,
    exact: true,
    component: CenterInvestLoan,
  },
  {
    key: 'fleetFinanceLeasing',
    path: EFinancingOfferRoute.fleetFinanceLeasing,
    exact: true,
    component: FleetFinanceLeasing,
  },
  {
    key: 'agroservices',
    path: EAgroservicesRoute.main,
    exact: true,
    component: Agroservices,
    asyncThunk: fetchAgroserviceProducts,
    metaInfo: {
      titleId: 'metaInfo.agroservices.title',
    },
  },
  {
    key: 'feedback',
    path: ECommonRoute.feedback,
    component: Feedback,
    metaInfo: {
      titleId: 'metaInfo.feedback.title',
    },
  },
  {
    key: 'faqList',
    path: [EFaqRouteUrl.QUESTION, EFaqRouteUrl.SPECIALIZATION, EFaqRouteUrl.CATEGORY, EFaqRouteUrl.AUTHOR],
    component: QuestionsAndAnswers,
    asyncThunk: getAsyncThunks(
      'faqCategories',
      { name: 'faqCategoryQuestions', shouldFetch: hasParamInUrl('specializationId') },
      {
        name: 'faqCategory',
        shouldFetch: (params, state) => {
          const hasCategoryIdInUrl = hasParamInUrl('categoryId')(params)

          return (hasCategoryIdInUrl && isDesktop()(state)) || (hasCategoryIdInUrl && !hasParamInUrl('specializationId')(params))
        },
      },
      { name: 'faqSpecialization', shouldFetch: (params, state) => hasParamInUrl('specializationId')(params) && !isDesktop()(state) },
      { name: 'faqArticle', shouldFetch: hasParamInUrl('questionId') },
      { name: 'faqAuthor', shouldFetch: hasParamInUrl('authorId') },
    ),
    getBreadcrumbs: getFaqBreadcrumbs,
    metaInfo: {
      titleId: 'metaInfo.faq.title',
    },
  },
  {
    key: 'faqSearch',
    component: FaqSearch,
    path: EFaqRouteUrl.SEARCH,
    asyncThunk: getAsyncThunks('faqQuestions'),
  },
  {
    key: 'faq',
    path: '/faq',
    exact: true,
    asyncThunk: getAsyncThunks('faqCategories'),
    component: Faq,
    metaInfo: {
      titleId: 'metaInfo.faq.title',
    },
  },
  {
    key: 'loyalty',
    path: EUserProfileRoute.loyalty,
    exact: true,
    shouldBeAuthenticated: true,
    component: Loyalty,
  },
  {
    key: 'infoportal',
    path: EInfoportalAdministrationRoute.MAIN,
    component: InfoportalAdministrationPage,
    shouldBeAuthenticated: true,
    permissions: [EUserPermission.ADMIN, EUserPermission.CONTENT_MANAGER],
    metaInfo: {
      titleId: 'metaInfo.infoportal.administration.title',
      robots: 'noindex',
    },
  },
  {
    key: 'requisites',
    path: '/requisites',
    component: Requisites,
    metaInfo: {
      titleId: 'metaInfo.requisites.title',
    },
  },
  {
    key: 'about-us',
    path: ECommonRoute.aboutUs,
    exact: true,
    component: AboutUs,
    metaInfo: {
      titleId: 'metaInfo.aboutUs.title',
      descriptionId: 'metaInfo.aboutUs.description',
    },
  },
  {
    key: 'journal-publication',
    path: `${EJournalRoute.PUBLICATION}/:publicationSlug`,
    exact: true,
    component: JournalPublication,
    ssrPrefetchQueries: journalPublicationSsrQueries,
  },
  {
    key: 'journal-news',
    path: [EJournalRoute.NEWS, `${EJournalRoute.NEWS}/:rubricId`],
    exact: true,
    component: JournalNews,
    ssrPrefetchQueries: journalNewsSsrQueries,
    metaInfo: {
      titleId: 'metaInfo.journalNews.title',
    },
  },
  {
    key: 'journal-news-latest',
    path: [`${EJournalRoute.NEWS}/:rubricId/latest`],
    exact: true,
    component: JournalRedirectToLatestNews,
  },
  {
    key: 'journal',
    path: [EJournalRoute.MAIN],
    exact: true,
    component: Journal,
    ssrPrefetchQueries: journalIndexSsrQueries,
    metaInfo: {
      titleId: 'metaInfo.journal.title',
    },
  },
  {
    key: 'journal-by-rubric',
    path: EJournalRoute.RUBRIC,
    exact: true,
    component: Journal,
    ssrPrefetchQueries: journalByRubricSsrQueries,
    metaInfo: {
      titleId: 'journal.metaInfo.categoryPage.title',
    },
  },
  {
    key: 'journal-by-tag',
    path: EJournalRoute.TAG,
    exact: true,
    component: Journal,
    ssrPrefetchQueries: journalByTagSsrQueries,
    metaInfo: {
      titleId: 'metaInfo.journal.title',
    },
  },
  {
    key: 'legalDocuments',
    path: ELegalDocumentsRoute.DOCUMENT_VERSION,
    exact: true,
    component: LegalDocumentsPage,
    metaInfo: {
      titleId: 'metaInfo.legalDocuments.title',
    },
  },
  {
    key: 'partnerLanding',
    path: EPartnerLandingRoute.registrationPartners,
    exact: true,
    component: PartnerLanding,
    metaInfo: {
      titleId: 'metaInfo.partnerLanding.title',
    },
  },
  {
    key: 'partnerLandingRedirect',
    path: EPartnerLandingRoute.buyer,
    exact: true,
    component: PartnerLandingRedirect,
    metaInfo: {
      robots: 'noindex',
    },
  },
  {
    key: 'vezdehodPromoLanding',
    path: EInputsPromoLandingRoute.vezdehod,
    exact: true,
    component: vezdehodPromoLanding,
    metaInfo: {
      titleId: 'metaInfo.vezdehodPromoLanding.title',
    },
  },
  {
    key: 'businessProcess',
    path: ECommonRoute.businessProcess,
    permissions: [EUserPermission.ADMIN],
    shouldBeAuthenticated: true,
    exact: true,
    component: BusinessProcess,
    asyncThunk: getAsyncThunks('statusModel'),
    metaInfo: {
      titleId: 'metaInfo.businessProcess.title',
    },
  },
  {
    key: 'SberAuthRedirect',
    path: ECommonRoute.sberAuthRedirect,
    exact: true,
    component: SberAuthRedirect,
  },
  {
    key: 'you-do',
    path: EAgroservicesRoute.youDo,
    exact: true,
    component: YouDo,
    metaInfo: {
      titleId: 'metaInfo.agroservices.title',
    },
  },
  {
    key: 'efis',
    path: EAgroservicesRoute.efisZsn,
    exact: true,
    component: EfisZsn,
    metaInfo: {
      titleId: 'metaInfo.agroservices.title',
    },
  },
  {
    key: 'tender-public',
    path: ECommonRoute.tenderPublic,
    exact: true,
    component: TenderPublic,
    metaInfo: {
      titleId: 'metaInfo.agroservices.title',
    },
  },
  {
    key: 'tender-public-comparative-table',
    path: ECommonRoute.tenderPublicComparativeTable,
    exact: true,
    component: TenderComparativeTable,
    metaInfo: {
      titleId: 'metaInfo.agroservices.title',
    },
  },

  {
    key: 'tender-public-offer-from',
    path: ECommonRoute.tenderPublicOfferForm,
    exact: true,
    component: TenderPublicOfferForm,
    metaInfo: {
      titleId: 'metaInfo.agroservices.title',
    },
  },
  {
    key: 'mobileRedirect',
    path: ECommonRoute.mobileRedirect,
    exact: true,
    component: MobileRedirect,
  },
]
