/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AuctionPayTypeData = {
    code: AuctionPayTypeData.code;
    name: string;
};

export namespace AuctionPayTypeData {

    export enum code {
        PREPAYMENT = 'PREPAYMENT',
        POSTPAYMENT = 'POSTPAYMENT',
        STAGED = 'STAGED',
    }


}

