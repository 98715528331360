import { OrderDataResult } from '@/api/kubik/monolith'

export enum ESignType {
  PEP = 'SIMPLE_SIGN_TYPE',
  UKEP = 'CRYPTO_SIGN_TYPE',
}

export interface ISelectSignTypeModalState {
  selectedType?: ESignType
  orderData?: OrderDataResult
}
