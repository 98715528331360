import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { IDocumentsResponse } from '@/services/documents/interfaces'
import { fetchDocuments, setDocuments, setPending } from './actions'
import { IDocumentsState, IFetchDocumentsPayload } from './interfaces'

const initialState: IDocumentsState = {
  isFetching: false,
  documents: [],
  types: [],
}

const reducer = createReducer(initialState, {
  [setPending.type]: (state, { payload }: PayloadAction<boolean>) => {
    state.isFetching = payload
  },
  [fetchDocuments.type]: (state, { payload }: PayloadAction<IFetchDocumentsPayload>) => {
    state.isFetching = !payload.shouldNotShowLoadingState
  },
  [setDocuments.type]: (state, { payload }: PayloadAction<IDocumentsResponse>) => {
    const { types, documents } = payload
    state.isFetching = false
    state.documents = documents
    state.types = types
  },
})

reducerManager.add('documents', reducer)

export const isFetching =
  () =>
  (state: RootState): boolean =>
    state.documents.isFetching
export const getDocuments = () => (state: RootState) => state.documents.documents
export const getTypes = () => (state: RootState) => state.documents.types
