import { requestData } from '@/api/request'
import { ECommonApiUrl } from '@/api/urls'
import { TIndexBanners } from '@/services/IndexBannersService/interfaces'
import { getMinioImagePath } from '@/utils/images'
import { getUrlWithQuery } from '@/utils/url'

export default class IndexBannersService {
  apiUrl: string

  constructor(apiUrl: string = '') {
    this.apiUrl = apiUrl
  }

  getBanners = async (regionCode?: string) => {
    const bannersResponse = await requestData<TIndexBanners>({
      url: getUrlWithQuery(`${this.apiUrl}${ECommonApiUrl.banners}`, regionCode ? { regionCode } : null),
      method: 'GET',
    })

    return bannersResponse.data.map(banner => ({
      ...banner,
      image: getMinioImagePath({ src: banner.image }),
    }))
  }
}
