import { createAction } from '@reduxjs/toolkit'
import { combineEpics } from 'redux-observable'
import { BehaviorSubject } from 'rxjs'
import { catchError, filter, mergeMap, takeUntil } from 'rxjs/operators'
import appEpics from '@/logic/app/epics'
import authEpics from '@/logic/auth/epics'
import cartEpics from '@/logic/cart/epics'
import dataEpics from '@/logic/data/epics'
import harvestSaleRequestEpics from '@/logic/harvestSaleRequest/epics'
import { Epic } from '@/logic/interfaces'
import metrikaEpic from '@/logic/metrika/epics'
import orderEpics from '@/logic/order/epics'
import profileEpics from '@/logic/profile/epics'

export const epicRegistry: Epic<undefined, undefined>[] = [
  appEpics,
  authEpics,
  cartEpics,
  metrikaEpic,
  profileEpics,
  dataEpics,
  orderEpics,
  harvestSaleRequestEpics,
]

export const rootEpic = (<any>combineEpics)(...epicRegistry)

export const epic$ = new BehaviorSubject(rootEpic)

export const rejectOldEpics = createAction('@@epics/REJECT_ALL_EPICS')

const rootEpicHandler: Epic<undefined, undefined> = (action$, state$) =>
  epic$.pipe(
    mergeMap(epic => epic(action$, state$, {}).pipe(takeUntil(action$.pipe(filter(rejectOldEpics.match))))),
    catchError((error, source) => {
      // eslint-disable-next-line no-console
      if (console) console.error(error)

      return source
    }),
  )

export default rootEpicHandler
