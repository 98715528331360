import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { useMultiCart } from '@/api/hooks/inputs/cart'
import { Loader } from '@/components/ui'
import { canUseCart } from '@/logic/app/reducer'
import { isAuthenticated } from '@/logic/auth/reducer'
import { fetchMultiCart } from '@/logic/cart/actions'
import { RootState } from '@/redux/interfaces'
import { ECartRoute } from '@/types'
import { useEffectAfterMount } from '@/utils/hooks'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import './styles.scss'

const mapState = (state: RootState) => ({
  canUserUseCart: canUseCart(state),
  isUserAuthenticated: isAuthenticated()(state),
})

const CartAction: React.FC = () => {
  const dispatch = useDispatch()
  const { isUserAuthenticated, canUserUseCart } = useSelector(mapState)

  const { isMobile } = useDeviceType()

  const { isLoading: isCartFetching, data } = useMultiCart()
  const { cartsItemsNumber } = data

  useEffectAfterMount(() => {
    if (canUserUseCart) dispatch(fetchMultiCart())
    //eslint-disable-next-line
  }, [canUserUseCart])

  if (!canUserUseCart) return <div className={classNames('cart-spacer', { 'cart-spacer_auth': isUserAuthenticated })} />

  return (
    <CommonLink url={ECartRoute.multiCart} className="cart-action">
      <span className="cart-action__icon">
        <Icon name={EIconName.Cart} size={isMobile ? EIconSize.XS : EIconSize.S} />
      </span>

      <span className="cart-action__quantity">{isCartFetching ? <Loader isVisible isStatic withoutOverlay /> : cartsItemsNumber}</span>
    </CommonLink>
  )
}

export default CartAction
