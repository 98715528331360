import { useMemo } from 'react'
import { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import { v4 as uuid } from 'uuid'
import { IProductCardLabel } from '@/components/ui/inputs/OfferLabels/interfaces'
import { EAgriculturalProductDiscountType } from '@/types/Catalog'
import { formatLocalizedString } from '@/utils/formatUtils'
import { messages } from './messages'
import { IUseOfferLabelsOptions } from './types'

export const useOfferLabels = (options: IUseOfferLabelsOptions) => {
  const { offer, isCatalogProductCard, isMainPageCard } = options

  const labels = useMemo(() => {
    if (!offer) return [] as IProductCardLabel[]
    const { promotions, postponement, discountType, discountPercent } = offer

    const labelList: IProductCardLabel[] = [...promotions].map(promo => ({ color: EChipModifier.ATTENTION_LIGHT, ...promo }))

    if (isCatalogProductCard || isMainPageCard) {
      labelList.push({
        id: uuid(),
        name: messages.chipNames.installment,
        color: EChipModifier.LIGHT_GREEN,
      })
    }

    if (postponement) {
      labelList.push({
        id: uuid(),
        name: messages.chipNames.postponement,
        color: EChipModifier.ATTENTION_LIGHT,
      })
    }

    if (isMainPageCard && discountType !== EAgriculturalProductDiscountType.NONE) {
      labelList.push({
        id: uuid(),
        name:
          discountType === EAgriculturalProductDiscountType.FLOATING
            ? formatLocalizedString(messages.chipNames.discount.floating, { discountPercent })
            : messages.chipNames.discount.fixed,
        color: EChipModifier.WARNING,
      })
    }

    return labelList
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer])

  return labels
}
