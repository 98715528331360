import React, { FC } from 'react'
import classNames from 'classnames'
import { ArticlePreview } from '@/components/composed'
import { Skeleton } from '@/components/ui'
import { useResponsiveValue } from '@/utils/hooks/responsive'
import { ResponsiveArticlesInPublicationSkeleton, ResponsiveArticlesSkeleton, ResponsiveGridBlock } from './constants'
import { IArticlesProps } from './interfaces'
import { ResponsiveBlocksPerGrid } from './ResponsiveBlocksPerGrid'
import './styles.scss'

const Articles: FC<IArticlesProps> = props => {
  const { articles, isArticlesLoading, isInPublication, className, blockId, onClick } = props

  const GridBlock = useResponsiveValue(ResponsiveGridBlock)
  const blocksPerGrid = useResponsiveValue(ResponsiveBlocksPerGrid)
  const articlesSkeletons = useResponsiveValue(isInPublication ? ResponsiveArticlesInPublicationSkeleton : ResponsiveArticlesSkeleton)

  return (
    <section id={blockId} className={classNames('journalArticles', className)} onClick={onClick}>
      <Skeleton isLoading={isArticlesLoading} skeletons={articlesSkeletons}>
        {articles?.map((article, index) => {
          const blockPositionInGrid = (index + 1) % blocksPerGrid || blocksPerGrid
          const Block = GridBlock[blockPositionInGrid] || ArticlePreview

          return <Block article={article} key={`${index}_${article.id}`} className="journalArticles__item" />
        })}
      </Skeleton>
    </section>
  )
}

export default Articles
