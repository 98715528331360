import { MAX_PROPERTY_LIST_LENGTH } from '@/pages/AgriculturalProductCatalog/components/ProductCard/constants'
import { ISku } from '@/types/Catalog'
import { prepareCharacteristics } from '@/utils/inputs/product/helpers'
import { IPropertyListProps } from './components/PropertyList/types'

export const makePropertyList = (product: ISku, isSeedCategory: boolean): IPropertyListProps['list'] => {
  let props: IPropertyListProps['list'] = []
  const { producer, cultures, specification, characteristics, category } = product
  // TODO: Временный костыль для отображения действующего вещества
  const activeSubstance = specification.length && specification.find(({ name }) => name === 'Действующее вещество')
  const activeSubstanceContent = specification.length && specification.find(({ name }) => name === 'Содержание действующего вещества')

  if (activeSubstance) props.push({ labelId: 'propertyList.label.substance', text: activeSubstance.value })
  if (activeSubstanceContent) props.push({ labelId: 'propertyList.label.content', text: activeSubstanceContent.value })
  if (!characteristics.length && category && !isSeedCategory) props.push({ labelId: 'propertyList.label.type', text: category.name })
  if (cultures.length) props.push({ labelId: 'propertyList.label.culture', text: cultures.map(({ name }) => name).join(', ') })
  if (characteristics.length)
    props.push(...prepareCharacteristics(characteristics).map(({ name, list }) => ({ label: name, text: list.join(', ') })))
  // т.к. Бренд всегда должен быть последним в списке
  if (producer) props = [...props.slice(0, MAX_PROPERTY_LIST_LENGTH - 1), { labelId: 'propertyList.label.brand', text: producer.name }]

  return props.slice(0, MAX_PROPERTY_LIST_LENGTH).sort((a, b) => {
    if (a.labelId === 'propertyList.label.type') return -1
    if (b.labelId === 'propertyList.label.type') return 1
    if (a.labelId === 'propertyList.label.brand') return 1
    if (b.labelId === 'propertyList.label.brand') return -1

    return 0
  })
}
