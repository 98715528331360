import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

export const useEffectAfterMount = (callback: EffectCallback, deps: DependencyList) => {
  const didMount = useRef(false)
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (didMount.current) return callback()

    didMount.current = true
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
