import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { updateSaleRequestType } from '@/pages/Profiles/actions'
import { IProfilesState, NProfilesAction } from '@/pages/Profiles/interface'
import { getInitialState } from '@/redux/helpers'
import { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { ESaleRequestType } from '@/types/ESaleRequestType'

const initialState: IProfilesState = {
  saleRequestType: ESaleRequestType.SALEREQUEST,
}
const reducerName = 'profiles'
const reducer = createReducer(getInitialState(reducerName, initialState), {
  [updateSaleRequestType.type]: (state, { payload }: PayloadAction<NProfilesAction.IUpdateSaleRequestType>) => {
    state.saleRequestType = payload.type
  },
})

reducerManager.add(reducerName, reducer, true)

export const getSaleRequestType = () => (state: RootState) => state[reducerName].saleRequestType
