import axios, { AxiosError, AxiosInstance } from 'axios'
import { IAxiosFactoryParams, RequestConfig, ResponseConfig } from '@/api/client/types'
import { getJwt } from '@/logic/auth/reducer'
import { reducerManager } from '@/redux/reducerManager'
import { AuthCookiesService } from '@/services'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'

export const createAxiosInstance = (params: IAxiosFactoryParams): AxiosInstance => {
  const { baseURL } = params
  const { isSsr } = ExecutionEnvironment

  const instance = axios.create({
    baseURL,
  })

  instance.interceptors.request.use(config => {
    const token = isSsr ? AuthCookiesService.getJwt() : getJwt()(reducerManager.store?.getState()!)

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  })

  return instance
}

export const createAxiosClient = (params: IAxiosFactoryParams) => {
  const { baseURL } = params
  const instance = createAxiosInstance({ baseURL })

  const client = async <TData, TError = unknown, TVariables = unknown>(
    config: RequestConfig<TVariables>,
  ): Promise<ResponseConfig<TData>> => {
    const promise = instance.request<TVariables, ResponseConfig<TData>>({ ...config }).catch((e: AxiosError<TError>) => {
      throw e
    })

    return promise
  }

  return client
}
