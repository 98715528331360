import { FC } from 'react'
import { TSkeletons } from '@frontend/pole-ui/lib/components/Skeleton'
import { articlePreviewSkeleton } from '@/components/composed/ArticlePreview/constants'
import { IArticlePreviewProps } from '@/components/composed/ArticlePreview/interfaces'
import ArticlePreviewWithoutImage from '@/components/composed/ArticlePreviewWithoutImage/ArticlePreviewWithoutImage'
import { articlePreviewWithoutImageSkeleton } from '@/components/composed/ArticlePreviewWithoutImage/constants'
import { IArticlePreviewWithoutImageProps } from '@/components/composed/ArticlePreviewWithoutImage/interfaces'
import { wideArticlePreviewSkeleton } from '@/components/composed/WideArticlePreview/constants'
import { IWideArticlePreviewProps } from '@/components/composed/WideArticlePreview/interfaces'
import { WideArticlePreview } from '@/components/composed/WideArticlePreview/WideArticlePreview'
import { TResponsiveValue } from '@/utils/hooks/responsive/interfaces'

const tabletArticlesSkeleton: TSkeletons = [
  articlePreviewSkeleton,
  articlePreviewWithoutImageSkeleton,
  articlePreviewSkeleton,
  wideArticlePreviewSkeleton,
  articlePreviewSkeleton,
  articlePreviewSkeleton,
  articlePreviewSkeleton,
  articlePreviewSkeleton,
]

export const articlesSkeletons: TSkeletons = [
  ...tabletArticlesSkeleton,
  wideArticlePreviewSkeleton,
  articlePreviewSkeleton,
  articlePreviewSkeleton,
  articlePreviewSkeleton,
  articlePreviewSkeleton,
]

export const ResponsiveArticlesInPublicationSkeleton: TResponsiveValue<TSkeletons> = {
  desktop: [articlePreviewSkeleton, articlePreviewWithoutImageSkeleton, articlePreviewSkeleton],
  tablet: [articlePreviewSkeleton, articlePreviewWithoutImageSkeleton],
  mobile: [articlePreviewSkeleton, articlePreviewWithoutImageSkeleton, articlePreviewSkeleton],
}

export const ResponsiveArticlesSkeleton: TResponsiveValue<TSkeletons> = {
  desktop: [
    ...tabletArticlesSkeleton,
    wideArticlePreviewSkeleton,
    articlePreviewSkeleton,
    articlePreviewSkeleton,
    articlePreviewSkeleton,
    articlePreviewSkeleton,
  ],
  tablet: tabletArticlesSkeleton,
  mobile: [
    articlePreviewSkeleton,
    wideArticlePreviewSkeleton,
    articlePreviewSkeleton,
    articlePreviewSkeleton,
    articlePreviewSkeleton,
    articlePreviewSkeleton,
    articlePreviewWithoutImageSkeleton,
    articlePreviewSkeleton,
  ],
}
export const ResponsiveGridBlock: TResponsiveValue<
  Record<number, FC<IArticlePreviewProps | IWideArticlePreviewProps | IArticlePreviewWithoutImageProps>>
> = {
  desktop: {
    2: ArticlePreviewWithoutImage,
    4: WideArticlePreview,
    12: WideArticlePreview,
  },
  tablet: {
    2: ArticlePreviewWithoutImage,
    3: WideArticlePreview,
    6: WideArticlePreview,
  },
  mobile: {
    2: WideArticlePreview,
    7: ArticlePreviewWithoutImage,
  },
}
