import React, { CSSProperties } from 'react'
import classNames from 'classnames'
import { createItems } from '@/utils/helpers'
import { IPaginationDotsProps } from './interface'
import './styles.scss'

const PaginationDots: React.FC<IPaginationDotsProps> = props => {
  const { activeDotNumber, dotSize = 10, activeDotWidth = 10, activeDotStyle, dotsQuantity, className, onClick } = props

  const DotTag = onClick ? 'a' : 'div'

  return (
    <div className={classNames('pagination-dots', className)}>
      {createItems(dotsQuantity, index => (
        <DotTag
          key={index}
          onClick={() => onClick?.(index)}
          className={classNames('pagination-dots__dot', { 'pagination-dots__dot_active': activeDotNumber === index + 1 })}
          style={
            {
              '--dot-size': `${dotSize}px`,
              '--active-dot-width': `${activeDotWidth}px`,
              '--active-dot-style': activeDotStyle,
            } as CSSProperties
          }
        />
      ))}
    </div>
  )
}

export default PaginationDots
