import React, { useState } from 'react'
import { useController } from 'react-hook-form'
import { Checkbox } from '@frontend/pole-ui/lib/components/Checkbox'
import { ICheckboxListProps } from './types'

export const HookFormCheckboxList: React.FC<ICheckboxListProps> = props => {
  const { name, options, control, rules, className } = props

  const { field } = useController({
    control,
    name,
    rules,
  })

  const [checkedValues, setCheckedValues] = useState<string[]>(field.value || [])

  const onCheckboxButtonClick = (isActive: boolean, value: string) => {
    const newValues = isActive ? checkedValues.filter(checkedValue => checkedValue !== value) : [...checkedValues, value]

    field.onChange(newValues)
    setCheckedValues(newValues)
  }

  return (
    <div className={className}>
      {options.map(({ value, label, isDisabled = false }, index) => {
        const isActive = checkedValues.includes(value)

        return (
          <div key={value + index}>
            <Checkbox value={isActive} isDisabled={isDisabled} onChange={() => onCheckboxButtonClick(isActive, value)}>
              {label}
            </Checkbox>
          </div>
        )
      })}
    </div>
  )
}
