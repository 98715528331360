import { TMessages } from '@/types/TMessages'

export const messages = {
  escort: 'Сопровождение',
  advancePaymentOffer: {
    button: {
      back: 'Вернуться к выбору',
    },
  },
  error: {
    paymentOffer: {
      status: {
        OKVEDS_NOT_FOUND:
          'В присвоенных Вашей организации кодах ОКВЭД не оказалось целевых кодов для продукта Авансирования. Ничего страшного, выберите иной подходящий Вам продукт финансирования и оформите заявку',
        STATUS_NOT_ACTIVE:
          'Текущий юридический статус Вашей организации не позволяет оформить заявку на продукт Авансирования. Ничего страшного, выберите иной подходящий Вам продукт финансирования и оформите заявку',
      },
    },
  },
} satisfies TMessages
