import React, { useContext } from 'react'
import { AccreditationContext } from '@/logic/accreditation/constants'
import { AccreditationBanner } from '..'
import { ILogisticsInfoAccreditationBannerProps } from './interfaces'
import { messages } from './messages'

const LogisticsInfoAccreditationBanner: React.FC<ILogisticsInfoAccreditationBannerProps> = () => {
  const { accreditationActions, mainStatus, bannerText } = useContext(AccreditationContext)

  let title = bannerText

  // На стадии подписание используется баннер SigningAccreditationModal, но
  // когда нет action'ов, мы показываем этот для замены:
  if (mainStatus === 'sign') {
    if (Object.keys(accreditationActions).length === 0) {
      title = messages.waitForAgreementToBeSigned
    } else {
      return null
    }
  }

  if (!title) return null

  return <AccreditationBanner className="userSignAccreditationBanner" iconUrl="black/docs" title={bannerText} />
}

export default LogisticsInfoAccreditationBanner
