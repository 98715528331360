import { TTemplatesFilterName } from '@/types/HarvestSaleLots'

export const templatesPerPage = 6

export const TemplatesFilterName = {
  CULTURES: 'cultures',
  CULTURE_CLASSES: 'cultureClasses',
  REGIONS: 'regionCodes',
  PROTEIN: 'protein',
  STORE: 'store',
  ELEVATORS: 'elevators',
  BUYERS: 'buyers',
  SORT_BY: 'sortBy',
  STUTUSES: 'statuses',
} as const

export const availableFilters: TTemplatesFilterName[] = [
  TemplatesFilterName.CULTURES,
  TemplatesFilterName.CULTURE_CLASSES,
  TemplatesFilterName.PROTEIN,
]

export enum ETagCreationType {
  purchase = 'PURCHASE',
}

export const includedFiltersFromResponse: TTemplatesFilterName[] = [TemplatesFilterName.ELEVATORS, TemplatesFilterName.BUYERS]

export enum EHarvestSaleRequestStatusName {
  MAKE_LEAD = 'makeLead_lots',
  // пока что нигде не используется
  REQUEST_HARVEST_SALE = 'requestHarvestSale',
}

export const notIndicatorsName = ['cultures', 'regionCodes', 'elevators', 'buyers', 'pageNumber']
