import React from 'react'
import { useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import classNames from 'classnames'
import { isUserBuyer } from '@/logic/auth/reducer'
import { RootState } from '@/redux/interfaces'
import { RoundedMark } from '..'
import { IBuyerBlackLabelProps } from './interfaces'
import './styles.scss'

const mapState = (state: RootState) => ({
  isBuyer: isUserBuyer()(state),
})

const BuyerBlackLabel: React.FC<IBuyerBlackLabelProps> = ({ isAgrarianLabeled, className, position = 'absolute' }) => {
  const { isBuyer } = useSelector(mapState)

  return (
    <RoundedMark
      isVisible={isAgrarianLabeled && isBuyer}
      className={classNames(`buyer-black-label buyer-black-label_${position}`, className)}
      color={EColor.RED}
    />
  )
}

export default BuyerBlackLabel
