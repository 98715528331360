import React, { PropsWithChildren } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  DropdownWithTags as PoleUIDropdownWithTags,
  TOnChangeDropdownWithTagsItem,
  TOnCloseDropdownTag,
} from '@frontend/pole-ui/lib/components/DropdownWithTags'
import omit from 'lodash/omit'
import { getDropdownPlaceholderToPass, getDropdownValidateFunction } from '@/components/ui/fields/helpers/Dropdown'
import { IDropdownWithTagsProps } from './interfaces'

function DropdownWithTags<GValue = string | number>(props: PropsWithChildren<IDropdownWithTagsProps<GValue>>) {
  const { errorValues, onChange, onCloseTag } = props
  const { formatMessage } = useIntl()
  const placeholderToPass = getDropdownPlaceholderToPass(props, formatMessage)

  const validate = getDropdownValidateFunction<GValue>(props)

  const initializeDropdownWithTags = (fieldProps: FieldRenderProps<GValue>) => {
    const { input, meta } = fieldProps

    const onItemChange: TOnChangeDropdownWithTagsItem<GValue> = (changedValues, item, event) => {
      input.onChange(changedValues)
      onChange?.(changedValues, item, event)
      input.onBlur()
    }

    const onRemoveTag: TOnCloseDropdownTag<GValue> = (values, event) => {
      onCloseTag?.(values, event)
    }

    return (
      <PoleUIDropdownWithTags<GValue>
        {...omit(props, 'customValidator')}
        {...fieldProps}
        value={input.value}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        errorText={<FormattedMessage id={`field.error.${meta.error || meta.data?.warning}`} values={errorValues} />}
        placeholder={placeholderToPass}
        onCloseTag={onRemoveTag}
        onChange={onItemChange}
      />
    )
  }

  return (
    <Field<GValue> {...props} validate={validate}>
      {initializeDropdownWithTags}
    </Field>
  )
}

export default DropdownWithTags
