import React, { FC, useContext } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { FaqContext } from '../../constants'
import { getTransliteratedGtmEvents } from '../../gtmHelpers'
import { getArticleUrl } from '../../helpers'
import { IFaqParams } from '../../types'
import { INearestArticleProps } from './interfaces'
import './styles.scss'

const NearestArticle: FC<INearestArticleProps> = ({ article, iconPosition }) => {
  const { specializationId, categoryId } = useContext(FaqContext).params as Required<IFaqParams>
  if (!article) return <div className="faq-nearest-article faq-nearest-article_empty" />

  const { id: questionId, question } = article

  const handleClick = () => {
    pushFormattedGtmEvent(
      getTransliteratedGtmEvents({
        blockName: 'blockSearch',
        eventLabel: `${iconPosition === 'left' ? 'buttonBack' : 'buttonNext'}`,
      }),
    )
  }

  return (
    <CommonLink
      className={`text_small-from-sm faq-nearest-article faq-nearest-article_${iconPosition}`}
      url={getArticleUrl({ categoryId, specializationId, questionId })}
      onClick={handleClick}
    >
      <span className={`faq-nearest-article__icon faq-nearest-article__icon_${iconPosition}`}>
        <Icon name={iconPosition === 'left' ? EIconName.ArrowLeft2 : EIconName.ArrowRight2} />
      </span>
      <span className={`faq-nearest-article__text faq-nearest-article__text_${iconPosition}`}>{question}</span>
    </CommonLink>
  )
}

export default NearestArticle
