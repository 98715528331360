/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AuctionStoreCreateData = {
    name: string;
    address: string;
    regionCode: string;
    type: AuctionStoreCreateData.type;
    longitude?: number;
    latitude?: number;
};

export namespace AuctionStoreCreateData {

    export enum type {
        WAREHOUSE = 'WAREHOUSE',
        STATION = 'STATION',
    }


}

