/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AuctionStatusInfo = {
    code: AuctionStatusInfo.code;
    name: string;
};

export namespace AuctionStatusInfo {

    export enum code {
        DRAFT = 'DRAFT',
        PLANNED = 'PLANNED',
        ACTIVE = 'ACTIVE',
        CLOSED = 'CLOSED',
        DELETED = 'DELETED',
    }


}

