import { isStatusOk } from '@/api/helpers'
import { requestData } from '@/api/request'
import IOAuthUserId from '@/types/IOAuthUserId'
import { ICreditRequest, IPayRequest, ISberBusinessUserData } from '@/types/ISberBisiness'

export interface ISberUserData {
  fullName: string
  inn: string
  phone: string
  email: string
  oauthUserId: IOAuthUserId
}

// придет либо jwt токен, либо данные для предзаполнения формы регистрации
interface ISberLoginResponse {
  token?: string
  userInfoData?: ISberUserData
}

export default class SberAuthService {
  login = async ({ code, redirectUri = '' }: { code: string; redirectUri: string }) => {
    const response = await requestData<ISberLoginResponse>({
      url: `/api/sber/business/user-info?code=${code}&redirectURI=${redirectUri}`,
      method: 'GET',
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response
  }

  userData = async () => {
    const response = await requestData<ISberBusinessUserData>({
      url: '/api/sber/business/users/me',
      method: 'GET',
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response
  }

  creditRequest = async (body: ICreditRequest) => {
    const response = await requestData({
      url: '/api/sber/business/credit/request',
      method: 'POST',
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response
  }

  pay = async (body: IPayRequest) => {
    const response = await requestData({
      url: '/api/sber/business/credit/payment',
      method: 'POST',
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response
  }
}
