import React, { memo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import { formatMoney, getPriceUom } from '@/utils/price'
import { IProductPriceProps } from './interfaces'
import './style.scss'

const ProductPrice: React.FC<IProductPriceProps> = props => {
  const {
    price,
    className,
    perUnitTextId,
    shouldLowerFraction,
    children,
    shouldShowEmptyPrice,
    displayVariant,
    valueClassName,
    unitClassName,
  } = props
  const { value, uom } = price || { uom: '', value: '' }
  const intl = useIntl()

  const isIndexVariant = displayVariant === 'index'

  const [integerPart, fraction] = formatMoney(value, intl).split(',')

  return (
    <div className={classNames(isIndexVariant ? 'productPrice--index' : 'productPrice text_nowrap', className)}>
      {/* без инлайновых дивов реакт теряет классы для дробной части числа или для знака валюты :c */}
      <div className={classNames(isIndexVariant ? 'productPrice__itemIndex' : 'productPrice__item text_nowrap', valueClassName)}>
        {shouldShowEmptyPrice ? (
          '—'
        ) : (
          <>
            {integerPart}
            {fraction && <span className={classNames({ productPrice__fraction: shouldLowerFraction })}>,{fraction}</span>}
          </>
        )}
        {!shouldLowerFraction && <>&nbsp;</>}
      </div>

      <div
        className={classNames(
          isIndexVariant ? 'productPrice__priceIndex' : 'productPrice__item productPrice__price color_pale-black',
          unitClassName,
        )}
      >
        {getPriceUom(uom)}
      </div>
      {perUnitTextId && (
        <>
          /<FormattedMessage id={perUnitTextId} />
        </>
      )}
      {children}
    </div>
  )
}

export default memo(ProductPrice, isEqual) as typeof ProductPrice
