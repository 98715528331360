
export enum BannerDataV1Action {
    "GO_TO_DEEPLINK" = "GO_TO_DEEPLINK",
    "GO_TO_URL" = "GO_TO_URL"
}
export enum BannerDataV1Deeplink {
    "STATUS_REQUEST" = "STATUS_REQUEST",
    "PROFILE" = "PROFILE",
    "SEND_REQUEST" = "SEND_REQUEST",
    "AUTH" = "AUTH",
    "BLOG" = "BLOG"
}
export enum BannerDataV1Status {
    "ACTIVE" = "ACTIVE",
    "INACTIVE" = "INACTIVE",
    "DELETED" = "DELETED"
}
export type BannerDataV1 = {
    /**
     * @type string
    */
    id: string;
    /**
     * @type string
    */
    title: string;
    /**
     * @type string
    */
    action: BannerDataV1Action;
    /**
     * @type string | undefined
    */
    deeplink?: BannerDataV1Deeplink;
    /**
     * @type string | undefined
    */
    url?: string;
    /**
     * @type string
    */
    status: BannerDataV1Status;
};
