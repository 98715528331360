import { requestData, requestDataWithRawResponse } from '@/api/request'
import { EHarvestSaleLotsApiUrl } from '@/api/urls'
import { IDocumentsResponse } from '@/services/documents/interfaces'
import { downloadFileFromResponse } from '@/utils/files'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'

interface IUploadDocumentOptions {
  file: File
  name: string
  type: string
  id: number
  version?: string
}

interface IUpdateDocumentOptions {
  file: File
  name: string
  type: string
  id: number
  version?: string
  documentId: string
}

interface IDeleteDocumentOptions {
  id: number
  version?: string
  documentId: string
}

/**
 * Сервис работы с документами заявки на продажу зерна в outputs
 */
export default class SaleRequestDocumentsService {
  /**
   * Загрузка документа в заявку
   * @param  {Object} options
   * @prop  {File} options.file - файл
   * @prop  {string} options.name - имя файла
   * @prop  {string} options.type - тип файла
   * @prop  {number} options.id - идентификатор заявки
   * @prop  {string} options.version - версия запроса, поле updateDate в заявке
   */
  uploadDocument = ({ file, name, type, id, version }: IUploadDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleLotsApiUrl.saleRequestDocuments, { requestId: id }), { version }),
      method: 'POST',
      body: formData,
      isJson: false,
    })
  }

  /**
   * Обновление документа в заявке
   * @param {Object} options
   * @prop {File} options.file - файл
   * @prop {string} options.name - имя файла
   * @prop {string} options.type - тип файла
   * @prop {number} options.id - идентификатор заявки
   * @prop {string} options.version - версия запроса, поле updateDate в заявке
   * @prop {string} options.documentId - идентификатор документа
   */
  updateDocument = ({ file, name, type, id, version, documentId }: IUpdateDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleLotsApiUrl.saleRequestDocument, { requestId: id, docId: documentId }), { version }),
      method: 'PUT',
      body: formData,
      isJson: false,
    })
  }

  /**
   * Удаление документа из заявки
   * @param  {Object} options
   * @prop {number} options.id - идентификатор заявки
   * @prop {string} options.version - версия запроса, поле updateDate в заявке
   * @prop {string} options.documentId - идентификатор документа
   */
  deleteDocument = ({ id, version, documentId }: IDeleteDocumentOptions) =>
    requestData({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleLotsApiUrl.saleRequestDocument, { requestId: id, docId: documentId }), {
        version,
      }),
      method: 'DELETE',
    })

  /**
   * Скачивание документа в заявке
   * @param  {string} url - версия запроса, поле updateDate в заявке
   * @param {string} fileName - идентификатор документа
   */
  downloadDocument = (url: string, fileName: string) =>
    requestDataWithRawResponse<Blob>({ url, method: 'GET' }).then(response => {
      downloadFileFromResponse(response, fileName)
    })

  /**
   * Запрос документов заявки
   * @param {number} id  - идентификатор заявки
   */
  fetchDocuments = (id: number) => {
    return requestData<IDocumentsResponse>({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleLotsApiUrl.saleRequestDocuments, { requestId: id }), { limit: 100 }),
      method: 'GET',
    })
  }
}
