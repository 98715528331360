import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Button, GlassMorphIcon, InjectHtml } from '@/components/ui'
import { IAccreditationBannerProps } from './interfaces'
import './styles.scss'

const AccreditationBanner: React.FC<IAccreditationBannerProps> = props => {
  const {
    link,
    linkId,
    buttonText,
    onButtonClick,
    buttonTextId,
    textId,
    text,
    titleId,
    title,
    iconUrl,
    className,
    buttonStyle = 'white-black',
    isHiddenButton,
  } = props

  const titleClassName = 'space-holder8-from-sm user-accreditation-banner__heading'
  const textClassName = 'user-accreditation-banner__text'

  return (
    <section className={classNames('user-accreditation-banner', className, { 'user-accreditation-banner_without-image': !iconUrl })}>
      {iconUrl && <GlassMorphIcon image={iconUrl} className="user-accreditation-banner__image shrink_no" />}

      <div
        className={classNames('space-holder-right32-from-sm user-accreditation-banner__title', {
          'user-accreditation-banner__title_without-btn': !(buttonText || buttonTextId || link || linkId),
        })}
      >
        {title !== undefined ? (
          <h4 className={titleClassName}>{title}</h4>
        ) : (
          titleId && <InjectHtml TagName="h4" className="space-holder8-from-sm user-accreditation-banner__heading" textId={titleId} />
        )}
        {text !== undefined ? (
          <p className={textClassName}>{text}</p>
        ) : (
          textId && (
            <p className={textClassName}>
              <FormattedMessage id={textId} />
            </p>
          )
        )}
      </div>
      {link && linkId && (
        <CommonLink className="user-accreditation-banner__button button button_white-black button_small" url={link}>
          <FormattedMessage id={linkId} />
        </CommonLink>
      )}
      {(buttonText || buttonTextId) && (
        <Button
          modifiers={[buttonStyle, 'small']}
          className="user-accreditation-banner__button"
          onClick={onButtonClick}
          textId={buttonTextId}
          isHidden={isHiddenButton}
        >
          {buttonText}
        </Button>
      )}
    </section>
  )
}

export default AccreditationBanner
