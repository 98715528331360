import { PayloadAction } from '@reduxjs/toolkit'
import { filter } from 'rxjs'
import { IRequestStatusPayload } from '@/logic/requestStatus/interfaces'
import { TLocationChangeAction } from '@/types'

export const filterChangingLocationPathname = () =>
  filter(
    ([lastAction, action]: [TLocationChangeAction, TLocationChangeAction]) =>
      action.payload.location.pathname !== lastAction.payload.location.pathname,
  )

export const shouldNotCloseModalOnChangingLocationAfterOpeningSuccessOrderModal = () =>
  filter(([, action]: [TLocationChangeAction, TLocationChangeAction]) => {
    return !action.payload.location.search.includes('shouldShowSuccessOrderModal=true')
  })

export const hasRequestStatusName = (requestName: string) =>
  filter(({ payload }: PayloadAction<IRequestStatusPayload>) => payload.name.includes(requestName))
