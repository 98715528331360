/* eslint-disable no-console */
export const consoleError = console.error.bind(console)

console.error = (message: any, ...args: any[]) => {
  if (
    (message instanceof Error && message.message.includes('[@formatjs/intl Error MISSING_TRANSLATION]')) ||
    (typeof message === 'string' &&
      (message.includes('A non-serializable value was detected in') || message.includes('Unexpected keys will be ignored.')))
  )
    return

  consoleError(message, ...args)
}
