import React, { FC, memo } from 'react'
import { EDocumentTypeId } from '@/types/Documents'
import { AccreditationDocumentCard, AccreditationTypedDocumentCard } from '../'
import { IAccreditationDocumentCardRenderProps } from './interfaces'

const AccreditationDocumentCardRender: FC<IAccreditationDocumentCardRenderProps> = props => {
  const { documents, documentsByType, hiddenDocumentType } = props

  return (
    <>
      {documents.map(typedDocument => {
        const { id } = typedDocument
        const shownDocuments = documentsByType[id]
        const isVisible = id !== EDocumentTypeId.ACCREDITATION_DOCUMENTS_ARCHIVE && shownDocuments && shownDocuments.length > 0

        if (isVisible) {
          return shownDocuments.map(document => <AccreditationDocumentCard key={document.id} document={document} />)
        }

        if (hiddenDocumentType[id]) return null

        return <AccreditationTypedDocumentCard key={id} typedDocument={typedDocument} />
      })}
    </>
  )
}

export default memo(AccreditationDocumentCardRender)
