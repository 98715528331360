import { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import { EAgrarianWorkingTimeHoursPeriod } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/enums'
import { IProduceAgrarianWarehouseData } from '@/types/HarvestSaleRequest/IProduceAgrarianWarehouseData'

export const AUTO_SAVE_INTERVAL = 1000

/** Данные для предзаполнения 3 страницы формы мультизаявки */
export const defaultProduceAgrarianWarehouseData: IProduceAgrarianWarehouseData = {
  minScalesLength: 6,
  maxScalesLength: 26,
  isScalesPlacedOutsideFarm: false,
  isFarmWorkingAroundTheClock: false,
  scalesLength: 12,
}

/** Доступные варианты для дропдаунов в разделе "Время работы" (Минуты) */
export const agrarianWorkingTimeMinutesOptions = [
  { value: '0', label: '00 мин' },
  { value: '30', label: '30 мин' },
]

/** Доступные варианты для дропдаунов в разделе "Время работы" (Часы) */
export const getAgrarianWorkingTimeHoursOptions = (period: EAgrarianWorkingTimeHoursPeriod): IDropdownOption[] => {
  const startOfWork = { start: 0, end: 12 }
  const endOfWork = { start: 13, end: 24 }
  const { start, end } = period === EAgrarianWorkingTimeHoursPeriod.START ? startOfWork : endOfWork
  const result = []

  for (let i = start; i <= end; i += 1) result.push({ value: `${i}`, label: `${i} ч` })

  return result
}

export const agrarianWorkingTimeStartHoursOptions = getAgrarianWorkingTimeHoursOptions(EAgrarianWorkingTimeHoursPeriod.START)
export const agrarianWorkingTimeEndHoursOptions = getAgrarianWorkingTimeHoursOptions(EAgrarianWorkingTimeHoursPeriod.END)

/** Допустимые расстояния от склада до весов в километрах */
export const MIN_DISTANCE_TO_SCALES = 1
export const MAX_DISTANCE_TO_SCALES = 5000
