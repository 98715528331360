import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { EAgriculturalProductsRoute } from '@/types'
import { EAgriculturalProductCategoryId, IAsyncThunkFetchProductCatalogPayload } from '@/types/Catalog'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'
import { IAgriculturalProductCatalogFiltersForm } from '../types'
import { mapFiltersFormToCatalogPayload } from './mappers'

export const createCatalogLinkBuilder =
  (form: IAgriculturalProductCatalogFiltersForm) =>
  (data: Partial<IAgriculturalProductCatalogFiltersForm>): string => {
    const newForm = { ...form, ...data }

    const { searchText } = newForm

    if (searchText && searchText !== form?.searchText) {
      const { params, search }: IAsyncThunkFetchProductCatalogPayload = {
        params: { categoryId: String(EAgriculturalProductCategoryId.SEARCH) },
        search: { searchText },
      }

      return getUrlWithQuery(createRouterLink(EAgriculturalProductsRoute.category, params), search)
    }

    // prettier-ignore
    const isFormHasChanges = !isEqual(
      omit(newForm, ['pageNumber']),
      omit(form, ['pageNumber']),
    )

    const { params, search } = mapFiltersFormToCatalogPayload({
      ...newForm,
      pageNumber: isFormHasChanges ? 1 : newForm.pageNumber,
    })

    return getUrlWithQuery(createRouterLink(EAgriculturalProductsRoute.category, params), search)
  }
