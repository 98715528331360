import { requestData, requestDataWithRawResponse } from '@/api/request'
import { ECommonApiUrl } from '@/api/urls'
import { TEntityId } from '@/types/TEntityId'
import { downloadFileFromResponse } from '@/utils/files'
import { getUrlWithQuery } from '@/utils/url'

export interface IUpdateDocumentOptions {
  file: File
  documentName: string
  documentType: string
  version: string
  documentId: string
  id: TEntityId
}

export interface IDeleteDocumentOptions {
  version: string
  documentId: string
  id: TEntityId
}

export interface IUploadDocumentOptions {
  file: File
  documentName?: string
  documentType: string
  version: string
  id: TEntityId
}

export interface IRejectDocumentOptions {
  version: string
  documentId: string
  comment: string
  id: TEntityId
}

/** Сервис работы с документами аккредитации */
export default class AccreditationDocumentsService {
  /**
   * Обновление документа аккредитации
   * @param {Object} options
   * @prop {string} options.version - версия аккредитации, обычно поле updateDate
   * @prop {string} options.documentType - тип документа
   * @prop {string} options.documentName - имя документа
   * @prop {File} options.file - файл
   * @prop {string} options.id - идентификатор аккредитации
   */
  updateDocument = ({ file, documentName, documentType, version, documentId, id }: IUpdateDocumentOptions) => {
    const body = new FormData()
    if (file) body.append('documentFile', file)
    if (documentName) body.append('documentTitle', documentName)
    if (documentType) body.append('documentType', documentType)

    return requestData({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/documents/${documentId}`, { version }),
      method: 'PUT',
      body,
      isJson: false,
    })
  }

  /**
   * Загрузка документа в аккредитацию
   * @param {Object} options
   * @prop {string} options.version - версия документо, обычно поле updateDate
   * @prop {string} options.documentType - тип документа
   * @prop {string} options.documentName - имя документа
   * @prop {File} options.file - файл
   * @prop {string} options.id - идентификатор аккредитации
   */
  uploadDocument = ({ file, documentName, documentType, version, id }: IUploadDocumentOptions) => {
    const body = new FormData()
    if (file) body.append('documentFile', file)
    if (documentName) body.append('documentTitle', documentName)
    if (documentType) body.append('documentType', documentType)

    return requestData({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/documents`, { version }),
      method: 'POST',
      body,
      isJson: false,
    })
  }

  /**
   * Удаляет документ из аккредитации
   * @param {Object} options
   * @prop {string} options.version - версия документо, обычно поле updateDate
   * @prop {string} options.documentId - идентификатор документа
   * @prop {string} options.id - идентификатор аккредитации
   */
  deleteDocument = ({ version, documentId, id }: IDeleteDocumentOptions) =>
    requestData({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/documents/${documentId}`, { version }),
      method: 'DELETE',
    })

  /**
   * Отклоняет документ аккредитации, с комментарием
   * @param {Object} options
   * @prop {string} options.version - версия документо, обычно поле updateDate
   * @prop {string} options.documentId - идентификатор документа
   * @prop {string} options.id - идентификатор аккредитации
   * @prop {string} options.comment - комментарий к отклонению
   */
  rejectDocument = ({ version, documentId, comment, id }: IRejectDocumentOptions) =>
    requestData({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/documents/${documentId}/actions/reject`, { version }),
      method: 'PUT',
      body: { comment },
    })

  /**
   * Скачивание документа аккредитации
   * @param  {string} url - версия запроса, поле updateDate аккредитации
   * @param {string} fileName - идентификатор документа
   */
  downloadDocument = (url: string, fileName: string) =>
    requestDataWithRawResponse<Blob>({ url, method: 'GET' }).then(response => {
      downloadFileFromResponse(response, fileName)
    })

  /**
   * Загрузка документа аккредитации из модалки
   * @param {Object} options
   * @prop {string} options.version - версия документо, обычно поле updateDate
   * @prop {string} options.documentType - тип документа
   * @prop {string} options.documentName - имя документа
   * @prop {File} options.file - файл
   */
  uploadDocumentFromModal = ({ file, documentName, documentType, version }: IUploadDocumentOptions) => {
    const body = new FormData()
    if (file) body.append('documentFile', file)
    if (documentName) body.append('documentTitle', documentName)
    if (documentType) body.append('documentType', documentType)

    return requestData({
      url: getUrlWithQuery(ECommonApiUrl.organisationDocuments, { version }),
      method: 'POST',
      body,
      isJson: false,
    })
  }
  /**
   * Обновление документа аккредитации из модалки
   * @param {Object} options
   * @prop {string} options.version - версия документо, обычно поле updateDate
   * @prop {string} options.documentType - тип документа
   * @prop {string} options.documentName - имя документа
   * @prop {File} options.file - файл
   * @prop {string} options.documentId - идентификатор документа
   */
  updateDocumentFromModal = ({ file, documentName, documentType, version, documentId }: IUpdateDocumentOptions) => {
    const body = new FormData()
    if (file) body.append('documentFile', file)
    if (documentName) body.append('documentTitle', documentName)
    if (documentType) body.append('documentType', documentType)

    return requestData({
      url: getUrlWithQuery(`${ECommonApiUrl.organisationDocuments}/${documentId}`, { version }),
      method: 'PUT',
      body,
      isJson: false,
    })
  }
}
