/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OnboardingStepDataCC } from './OnboardingStepDataCC';

/**
 * Результат
 */
export type OnboardingDataCC = {
    id: string;
    name: string;
    condition: OnboardingDataCC.condition;
    status: OnboardingDataCC.status;
    priority: number;
    steps: Array<OnboardingStepDataCC>;
};

export namespace OnboardingDataCC {

    export enum condition {
        FIRST_START = 'FIRST_START',
        SECOND_START = 'SECOND_START',
    }

    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
        DELETED = 'DELETED',
    }


}

