import { useEffect, useMemo } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useStore } from 'react-redux'
import debounce from 'lodash/debounce'
import { PRODUCTS } from '@/components/composed/InstallmentCalculator/constants'
import { messages } from '@/components/composed/InstallmentCalculator/messages'
import { IInstallmentCalculatorForm } from '@/logic/financing/interfaces'
import { getUserGtmInfo, pushGtmEvent } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { EInstallmentCalculatorProduct, EPriceCurrency } from '@/types'
import { dateAfterDays } from '@/utils/date'
import { formatLocalizedString } from '@/utils/formatUtils'
import { useFormatDate } from '@/utils/hooks/format/useFormatDate'

export const useInstallmentCalculator = (sumValue: number, daysValue: number) => {
  const { formatDate } = useFormatDate()
  const { formatNumber } = useIntl()

  const checkIsInactive = (productId: EInstallmentCalculatorProduct) => {
    const { maxSum, maxDays, disableIfMaxSumExceeded } = PRODUCTS[productId]

    if (maxSum !== undefined && sumValue > maxSum && disableIfMaxSumExceeded) return true
    if (maxDays !== undefined && daysValue > maxDays) return true

    return false
  }

  const inactiveClassName = (productId: EInstallmentCalculatorProduct, forceIsInactive?: boolean) =>
    checkIsInactive(productId) || forceIsInactive ? 'installmentCalculator--inactive' : undefined

  const formatSum = (value: number) => `${formatNumber(value)} ${EPriceCurrency.RUB}`
  const formatDaysNoun = (days: number) => formatLocalizedString(messages.format.daysNoun, { days })
  const formatDaysLabel = (days: number) => `${days} ${formatDaysNoun(days)}`

  const formatDaysEnding = (days: number) =>
    formatLocalizedString(messages.format.periodTail, {
      daysNoun: formatDaysNoun(days),
      until: formatDate(dateAfterDays(days), 'd MMMM yyyy'),
    })

  return {
    checkIsInactive,
    inactiveClassName,
    formatSum,
    formatDaysLabel,
    formatDaysEnding,
  }
}

export const useSendGtmEventsOnFormChange = (control: Control<IInstallmentCalculatorForm>, debounceTimeInMilliseconds = 1e3) => {
  const [sum, days] = useWatch({ control, name: ['sum', 'days'] })
  const { getState } = useStore()

  const sendGtmInstallmentCalculatorEvent = useMemo(
    () =>
      debounce((eventLabel: string, eventContext: string) => {
        const rootState = getState()
        const userGtmInfo = getUserGtmInfo()(rootState)
        const userRegion = getUserRegion()(rootState)

        pushGtmEvent({
          event: 'userEvent',
          userAuth: userGtmInfo.userAuth,
          userImpersonalization: userGtmInfo.userImpersonalization,
          memberID: userGtmInfo.memberID,
          uid: userGtmInfo.uid,
          regionSelected: userRegion.name,
          eventCategory: 'interactions',
          eventAction: 'input',
          eventStatus: 'success',
          eventNonInteraction: '0',
          blockName: 'blockInstallmentCalculator',
          eventLabel,
          eventContext,
        })
      }, debounceTimeInMilliseconds),
    [debounceTimeInMilliseconds, getState],
  )

  useEffect(() => {
    sendGtmInstallmentCalculatorEvent('amount', String(sum))
  }, [sendGtmInstallmentCalculatorEvent, sum])

  useEffect(() => {
    sendGtmInstallmentCalculatorEvent('term', String(days))
  }, [days, sendGtmInstallmentCalculatorEvent])
}
