import { baseClickGtmEvent } from '@/logic/metrika/constants'
import { IFormattedGtmEventPayload } from '@/logic/metrika/interfaces'

export enum EClickEventBlockId {
  faq = 'faq',
  blockAgronomicSupport = 'blockAgronomicSupport',
  seedingRateCalc = 'seedingRateCalc',
  polePlus = 'polePlus',
  poleScouting = 'poleScouting',
  poleDiff = 'poleDiff',
  poleExpert = 'poleExpert',
  farmlandCropValuation = 'farmlandСropValuation',
}

export const ArgoservicesClickGtmEventPayload: Record<EClickEventBlockId, IFormattedGtmEventPayload> = {
  [EClickEventBlockId.faq]: {
    ...baseClickGtmEvent,
    blockName: 'blockQuestionsAndAnswers',
    eventLabel: 'buttonFaq',
  },
  [EClickEventBlockId.blockAgronomicSupport]: {
    ...baseClickGtmEvent,
    blockName: 'blockAgronomicSupport',
    eventLabel: 'buttonLearnMore',
  },
  [EClickEventBlockId.seedingRateCalc]: {
    ...baseClickGtmEvent,
    blockName: 'blockSeedingRateCalculator',
    eventLabel: 'buttonCalculateSeedingRate',
  },
  [EClickEventBlockId.polePlus]: {
    ...baseClickGtmEvent,
    blockName: 'blockPolePlus',
    eventLabel: 'buttonMoreDetails',
  },
  [EClickEventBlockId.poleScouting]: {
    ...baseClickGtmEvent,
    blockName: 'blockScouting',
    eventLabel: 'buttonMoreDetails',
  },
  [EClickEventBlockId.poleDiff]: {
    ...baseClickGtmEvent,
    blockName: 'blockFieldDifferentiation',
    eventLabel: 'buttonMoreDetails',
  },
  [EClickEventBlockId.poleExpert]: {
    ...baseClickGtmEvent,
    blockName: 'blockFieldExpert',
    eventLabel: 'buttonMoreDetails',
  },
  [EClickEventBlockId.farmlandCropValuation]: {
    ...baseClickGtmEvent,
    blockName: null,
    eventLabel: 'buttonlearnMore',
  },
}
