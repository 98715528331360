import { combineEpics, ofType } from 'redux-observable'
import { filter, race } from 'rxjs'
import { mergeMap, take, tap } from 'rxjs/operators'
import { requestHarvestSale, requestingHarvestSaleFailed, requestingHarvestSaleFinished } from '@/logic/harvestSale/actions'
import { NHarvestSaleAction } from '@/logic/harvestSale/interfaces'
import { Epic } from '@/logic/interfaces'
import { TCreatingHarvestSaleRequestFinishedPayload } from '@/pages/HarvestSaleLots/interfaces'
import { EOrderType } from '@/types/HarvestSaleLots'
import { getErrorTextFromResponseMeta } from '@/utils/metrika/helpers'
import { pushHarvestSaleRequestGtmEvent } from './gtmEvents'
import { calculateHarvestSalePrice } from './helpers'
import { TGtmEventLabelType } from './interfaces'

const getTrueEventContextForRequest = (sourceOfOpening: string, countBuyer: number, templateId?: string, pickupMethod?: string) =>
  `${templateId ? `prd:${templateId}&` : ''}lst:${sourceOfOpening}${countBuyer > 0 ? `&cntbuyers:${countBuyer}` : ''}${
    pickupMethod ? `&pckp:${pickupMethod}` : ''
  }`

const onRequestHarvestSaleDoPushAddingProductGtmEvent: Epic<
  NHarvestSaleAction.IRequestHarvestSalePayload | TCreatingHarvestSaleRequestFinishedPayload,
  typeof requestHarvestSale
> = action$ =>
  action$.pipe(
    filter(requestHarvestSale.match),
    mergeMap(({ payload }) =>
      race(
        action$.pipe(
          ofType(requestingHarvestSaleFinished.type, requestingHarvestSaleFailed.type),
          take(1),
          tap(({ type, payload: actionPayload }) => {
            const { template, formValues, sourceOfOpening } = payload
            const { orderType } = formValues
            const { id: templateId } = template || {}
            const { data } = <TCreatingHarvestSaleRequestFinishedPayload>actionPayload
            const requestId = (Array.isArray(data) ? data[0] : data)?.id

            let eventLabel: TGtmEventLabelType = 'multiApplication'
            if (orderType !== EOrderType.multiple) eventLabel = templateId ? 'submitApplication' : 'singleApplication'

            pushHarvestSaleRequestGtmEvent({
              items: Array.isArray(data) ? [data[0]!] : [data],
              templateId,
              isMultiRequest: orderType === EOrderType.multiple,
              blockName: null,
              eventAction: 'formSubmit',
              eventCategory: 'ecommerce',
              eventLabel,
              ecommerceType: 'purchase',
              eventEcommerce: 'purchase',
              eventNonInteraction: '0',
              pagePart: 'popUp',
              eventStatus:
                type === requestingHarvestSaleFinished.type
                  ? 'success'
                  : (`err:${getErrorTextFromResponseMeta(
                      (<TCreatingHarvestSaleRequestFinishedPayload>actionPayload)?.meta,
                    )}` as `err:${string}`),
              trueEventContext: getTrueEventContextForRequest(
                sourceOfOpening,
                orderType === EOrderType.multiple && Array.isArray(data) ? data.length : 0,
                templateId,
                formValues.produceAgrarianWarehouseData?.loadingMethod,
              ),
              actionField: {
                id: requestId,
                revenue: calculateHarvestSalePrice({
                  price: formValues.desiredPrice,
                  volume: formValues.volume,
                  vatUsed: formValues.vatUsed,
                }),
                list: sourceOfOpening,
              },
            })
          }),
        ),
      ),
    ),
  )

export default (<any>combineEpics)(onRequestHarvestSaleDoPushAddingProductGtmEvent)
