import { Request, Response } from 'express'
import Cookies from 'js-cookie'
import { v4 as uuid } from 'uuid'
import { clientIdCookieName } from './constants'

const ClientIdCookieService = {
  set: (req?: Request, res?: Response): string => {
    const clientId = req?.cookies[clientIdCookieName] || Cookies.get(clientIdCookieName) || uuid()
    res?.cookie(clientIdCookieName, clientId)
    Cookies.set(clientIdCookieName, clientId)

    return clientId
  },
  get: (req?: Request): string => req?.cookies[clientIdCookieName] || Cookies.get(clientIdCookieName),
}

export default ClientIdCookieService
