import { IUserGtmInfoPayload } from '@/interfaces'
import { IFinancingProductInfo } from '@/logic/metrika/financing/types'

export const getEventContext = (block?: string, productInfo?: IFinancingProductInfo, pathname?: string) => {
  return `section:${block || null};cd:${productInfo?.product || null};bank:${productInfo?.bank || null};pp:${pathname || null}`
}

export const getGtmScrollEventPayloadMultipleLeasing = (userGtmInfo: IUserGtmInfoPayload, path?: string) => {
  return {
    multipleLeasing: {
      event: 'userEvent',
      pagePart: 'content',
      blockName: 'blockMultiApplicationForm',
      eventCategory: 'interactions',
      eventLabel: 'scrollMultiApplication',
      eventAction: 'scrolling',
      userSegment: null,
      eventEcommerce: null,
      ecommerce: null,
      eventContext: path || null,
      ...userGtmInfo,
    },
  }
}
