import React, { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { Toggle } from 'rsuite'
import { ISwitchButtonProps } from './interfaces'
import './styles.scss'

const SwitchButton: React.FC<ISwitchButtonProps> = props => {
  const {
    onChange,
    labelId,
    label,
    switcherProps,
    value,
    isDefaultChecked,
    switcherStyle,
    switcherThumbStyle,
    labelPosition = 'right',
    className,
    labelClassName = 'text_small',
  } = props
  const labelText = label || (labelId && <FormattedMessage id={labelId} />)

  return (
    <div
      className={classNames('toggle-block', className, { row_column: ['bottom', 'top'].includes(labelPosition) })}
      style={{ '--switcher-style': switcherStyle, '--switcher-thumb-style': switcherThumbStyle } as CSSProperties}
    >
      {labelText && <p className={classNames(`toggle-block__label toggle-block__label_${labelPosition}`, labelClassName)}>{labelText}</p>}
      <Toggle {...switcherProps} onChange={onChange} checked={value} defaultChecked={isDefaultChecked} />
    </div>
  )
}

export default SwitchButton
