import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import { AccreditationLabelLink, Avatar, Chip } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import { ICounterpartyProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const Counterparty: React.FC<ICounterpartyProps> = ({ counterparty, withLink }) => {
  const { accreditationData, organization } = counterparty
  const { inn, name } = organization

  return (
    <div className="counterparty-info space-holder4-from-sm">
      <div className="counterparty-info__container row row_nowrap">
        {/*// TODO В рамках OUT-523 вернуть фото для аватара*/}
        <Avatar avatar={undefined} className="space-holder-right24-from-sm space-holder-right16-sm" size="56px" />
        <div className="counterparty-info__name">
          <h5>{name}</h5>
          <p className="color_pale-black text_small">
            <FormattedMessage id="counterparty.inn" values={{ counterpartyInn: inn }} />
          </p>
        </div>
      </div>

      {accreditationData &&
        (accreditationData.status.id !== 'NOT_STARTED' ? (
          <AccreditationLabelLink accreditation={accreditationData} withLink={withLink} />
        ) : (
          <Chip className="space-holder-top8-sm" color={EChipModifier.LIGHT_GRAY} textId="counterparty.accreditation.notAccredited" />
        ))}
    </div>
  )
}

export default memo(Counterparty)
