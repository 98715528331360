import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetDocumentsByOrganizationIdMutationRequest, GetDocumentsByOrganizationIdMutationResponse } from "../../types/GetDocumentsByOrganizationId";

/**
     * @link /api/document/purchase-offer/v1/getDocumentsByOrganizationId
     */
export async function getDocumentsByOrganizationId (data?: GetDocumentsByOrganizationIdMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetDocumentsByOrganizationIdMutationResponse>["data"]> {
    const { data: resData } = await client<GetDocumentsByOrganizationIdMutationResponse, GetDocumentsByOrganizationIdMutationRequest>({
        method: "post",
        url: `/api/document/purchase-offer/v1/getDocumentsByOrganizationId`,
        data,
        ...options
    });
    
    return resData;
};