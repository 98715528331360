import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INITIAL_BADGES } from '@/logic/notifications/constants'
import { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { INotifications, INotificationsState } from './types'

const reducerName = 'notifications'

const initialState: INotificationsState = {
  badges: INITIAL_BADGES,
  intervalId: null,
}

const notificationsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<INotifications>) => {
      state.badges = action.payload
    },
    setNotificationsIntervalId: (state, action: PayloadAction<NodeJS.Timeout | null>) => {
      state.intervalId = action.payload
    },
  },
})

export const { setNotifications, setNotificationsIntervalId } = notificationsSlice.actions

export const getNotificationsValues = () => (state: RootState) => state[reducerName].badges
export const getNotificationsIntervalId = () => (state: RootState) => state[reducerName].intervalId

export default notificationsSlice.reducer

reducerManager.add(reducerName, notificationsSlice.reducer, true)
