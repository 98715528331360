/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OnboardingStepDataCC = {
    title: string;
    description: string;
    imageUrl: string;
    blurhash?: string;
    action?: OnboardingStepDataCC.action;
    subject?: string;
    url?: string;
    deeplink?: OnboardingStepDataCC.deeplink;
};

export namespace OnboardingStepDataCC {

    export enum action {
        GO_TO_DEEPLINK = 'GO_TO_DEEPLINK',
        GO_TO_URL = 'GO_TO_URL',
    }

    export enum deeplink {
        STATUS_REQUEST = 'STATUS_REQUEST',
        PROFILE = 'PROFILE',
        SEND_REQUEST = 'SEND_REQUEST',
        AUTH = 'AUTH',
        BLOG = 'BLOG',
    }


}

