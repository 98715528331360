
export enum MobileBannerDataV1Action {
    "SEND_REQUEST" = "SEND_REQUEST",
    "GO_TO_DEEPLINK" = "GO_TO_DEEPLINK",
    "GO_TO_URL" = "GO_TO_URL"
}
export enum MobileBannerDataV1Deeplink {
    "STATUS_REQUEST" = "STATUS_REQUEST",
    "PROFILE" = "PROFILE",
    "SEND_REQUEST" = "SEND_REQUEST",
    "AUTH" = "AUTH",
    "BLOG" = "BLOG"
}
export type MobileBannerDataV1 = {
    /**
     * @type string
    */
    id: string;
    /**
     * @type string
    */
    name: string;
    /**
     * @type string | undefined
    */
    action?: MobileBannerDataV1Action;
    /**
     * @type string | undefined
    */
    subject?: string;
    /**
     * @type array | undefined
    */
    emails?: string[];
    /**
     * @type string | undefined
    */
    url?: string;
    /**
     * @type string | undefined
    */
    deeplink?: MobileBannerDataV1Deeplink;
    /**
     * @type string
    */
    imgUrl: string;
    /**
     * @type string date-time
    */
    createDate: string;
};
