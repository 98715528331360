import { ReactNode } from 'react'
import { IntlFormatters } from 'react-intl'
import { IBaseFormFieldContainerProps } from '@frontend/pole-ui/lib/components/BaseFormFieldContainer'
import { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import { FieldValidator } from 'final-form'
import find from 'lodash/find'
import get from 'lodash/get'
import { IDropdownProps } from '@/components/ui/fields/Dropdown/interfaces'
import { getValidateFunction, isFieldRequired } from '@/utils/validators'
import { IDropdownAdapterProps } from '../interfaces/Dropdown'

export const getDropdownValidateFunction = <GValue>(
  props: IDropdownAdapterProps<GValue> & Pick<IBaseFormFieldContainerProps<GValue>, 'isRequired' | 'isDisabled'>,
) =>
  getValidateFunction(() => {
    const validators: FieldValidator<GValue>[] = []
    if (props.isRequiredImportant || ((props.isRequired || props.isRequiredWithoutSign) && !props.isDisabled))
      validators.push(isFieldRequired)
    if (props.customValidator) validators.push(props.customValidator)

    return validators
  })

export type TFormatOption<GOption, GValue = string> = (item: IDropdownOption<GValue>, option: GOption) => IDropdownOption<GValue>
export type TPredicateOption<GValue> = (option: IDropdownOption<GValue>) => boolean

export interface ITransformOptionsConfig<GOption, GValue> {
  options?: Readonly<GOption[]>
  valueKey?: string
  labelKey?: string
  formatOption?: TFormatOption<GOption, GValue>
  predicateOption?: TPredicateOption<GValue>
}

export const transformOptions = <GOption, GValue>(config: ITransformOptionsConfig<GOption, GValue>): IDropdownOption<GValue>[] => {
  const { options = [], valueKey = 'value', labelKey = 'name', formatOption = option => option, predicateOption = () => true } = config

  return options.reduce((acc, option) => {
    const formattedOption = formatOption({ value: get(option, valueKey), label: get(option, labelKey) }, option)

    if (predicateOption?.(formattedOption as IDropdownOption<GValue>)) {
      acc.push(formattedOption as IDropdownOption<GValue>)
    }

    return acc
  }, [] as IDropdownOption<GValue>[]) as IDropdownOption<GValue>[]
}

export const getDefaultOptionByKey = (
  options: IDropdownOption<string | number>[],
  value?: string,
  key: 'value' | 'label' = 'value',
): string | number | undefined => find(options, { [key]: value })?.value as string

export const getOption = <GValue = string>(label: string, value: GValue): IDropdownOption<GValue> => ({ label, value })

export const getDropdownPlaceholderToPass = (
  { placeholderId, label, labelId, placeholder }: Pick<IDropdownProps<unknown>, 'placeholder' | 'placeholderId' | 'label' | 'labelId'>,
  formatMessage: IntlFormatters['formatMessage'],
): ReactNode | undefined => {
  if (placeholderId) {
    return formatMessage({ id: placeholderId })
  }

  if (label) {
    return label
  }

  if (labelId) {
    return formatMessage({ id: labelId })
  }

  return placeholder
}
