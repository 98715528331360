import React from 'react'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { Fading } from '../animations'
import { IRoundedMarkProps } from './interfaces'

const RoundedMark: React.FC<IRoundedMarkProps> = ({ isVisible, color, className }) => (
  <Fading isVisible={isVisible as boolean}>
    <span className={className}>
      <Icon style={{ color }} name={EIconName.Empty} size={EIconSize.XS} />
    </span>
  </Fading>
)

export default RoundedMark
