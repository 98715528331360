import { useSelector } from 'react-redux'
import { canUseCart } from '@/logic/app/reducer'
import { useData } from '@/logic/data'
import { RootState } from '@/redux/interfaces'
import { EPriceUom } from '@/types'
import { IMultiCartResponse } from '@/types/Cart'
import { IUseMultiCartOptions, TUseMultiCart } from './interfaces'

const mapState = (state: RootState) => ({
  canUserUseCart: canUseCart(state),
})

const defaultData: IMultiCartResponse = {
  carts: [],
  cartsItemsNumber: 0,
  cartsItemsTotalPrices: [{ value: 0, uom: EPriceUom.RUB }],
  cartsNumber: 0,
}

const defaultOptions: IUseMultiCartOptions = {
  shouldLoadForcibly: true,
}

export const useMultiCart: TUseMultiCart = ({ shouldLoadForcibly } = defaultOptions) => {
  const { canUserUseCart } = useSelector(mapState)
  const { data = defaultData, ...requestState } = useData<IMultiCartResponse>('multiCart', {
    shouldLoadForcibly,
    shouldNotLoadDataOnMount: !canUserUseCart,
  })

  return { data, ...requestState }
}
