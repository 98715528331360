import { createAction } from '@reduxjs/toolkit'
import { IResponseMeta } from '@/api/interfaces'
import { TApiUrlName, TRequestParams } from './interfaces'

export declare namespace NDataAction {
  interface ILoadData {
    name: TApiUrlName
    params?: TRequestParams
    shouldLoadForcibly?: boolean
    shouldNotShowLoadingState?: boolean
    shouldAccumulate?: boolean
    isLoadingOnMount?: boolean
  }

  interface ILoadingDataStarted {
    name: TApiUrlName
  }

  interface ILoadingDataFinished<GData = any> {
    data: GData
    name: TApiUrlName
    shouldAccumulate?: boolean
  }

  interface ILoadingDataFailed {
    name: TApiUrlName
    meta: IResponseMeta
  }

  interface IClearData {
    name: TApiUrlName
  }
}

export const loadData = createAction<NDataAction.ILoadData>('@@data/LOAD_DATA')

export const loadingDataFinished = createAction<NDataAction.ILoadingDataFinished>('@@data/LOADING_DATA_FINISHED')

export const loadingDataFailed = createAction<NDataAction.ILoadingDataFailed>('@@data/LOADING_DATA_FAILED')

export const loadingDataStarted = createAction<NDataAction.ILoadingDataStarted>('@@data/LOADING_DATA_STARTED')

export const clearData = createAction<NDataAction.IClearData>('@@data/CLEAR_DATA')
