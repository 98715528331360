/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateSkuPromotionFullRequestDataCC = {
    name?: string;
    description?: string;
    serviceName?: string;
    startPromotion?: string;
    endPromotion?: string;
    type?: UpdateSkuPromotionFullRequestDataCC.type;
};

export namespace UpdateSkuPromotionFullRequestDataCC {

    export enum type {
        ACTIVE = 'ACTIVE',
        DRAFT = 'DRAFT',
        ARCHIVE = 'ARCHIVE',
    }


}

