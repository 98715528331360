/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ChatMessageData = {
    id: number;
    text: string;
    author: ChatMessageData.author;
    date: string;
    read: boolean;
};

export namespace ChatMessageData {

    export enum author {
        COMPANION = 'COMPANION',
        ME = 'ME',
    }


}

