import React, { useContext } from 'react'
import { AccreditationContext } from '@/logic/accreditation/constants'
import { injectMessages } from '@/logic/translation/utils'
import { AccreditationBanner } from '..'
import { IRejectedAccreditationBannerProps } from './interfaces'
import messages from './messages.json'

injectMessages(messages)

const RejectedAccreditationBanner: React.FC<IRejectedAccreditationBannerProps> = ({ comment }) => {
  const { openRejectedEntityHintModal } = useContext(AccreditationContext)

  if (!comment) return null

  return (
    <AccreditationBanner
      titleId="userAccreditation.rejectedAccreditationBanner.title"
      buttonTextId="userAccreditation.rejectedAccreditationBanner.button"
      onButtonClick={() => openRejectedEntityHintModal(comment, 'accreditation')}
    />
  )
}

export default RejectedAccreditationBanner
