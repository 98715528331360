import React, { FC, memo } from 'react'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import cn from 'classnames'
import { Button, Icon } from '@/components/ui'
import { FormUploadButton } from '@/components/ui/ReactHookFormFields/FormUploadButton'
import { formatLocalizedString } from '@/utils/formatUtils'
import { isFileGreaterThenMb } from '@/utils/validators'
import { messages } from './messages'
import { IFileActionPanelProps } from './types'
import './styles.scss'

const FileActionPanel: FC<IFileActionPanelProps> = props => {
  const { file, accept, maxFileSizeMB, onDownload, className, name, onRemove } = props

  return (
    <div className={cn('FileActionPanel', className)}>
      <div className="FileActionPanel__name text">
        <Icon name={EIconName.OkFill} />
        {file.name}
      </div>
      <div className="FileActionPanel__actions">
        <Button className="FileActionPanel__actions-item" isUnstyled onClick={onDownload} iconProps={{ name: EIconName.Download }} />
        <FormUploadButton
          name={name}
          accept={accept}
          rules={{
            validate: {
              maxFileSize: doc => {
                // prettier-ignore
                return maxFileSizeMB && doc && isFileGreaterThenMb(doc, maxFileSizeMB)
                  ? formatLocalizedString(messages.invalidMaxFileSize, { size: maxFileSizeMB })
                  : true
              },
            },
          }}
          buttonProps={{ isUnstyled: true, className: 'FileActionPanel__actions-item', iconProps: { name: EIconName.Update } }}
        />
        <Button className="FileActionPanel__actions-item" isUnstyled onClick={onRemove} iconProps={{ name: EIconName.Delete }} />
      </div>
    </div>
  )
}

export default memo(FileActionPanel)
