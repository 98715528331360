import { createReducer, createSelector, PayloadAction } from '@reduxjs/toolkit'
import pick from 'lodash/pick'
import { RootState } from '@/redux/interfaces'
import { isNonNullable } from '@/typeguards/common'
import { EUserPermission } from '@/types'
import { getJwtExpirationTime, getTokenInfo } from '@/utils/helpers'
import {
  authDialogClosed,
  authImpersonationRequestFailed,
  authImpersonationRequestFinished,
  authRequestFailed,
  authRequestFinished,
  authRequestStarted,
  clearError,
  clearSession,
  NAuthAction,
  registrationRequestFailed,
  registrationRequestSuccess,
  resetAuthState,
  sendCodeBySms,
  sendingSmsRequestFailed,
  sendingSmsRequestFinished,
  sendRegistrationRequest,
  setJwt,
  setSendingCodeState,
  submitLoginForm,
} from './actions'
import { getProfilePathByPermissions, hasPermission, isInnOfIndividualEntrepreneur } from './helpers'
import { AuthPopupState, AuthState, IUser } from './interfaces'

const initialPopupState: AuthPopupState = {
  isFetching: false,
  isCodeSentSuccessfully: false,
  isCodeSentUnsuccessfully: false,
  isSendingCodeInProcess: false,
  canSendCodeAgain: true,
  shouldRegisterUser: false,
  isRegistrationInProgress: false,
  error: {},
}

const initialState: AuthState = {
  isAuthenticated: false,
  profilePath: '/profile',
  isImpersonationUser: false,
  impersonationRequestErrorStatus: '',
  isUserAdmin: false,
  isUserAgrarian: false,
  isUserBuyer: false,
  isUserSupplier: false,
  isUserContentManager: false,
  isUserLogisticsOperator: false,
  ...initialPopupState,
}

const userPropertiesFromJwt = [
  'userId',
  'firstName',
  'fullName',
  'lastName',
  'middleName',
  'email',
  'organizationId',
  'organizationInn',
  'organizationName',
  'role',
  'permissions',
  'adminPhone',
  'sub',
  'organizationHolding',
]

export default createReducer(initialState, {
  [submitLoginForm.type]: state => {
    state.isFetching = true
    state.authErrorStatus = undefined
  },
  [authRequestStarted.type]: state => {
    state.isFetching = true
  },
  [authRequestFinished.type]: state => {
    state.isFetching = false
  },
  [clearSession.type]: () => initialState,
  [setJwt.type]: (state, { payload }) => {
    const { jwt } = payload
    const user = pick(getTokenInfo(jwt)?.payload, userPropertiesFromJwt) as IUser
    const { permissions = [] } = user

    state.user = user
    state.profilePath = getProfilePathByPermissions(permissions)
    state.isUserAdmin = hasPermission(permissions, EUserPermission.ADMIN)
    state.isUserAgrarian = hasPermission(permissions, EUserPermission.AGRARIAN)
    state.isUserBuyer = hasPermission(permissions, EUserPermission.BUYER)
    state.isUserSupplier = hasPermission(permissions, EUserPermission.SUPPLIER)
    state.isUserContentManager = hasPermission(permissions, EUserPermission.CONTENT_MANAGER)
    state.isUserLogisticsOperator = hasPermission(permissions, EUserPermission.LOGISTICS_OPERATOR)
    state.jwt = jwt
    state.jwtSetTime = Date.now()
    state.sessionTime = getJwtExpirationTime(jwt) - Date.now()
    state.isAuthenticated = true
    state.isFetching = false
    state.isCodeSentSuccessfully = false
    state.isCodeSentUnsuccessfully = false
    state.isImpersonationUser = Boolean(user.adminPhone)
    state.isUserIndividualEntrepreneur = isNonNullable(user.organizationInn) && isInnOfIndividualEntrepreneur(user.organizationInn)
  },
  [authRequestFailed.type]: (state, { payload }: PayloadAction<NAuthAction.ISetAuthErrorStatus>) => {
    state.authErrorStatus = payload.errorStatus
    state.isFetching = false
    state.isCodeSentUnsuccessfully = false
  },
  [sendCodeBySms.type]: state => {
    state.isCodeSentUnsuccessfully = false
    state.isCodeSentSuccessfully = false
    state.isSendingCodeInProcess = true
    state.authErrorStatus = undefined
  },
  [sendingSmsRequestFinished.type]: (state, { payload }: PayloadAction<NAuthAction.ISendingSmsRequestFinishedPayload>) => {
    state.isCodeSentSuccessfully = true
    state.isSendingCodeInProcess = false
    state.tokenId = payload.tokenId
  },
  [sendingSmsRequestFailed.type]: (state, { payload }: PayloadAction<NAuthAction.ISendingSmsRequestFailedPayload>) => {
    state.isCodeSentUnsuccessfully = true
    state.isSendingCodeInProcess = false
    state.shouldRegisterUser = payload.shouldRegisterUser
  },
  [setSendingCodeState.type]: (state, { payload }: PayloadAction<NAuthAction.ISetSendingSmsCodeStatePayload>) => {
    state.timeUntilSendingCode = payload.timeUntilSendingCode
    state.canSendCodeAgain = payload.canSendCodeAgain
    if (payload.canSendCodeAgain) state.isCodeSentUnsuccessfully = false
  },
  [sendRegistrationRequest.type]: state => {
    state.isRegistrationInProgress = true
    state.isRegistrationSuccessful = undefined
    state.error = {}
  },
  [registrationRequestSuccess.type]: (state, { payload }: PayloadAction<NAuthAction.IRegistrationSuccessPayload>) => {
    state.isRegistrationInProgress = false
    state.isRegistrationSuccessful = true
    state.isCodeSentUnsuccessfully = false
    state.isCodeSentSuccessfully = true
    state.tokenId = payload.tokenId
  },
  [registrationRequestFailed.type]: (state, { payload }: PayloadAction<NAuthAction.IRegistrationRequestFailedPayload>) => {
    state.isRegistrationInProgress = false
    state.isRegistrationSuccessful = false
    state.error = payload
  },
  [authDialogClosed.type]: state => ({
    ...state,
    ...initialPopupState,
  }),
  [resetAuthState.type]: () => ({
    ...initialState,
  }),
  [authImpersonationRequestFinished.type]: state => {
    state.impersonationRequestErrorStatus = ''
  },
  [authImpersonationRequestFailed.type]: (state, { payload }) => {
    state.impersonationRequestErrorStatus = payload.status
  },
  [clearError.type]: state => {
    state.error = {}
  },
})

export const isFetching = () => (state: RootState) => state.auth.isFetching
export const isAuthenticated = () => (state: RootState) => state.auth.isAuthenticated
export const getJwt = () => (state: RootState) => state.auth.jwt
export const getUser = () => (state: RootState) => state.auth.user
export const getUserPermissions = createSelector(getUser(), user => user?.permissions || [])
export const getJwtSetTime = () => (state: RootState) => state.auth.jwtSetTime
export const getSessionTime = () => (state: RootState) => state.auth.sessionTime
export const getAuthErrorStatus = () => (state: RootState) => state.auth.authErrorStatus
export const isCodeSentSuccessfully = () => (state: RootState) => state.auth.isCodeSentSuccessfully
export const isCodeSentUnsuccessfully = () => (state: RootState) => state.auth.isCodeSentUnsuccessfully
export const isSendingCodeInProcess = () => (state: RootState) => state.auth.isSendingCodeInProcess
export const canSendCodeAgain = () => (state: RootState) => state.auth.canSendCodeAgain
export const getAuthTokenId = () => (state: RootState) => state.auth.tokenId
export const getTimeUntilSendingCode = () => (state: RootState) => state.auth.timeUntilSendingCode
export const shouldRegisterUser = () => (state: RootState) => state.auth.shouldRegisterUser
export const isRegistrationInProgress = () => (state: RootState) => state.auth.isRegistrationInProgress
export const isRegistrationSuccessful = () => (state: RootState) => state.auth.isRegistrationSuccessful
export const getProfilePath = () => (state: RootState) => state.auth.profilePath
export const isImpersonationUser = () => (state: RootState) => state.auth.isImpersonationUser
export const getImpersonationRequestErrorStatus = () => (state: RootState) => state.auth.impersonationRequestErrorStatus
export const isUserAdmin = () => (state: RootState) => state.auth.isUserAdmin
export const isUserAgrarian = () => (state: RootState) => state.auth.isUserAgrarian
export const isUserBuyer = () => (state: RootState) => state.auth.isUserBuyer
export const isUserSupplier = () => (state: RootState) => state.auth.isUserSupplier
export const isUserLogisticsOperator = () => (state: RootState) => state.auth.isUserLogisticsOperator
export const isUserContentManager = () => (state: RootState) => state.auth.isUserContentManager
export const getError = () => (state: RootState) => state.auth.error
export const isUserIndividualEntrepreneur = () => (state: RootState) => state.auth.isUserIndividualEntrepreneur
export const getRootState = () => (state: RootState) => state

export const isUserManager = createSelector([isUserSupplier(), isUserBuyer(), isUserAdmin()], (...roles) => roles.some(Boolean))
