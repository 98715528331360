import { TMessages } from '@/types/TMessages'

export const messages = {
  options: {
    replenishmentOfWorkingCapital: 'Пополнение оборотных средств',
    purchaseOfFixedAssets: 'Приобретение основных средств',
    purchaseOfGoodsAndMaterials: 'Приобретение ТМЦ',
    refinancingCurrentLoans: 'Рефинансирование текущих кредитов',
    businessDevelopment: 'Развитие бизнеса',
    other: 'Иные цели',
  },
  multiSelect: {
    placeholder: 'Цели кредита',
  },
  otherPurposeInput: {
    placeholder: 'Иные цели кредита',
  },
} satisfies TMessages
