import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import { RootState } from '@/redux/interfaces'
import { ICart, IMultiCartResponse, IOrderPaymentVariant, TCartId } from '@/types/Cart'
import { getRequestData } from '../data/reducer'
import { isCartProductsSameVatRate } from './helpers'

export const getCartRequest = () => (state: RootState) => getRequestData<ICart>('cart')(state)
export const getPaymentVariantsOnRegionCode = () => (state: RootState) =>
  getRequestData<IOrderPaymentVariant[]>('paymentVariants')(state).data
export const getMultiCartResponse = () => (state: RootState) => getRequestData<IMultiCartResponse>('multiCart')(state)?.data

export const getCartIdOfProduct = createSelector(
  getMultiCartResponse(),
  (_state: RootState, productId: string) => productId,
  (cartResponse, productId) => {
    const carts = cartResponse?.carts
    if (!Array.isArray(carts)) return undefined

    for (const cart of carts) {
      if (cart.items.some(item => item.productOffer.id === productId)) {
        return cart.id
      }
    }

    return undefined
  },
)

export const getCartItemsById = createSelector(
  getMultiCartResponse(),
  (_state: RootState, cartId: TCartId) => cartId,
  (response, cartId) => response?.carts.find(cart => cart.id === cartId),
)

export const getCartPositionById = createSelector(
  getMultiCartResponse(),
  (_state: RootState, cartId?: TCartId) => cartId,
  (response, cartId) => (cartId ? (response?.carts.findIndex(cart => cart.id === cartId) || 0) + 1 : undefined),
)

export const getStoreAddressByCardId = createSelector(
  getMultiCartResponse(),
  (_state: RootState, cartId?: TCartId) => cartId,
  (multiCart, cartId) => multiCart?.carts.find(({ id }) => id === cartId)?.deliveryOptions?.supplierStore?.location.address || '',
)

export const getCartData = createSelector(getCartRequest(), ({ data }) => data)
export const getDeliveryOptions = createSelector(getCartData, data => data?.deliveryOptions)
export const getCurrentCartStoreAddress = createSelector(getDeliveryOptions, options => options?.supplierStore?.location.address)
export const getCartItems = createSelector(getCartData, data => data?.items || [])
export const getDeliveryConditionsText = createSelector(getCartData, data => data?.deliveryConditions?.description)
export const isProductsSameVatRate = createSelector(getCartItems, isCartProductsSameVatRate)
export const getPaymentVariants = createSelector(getCartData, data => data?.payment || [])
export const getDeliveryRegions = createSelector(getCartItems, items =>
  uniq(items.reduce((result: number[], item) => [...result, ...map(item.productOffer.deliveryRegions, 'code')], [])),
)
export const getCartItemsQuantityById = createSelector(
  getRequestData<IMultiCartResponse>('multiCart'),
  (_state: RootState, id: string) => id,
  ({ data }, id) => {
    const { carts } = data || {}
    if (!carts) return 0

    for (let i = 0; i < carts.length; i += 1) {
      const item = find(carts[i]!.items, { productOffer: { id } })
      if (item) return item.quantity
    }

    return 0
  },
)
export const isCartEmpty = createSelector([getCartItems, getCartRequest()], (items, { isLoading }) => items.length === 0 && !isLoading)
