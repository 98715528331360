import React, { createElement, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TRichTextElements } from '@/logic/translation/interfaces'
import { IInjectHtmlWithFormatProps } from './interfaces'

const InjectHtmlWithFormat: React.FC<IInjectHtmlWithFormatProps> = ({ textId, textValues }) => {
  const { formatMessage } = useIntl()

  const formValues = useMemo(() => {
    const message = formatMessage({ id: textId })
    const tempDiv: HTMLDivElement = document.createElement('div')
    tempDiv.innerHTML = message
    const tempDivChildren = tempDiv.getElementsByTagName('*')
    const tags: TRichTextElements = {}

    // eslint-disable-next-line no-restricted-syntax
    for (const tag of Array.from(tempDivChildren)) {
      tags[tag.localName] = chunks => createElement(tag.localName, { className: tag.className }, ...chunks)
    }

    return { ...tags, ...textValues }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(textValues), textId, formatMessage])

  return <FormattedMessage id={textId} values={formValues} />
}

export default InjectHtmlWithFormat
