
export enum FundingProductFiltersOptions {
    "REPAYMENT_AT_THE_END" = "REPAYMENT_AT_THE_END",
    "UNSECURED" = "UNSECURED",
    "PREFERENTIAL_RATES" = "PREFERENTIAL_RATES"
}
export type FundingProductFilters = {
    /**
     * @type integer int32
    */
    regionCode: number;
    /**
     * @type array
    */
    options: FundingProductFiltersOptions[];
};
