import { useSelector } from 'react-redux'
import { RootState } from '@/redux/interfaces'
import { IRegion } from '@/types'
import { useData } from '../data'
import { getUserRegion } from './reducer'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

export const useRegionItemDropdownOptions = () => {
  const { userRegion } = useSelector(mapState)
  const { data: regions = [], ...states } = useData<IRegion[]>('regions')

  return { userRegion, regionOptions: regions.map(region => ({ value: region, label: region.name })), ...states }
}
