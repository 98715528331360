/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Результат
 */
export type NotificationData = {
    count?: number;
    type?: NotificationData.type;
};

export namespace NotificationData {

    export enum type {
        UNREAD_MESSAGES_COUNT = 'UNREAD_MESSAGES_COUNT',
        UNREAD_WEB_NOTIFICATION_COUNT = 'UNREAD_WEB_NOTIFICATION_COUNT',
        UNREAD_FUNDING_MESSAGES_COUNT = 'UNREAD_FUNDING_MESSAGES_COUNT',
        UNWATCHED_FUNDING_DOCUMENTS_COUNT = 'UNWATCHED_FUNDING_DOCUMENTS_COUNT',
    }


}

