/* eslint-disable no-underscore-dangle */
import { RootState } from '@/redux/interfaces'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'

export const preloadedState = ExecutionEnvironment.isSsr ? ({} as Partial<RootState>) : { ...window.__PRELOADED_STATE__ }

if (!ExecutionEnvironment.isSsr) {
  //@ts-ignore
  delete window.__PRELOADED_STATE__
}
