import React from 'react'
import { history } from '../../../browserHistory'
import { Button } from '..'
import { useAuthenticateUser } from './hooks'
import { IAuthButtonOwnProps } from './interfaces'

const AuthButton: React.FC<IAuthButtonOwnProps> = props => {
  const { children, buttonProps, onClick, shouldHideForAuthenticatedUser, shouldRedirectToProfileIfAuth } = props
  const { profilePath, isAuthenticated, logoutUser, openAuthModal } = useAuthenticateUser()
  if (shouldHideForAuthenticatedUser && isAuthenticated) return null

  return (
    <Button
      isUnstyled
      {...buttonProps}
      onClick={e => {
        if (shouldRedirectToProfileIfAuth && profilePath && isAuthenticated) history.push(profilePath)
        else if (isAuthenticated) logoutUser()
        else openAuthModal()
        onClick?.(isAuthenticated, e)
      }}
    >
      {children}
    </Button>
  )
}

export default AuthButton
