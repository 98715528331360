import { ReactNode } from 'react'
import { ISimpleDetail } from '@/components/ui/SimpleDetails/interfaces'
import { TApiUrlName } from '@/logic/data/interfaces'
import { IDiscountData } from '@/types'
import { ESaleRequestType } from '@/types/ESaleRequestType'
import { IHarvestSaleQuality } from '@/types/HarvestSaleLots'

export interface IAgrarianSale {
  requestId: string
  saleRequestType: ESaleRequestType
  saleRequestApiName: TApiUrlName
  isMultiRequest: boolean
}

export type TQualityFieldControl = (detail: ISimpleDetail) => ReactNode

export interface IAgrarianSaleRequestFormValues {
  deliveryBasisId: string
  elevatorId: string
  price: string
  quality: IHarvestSaleQuality
  storeId: string
  volume: string
  deliveryRange: [string, string]
  prepaymentPercentage?: number
  paymentType: string
  discount?: string
  transportType?: string
  changeType?: string
  requestDiscountData?: {
    discountData: IDiscountData[]
    cultureId: string
    limitQualityIndicators: {
      proteinFrom: string
      fallingNumber: string
      nature: string
      weedAdmixture: string
      humidity: string
      grainAdmixture: string
      ergot: string
      gluten: string
      damagedByTurtleBugSeedsContent: string
    }
  }
}

export type TTransportTypeOptions = 'UNDEFINED' | 'AUTOMOBILE' | 'RAILWAY'

export type TAgrarianSaleRequestFormValues =
  | 'deliveryBasisId'
  | 'elevatorId'
  | 'price'
  | 'quality'
  | 'storeId'
  | 'volume'
  | 'deliveryRange'
  | 'prepaymentPercentage'
  | 'transportType'

export enum ERequestActiveTabIndex {
  details = 0,
  chat = 1,
  shipping = 2,
  documents = 3,
  history = 4,
}
export enum EMultiRequestActiveTabIndex {
  details = 0,
  chat = 1,
  history = 2,
}

export type TRequestTab = 'details' | 'documents' | 'shipping' | 'chat' | 'history'
export type TMultiRequestTab = 'details' | 'chat' | 'history'

export type TDeliveryBasisId = 'wagon' | 'exw' | 'pickup' | 'elevator'

export type TDiscountsId = 'PROTEIN_CONTENT' | 'FALLING_NUMBER'

export enum EDeliveryBasisName {
  wagon = 'Франко-вагон (FCA)',
  exw = 'Перепись (EXW)',
  pickup = 'Со склада хозяйства (FCA)',
  elevator = 'Элеватор/порт (CPT)',
}

//TODO https://jira.poleinvest.ru/browse/OUT-771
export enum EDiscountsName {
  // eslint-disable-next-line max-len
  PROTEIN_CONTENT = 'Скидка применяется, только если окончательное значение ниже Базисного значения более чем на 0.2 процентного пункта, и начисляется за каждые 0.1 процентного пункта ниже Базисного значения',
  FALLING_NUMBER = 'Окончательное значение ниже Базисного значения',
}
