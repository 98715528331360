import React from 'react'
import classNames from 'classnames'
import { IProductsListProps } from './interfaces'
import './styles.scss'

const ProductsList: React.FC<IProductsListProps> = props => {
  const { items, title, className, textClassName = 'text_small-sm' } = props

  return (
    <div className={classNames(className, 'productsContainer')}>
      {title && <p className="text_small color_pale-black">{title}</p>}

      {items.map(({ name, packageType, quantity }) => {
        return (
          <div key={name} className={classNames(textClassName, 'orderProduct')}>
            <span className="orderProduct__name">{name}</span>
            <span className="orderProduct__weight">
              {packageType.volume * quantity}
              {packageType.unitCode}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default ProductsList
