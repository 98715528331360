import { CalculatedPaymentInstallmentAmount, OrganizationData, UserOrganizationData } from '@/api/generated/base'
import { ESignType } from '@/logic/signs/selectSignType/types'
import { IPrice, IStore } from '@/types'
import { EOrderPaymentVariantType } from '@/types/Cart'
import { TProductCategoryId } from '@/types/Catalog'
import { ESkuOrderType } from '@/types/Catalog/ESkuOrderType'
import { EOrderDeliveryType, IOrderCategory, IOrderItem, IOrderStatus, IOrderSupply } from '@/types/Order'
import { IProductInfo } from '@/types/Order/IProductInfo'

export interface IAgrarianOrder {
  id: number
  creationDate: string
  updateDate: string
  status: IOrderStatus
  sumPrice: IPrice
  sumPricePrevious?: IPrice
  supplier: OrganizationData
  category?: IOrderCategory
  deliveryOptions: {
    store?: IStore
    storePrevious?: IStore
    supplierStore?: IStore
    supplierStorePrevious?: IStore
    startDate: string
    startDatePrevious?: string
    endDate: string
    endDatePrevious?: string
    deliveryType: EOrderDeliveryType
    deliveryTypePrevious?: EOrderDeliveryType
  }
  productInfoData: IProductInfo
  statusComment?: string
  items: IOrderItem[]
  supplies?: Array<IOrderSupply>
  paymentType?: EOrderPaymentVariantType
  paymentTypeName?: string
  signValidTo: string
  agrarianOrganization?: UserOrganizationData
  consignee?: string
  consigneePrevious?: string
  vatAmount: IPrice
  vatAmountPrevious?: IPrice
  prepaymentPercentage: number
  prepaymentPercentagePrevious?: number
  prepaymentAmount: IPrice
  paymentAmount: IPrice
  offerCategoryId?: TProductCategoryId
  typeOrder: ESkuOrderType
  countActiveMultiOrders?: number
  agentId?: string
  signMethod: ESignType
  validityPeriod: number
  calculatedPaymentInstallmentAmountList?: CalculatedPaymentInstallmentAmount[]
}

export enum ESupplierSignType {
  ANY = 'ANY',
  PEP = 'PEP',
  UKEP = 'UKEP',
}
