import { LOCATION_CHANGE } from 'connected-react-router'
import { ofType } from 'redux-observable'
import { pairwise, skipWhile, tap } from 'rxjs/operators'
import { TLocationChangeAction, TLocationChangeEpic } from '@/types'
import { filterChangingLocationPathname } from '@/utils/rxjs/filter'
import { getEventContextGtm, getUserGtmInfo, pushPageViewGtmEvent } from '../helpers'

const onLocationChangedDoPushUserInfoToGtm: TLocationChangeEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    pairwise<TLocationChangeAction>(),
    filterChangingLocationPathname(),
    tap(([, { payload }]) => {
      pushPageViewGtmEvent({
        eventContext: getEventContextGtm(payload.location.pathname, state$.value.app.prevLocation),
        ...getUserGtmInfo()(state$.value),
      })
    }),
    skipWhile(() => true),
  )

export default onLocationChangedDoPushUserInfoToGtm
