import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { formats } from '@/utils/translation'
import { setMessages } from './actions'
import { IIntlState, TLocaleMessages } from './interfaces'

const initialState: IIntlState = {
  locale: 'ru',
  messages: {},
  formats,
}

const reducer = createReducer(initialState, {
  [setMessages.type]: (state, { payload: locales }: PayloadAction<TLocaleMessages>) => {
    state.messages = { ...state.messages, ...locales[state.locale] }
  },
})

export default reducer
