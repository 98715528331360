import { requestData, requestDataWithRawResponse } from '@/api/request'
import { IDocumentsResponse } from '@/services/documents/interfaces'
import { downloadFileFromResponse } from '@/utils/files'
import { getUrlWithQuery } from '@/utils/url'

interface IUploadDocumentOptions {
  file: File
  name: string
  type: string
  id: number
  version?: string
}

interface IUpdateDocumentOptions {
  file: File
  name: string
  type: string
  id: number
  version?: string
  documentId: string
}

interface IDeleteDocumentOptions {
  id: number
  version?: string
  documentId: string
}

/**
 * Сервис работы с документами заказа в inputs
 */
export default class OrderDocumentsService {
  /**
   * Загрузка документа в заказ
   * @param  {Object} options
   * @prop  {File} options.file - файл
   * @prop  {string} options.name - имя файла
   * @prop  {string} options.type - тип файла
   * @prop  {number} options.id - идентификатор заказа
   * @prop  {string} options.version - версия запроса, поле updateDate в заказе
   */
  uploadDocument = ({ file, name, type, id, version }: IUploadDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: getUrlWithQuery(`/api/orders/${id}/documents`, { version }),
      method: 'POST',
      body: formData,
      isJson: false,
    })
  }

  /**
   * Обновление документа в заказе
   * @param  {Object} options
   * @prop  {File} options.file - файл
   * @prop  {string} options.name - имя файла
   * @prop  {string} options.type - тип файла
   * @prop  {number} options.id - идентификатор заказа
   * @prop  {string} options.version - версия запроса, поле updateDate в заказе
   * @prop {string} options.documentId - идентификатор документа
   */
  updateDocument = ({ file, name, type, id, version, documentId }: IUpdateDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: getUrlWithQuery(`/api/orders/${id}/documents/${documentId}`, { version }),
      method: 'PUT',
      body: formData,
      isJson: false,
    })
  }

  /**
   * Удаление документа из заказа
   * @param  {Object} options
   * @prop  {number} options.id - идентификатор заказа
   * @prop  {string} options.version - версия запроса, поле updateDate в заказе
   * @prop {string} options.documentId - идентификатор документа
   */
  deleteDocument = ({ id, version, documentId }: IDeleteDocumentOptions) =>
    requestData({
      url: getUrlWithQuery(`/api/orders/${id}/documents/${documentId}`, { version }),
      method: 'DELETE',
    })

  /**
   * Скачивание документа в заказе
   * @param  {string} url - версия запроса, поле updateDate в заказе
   * @param {string} fileName - идентификатор документа
   */
  downloadDocument = (url: string, fileName: string) =>
    requestDataWithRawResponse<Blob>({ url, method: 'GET' }).then(response => {
      downloadFileFromResponse(response, fileName)
    })

  /**
   * Запрос документов заказа
   * @param {number} id  - идентификатор заказа
   */
  fetchDocuments = (id: number) =>
    requestData<IDocumentsResponse>({
      url: getUrlWithQuery(`/api/orders/${id}/documents`, { limit: 100 }),
      method: 'GET',
    })
}
