import React, { memo, useMemo, useState } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useRegions } from '@/api/kubik/credit'
import { transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import { EDirectoryRegions } from '@/components/ui/fields/RegionSelect/constants'
import { HookFormDropdown } from '@/components/ui/ReactHookFormFields/Dropdown'
import { getRegions } from '@/logic/userRegion'
import { RootState } from '@/redux/interfaces'
import { IRegion } from '@/types'
import { messages } from './messages'
import { IRegionSelectProps } from './types'
import './styles.scss'

const mapState = (state: RootState) => ({
  regions: getRegions(state),
})

const RegionSelect = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
  props: IRegionSelectProps<TFieldValues, TName>,
) => {
  const {
    name,
    isHidden,
    containerClassName,
    modifiers = ['white'],
    directoryRegions = EDirectoryRegions.POLE,
    placeholder = messages.placeholder,
  } = props
  const { regions: directoryRegionsPole } = useSelector(mapState)
  const [regions, setRegions] = useState<readonly IRegion[]>()

  const { data: directoryRegionsSber = [] } = useRegions({ query: { enabled: directoryRegions === EDirectoryRegions.SBER } })

  const setDirectoryRegionsSber = async () => {
    if (directoryRegionsSber.length) {
      setRegions(directoryRegionsSber.map(region => ({ code: region.id, name: region.name })))
    }
  }

  if (!regions?.length) {
    switch (directoryRegions) {
      case EDirectoryRegions.SBER:
        setDirectoryRegionsSber()
        break
      default:
        if (directoryRegionsPole) setRegions(directoryRegionsPole)
    }
  }

  const regionOptions = useMemo(() => transformOptions<IRegion, number>({ options: regions, valueKey: 'code' }), [regions])

  if (isHidden) {
    return null
  }

  return (
    <HookFormDropdown
      {...props}
      containerClassName={classNames('regionSelect', containerClassName)}
      name={name}
      options={regionOptions}
      modifiers={modifiers}
      label={placeholder}
    />
  )
}

export const HookFormRegionSelect = memo(RegionSelect) as typeof RegionSelect
