import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { EExactFarmingProductId } from '@/types'
import { ExactFarmingClickGtmEventPayload } from './gtmConstants'
import { IExactFarmingOffer } from './interfaces'

export const ExactFarmingOffer: Record<EExactFarmingProductId, IExactFarmingOffer> = {
  ef_diff: {
    id: 'ef_diff',
    formId: 'poleDiffForm',
    url: '/agroservices/polediff',
    condition: 'exactFarmingOtherOffersSection.poleDiff.condition',
    name: 'exactFarmingOtherOffersSection.poleDiff.name',
    description: 'exactFarmingOtherOffersSection.poleDiff.description',
    price: null,
    bottomText: 'exactFarmingOtherOffersSection.poleDiff.bottomText',
    onLeaveRequestPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.differentiation),
    onMoreButtonClickPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.buttonMorePoleDifferentiation),
  },
  ef_plus: {
    id: 'ef_plus',
    formId: 'polePlusForm',
    url: '/agroservices/poleplus',
    condition: 'exactFarmingOtherOffersSection.polePlus.condition',
    name: 'exactFarmingOtherOffersSection.polePlus.name',
    price: null,
    description: 'exactFarmingOtherOffersSection.polePlus.description',
    onLeaveRequestPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.polePlus),
    onMoreButtonClickPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.buttonMoreDetailsPolePlus),
  },
  ef_scout: {
    id: 'ef_scout',
    formId: 'poleScoutingForm',
    url: '/agroservices/polescouting',
    condition: 'exactFarmingOtherOffersSection.poleScouting.condition',
    name: 'exactFarmingOtherOffersSection.poleScouting.name',
    description: 'exactFarmingOtherOffersSection.poleScouting.description',
    price: null,
    bottomText: 'exactFarmingOtherOffersSection.poleScouting.bottomText',
    onLeaveRequestPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.poleScouting),
    onMoreButtonClickPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.buttonMorePoleScouting),
  },
  ef_expert: {
    id: 'ef_expert',
    formId: 'poleExpertForm',
    url: '/agroservices/poleexpert',
    condition: 'exactFarmingOtherOffersSection.poleExpert.condition',
    name: 'exactFarmingOtherOffersSection.poleExpert.name',
    description: 'exactFarmingOtherOffersSection.poleExpert.description',
    price: null,
    bottomText: 'exactFarmingOtherOffersSection.poleExpert.bottomText',
    onLeaveRequestPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.poleExpert),
    onMoreButtonClickPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.buttonMoreDetailsPoleExpert),
  },
  soil_landscape_survey: {
    id: 'soil_landscape_survey',
    formId: 'farmlandCropValuationForm',
    url: '/agroservices/farmland-crop-valuation',
    name: 'exactFarmingOtherOffersSection.farmlandCropValuation.name',
    description: 'exactFarmingOtherOffersSection.farmlandCropValuation.description',
    onLeaveRequestPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.farmlandCropValuation),
    onMoreButtonClickPushGtmEvent: () => pushFormattedGtmEvent(ExactFarmingClickGtmEventPayload.buttonMoreDetailsFarmlandCropValuation),
  },
}
