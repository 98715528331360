import React from 'react'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { IResponsiveViewProps } from './interfaces'

function ResponsiveView<Props extends object = {}>({ Desktop, Tablet, Mobile, componentsProps }: IResponsiveViewProps<Props>) {
  const { isDesktop, isTablet, isMobile } = useDeviceType()
  if (isDesktop || (isTablet && !Tablet)) return <Desktop {...(componentsProps as Props)} />
  if (isTablet && Tablet) return <Tablet {...(componentsProps as Props)} />
  if (isMobile) return <Mobile {...(componentsProps as Props)} />

  return null
}

export default ResponsiveView
