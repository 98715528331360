import type { TemplateDataDocumentClauseDto } from "./TemplateDataDocumentClauseDto";
import type { DocumentTemplateCommentDto } from "./DocumentTemplateCommentDto";

export enum PurchaseOfferTemplateDataResponseStatus {
    "DRAFT" = "DRAFT",
    "ON_APPROVAL" = "ON_APPROVAL",
    "PROPOSED_CHANGES" = "PROPOSED_CHANGES",
    "UNDER_REVIEW" = "UNDER_REVIEW",
    "PUBLISHED" = "PUBLISHED"
}
export type PurchaseOfferTemplateDataResponse = {
    /**
     * @type integer int64
    */
    id: number;
    /**
     * @type string
    */
    name: string;
    /**
     * @type string
    */
    organizationId: string;
    /**
     * @type string | undefined
    */
    organizationName?: string;
    /**
     * @type string
    */
    typeId: string;
    /**
     * @type string
    */
    status: PurchaseOfferTemplateDataResponseStatus;
    /**
     * @type array
    */
    data: TemplateDataDocumentClauseDto[];
    /**
     * @type array
    */
    comments: DocumentTemplateCommentDto[];
    /**
     * @type string | undefined
    */
    contractorInn?: string;
    /**
     * @type string | undefined
    */
    contractorName?: string;
};
