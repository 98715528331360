import React, { FC, memo } from 'react'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import { ProductPrice } from '@/components/composed'
import { HintWrapper } from '@/components/ui'
import { IPrice } from '@/types'
import { EPriceVariant, IFcaPrice } from '@/types/HarvestSaleLots'
import { ILotPriceProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const LotPrice: FC<ILotPriceProps> = props => {
  const { price, priceVariant, hintMessageId, isMobileDevice, perUnitTextId, displayVariant } = props
  const isIndexVariant = displayVariant === 'index'
  const templatePrice: IPrice | undefined = priceVariant === EPriceVariant.STORE ? (price as IFcaPrice)?.price : (price as IPrice)

  return (
    <div className={classNames(isIndexVariant ? 'lot-price-index' : 'lot-price')}>
      {templatePrice ? (
        <ProductPrice
          className={classNames(isIndexVariant ? 'h5' : 'h4-from-sm h3-sm')}
          price={templatePrice}
          shouldLowerFraction={isMobileDevice}
          perUnitTextId={perUnitTextId}
          displayVariant={displayVariant}
        />
      ) : (
        <HintWrapper
          header={
            <h5 className="space-holder8-md">
              {messages.emptyPriceTip.hint.title[hintMessageId as keyof typeof messages.emptyPriceTip.hint.title]}
            </h5>
          }
          message={messages.emptyPriceTip.hint.description[hintMessageId as keyof typeof messages.emptyPriceTip.hint.description]}
          shouldDisplayHint={Boolean(hintMessageId)}
          shouldBeHintInModal={isMobileDevice}
        >
          <div className="lot-price-tip">
            <span className="lot-price-tip__text h4 h3-sm">—</span>
            <Icon name={EIconName.QuestFill} style={{ opacity: 0.15 }} />
          </div>
        </HintWrapper>
      )}
      <div className={classNames(isIndexVariant ? 'text_super-small lot-price-index__label' : 'text_small-from-sm')}>
        {messages.label[priceVariant as keyof typeof messages.label]}
      </div>
    </div>
  )
}

export default memo(LotPrice, isEqual)
