import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { getRemaining } from '@/utils/formatUtils'
import { ITimerProps, ITimerState } from './interfaces'

class Timer extends React.PureComponent<ITimerProps, ITimerState> {
  state = { seconds: getRemaining(this.props.defaultTimestamp, 1000) }
  // eslint-disable-next-line react/sort-comp
  timer: NodeJS.Timeout | null = null
  componentDidMount() {
    const { onComplete, defaultTimestamp } = this.props
    let counter = getRemaining(defaultTimestamp, 1000)

    if (counter <= 0) {
      onComplete?.()
    } else {
      this.timer = setInterval(() => {
        counter -= 1
        this.setState({ seconds: counter })
        if (counter <= 0) {
          onComplete?.()
          this.clearTimer()
        }
      }, 1000)
    }
  }

  componentWillUnmount() {
    this.clearTimer()
  }

  clearTimer = () => clearInterval(this.timer as NodeJS.Timeout)

  render() {
    const { seconds } = this.state
    const { className, textId } = this.props

    return (
      <p className={classNames('timer', className)}>
        <FormattedMessage id={textId} values={{ seconds }} />
      </p>
    )
  }
}

export default Timer
