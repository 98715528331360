import React from 'react'
import { Field } from 'react-final-form'
import CheckboxButtonsView from './CheckboxButtonsView'
import { ICheckboxButtonsGroupProps, TCheckboxButtonsGroupProps } from './interfaces'

const CheckboxButtons: React.FC<ICheckboxButtonsGroupProps> = props => (
  <Field<string[]> {...props}>{(fieldProps: TCheckboxButtonsGroupProps) => <CheckboxButtonsView {...fieldProps} {...props} />}</Field>
)

export default CheckboxButtons
