import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import isFunction from 'lodash/isFunction'
import { DotSpacer, InjectHtml } from '@/components/ui'
import { TextInput } from '../fields'
import { ISimpleDetailsProps } from './interfaces'
import './styles.scss'

const SimpleDetails: React.FC<ISimpleDetailsProps> = ({
  titleId,
  title,
  details,
  className,
  shouldEditValues,
  isPreviousOnTheLeft = false,
  detailsDescriptionId,
  editDetailsDescriptionId,
}) => {
  return (
    <section className={className}>
      {titleId && (
        <h4 className="space-holder40-from-md space-holder16-sm simple-details-title">
          <FormattedMessage id={titleId} />
        </h4>
      )}
      {title && <h4 className="space-holder40-from-md space-holder16-sm simple-details-title">{title}</h4>}
      <ul className="simple-details">
        {details.map(detail => {
          const {
            value,
            label,
            labelId,
            fieldName,
            inputProps,
            previousValue,
            getFieldControl,
            shouldEditValue,
            shouldShowIfEmpty,
            detailClassName = '',
            labelClassName = '',
            emptyValue,
          } = detail
          if (!shouldShowIfEmpty && !value && (!shouldEditValues || !shouldEditValue)) return null

          const isDetailEditable = shouldEditValues && shouldEditValue
          let fieldControl
          if (isDetailEditable) {
            fieldControl = isFunction(getFieldControl) ? (
              getFieldControl(detail)
            ) : (
              <TextInput
                name={fieldName as string}
                // defaultValue лучше задавать в initialValues формы, чтобы не было лишних апдейтов стейта
                modifiers={['outline-none']}
                {...inputProps}
                className="simple-details-property__input"
              />
            )
          }

          const isPreviousValuesVisible = !shouldEditValues && typeof previousValue !== 'undefined' && previousValue !== value

          const shouldPreviousValuesBeOnTheRight = isPreviousValuesVisible && !isPreviousOnTheLeft
          const shouldPreviousValuesBeOnTheLeft = isPreviousValuesVisible && isPreviousOnTheLeft

          return (
            <React.Fragment key={labelId as string}>
              {shouldPreviousValuesBeOnTheRight && (
                <span className="simple-details-property__value_previous text_small text_strike color_red">{previousValue}</span>
              )}
              <li
                className={classNames('simple-details-property text_small', detailClassName, {
                  'simple-details-property_editing': shouldEditValues,
                })}
              >
                <span
                  className={classNames('simple-details-property__label text_nowrap-from-sm', labelClassName, {
                    'simple-details-property__label_editing': shouldEditValues,
                  })}
                >
                  {label || <FormattedMessage id={labelId} />}
                </span>
                <DotSpacer />
                <span
                  className={classNames('simple-details-property__value', `${detailClassName}`, {
                    [`simple-details-property__value_${shouldEditValue ? '' : 'not-'}editable`]: shouldEditValues,
                  })}
                >
                  {shouldPreviousValuesBeOnTheLeft && (
                    <span className="simple-details-property__value_previous text_small text_strike color_red">{previousValue} </span>
                  )}
                  &nbsp;
                  {fieldControl || value || emptyValue}
                </span>
              </li>
            </React.Fragment>
          )
        })}
      </ul>
      {detailsDescriptionId && (
        <div className="text_super-small simple-details-property__detailsDescriptionId">
          {editDetailsDescriptionId ? (
            <>
              {shouldEditValues ? (
                <InjectHtml className="simple-details-property__editDetailsDescriptionId" textId={editDetailsDescriptionId} />
              ) : (
                <InjectHtml textId={detailsDescriptionId} />
              )}
            </>
          ) : (
            <InjectHtml textId={detailsDescriptionId} />
          )}
        </div>
      )}
    </section>
  )
}

export default memo(SimpleDetails)
