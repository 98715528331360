import { baseClickGtmEvent } from '@/logic/metrika/constants'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { translit } from '@/utils/helpers'
import { gtmFaqChangeEventPayload, gtmFaqFocusEventPayload, gtmFaqSearchEventPayload } from './gtmConstants'
import { IFaqGtmEventHandlers, TGetTransliteratedGtmEvents, TIGetSearchResultsEventPayload } from './types'

export const faqBlockNameByCategoryName: Record<string, string> = {
  'Pitanie rastenij': 'buttonPlantNutrition',
}

export const getTransliteratedGtmEvents: TGetTransliteratedGtmEvents = ({ blockName, context, isCategory, eventLabel }) => {
  const { name: contextName = '', prefix } = context || {}
  const transliteratedBlockName: string = translit(blockName)
  let transliteratedContext
  const eventBlockName: string = isCategory ? faqBlockNameByCategoryName[transliteratedBlockName]! : transliteratedBlockName
  if (context) transliteratedContext = `${prefix}: ${translit(contextName)}`

  return {
    ...baseClickGtmEvent,
    blockName: eventBlockName,
    eventLabel: eventLabel || eventBlockName,
    eventContext: transliteratedContext || null,
  }
}

export const useFaqGtmEventHandlers = (): IFaqGtmEventHandlers => {
  const pushFocusEventToGtm = () => {
    const field = document.querySelector<HTMLInputElement>("[name='text']")
    const { isTouched } = field?.dataset || {}

    if (!isTouched) {
      pushFormattedGtmEvent(gtmFaqFocusEventPayload)
      field?.setAttribute('data-is-touched', 'true')
    }
  }

  const pushChangeEventToGtm = () => {
    const field = document.querySelector<HTMLInputElement>("[name='text']")
    const { isChanged } = field?.dataset || {}

    if (!isChanged) {
      pushFormattedGtmEvent(gtmFaqChangeEventPayload)
      field?.setAttribute('data-is-changed', 'true')
    }
  }

  return {
    pushFocusEventToGtm,
    pushChangeEventToGtm,
  }
}

export const getSearchResultsEventPayload: TIGetSearchResultsEventPayload = ({ resultsAmount, keyword, isSuccess }) => ({
  ...gtmFaqSearchEventPayload,
  eventContext: `resultsAmount:${resultsAmount}&keyword:${translit(keyword)}`,
  eventStatus: isSuccess ? 'success' : 'unsuccess',
})
