import React from 'react'
import classNames from 'classnames'
import { SwiperArrow } from '@/components/ui'
import { ISwiperArrowsProps } from './types'

const SwiperArrows: React.FC<ISwiperArrowsProps> = ({
  modifier,
  className,
  buttonClassName,
  animation,
  onClickLeftArrow,
  onClickRightArrow,
}) => (
  <div className={classNames('row row_nowrap', className)} {...animation}>
    <SwiperArrow onClick={onClickLeftArrow} modifier={modifier} className={buttonClassName} direction="left" />
    <SwiperArrow onClick={onClickRightArrow} modifier={modifier} className={buttonClassName} direction="right" />
  </div>
)

export default SwiperArrows
