import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentLocale } from '@/logic/translation/selectors'
import { formatDate, FormatDateLocale } from '../../formatUtils'
import { IUseFormatDateResult } from './interfaces'

export const useFormatDate = (): IUseFormatDateResult => {
  const currentLanguage = useSelector(getCurrentLocale())

  return {
    formatDate: useCallback(
      (date, dateFormat = 'dd.MM.yyyy, HH:mm', options) =>
        formatDate(date, dateFormat, { ...options, locale: FormatDateLocale[currentLanguage] }),
      [currentLanguage],
    ),
    currentLanguage,
  }
}
