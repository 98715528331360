import { NotificationData } from '@/api/generated/base'
import { INotifications } from '@/logic/notifications/types'

export const POLLING_INTERVAL = 60000

export const INITIAL_BADGES: Readonly<INotifications> = {
  unreadChatMessagesCount: 0,
  unreadNotificationsCount: 0,
  totalUnreadNotificationsCount: 0,
  unreadFundingMessagesCount: 0,
  unwatchedFundingDocumentsCount: 0,
}

export const STATE_KEY_BY_NOTIFICATION_DATA_TYPE: Readonly<Record<NotificationData.type, keyof INotifications>> = {
  [NotificationData.type.UNREAD_MESSAGES_COUNT]: 'unreadChatMessagesCount',
  [NotificationData.type.UNREAD_WEB_NOTIFICATION_COUNT]: 'unreadNotificationsCount',
  [NotificationData.type.UNREAD_FUNDING_MESSAGES_COUNT]: 'unreadFundingMessagesCount',
  [NotificationData.type.UNWATCHED_FUNDING_DOCUMENTS_COUNT]: 'unwatchedFundingDocumentsCount',
}
