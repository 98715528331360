import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, EIconSize, IIconProps } from '@frontend/pole-ui/lib/components/Icon'
import { TextWithIcon } from '@/components/ui'
import { EDocumentStatus } from '@/types/Documents'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { IAccreditationDocumentsStatusProps } from '../interfaces'

const emptyIconProps: IIconProps = {
  name: EIconName.Empty,
  style: { opacity: 0.5, color: EColor.GREEN },
}

export const DocumentStatusIcon: Record<EDocumentStatus, IIconProps> = {
  REJECTED: {
    name: EIconName.Error,
    style: { color: EColor.RED },
  },
  PROPOSED: {
    name: EIconName.OkFill,
    style: { color: EColor.GREEN },
  },
  UPDATED: {
    name: EIconName.Renewalofold1Fill,
    style: { color: EColor.ORANGE },
  },
  APPROVED: {
    name: EIconName.OkFill,
    style: { color: EColor.GREEN },
  },
}

const DocumentStatus: React.FC<IAccreditationDocumentsStatusProps> = ({ status }) => {
  const { isMobile } = useDeviceType()

  return (
    <TextWithIcon
      textClassName="color_pale-black-sm"
      iconProps={
        status
          ? { ...DocumentStatusIcon[status], size: isMobile ? EIconSize.XS : EIconSize.M }
          : { ...emptyIconProps, size: isMobile ? EIconSize.XS : EIconSize.M }
      }
      textId={`userAccreditation.document.status.${status}`}
    />
  )
}

export default DocumentStatus
