/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SkuPromotionData_ = {
    id: string;
    name: string;
    description: string;
    serviceName: string;
    startPromotion: string;
    endPromotion: string;
    type: SkuPromotionData_.type;
};

export namespace SkuPromotionData_ {

    export enum type {
        ACTIVE = 'ACTIVE',
        DRAFT = 'DRAFT',
        ARCHIVE = 'ARCHIVE',
    }


}

