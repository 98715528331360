import React, { forwardRef, PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { IBaseRadioButtonProps } from './types'
import './styles.scss'

const BaseRadioButton = forwardRef<HTMLInputElement, PropsWithChildren<IBaseRadioButtonProps>>((props, ref) => {
  const {
    label,
    onFocus,
    onBlur,
    onChange,
    labelId,
    children,
    className = '',
    CustomLabel,
    disabled,
    labelTextClassName,
    controlStyle,
    sizeMobile,
    name,
    checked,
    value,
    error,
  } = props

  const { isMobile } = useDeviceType()

  const size = isMobile && sizeMobile ? sizeMobile : null

  return (
    <label className={classNames('customRadio', className)}>
      <input
        type="radio"
        name={name}
        checked={checked}
        value={value}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
      />
      <span
        className={classNames(
          'customRadio__checkmark',
          size && `customRadio__checkmark_${size}`,
          error && `customRadio__checkmark_error`,
          controlStyle && `customRadio__checkmark_${controlStyle}`,
        )}
      />
      <span className={classNames('customRadio__label', labelTextClassName)}>
        {CustomLabel || label || (labelId && <FormattedMessage id={labelId} />)}
      </span>
      {children}
    </label>
  )
})

export default BaseRadioButton
