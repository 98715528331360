import React from 'react'
import { getMinioImagePath, getStaticImagePath } from '@/utils/images'
import { useFallbackImageProps } from './hooks'
import { IImageProps } from './interfaces'

const Image: React.FC<IImageProps> = props => {
  const {
    src,
    version,
    className,
    alt = '',
    isInStatic,
    style,
    placeholderImage,
    animation,
    hideIfEmpty,
    isBackground,
    shouldUseExactSrc = false,
    onLoad,
  } = props

  const fallbackUrl = (placeholderImage || getStaticImagePath('placeholder.png')) ?? ''
  const fallbackImageProps = useFallbackImageProps(fallbackUrl, hideIfEmpty)

  if (!src && hideIfEmpty) return null

  let url = fallbackUrl

  if (src) {
    if (isInStatic) {
      url = getStaticImagePath(src.replace(/^\//, '')) ?? ''
    } else if (shouldUseExactSrc) url = src
    else url = getMinioImagePath({ src, version })
  }

  const baseProps = { className, ...animation }

  return isBackground ? (
    <div style={{ backgroundImage: `url("${url}"), url("${fallbackUrl}")`, ...style }} {...baseProps} />
  ) : (
    <img loading="lazy" src={url} alt={alt} style={style} {...fallbackImageProps} {...baseProps} onLoad={onLoad} />
  )
}

export default Image
