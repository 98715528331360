import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Chip, Image, InjectHtml } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import { formatMoney } from '@/utils/price'
import { IExactFarmingHeaderProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const ExactFarmingHeader: React.FC<IExactFarmingHeaderProps> = ({ titleId, textId, price, oldPrice, onActionClick }) => {
  const intl = useIntl()

  return (
    <section className="exact-farming-header page-wrapper">
      <div className="exact-farming-header-container space-holder-right32-from-sm">
        <Chip textId="global.exclusiveOnPole" className="space-holder16" />

        <h2 className="space-holder24-from-sm space-holder16-sm">
          <FormattedMessage id={titleId} />
        </h2>

        <InjectHtml textId={textId} className="exact-farming-header__subtitle-text" TagName="p" />

        <Image
          src="agroservices/exactFarmingLogo.png"
          isInStatic
          className="exact-farming-header-action-row__logo exact-farming-header-action-row__logo_mobile hidden-from-sm"
        />

        <div className="exact-farming-header-bottom">
          {oldPrice && <div className="exact-farming-header-price-old-price">{`${formatMoney(oldPrice, intl)} ₽`}</div>}

          {price && (
            <div className="exact-farming-header-price space-holder16-from-sm space-holder24-sm">
              <span className="exact-farming-header-price__value">{formatMoney(price, intl)} ₽</span>
              <span className="exact-farming-header-price__per text_body">
                /
                <FormattedMessage id="exactFarmingHeader.perYear" />
              </span>
            </div>
          )}

          <div className="exact-farming-header-action-row">
            <Button
              onClick={onActionClick}
              modifiers={['green']}
              textId="global.orderRequest"
              className="exact-farming-header-action-row__button space-holder-right32-from-sm"
            />

            <Image src="agroservices/exactFarmingLogo.png" isInStatic className="exact-farming-header-action-row__logo hidden-sm" />
          </div>
        </div>
      </div>
      <div className="page-wrapper page-wrapper_no-desktop page-wrapper_no-tablet exact-farming-header-image-container">
        <Image
          src="agroservices/exactFarmingHeader.png"
          isInStatic
          className="space-holder-top48-sm space-holder-top8-only-md  exact-farming-header-image-container__image"
        />
      </div>
    </section>
  )
}

export default ExactFarmingHeader
