import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import format from 'date-fns/format'
import { Skeleton } from '@/components/ui'
import { EJournalRoute } from '@/types'
import { useFormatPublicationDate } from '@/utils/hooks/format/useFormatPublicationDate'
import { getJournalNewsUrlByRubric, getJournalPublicationUrl } from '@/utils/infoportal/helpers'
import { additionalNewsSkeletons, mainNewsSkeletons } from './constants'
import { INewsPreviewProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const NewsPreview: FC<INewsPreviewProps> = props => {
  const { news = [], className, isLoading, rubric, onClick, children } = props
  const { slug: firstNewsSlug, title: firstNewsTitle, publicationDate: firstNewsPublicationDate, type } = news[0] || {}

  const { formatPublicationDate } = useFormatPublicationDate(type)

  return (
    <section className={classNames('journalShortNews page-wrapper', className)} onClick={onClick}>
      <h2 className="journalShortNews__header">{messages.newsHeader}</h2>
      {children}
      <div className="journalShortNews__content">
        <Skeleton isLoading={isLoading} skeletons={mainNewsSkeletons}>
          <article className="journalShortNews__main">
            <CommonLink url={firstNewsSlug && getJournalPublicationUrl(firstNewsSlug)} className="journalShortNews__mainLink">
              <h4 className="journalShortNews__mainTitle">{firstNewsTitle}</h4>
              {firstNewsPublicationDate && (
                <time
                  className="text_super-small journalShortNews__mainDatePublished"
                  itemProp="publishedDate"
                  dateTime={format(new Date(firstNewsPublicationDate), 'yyyy-MM-dd')}
                >
                  {formatPublicationDate(firstNewsPublicationDate)}
                </time>
              )}
            </CommonLink>
          </article>
        </Skeleton>

        <div className="journalShortNews__newsList">
          <Skeleton isLoading={isLoading} skeletons={additionalNewsSkeletons}>
            {news.slice(1, 5).map(item => {
              const { id, title, publicationDate, slug } = item

              return (
                <article key={id} className="journalShortNews__listItem space-holder16-from-md space-holder-top24-md">
                  <h2 className="h5 font_base font-weight_normal journalShortNews__listItemTitle">
                    <CommonLink url={getJournalPublicationUrl(slug)} className="text_body">
                      {title}
                    </CommonLink>
                  </h2>

                  {publicationDate && (
                    <time
                      className="text_super-small color_pale-black"
                      itemProp="publishedDate"
                      dateTime={format(new Date(publicationDate), 'yyyy-MM-dd')}
                    >
                      {formatPublicationDate(publicationDate)}
                    </time>
                  )}
                </article>
              )
            })}
          </Skeleton>

          <CommonLink
            className="color_green underline underline_green text_body space-holder-top24-md"
            activeClassName=""
            url={rubric ? getJournalNewsUrlByRubric(rubric.id) : EJournalRoute.NEWS}
          >
            <FormattedMessage id={messages.allNews} />
          </CommonLink>
        </div>
      </div>
    </section>
  )
}

export default NewsPreview
