import find from 'lodash/find'
import { ELegalDocumentsRoute } from '@/types/ELegalDocumentsRoute'
import { ELegalDocumentType, ILegalDocument } from '@/types/legalDocuments'
import { createRouterLink } from '@/utils/url'

export const getLegalDocumentUrl = (documents: Readonly<ILegalDocument[]>, typeId: ELegalDocumentType): string | undefined => {
  const document = find(documents, { typeId })

  if (!document) {
    return undefined
  }

  return createRouterLink(ELegalDocumentsRoute.DOCUMENT_VERSION, { documentId: document.typeId, versionId: document.id })
}
