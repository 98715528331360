import React, { memo, useCallback } from 'react'
import Script from 'react-load-script'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { yandexMapsUrl } from '@/config'
import { getIsUserRegionConfirmed, getRegions, setUserRegion } from '@/logic/userRegion'
import { RootState } from '@/redux/interfaces'

const mapState = (state: RootState) => ({
  regions: getRegions(state),
  isConfirmed: getIsUserRegionConfirmed()(state),
})

const UserRegionDefiner: React.FC = () => {
  const dispatch = useDispatch()
  const { regions, isConfirmed } = useSelector(mapState)

  const onYandexMapsLoad = useCallback(() => {
    window.ymaps.ready(() => {
      //@ts-ignore
      ymaps.geolocation.get({ provider: 'yandex' }).then(res => {
        const name = get(
          res.geoObjects.get(0).properties['_data'],
          'metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.AdministrativeAreaName',
        )
        const region = regions?.find(reg => reg.name.includes(name)) ?? null
        if (region) dispatch(setUserRegion(region))
      })
    })
  }, [dispatch, regions])

  return <>{!isConfirmed && <Script url={yandexMapsUrl} onLoad={onYandexMapsLoad} />}</>
}

export default memo(UserRegionDefiner)
