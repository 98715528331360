import { IMultiSelectOption } from '@frontend/pole-ui/lib/components/MultiSelect'
import { messages } from './messages'
import { ELoanPurpose } from './types'

export const LABEL_BY_LOAN_PURPOSE: Readonly<Record<ELoanPurpose, string>> = {
  [ELoanPurpose.REPLENISHMENT_OF_WORKING_CAPITAL]: messages.options.replenishmentOfWorkingCapital,
  [ELoanPurpose.PURCHASE_OF_FIXED_ASSETS]: messages.options.purchaseOfFixedAssets,
  [ELoanPurpose.PURCHASE_OF_GOODS_AND_MATERIALS]: messages.options.purchaseOfGoodsAndMaterials,
  [ELoanPurpose.REFINANCING_CURRENT_LOANS]: messages.options.refinancingCurrentLoans,
  [ELoanPurpose.BUSINESS_DEVELOPMENT]: messages.options.businessDevelopment,
  [ELoanPurpose.OTHER]: messages.options.other,
}

export const LOAN_PURPOSE_OPTIONS: IMultiSelectOption[] = Object.entries(LABEL_BY_LOAN_PURPOSE).map(([value, label]) => ({
  value,
  label,
}))

export enum ELoanPurposeFieldName {
  CHECK_PICKER = 'loanPurpose',
}

export const LOAN_PURPOSE_FIELD_NAMES: Readonly<ELoanPurposeFieldName[]> = Object.values(ELoanPurposeFieldName)
