import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fromEvent } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { onAppMountWithEpics, onResize } from '@/logic/app/actions'
import { isAuthenticated } from '@/logic/auth/reducer'
import { startNotificationsPolling } from '@/logic/notifications/thunks'
import { RootState } from '@/redux/interfaces'
import { useMount } from '@/utils/hooks'

const mapState = (state: RootState) => ({
  isUserAuthenticated: isAuthenticated()(state),
})

export const useOnAppMount = () => {
  const { pathname } = useLocation()
  const { isUserAuthenticated } = useSelector(mapState)
  const dispatch = useDispatch()

  const onScreenResize = () => dispatch(onResize())

  useMount(() => {
    dispatch(onAppMountWithEpics())
    const resizeEvent = fromEvent(window, 'resize').pipe(debounceTime(100)).subscribe(onScreenResize)
    onScreenResize()

    return () => resizeEvent.unsubscribe()
  })

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(startNotificationsPolling())
    }
  }, [pathname, isUserAuthenticated, dispatch])
}
