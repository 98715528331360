import { baseChangeGtmEvent, baseFocusGtmEvent } from '@/logic/metrika/constants'
import { IFormattedGtmEventPayload } from '@/logic/metrika/interfaces'

export const gtmFaqFocusEventPayload: IFormattedGtmEventPayload = {
  ...baseFocusGtmEvent,
  blockName: 'blockSearch',
  eventLabel: 'focusSearch',
}

export const gtmFaqChangeEventPayload: IFormattedGtmEventPayload = {
  ...baseChangeGtmEvent,
  blockName: 'blockSearch',
  eventLabel: 'inputSearch',
}

export const gtmFaqSearchEventPayload: Omit<IFormattedGtmEventPayload, 'eventContext' | 'eventStatus'> = {
  event: 'userEvent',
  pagePart: 'content',
  blockName: 'blockSearch',
  eventCategory: 'interactions',
  eventAction: 'backgroundProcess',
  eventLabel: 'inputChangeSearch',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}
