import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { IGenerateMenuProps, NavLink } from '@/components/composed/MobileNavBar/interfaces'
import { EAgriculturalProductsRoute, EFinancingOfferRoute, EJournalRoute, EUserProfileRoute } from '@/types'
import { EOfferRequestsRoute } from '@/types/EOfferRequestsRoute'

export const generateMenu = ({
  isAuthenticated,
  isBuyer,
  isSupplier,
  isLogisticsOperator,
  notificationsCount,
}: IGenerateMenuProps): NavLink[] => {
  if (!isAuthenticated)
    return [
      {
        textId: 'skus',
        url: EAgriculturalProductsRoute.main,
        icon: EIconName.Search,
      },
      {
        textId: 'offerRequests',
        url: EOfferRequestsRoute.main,
        icon: EIconName.Scale,
      },
      {
        textId: 'credits',
        url: EFinancingOfferRoute.main,
        icon: EIconName.Wallet,
      },
      {
        textId: 'news',
        url: EJournalRoute.MAIN,
        icon: EIconName.NewsTab,
      },
    ]

  if (isBuyer)
    return [
      {
        textId: 'requests',
        url: EUserProfileRoute.sales,
        icon: EIconName.List1,
      },
      {
        textId: 'documents',
        url: EUserProfileRoute.myDocuments,
        icon: EIconName.Shielddone,
      },
      {
        textId: 'notifications',
        url: EUserProfileRoute.notifications,
        icon: EIconName.Bell,
        counter: notificationsCount.totalUnreadNotificationsCount,
      },
      {
        textId: 'chat',
        url: EUserProfileRoute.myChats,
        icon: EIconName.Chat,
        counter: notificationsCount.unreadChatMessagesCount,
      },
    ]

  if (isSupplier)
    return [
      {
        textId: 'orders',
        url: EUserProfileRoute.orders,
        icon: EIconName.Cart,
      },
      {
        textId: 'notifications',
        url: EUserProfileRoute.notifications,
        icon: EIconName.Bell,
        counter: notificationsCount.totalUnreadNotificationsCount,
      },
      {
        textId: 'chat',
        url: EUserProfileRoute.myChats,
        icon: EIconName.Chat,
        counter: notificationsCount.unreadChatMessagesCount,
      },
    ]

  if (isLogisticsOperator) return []

  return [
    {
      textId: 'skus',
      url: EAgriculturalProductsRoute.main,
      icon: EIconName.Search,
    },
    {
      textId: 'offerRequests',
      url: EOfferRequestsRoute.main,
      icon: EIconName.Scale,
    },
    {
      textId: 'credits',
      url: EFinancingOfferRoute.main,
      icon: EIconName.Wallet,
    },
    {
      textId: 'deals',
      url: '',
      icon: EIconName.List1,
      additional: true,
    },
    {
      textId: 'chat',
      url: EUserProfileRoute.myChats,
      icon: EIconName.Chat,
      counter: notificationsCount.unreadChatMessagesCount,
    },
  ]
}
