import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { getDeviceContext } from '@/logic/app/reducer'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { injectMessages } from '@/logic/translation/utils'
import { RootState } from '@/redux/interfaces'
import { ECommonRoute } from '@/types'
import { IAdditionalMenuLinksProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  deviceContext: getDeviceContext(state),
})

const AdditionalMenuLinks: FC<IAdditionalMenuLinksProps> = () => {
  const { deviceContext } = useSelector(mapState)

  const pushCallEvent = () => {
    pushFormattedGtmEvent({
      event: 'userEvent',
      pagePart: 'header',
      blockName: 'blockButtonCall',
      eventCategory: 'interactions',
      eventAction: 'click',
      eventLabel: 'buttonCall',
      eventContext: deviceContext,
      eventStatus: 'success',
      eventEcommerce: null,
      eventNonInteraction: '1',
      firingOptions: 'oncePerEvent',
      ecommerce: null,
    })
  }

  return (
    <div className="additional-menu-links">
      <CommonLink onClick={pushCallEvent} url="tel:88007070385" className="additional-menu-link">
        <Icon name={EIconName.Call} className="additional-menu-link__icon hidden-md" size={EIconSize.S} />
        <span className="pt1">8 800 707-03-85</span>
      </CommonLink>

      <CommonLink url={ECommonRoute.feedback} className="additional-menu-link">
        <Icon name={EIconName.Chat} className="additional-menu-link__icon hidden-md" size={EIconSize.S} />
        <span>
          <FormattedMessage id="additionalMenu.link.askQuestion" />
        </span>
      </CommonLink>
    </div>
  )
}

export default AdditionalMenuLinks
