import { useCallback, useState } from 'react'
import { SlideImage } from 'yet-another-react-lightbox'

export const useLightbox = () => {
  const [slides, setSlides] = useState<SlideImage[]>([])
  const [imageIndex, setImageIndex] = useState(-1)

  const addSlide = useCallback((slide: SlideImage) => {
    setSlides(prev => [...prev, slide])
  }, [])

  const openLightbox = (imageSrc: string) => {
    const currentIndex = slides.findIndex(slide => slide.src.includes(imageSrc))

    setImageIndex(currentIndex)
  }

  const closeLightbox = () => setImageIndex(-1)
  const reset = () => setSlides([])

  return { addSlide, slides, openLightbox, closeLightbox, isLightboxOpen: imageIndex >= 0, currentIndex: imageIndex, reset }
}
