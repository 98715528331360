import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { injectMessages } from '@/logic/translation/utils'
import { HintWrapper, InjectHtml } from '../..'
import { ITradingPriceHintProps } from './interfaces'
import messages from './messages.json'

injectMessages(messages)

const TradingPriceHint: FC<ITradingPriceHintProps> = ({ className, shouldBeHintInModal, isIcon, onToggleHint }) => (
  <HintWrapper
    className={className}
    header={<InjectHtml className="space-holder8 text_small-from-sm font-weight_bold" textId="tradingPriceHint.title" TagName="p" />}
    messageId="tradingPriceHint.text"
    shouldBeHintInModal={shouldBeHintInModal}
    shouldDisplayHint
    messageClass="text_small"
    onToggleHint={onToggleHint}
  >
    {isIcon ? (
      <Icon name={EIconName.CommentAccount} style={{ color: EColor.CONTROL_COLOR }} />
    ) : (
      <span className="underline underline_green color_green text_small">
        <FormattedMessage id="tradingPriceHint.content" />
      </span>
    )}
  </HintWrapper>
)

export default TradingPriceHint
