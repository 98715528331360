import { ESkuCharacteristicType } from '@/types/Catalog/ESkuCharacteristicType'
import { ISkuCharacteristicMultiSelectValue } from '@/types/Catalog/ISkuCharacteristicMultiSelectValue'
import { ISkuCharacteristicNumberValue } from '@/types/Catalog/ISkuCharacteristicNumberValue'
import { ISkuCharacteristicSelectValue } from '@/types/Catalog/ISkuCharacteristicSelectValue'
import { ISkuCharacteristicTextValue } from '@/types/Catalog/ISkuCharacteristicTextValue'
import { TSkuCharacteristicValue } from '@/types/Catalog/TSkuCharacteristicValue'

export const isTextCharacteristicValue = (value: TSkuCharacteristicValue): value is ISkuCharacteristicTextValue => {
  return value.type === ESkuCharacteristicType.TEXT
}

export const isSelectCharacteristicValue = (value: TSkuCharacteristicValue): value is ISkuCharacteristicSelectValue => {
  return value.type === ESkuCharacteristicType.SINGLE_SELECT
}

export const isMultiSelectCharacteristicValue = (value: TSkuCharacteristicValue): value is ISkuCharacteristicMultiSelectValue => {
  return value.type === ESkuCharacteristicType.MULTI_SELECT
}

export const isNumberCharacteristicValue = (value: TSkuCharacteristicValue): value is ISkuCharacteristicNumberValue => {
  return value.type === ESkuCharacteristicType.NUMBER
}
