import React, { forwardRef, memo, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import { Button, Image, Loader } from '@/components/ui'
import { RangeSlider } from '@/components/ui/ReactHookFormFields'
import { ECalculatorIndex, IInstallmentCalculatorForm, IInstallmentCalculatorPayload } from '@/logic/financing/interfaces'
import { getCalculatorResults, getIsFetching, requestInstallmentCalculator, setCalculatorState } from '@/logic/financing/reducer'
import { getUserRegion } from '@/logic/userRegion'
import { RootState } from '@/redux/interfaces'
import { EPriceCurrency } from '@/types'
import { formatLocalizedString } from '@/utils/formatUtils'
import { asyncScrollIntoView } from '@/utils/helpers'
import { useResponsiveValue } from '@/utils/hooks/responsive'
import {
  DEBOUNCE_DELAY,
  DEFAULT_VALUES,
  MILLION_RUBLES,
  PRICE_REQUEST_BUTTON_SCROLL_TO_OFFSET,
  PRODUCTS,
  RANGES,
  RANGES_FOR_SBER,
} from './constants'
import { useInstallmentCalculator, useSendGtmEventsOnFormChange } from './hooks'
import { messages } from './messages'
import { IInstallmentCalculatorProps } from './types'
import './styles.scss'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
  isFetching: getIsFetching()(state),
  calculatorResults: getCalculatorResults()(state),
})

const InstallmentCalculatorComponent = forwardRef<HTMLDivElement, IInstallmentCalculatorProps>((props, ref) => {
  const {
    id = ECalculatorIndex.DEFAULT,
    className,
    fixedPrice,
    withoutTitle,
    daysLabelText,
    isActive = true,
    isForLanding,
    priceRequestButton = {
      isShown: false,
    },
    createPaymentUrl,
  } = props

  const dispatch = useDispatch()
  const { userRegion, calculatorResults } = useSelector(mapState)
  const { isFetching = false, products, calculateSberPaymentAmount } = calculatorResults[id] || {}
  const { formatNumber } = useIntl()
  const priceRequestButtonScrollToOffset = useResponsiveValue(PRICE_REQUEST_BUTTON_SCROLL_TO_OFFSET)

  const isFixedPrice = fixedPrice !== undefined

  const INITIAL_FORM_VALUES: IInstallmentCalculatorForm = useMemo(
    () => ({
      sum: fixedPrice ?? DEFAULT_VALUES.sum,
      days: DEFAULT_VALUES.days,
    }),
    [fixedPrice],
  )

  const { control, getValues, setValue, watch } = useForm<IInstallmentCalculatorForm>({
    defaultValues: INITIAL_FORM_VALUES,
  })
  const [sumValue, daysValue] = watch(['sum', 'days'])
  const { checkIsInactive, inactiveClassName, formatSum, formatDaysLabel, formatDaysEnding } = useInstallmentCalculator(sumValue, daysValue)

  const debouncedRequest = useMemo(
    () =>
      debounce((payload: IInstallmentCalculatorPayload) => {
        dispatch(requestInstallmentCalculator(payload))
      }, DEBOUNCE_DELAY),
    [dispatch],
  )

  useSendGtmEventsOnFormChange(control)

  useEffect(() => {
    const { sum } = getValues()

    if (sum !== INITIAL_FORM_VALUES.sum) {
      setValue('sum', INITIAL_FORM_VALUES.sum)
    }
  }, [INITIAL_FORM_VALUES.sum, getValues, setValue])

  useEffect(() => {
    if (isActive) {
      dispatch(setCalculatorState({ id, isFetching: true }))
      // TODO: Поправить race condition https://jira.poleinvest.ru/browse/IFA-964
      debouncedRequest({ id, payload: { regionId: userRegion.code, days: daysValue, sum: sumValue, onlySber: isForLanding } })
    }
  }, [isActive, id, sumValue, daysValue, userRegion.code, debouncedRequest, dispatch, isForLanding])

  if (!isActive || (!products && typeof calculateSberPaymentAmount !== 'number')) return null

  const renderButton = (isDisabled?: boolean) => (
    <Button className="installmentCalculator__button" isDisabled={isDisabled} modifiers={['green', 'small']}>
      {messages.common.select}
    </Button>
  )

  const wrapperClasses = classNames('installmentCalculator', className, {
    'installmentCalculator--landing': isForLanding,
  })

  const footNoteClasses = classNames('text_super-small', 'installmentCalculator__footnote', {
    'installmentCalculator__footnote--landing': isForLanding,
  })

  const sliderClasses = classNames('installmentCalculator__sliders', {
    'installmentCalculator__sliders--landing': isForLanding,
  })

  const ranges = isForLanding ? RANGES_FOR_SBER : RANGES

  const onClickPriceRequestButton = () => {
    if (priceRequestButton.isShown) {
      asyncScrollIntoView(priceRequestButton.blockSelectorToScrollTo, priceRequestButtonScrollToOffset)
    }
  }

  return (
    <div ref={ref} className={wrapperClasses}>
      {!withoutTitle && <h3 className="text_body-sm installmentCalculator__title">{messages.title}</h3>}
      <div className={sliderClasses}>
        {!isFixedPrice && (
          <RangeSlider
            label={messages.labels.sum}
            name="sum"
            sliderProps={ranges.sum}
            formatValue={formatNumber}
            inputEnding={EPriceCurrency.RUB}
            formatLabel={formatSum}
            control={control}
          />
        )}
        <RangeSlider
          label={daysLabelText || messages.labels.days}
          name="days"
          sliderProps={ranges.days}
          inputEnding={formatDaysEnding}
          formatLabel={formatDaysLabel}
          control={control}
        />
      </div>
      {isForLanding ? (
        <div className="installmentCalculator__landingResults">
          <div className="installmentCalculator__landingResultsBanner">
            <div className="installmentCalculator__landingResultsBannerInfo">
              <div className="installmentCalculator__landingResultsBannerTitle">{messages.banner.title}</div>
              <h3 className="installmentCalculator__landingResultsSumm">
                {calculateSberPaymentAmount && formatSum(calculateSberPaymentAmount)}
              </h3>
            </div>
            <CommonLink url="/agricultural-products">
              <Button modifiers={['green', 'small', 'full-width-mobile']} className="installmentCalculator__landingResultsButton">
                {messages.banner.button}
              </Button>
            </CommonLink>
          </div>
          <div className={footNoteClasses}>{messages.footnote}</div>
        </div>
      ) : (
        <>
          <div className="installmentCalculator__results">
            <div className="installmentCalculator__details">
              {products?.map(({ productId }) => {
                const { link, maxSum, textDescription, logo } = PRODUCTS[productId]
                const limitDescription = formatLocalizedString(messages.product.limit.maxSum, {
                  sumInMillions: maxSum / MILLION_RUBLES,
                })

                return (
                  <div
                    className={classNames('text_super-small-sm installmentCalculator__detail', inactiveClassName(productId))}
                    key={productId}
                  >
                    <div className="installmentCalculator__logoContainer">
                      <Image className="installmentCalculator__logo" src={logo} isInStatic />
                    </div>
                    <p>{textDescription.firstLine}</p>
                    {!isFixedPrice && <p>{limitDescription}</p>}
                    <p>{textDescription.lastLine}</p>
                    {link && (
                      <CommonLink className="active" url={link}>
                        {messages.more}
                      </CommonLink>
                    )}
                  </div>
                )
              })}
            </div>
            <div className="installmentCalculator__summaries">
              {products?.map(({ productId, paymentId, calculatePaymentAmount }) => (
                <div className="installmentCalculator__summary" key={productId}>
                  <div className="installmentCalculator__calculation">
                    <Loader isVisible={isFetching} withoutOverlay />
                    <div className={classNames(inactiveClassName(productId, isFetching))}>
                      {checkIsInactive(productId) ? (
                        <span className="installmentCalculator__mdash">&mdash;</span>
                      ) : (
                        <>
                          <h6 className="text_base">{formatSum(calculatePaymentAmount)}</h6>
                          {messages.refundableAmount}
                        </>
                      )}
                    </div>
                  </div>
                  {createPaymentUrl !== undefined &&
                    (isFetching || checkIsInactive(productId) ? (
                      renderButton(true)
                    ) : (
                      <CommonLink url={createPaymentUrl(paymentId)}>{renderButton()}</CommonLink>
                    ))}
                </div>
              ))}
            </div>
          </div>
          {priceRequestButton.isShown && (
            <Button
              className="installmentCalculator__priceRequestButton"
              onClick={onClickPriceRequestButton}
              modifiers={['green', 'full-width-mobile']}
            >
              {messages.button.priceRequest}
            </Button>
          )}
          <div className={footNoteClasses}>{messages.footnote}</div>
        </>
      )}
    </div>
  )
})

export const InstallmentCalculator = memo(InstallmentCalculatorComponent)
