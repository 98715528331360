/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NotificationTemplateRequest = {
    templateId?: number;
    initiator?: string;
    oldStatus?: string;
    newStatus?: string;
    supplierPhone?: string;
    action?: NotificationTemplateRequest.action;
    receiverEmail?: string;
    service?: string;
    group?: string;
    contractorInn?: string;
    confirm?: string;
    templateOrganizationId?: string;
    email?: boolean;
    sms?: boolean;
};

export namespace NotificationTemplateRequest {

    export enum action {
        APPROVAL_WITH_AGRARIAN = 'APPROVAL_WITH_AGRARIAN',
        APPROVAL_WITH_LAWYER = 'APPROVAL_WITH_LAWYER',
        APPROVED_BY_AGRARIAN = 'APPROVED_BY_AGRARIAN',
        CHANGES_OFFERED_BY_AGRARIAN = 'CHANGES_OFFERED_BY_AGRARIAN',
        CHANGES_OFFERED_BY_LAWYER = 'CHANGES_OFFERED_BY_LAWYER',
        PUBLISHED_BY_LAWYER = 'PUBLISHED_BY_LAWYER',
    }


}

