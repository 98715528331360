import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { CSSProperties } from 'styled-components'
import { injectMessages } from '@/logic/translation/utils'
import messages from './messages.json'
import { IPropertyListItem, IPropertyListProps } from './types'
import './styles.scss'

injectMessages(messages)

const DEFAULT_LIST: IPropertyListItem[] = []

const PropertyList: React.FC<IPropertyListProps> = ({ list = DEFAULT_LIST }) => {
  return (
    <ul className="property-list text_small color_black">
      {list.map(({ labelId, text, label }, index) => (
        <li key={index} className="text_cropped property-list-item" style={{ '--lines': 2 } as CSSProperties}>
          <label className="property-list-item__label">{labelId ? <FormattedMessage id={labelId} ignoreTag /> : label}</label>
          <span className="property-list-item__text">{text}</span>
        </li>
      ))}
    </ul>
  )
}

export default memo(PropertyList)
