/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RegionData } from './RegionData';

/**
 * Результат
 */
export type MainPageSliderBannerData = {
    id: string;
    url?: string;
    specialAction?: MainPageSliderBannerData.specialAction;
    name: string;
    regions: Array<RegionData>;
    webImage: string;
    mobileImage: string;
    position: number;
    status: string;
};

export namespace MainPageSliderBannerData {

    export enum specialAction {
        REGISTRATION_FORM = 'REGISTRATION_FORM',
        URL = 'URL',
    }


}

