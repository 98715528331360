import { updateIntl } from 'react-intl-redux'
import { createAction } from '@reduxjs/toolkit'
import { TAppLocale } from '../app/interfaces'
import { TLocaleMessages } from './interfaces'

export const updateLocale = (locale: TAppLocale, locales: TLocaleMessages) =>
  updateIntl({
    locale,
    messages: locales[locale],
  })

export const setMessages = createAction<TLocaleMessages>('@@intl/SET_MESSAGES')
