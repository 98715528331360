import { requestData } from '@/api/request'
import { IHistoryItem } from '@/types/History'

/** Сервис работы с историей заказа inputs */
export default class OrderHistoryService {
  /**
   * Получение истории заказа
   * @param {number} id - идентификатор заказа
   */
  getHistory = (id: number) =>
    requestData<IHistoryItem[]>({
      url: `/api/orders/${id}/history`,
      method: 'GET',
    })
}
