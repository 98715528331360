import React from 'react'
import classNames from 'classnames'
import { InjectHtml } from '../index'
import { IErrorMessageProps } from './interfaces'

const ErrorMessage: React.FC<IErrorMessageProps> = ({ textId, className, text, isVisible, textValues }) => {
  if (!isVisible) return null

  return (
    <p className={classNames(className, 'color_red error-message text_small-sm text_super-small-from-sm')}>
      <InjectHtml html={text} textId={textId} textValues={textValues} TagName="span" />
    </p>
  )
}

export default ErrorMessage
