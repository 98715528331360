import { ISkeletonLoaderProps, SkeletonStyle, TSkeletons } from '@frontend/pole-ui/lib/components/Skeleton'
import { createItems } from '@/utils/helpers'

export const faqArticleTagSkeleton: ISkeletonLoaderProps = {
  height: '25px',
  className: 'tag space-holder-right8 space-holder-top16',
  width: '10%',
  isBlock: false,
}

export const faqArticleSkeletons: TSkeletons = [
  { className: 'faq-article__title h3', height: '1.5em', width: '60%' },
  { className: 'space-holder32', height: '6em' },
  { height: '1.5em', width: '15%', className: 'text_small-from-sm' },
  createItems(3, () => faqArticleTagSkeleton),
]

export const nearestArticlesSkeletons: TSkeletons = createItems(2, () => ({ className: 'faq-nearest-article', ...SkeletonStyle.whiteGray }))
