import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { getBreadcrumbs } from '@/logic/breadcrumbs/reducer'
import { injectMessages } from '@/logic/translation/utils'
import { useLocation } from '@/router/hooks'
import { IBreadcrumb } from './Breadcrumb/interfaces'
import Breadcrumb from './Breadcrumb'
import { IBreadcrumbsProps } from './interfaces'
import messages from './messages.json'
import './breadcrumbs.scss'

injectMessages(messages)
const ignorePath: string[] = []

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ customBreadcrumbs, className, isMobile }) => {
  const { pathname } = useLocation()
  const breadcrumbs: IBreadcrumb[] = useSelector(getBreadcrumbs())
  const items = customBreadcrumbs || breadcrumbs
  const previousStep = customBreadcrumbs && customBreadcrumbs.length > 1 ? customBreadcrumbs[customBreadcrumbs.length - 2] : undefined

  if ((ignorePath.some(path => pathname?.startsWith(path)) || !breadcrumbs.length) && !customBreadcrumbs) return null

  return (
    <>
      {isMobile ? (
        <CommonLink url={previousStep?.link} spanIfEmpty className={classNames('breadcrumb breadcrumb_back breadcrumb_link', className)}>
          {previousStep && <FormattedMessage id={previousStep?.caption ? previousStep.caption : previousStep?.captionId} />}
        </CommonLink>
      ) : (
        <div className={classNames('breadcrumbs', className)}>
          {items.map((breadcrumb, index) => (
            <Breadcrumb
              key={`${breadcrumb.id || breadcrumb.captionId || breadcrumb.caption}_${index}`}
              breadcrumb={breadcrumb}
              isLast={index === items.length - 1}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default Breadcrumbs
