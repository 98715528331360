import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getUser, isImpersonationUser, isUserAgrarian, isUserSupplier } from '@/logic/auth/reducer'
import { RootState } from '@/redux/interfaces'
import { getContractPermissions, TContractPermissionsContract } from '@/utils/inputs/contracts'

const mapState = (state: RootState) => ({
  user: getUser()(state),
  isAgrarian: isUserAgrarian()(state),
  isSupplier: isUserSupplier()(state),
  isImpersonated: isImpersonationUser()(state),
})

export function useContractPermissions(contract?: TContractPermissionsContract) {
  const { user, isAgrarian, isSupplier, isImpersonated } = useSelector(mapState)

  return useMemo(
    () =>
      getContractPermissions(contract ?? null, {
        user,
        isAgrarian,
        isSupplier,
        isImpersonated,
      }),
    [contract, user, isAgrarian, isSupplier, isImpersonated],
  )
}
