import React, { PropsWithChildren } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dropdown as PoleUIDropdown, TOnChangeDropdownItem } from '@frontend/pole-ui/lib/components/Dropdown'
import omit from 'lodash/omit'
import { getDropdownPlaceholderToPass, getDropdownValidateFunction } from '../helpers/Dropdown'
import { IDropdownProps } from './interfaces'

function Dropdown<GValue = string | number>(props: PropsWithChildren<IDropdownProps<GValue>>) {
  const { errorValues, onChange, errorText, customPlaceHolder } = props
  const { formatMessage } = useIntl()
  const labelToPass = getDropdownPlaceholderToPass(props, formatMessage)

  const validate = getDropdownValidateFunction<GValue>(props)

  const initializeDropdown = (fieldProps: FieldRenderProps<GValue>) => {
    const { input, meta } = fieldProps

    const onItemChange: TOnChangeDropdownItem<GValue> = (changedValue, item, event) => {
      input.onChange(changedValue)
      onChange?.(changedValue, item, event)
      input.onBlur()
    }

    return (
      <PoleUIDropdown<GValue>
        {...omit(props, 'customValidator')}
        {...fieldProps}
        value={input.value}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        errorText={errorText || <FormattedMessage id={`field.error.${meta.error || meta.data?.warning}`} values={errorValues} />}
        onChange={onItemChange}
        label={labelToPass}
        placeholder={customPlaceHolder}
      />
    )
  }

  return (
    <Field<GValue> {...props} validate={validate}>
      {initializeDropdown}
    </Field>
  )
}

export default Dropdown
