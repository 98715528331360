import React, { memo } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Button, Image } from '@/components/ui'
import { IAgroserviceSmallCardProps } from './interfaces'
import './styles.scss'

const AgroserviceSmallCard: React.FC<IAgroserviceSmallCardProps> = props => {
  const {
    url,
    logoUrl,
    isLogoInStatic = true,
    initialPrice,
    onClick,
    logoClassName,
    paymentPeriod,
    price,
    className,
    backgroundImageUrl = '',
    isBackgroundImageInStatic = true,
    buttonText,
    titleText,
    descriptionText,
  } = props

  return (
    <div className={classNames('agroservice-card-wrapper', className)}>
      <div
        className={classNames(
          'with-blurred-bottom-block agroservice-small-card-item agroservice-card-item-wrapper',
          'with-blurred-bottom-block_visible',
        )}
      >
        <h4 className={'agroservice-small-card__title space-holder8'}>{titleText}</h4>
        <div className="agroservice-small-card-item__text space-holder16">{descriptionText}</div>
        <section className="space-holder-top-auto">
          <div onClick={onClick} className="agroservice-small-card-item__bottom row row_nowrap space-holder-top-auto">
            <CommonLink url={url}>
              <Button onClick={onClick} modifiers={['white', 'small']}>
                {buttonText}
              </Button>
            </CommonLink>
            {price && (
              <div className="agroservice-small-price">
                {initialPrice && <h5 className="agroservice-small-price__initial">{initialPrice}</h5>}
                <div className="agroservice-small-price__actual row row_nowrap">
                  <span className="h4">{price}</span>
                  &nbsp;
                  <span className="agroservice-small-price__period">{paymentPeriod}</span>
                </div>
              </div>
            )}
          </div>
        </section>
        {logoUrl && <Image src={logoUrl} isInStatic={isLogoInStatic} className={logoClassName || 'agroservice-card-item-wrapper__logo'} />}
      </div>
      <Image src={backgroundImageUrl} className="agroservice-card-background-image" isInStatic={isBackgroundImageInStatic} />
    </div>
  )
}

export default memo(AgroserviceSmallCard)
