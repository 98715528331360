import React, { useCallback } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { RangeSlider } from '@frontend/pole-ui/lib/components/RangeSlider'
import { getMappedFieldMetaState, splitHookFormControllerProps } from '@/utils/fields'
import { IRangeSliderProps } from './types'

export function HookFormRangeSlider<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: IRangeSliderProps<TFieldValues, TName>) {
  const [controllerProps, { isDisabled, onBlur, onChange, ...componentProps }] = splitHookFormControllerProps(props)

  const disabled = controllerProps.disabled || isDisabled

  const { field, fieldState } = useController({ ...controllerProps, disabled })

  const onInputBlur: NonNullable<typeof onBlur> = useCallback(() => {
    field.onBlur()
    onBlur?.()
  }, [field, onBlur])

  const onInputChange: NonNullable<typeof onChange> = useCallback(
    event => {
      field.onChange(event)
      onChange?.(event)
    },
    [field, onChange],
  )

  return (
    <RangeSlider
      errorText={fieldState.error?.message}
      {...componentProps}
      {...field}
      isDisabled={field.disabled}
      onBlur={onInputBlur}
      onChange={onInputChange}
      meta={getMappedFieldMetaState(fieldState)}
    />
  )
}
