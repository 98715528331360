import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
// TODO: роуты вынести выше при выносе футера
// https://jira.poleinvest.ru/browse/BETS-320
import {
  EAgriculturalProductsRoute,
  EAgroservicesRoute,
  ECommonRoute,
  EFinancingOfferRoute,
  EHarvestSaleRoute,
  EJournalRoute,
  EPartnerLandingRoute,
} from '@/types'
import { ELegalDocumentsRoute } from '@/types/ELegalDocumentsRoute'
import { EOfferRequestsRoute } from '@/types/EOfferRequestsRoute'
import { getUrlWithQuery } from '@/utils/url'
import { EContactEmail, IFooterLink } from './interfaces'

const generatePathWithParams = (path: string, params: { [key: string]: string }): string =>
  Object.keys(params).reduce((acc, key) => {
    const regex = new RegExp(`:${key}`, 'g')

    return acc.replace(regex, `${params[key]}`)
  }, path)

export const links: Record<'main' | 'company' | 'social' | 'contacts' | 'contactUs', IFooterLink[]> = {
  main: [
    {
      name: 'agriculturalProducts',
      icon: EIconName.Farming,
      url: EAgriculturalProductsRoute.main,
    },
    {
      name: 'harvestSale',
      icon: EIconName.Harvest,
      url: EHarvestSaleRoute.main,
    },
    { name: 'offerRequests', icon: EIconName.Scale, url: getUrlWithQuery(EOfferRequestsRoute.main, { offset: 0 }) },
    {
      name: 'financing',
      icon: EIconName.Wallet,
      url: EFinancingOfferRoute.main,
    },
    {
      name: 'services',
      icon: EIconName.Services,
      url: EAgroservicesRoute.main,
    },
    {
      name: 'journal',
      icon: EIconName.Journal,
      url: EJournalRoute.MAIN,
    },
  ],
  company: [
    {
      url: ECommonRoute.aboutUs,
      name: 'aboutUs',
    },
    {
      url: EPartnerLandingRoute.registrationPartners,
      name: 'partners',
    },
    {
      url: '/requisites',
      name: 'requisites',
    },
    {
      url: 'https://hh.ru/employer/5188406',
      name: 'vacancies',
      shouldOpenInNewWindow: true,
    },
    {
      url: generatePathWithParams(ELegalDocumentsRoute.DOCUMENT_VERSION, {
        documentId: 'USER_REGISTRATION_RULES',
        versionId: 'USER_REGISTRATION_RULES',
      }),
      name: 'policy',
      shouldOpenInNewWindow: true,
    },
  ],
  social: [
    {
      url: 'https://t.me/+WQS9REh0HtY2ZWRi',
      name: 'telegram',
      icon: EIconName.Telegram1,
      isFilled: true,
      shouldOpenInNewWindow: true,
    },
    {
      url: 'https://vk.com/pole_rf',
      name: 'vkontakte',
      icon: EIconName.VkontakteFill,
      isFilled: true,
      shouldOpenInNewWindow: true,
    },
    {
      url: 'https://youtube.com/@pole_rf',
      name: 'youtube',
      icon: EIconName.YoutubeFill,
      isFilled: true,
      shouldOpenInNewWindow: true,
    },
    {
      url: 'https://zen.yandex.ru/id/617fd5362b2cdc4479b3fba3?lang=ru&utm_source=sitedzen&utm_medium=sitedzen&utm_campaign=sitedzen',
      name: 'yandexZen',
      icon: EIconName.YandexZen,
      isFilled: true,
      shouldOpenInNewWindow: true,
    },
  ],
  contacts: [
    {
      url: 'tel:88007070385',
      name: 'phone',
      icon: EIconName.Call,
      isLarge: true,
    },
    {
      url: '/feedback',
      name: 'feedback',
      icon: EIconName.Chat,
    },
  ],
  contactUs: [
    {
      name: 'cooperation',
      email: EContactEmail.FEEDBACK,
      withLabel: true,
      isUnderlined: true,
    },
    {
      name: 'pr',
      email: EContactEmail.PR,
      withLabel: true,
      isUnderlined: true,
    },
    {
      name: 'hr',
      email: EContactEmail.HR,
      withLabel: true,
      isUnderlined: true,
    },
    {
      name: 'journal',
      email: EContactEmail.MEDIA,
      withLabel: true,
      isUnderlined: true,
    },
  ],
}
