import React, { FC } from 'react'
import classNames from 'classnames'
import { Breadcrumb } from '@/components/ui'
import Breadcrumbs from '@/components/ui/Breadcrumbs'
import { IWideBreadcrumbsProps } from './interfaces'
import './styles.scss'

const WideBreadcrumbs: FC<IWideBreadcrumbsProps> = ({ customBreadcrumbs, captionId, link, children, className, isUserAuthenticated }) => (
  <div className={classNames('wide-breadcrumbs', className)}>
    <div className="page-wrapper">
      <div className="wide-breadcrumbs__wrapper">
        <div>
          <Breadcrumbs customBreadcrumbs={customBreadcrumbs} />
          <div className="wide-breadcrumbs__back-btn">
            <Breadcrumb
              breadcrumb={{
                link,
                captionId,
                withBackIcon: true,
              }}
            />
          </div>
        </div>
      </div>

      {children}
    </div>
  </div>
)

export default WideBreadcrumbs
