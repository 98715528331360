import { TDatePickerValue } from '@frontend/pole-ui/lib/components/DatePicker'
import { isSameMonth, isSameYear } from 'date-fns'
import { FieldValidator } from 'final-form'
import { FieldValue } from 'firebase/firestore/lite'
import { EMAIL_RE } from '@/constants/regex'
import { normalizePhoneNumber } from './formatUtils'

export const isFieldRequired: FieldValidator<any> = value =>
  typeof value !== 'undefined' && String(value)?.length ? undefined : 'required'

export const isFileGreaterThenMb = (file: Pick<File, 'size'>, maxFileSizeInMB: number): boolean => {
  const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB

  return file.size > maxFileSizeInKB
}

export const isSameMonthInYear = (value: TDatePickerValue): string | undefined => {
  if (!Array.isArray(value)) return undefined

  const [start = '', end = ''] = value
  const startDate = new Date(start)
  const endDate = new Date(end)

  return isSameMonth(startDate, endDate) && isSameYear(startDate, endDate) ? undefined : 'invalidCurrentMonth'
}

export const hasMinValue =
  (min: number, errorDescription = 'incorrectMinValue') =>
  (value: string): string | undefined =>
    typeof value === 'undefined' || Number(value) >= min ? undefined : errorDescription

export const hasMaxValue =
  (max: number, errorDescription = 'incorrectMaxValue') =>
  (value: string): string | undefined =>
    !value || Number(value) <= max ? undefined : errorDescription

export const rangeOfValues =
  (min: number, max: number, minErrorDescription = 'incorrectMinValue', maxErrorDescription = 'incorrectMaxValue') =>
  (value: string): string | undefined => {
    if (Number(value) < min) return minErrorDescription
    if (Number(value) > max) return maxErrorDescription

    return undefined
  }

export const numbersAfterFloatValid =
  (numAfterDot: number, errorDescription = 'numbersAfterDot') =>
  (value: string): string | undefined | void => {
    if (!value) return undefined
    if (value.split('.')[1]?.length && value.split('.')[1]!.length > numAfterDot) return errorDescription

    return undefined
  }

export const isPhoneNumber = ((value = ''): string | undefined => {
  if (!value) return undefined

  const phoneNumber = normalizePhoneNumber(value)

  return phoneNumber.length === 11 ? undefined : 'shouldBeInPhoneNumberFormat'
}) satisfies FieldValidator<any>

export const isValidEmail: FieldValidator<any> = (value = ''): string | undefined => {
  if (!value) return undefined

  return EMAIL_RE.test(value) ? undefined : 'shouldBeInEmailFormat'
}

export const isNeededValueLength =
  (neededLength: number): FieldValidator<any> =>
  (value = '') =>
    value.length === neededLength ? undefined : 'required'

export const composeValidators =
  (...validators: FieldValidator<any>[]) =>
  (value: string, allValues: object) =>
    validators.reduce((error: string | undefined, validator) => error || validator(value, allValues), undefined)

export type TGetValidateFunction = (getValidators: () => FieldValidator<any>[]) => FieldValidator<any> | undefined

export const getValidateFunction: TGetValidateFunction = getValidators => {
  const validators = getValidators()
  let validate
  if (validators.length) validate = composeValidators(...validators)

  return validate
}

export const isCheckboxRequired: FieldValidator<boolean> = value => (value ? undefined : 'required')
export const isAutosuggestFieldRequired =
  (errorCustomMessage = 'required') =>
  (value: FieldValue): any | Promise<any> =>
    value ? undefined : errorCustomMessage

export const isMinLength =
  (minLength: number): FieldValidator<any> =>
  (value = '') =>
    value.length < minLength ? 'minLength' : undefined

export const isMaxLength =
  (maxLength: number): FieldValidator<any> =>
  (value = '') =>
    value.length > maxLength ? 'maxLength' : undefined

export const getStoreValidator =
  (isRequired: boolean) =>
  (value: any): string | undefined =>
    isRequired && (!value || value === 'placeholder') ? 'requiredStoreId' : undefined

const isFormValuesWithEmptyDadataResponseFlag = (values: unknown): values is { __shouldShowEmptyDadataResponse__: boolean } => {
  const formValues = (values || {}) as { __shouldShowEmptyDadataResponse__: boolean }

  return '__shouldShowEmptyDadataResponse__' in formValues
}

export const autosuggestOrganizationProfileValidator: (defaultError?: string) => FieldValidator<any> =
  (defaultError = 'incorrectInn') =>
  (value, allValues) => {
    if (isFormValuesWithEmptyDadataResponseFlag(allValues) && allValues['__shouldShowEmptyDadataResponse__']) return 'emptyDadataResponse'

    if (!value) return defaultError

    return undefined
  }
