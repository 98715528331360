import React from 'react'
import { useHistory } from 'react-router-dom'
import { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { Chip, HintWrapper, StatusMessage, TextWithIcon } from '@/components/ui'
import Breadcrumb from '@/components/ui/Breadcrumbs/Breadcrumb'
import { EAgrarianOrderStatus } from '@/types/Order'
import { formatLocalizedString } from '@/utils/formatUtils'
import { IOrderHeaderProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const OrderHeader: React.FC<IOrderHeaderProps> = props => {
  const { title, status, iconProps, sellingOrganizationName, buyerName, isMultiOrder, Footer } = props
  const history = useHistory()

  return (
    <header className="orderHeader">
      <Breadcrumb
        breadcrumb={{
          caption: messages.otherProduct.breadcrumb.text,
          className: 'space-holder40-from-sm space-holder24-sm',
          withBackIcon: true,
          onClick: () => history.goBack(),
        }}
      />
      <div className="orderHeader__wrapper">
        <div>
          <div className="orderHeader__title h3">{title}</div>
          <div className="row">
            {isMultiOrder && (
              <div className="space-holder-top8 orderHeader__rowMultiOrder">
                <Chip color={EChipModifier.LIGHT_GREEN} text={messages.orderHeader.multiOrder.title} />
                <HintWrapper
                  className="space-holder-left4"
                  header={<div className="space-holder8-md h5"> {messages.orderHeader.multiOrder.hint.title}</div>}
                  message={messages.orderHeader.multiOrder.hint.message}
                  shouldDisplayHint
                >
                  <Icon name={EIconName.QuestFill} size={EIconSize.XS} style={{ opacity: 0.15 }} />
                </HintWrapper>
              </div>
            )}
            {sellingOrganizationName && (
              <div className="text_super-small-from-sm space-holder-top4">
                {formatLocalizedString(messages.orderHeader.seller, { sellingOrganizationName })}
              </div>
            )}
            {buyerName && (
              <div className="text_super-small-from-sm space-holder-top4">
                {formatLocalizedString(messages.orderHeader.buyer, { buyerName })}
              </div>
            )}
            {Footer}
          </div>
        </div>
        <div className="orderHeader__status">
          <TextWithIcon
            iconProps={iconProps}
            text={status.name}
            textModifier="base"
            textClassName="text_wrap"
            className="orderHeader__statusTitle"
          />
          {status.id === EAgrarianOrderStatus.CANCELED && (
            <StatusMessage className="orderHeader__statusMessage" isRightTriangle text={status.comment} />
          )}
        </div>
      </div>
    </header>
  )
}

export default OrderHeader
