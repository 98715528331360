import { ReactNode } from 'react'
import { Locale, parseISO } from 'date-fns'
import format from 'date-fns/format'
import formatDateLocaleRu from 'date-fns/locale/ru'
import IntlMessageFormat from 'intl-messageformat'
import { TAppLocale } from '@/logic/app/interfaces'
import { memoizeObject } from './helpers'

export const FormatDateLocale: Record<TAppLocale, Locale> = {
  ru: formatDateLocaleRu,
}

export const normalizePhoneNumber = (phoneNumber: string = '') => {
  const phoneNumberNormalized = String(phoneNumber).replace(/\D/g, '')

  return phoneNumberNormalized.length === 10 && phoneNumberNormalized[0] !== '7' ? `7${phoneNumberNormalized}` : phoneNumberNormalized
}

export const formatDate = (date: string | number | Date, dateFormat: string = 'dd.MM.yyyy, HH:mm', options?: any) => {
  const fixedFormat = dateFormat.replace(/D|Y/g, matchedChar => matchedChar.toLowerCase())

  const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date)

  return format(parsedDate, fixedFormat, options)
}

export const getNumberFromString = (value: string = ''): number => Number(value?.replace(/\D/g, ''))

/** Функция возвращает числовое значение из строки. Кроме чисел возможны значения "." и "-" для отображения дробных и отрицательных чисел */
export const getNumericValueFromString = (value: string = ''): number => Number(value?.replace(/[^(\d|.|\-)]/g, ''))

/* eslint-disable no-useless-escape */
export const getFloatFromString = (value: string) => value.match(/\d+[\.,]?\d*/)?.[0]
/* eslint-enable */

export const getFormattedDatesInterval = (dates: [string, string]): string =>
  memoizeObject(dates.map(date => formatDate(date, 'dd.MM.yyyy')).join(' - '))

export const removeSpaces = (value: string | number) => String(value).replace(/\s+/g, '')

export const getRemaining = (timestamp?: number, divider?: number) =>
  timestamp && divider ? Math.floor((timestamp - Date.now()) / divider) : 0

/**
 * Форматирует строку по шаблону ICU в зависимости от локали
 * https://formatjs.io/docs/intl-messageformat/
 */
export type TFormatOptions = Record<string, string | number | ReactNode | boolean | TRichTextFormatFunction | null | undefined>

export type TRichTextFormatFunction = (chunk: string) => ReactNode

export function formatLocalizedString(template: string, options: TFormatOptions, locale = 'ru') {
  return new IntlMessageFormat(template, locale).format(options) as string
}
