import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button as ButtonView } from '@frontend/pole-ui/lib/components/Button'
import { Icon } from '@frontend/pole-ui/lib/components/Icon'
import { IButtonProps } from './interfaces'

const Button: React.FC<IButtonProps> = props => {
  if (props.isHidden) return null

  const { children, textId, iconProps, textValues, isTextInSpan, animation, animatedContainerClass, ...otherProps } = props

  const button = (
    <ButtonView {...otherProps} icon={iconProps && <Icon {...iconProps} />}>
      {textId && <FormattedMessage tagName={isTextInSpan ? 'span' : undefined} id={textId} values={textValues} />}
      {children}
    </ButtonView>
  )

  if (!animation) return button

  return (
    <div {...animation} className={animatedContainerClass}>
      {button}
    </div>
  )
}

export default Button
