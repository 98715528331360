import { EAuthGtmFieldName } from '@/components/composed/modals/AuthModal/gtmConstants'
import { ELegalDocumentType } from '@/types/legalDocuments'
import { messages } from './messages'
import { IRegistrationDocument } from './types'

export const REGISTRATION_DOCUMENTS_DATA: Array<IRegistrationDocument> = [
  {
    name: EAuthGtmFieldName.isAgreedWithPolicy,
    isRequired: true,
    descriptionParts: [
      { text: messages.requiredText.part1 },
      {
        link: {
          text: messages.rulesLink.policy,
          type: ELegalDocumentType.registrationPolicy,
        },
      },
      { text: messages.requiredText.part2 },
      {
        link: {
          text: messages.rulesLink.part1,
          type: ELegalDocumentType.registrationRules,
        },
      },
      { text: messages.requiredText.part3 },
      {
        link: {
          text: messages.agree.link,
          type: ELegalDocumentType.registrationRegulation,
        },
      },
    ],
  },
  {
    name: EAuthGtmFieldName.receiveAdvertising,
    isRequired: false,
    descriptionParts: [
      { text: messages.noRequiredText.part1 },
      {
        link: {
          text: messages.rulesLink.part2,
          type: ELegalDocumentType.registrationRules,
        },
      },
      { text: messages.noRequiredText.part2 },
    ],
  },
]
