/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SupplyActionDto = {
    name: string;
    type: SupplyActionDto.type;
    url?: string;
    nextStatus: SupplyActionDto.nextStatus;
};

export namespace SupplyActionDto {

    export enum type {
        CHANGE_STATUS = 'CHANGE_STATUS',
        DOWNLOAD = 'DOWNLOAD',
        EDIT_SUPPLY = 'EDIT_SUPPLY',
        DELETE_SUPPLY = 'DELETE_SUPPLY',
    }

    export enum nextStatus {
        SCHEDULED = 'SCHEDULED',
        COMPLETED = 'COMPLETED',
        PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
    }


}

