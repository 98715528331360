import React, { useMemo } from 'react'
import classNames from 'classnames'
import { EHarvestQualityInputsType, EQualityIndicatorsId } from '@/components/composed/HarvestQualityInputs/Enum'
import { Dropdown, TextInput } from '@/components/ui/fields'
import { maxTextQualityIndicatorLength } from '@/constants/outputs'
import { EQualityIndicatorValueType } from '@/types/HarvestSaleLots'
import { IHarvestQualityInputsProps } from './interfaces'
import './styles.scss'

/**
 * Компонент для описания качественных характеристик урожая
 * @param include - В данном параметре необходимо перечислить те свойства, которые необходимо отобразить.
 * При испотзовании данного параметра не будет работать параметр exclude.
 * @param exclude - В данном параметре необходимо перечислить те свойства, которые необходимо исключить.
 * @param customFilter - Данный параметр позволяет расширить массивы include и exclude для конкретных культур.
 * */

const HarvestQualityInputs: React.FC<IHarvestQualityInputsProps> = props => {
  const {
    qualityIndicators,
    onFocusInput,
    type = EHarvestQualityInputsType.GENERAL,
    className,
    cultureId,
    customFilter,
    exclude = [],
    include = [],
    withTemplate,
    template,
  } = props

  const { excludingQualityIndicatorIds, includingQualityIndicatorIds } = useMemo(() => {
    const excludingQualityIndicatorId: Array<EQualityIndicatorsId | string> = [...exclude]
    const includingQualityIndicatorId: Array<EQualityIndicatorsId | string> = [...include]

    if (customFilter && Boolean(customFilter.length)) {
      customFilter.forEach(filterIndicator => {
        if (filterIndicator.cultureId === cultureId) {
          if (filterIndicator.exclude?.length) excludingQualityIndicatorId.unshift(...filterIndicator.exclude)
          if (filterIndicator.include?.length) includingQualityIndicatorId.unshift(...filterIndicator.include)
        }
      })
    }

    return { excludingQualityIndicatorIds: excludingQualityIndicatorId, includingQualityIndicatorIds: includingQualityIndicatorId }
    // eslint-disable-next-line
  }, [JSON.stringify(exclude), JSON.stringify(include), JSON.stringify(customFilter)])

  if (!qualityIndicators) return null

  let indicators = qualityIndicators

  if (includingQualityIndicatorIds.length === 0 && excludingQualityIndicatorIds.length >= 1) {
    indicators = qualityIndicators.filter(indicator => !excludingQualityIndicatorIds.includes(indicator.id))
  } else if (includingQualityIndicatorIds.length >= 1) {
    indicators = qualityIndicators.filter(indicator => includingQualityIndicatorIds.includes(indicator.id))
  }

  const harvestQualityInputsList = indicators.map(
    ({ id, name, possibleValues, required, maxRange, minRange, dataType, unit, isDisplayedOnCreation }) => {
      const fieldName = `quality.${id}`
      const label = `${name}${type !== EHarvestQualityInputsType.ADDITIONAL_PARAMS ? '*' : ''}`
      const isFieldDisabled = withTemplate && template?.qualityIndicators?.indicators && id in template.qualityIndicators.indicators
      if (!isDisplayedOnCreation) return null

      switch (dataType) {
        case EQualityIndicatorValueType.list:
          return (
            <Dropdown
              key={id}
              isDisabled={isFieldDisabled}
              name={fieldName}
              placeholder={label}
              options={possibleValues.map(possibleValue => ({ label: possibleValue, value: possibleValue }))}
              isRequiredWithoutSign={required}
              onOpen={() => onFocusInput?.(fieldName)}
              className={className}
            />
          )
        case EQualityIndicatorValueType.double:
          return (
            <TextInput
              key={id}
              isDisabled={isFieldDisabled}
              name={fieldName}
              label={label}
              type="numberFloat"
              maxValue={maxRange}
              minValue={minRange}
              isRequiredWithoutSign={required}
              className={classNames('harvest-quality-inputs-text-input-size', className)}
            >
              <span>{unit}</span>
            </TextInput>
          )
        case EQualityIndicatorValueType.integer:
          return (
            <TextInput
              key={id}
              isDisabled={isFieldDisabled}
              name={fieldName}
              label={label}
              type="num"
              maxValue={maxRange}
              minValue={minRange}
              isRequiredWithoutSign={required}
              onFocus={() => onFocusInput?.(fieldName)}
              className={classNames('harvest-quality-inputs-text-input-size', className)}
            >
              <span>{unit}</span>
            </TextInput>
          )
        default:
          return (
            <TextInput
              key={id}
              isDisabled={isFieldDisabled}
              name={fieldName}
              label={label}
              type="text"
              maxLength={maxTextQualityIndicatorLength}
              isRequiredWithoutSign={required}
              onFocus={() => onFocusInput?.(fieldName)}
              className={classNames('harvest-quality-inputs-text-input-size', className)}
            >
              <span>{unit}</span>
            </TextInput>
          )
      }
    },
  )

  return <>{harvestQualityInputsList}</>
}

export default HarvestQualityInputs
