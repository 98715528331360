import React from 'react'
import classNames from 'classnames'
import { IProgressBarProps } from './interfaces'
import './styles.scss'

const ProgressBar: React.FC<IProgressBarProps> = ({ percentage, className }) => (
  <div className={classNames('progress-bar', className)}>
    <div className="progress-bar__indicator" style={{ width: `${percentage}%` }} />
    <span className="progress-bar__value text_super-small">{percentage}%</span>
  </div>
)

export default ProgressBar
