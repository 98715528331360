/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateMainPageSliderBannerData = {
    id: string;
    url?: string;
    specialAction?: UpdateMainPageSliderBannerData.specialAction;
    name?: string;
    regions?: Array<number>;
    webImage?: string;
    mobileImage?: string;
    status?: string;
};

export namespace UpdateMainPageSliderBannerData {

    export enum specialAction {
        REGISTRATION_FORM = 'REGISTRATION_FORM',
        URL = 'URL',
    }


}

