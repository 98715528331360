import { requestData } from '@/api/request'
import { ECommonApiUrl } from '@/api/urls'
import { EAccreditationType, IMyAccreditation } from '@/types/Accreditation'
import { TEntityId } from '@/types/TEntityId'
import { ISendingSmsCodeResponse } from '@/types/Verification'
import { getUrlWithQuery } from '@/utils/url'
import {
  IConfirmAccreditationParameters,
  IEditingDisagreementProtocolParameters,
  IRejectAccreditationParameters,
  ISignCodeRequestParameters,
  ISignRequestParameters,
} from './interfaces'

/** Сервис работы с аккредитацией */
export default class AccreditationService {
  /**
   * Создание аккредитации
   * @param {number} organizationId - идентификатор организации, к которой прикрепляется аккредитация
   */
  createAccreditation = (organizationId: string, accreditationType: EAccreditationType) =>
    requestData<IMyAccreditation>({
      url: ECommonApiUrl.accreditation,
      method: 'POST',
      body: { organizationId, accreditationType },
    })

  /**
   * Подтверждение аккредитации ораганизацией (используется в сценарии inputs)
   * @param {Object} body - параметры запроса
   * @prop {string} body.validTo? - выставленная дата окончания аккредитации
   * @param {number} id - идентификатор аккредитации
   * @param {string} version - версия аккредитации, обычно updateDate
   */
  confirmAccreditation = (body: IConfirmAccreditationParameters, id: TEntityId, version: string) =>
    requestData<IMyAccreditation>({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/actions/confirm`, { version }),
      method: 'PUT',
      body,
    })

  /**
   * Переводит в статус редактирования документов
   * @param {number} id - айди аккредитации
   * @param {string} version - версия аккредитации, обычно updateDate
   */
  editAccreditation = (id: TEntityId, version: string) =>
    requestData<IMyAccreditation>({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/actions/edit`, { version }),
      method: 'PUT',
      body: {},
    })

  /**
   * Подписывает аккредитацию электронным токеном
   * @param {Object} body - параметры запроса
   * @prop {string} body.token - токен из ответа sendCode
   * @prop {string} body.code - код из sms, отправленный методом sendCode
   * @param {number} id - идентификатор аккредитации
   * @param {string} version - версия аккредитации, обычно updateDate
   */
  signAccreditation = (body: ISignRequestParameters, id: TEntityId, version: string) =>
    requestData<IMyAccreditation>({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/actions/sign`, { version }),
      method: 'PUT',
      body,
    })

  /**
   * Подписывает аккредитацию электронным токеном и отсылает sms с кодом (используется в сценарии outputs)
   * @param {Object} body - параметры запроса
   * @prop {string} body.validTo - выставленная дата окончания аккредитации
   * @param {number} id - идентификатор аккредитации
   * @param {string} version - версия аккредитации, обычно updateDate
   */
  sendCode = (body: ISignCodeRequestParameters, id: TEntityId, version: string) =>
    requestData<ISendingSmsCodeResponse>({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/actions/code`, { version }),
      method: 'PUT',
      body,
    })

  /**
   * Отклоняет аккредитацию, с комментарием
   * @param {Object} body - параметры запроса
   * @prop {string} body.comment - комментарий отклонения аккредитации
   * @param {number} id - идентификатор аккредитации
   * @param {string} version - версия аккредитации, обычно updateDate
   */
  rejectAccreditation = (body: IRejectAccreditationParameters, id: TEntityId, version: string) =>
    requestData<IMyAccreditation>({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/actions/reject`, { version }),
      method: 'PUT',
      body,
    })

  /**
   * Редактирование протокола разнагласия
   * @param {Object} body - параметры запроса
   * @prop {string} body.disagreementProtocol - html текст протокола разногласий
   * @param {number} id - идентификатор аккредитации
   * @param {string} version - версия аккредитации, обычно updateDate
   */
  editDisagreementProtocol = (body: IEditingDisagreementProtocolParameters, id: TEntityId, version: string) =>
    requestData<IMyAccreditation>({
      url: getUrlWithQuery(`${ECommonApiUrl.accreditation}/${id}/actions/disagreement`, { version }),
      method: 'PUT',
      body,
    })
}
