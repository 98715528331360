import { useEffect, useState } from 'react'
import { useResponsiveValue } from '../responsive'
import { ResponsiveThreshold } from './constants'
import { TDirection } from './useScrollDirection'

interface IUseBlockIntersectionByIdOptions {
  blockIds: Readonly<string[]>
  scrollDirection: TDirection
  isFitsFullScreen?: boolean
}

export const useBlocksIntersectionWhenScrollingDown = (options: IUseBlockIntersectionByIdOptions): string => {
  const { blockIds, scrollDirection } = options
  const [intersectedBlockId, setIntersectedBlockId] = useState('')
  const threshold = useResponsiveValue(ResponsiveThreshold)

  useEffect(() => {
    /*setIntersectedBlockId в этом месте нужен для сброса стейта при изменении blockIds*/
    setIntersectedBlockId('')
    const observer = new IntersectionObserver(
      ([entity]) => {
        if (entity?.isIntersecting) {
          setIntersectedBlockId(entity.target.id)
        }
      },
      { threshold },
    )

    blockIds.forEach(blockId => {
      const block = document.getElementById(blockId)
      if (block) observer.observe(block)
    })

    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [String(blockIds)])

  return scrollDirection === 'down' ? intersectedBlockId : ''
}
