import { createAsyncThunk } from '@reduxjs/toolkit'
import { isStatusOk } from '@/api/helpers'
import { requestData } from '@/api/request'
import { EPoolingApiUrl } from '@/api/urls/EPoolingApiUrl'
import { getUserRegion } from '@/logic/userRegion'
import { IThunkProps } from '@/redux/interfaces'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'
import { poolingLandingRequestFailed, poolingLandingRequestFinished, poolingLandingRequestStarted } from './actions'
import { IFetchPoolingLandingPayload, IPoolingLandingResponse } from './interfaces'

export const fetchPoolingLanding = createAsyncThunk<unknown, IFetchPoolingLandingPayload, IThunkProps>(
  '@@pooling/FETCH_POOLING_LANDING',
  async ({ params, apiUrl = '' }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI
    const { identifier } = params || {}

    dispatch(poolingLandingRequestStarted(identifier))
    const regionCode = getUserRegion()(getState()).code
    const url = getUrlWithQuery(`${apiUrl}${createRouterLink(EPoolingApiUrl.landing, { identifier })}`, { regionCode })
    const response = await requestData<IPoolingLandingResponse>({ url, method: 'GET', state: getState() })

    if (!isStatusOk(response)) {
      dispatch(poolingLandingRequestFailed(response, identifier))

      return
    }

    const { data } = response
    dispatch(poolingLandingRequestFinished(data))
  },
)
