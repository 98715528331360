import { isStatusOk } from '@/api/helpers'
import { requestData } from '@/api/request'
import { ECreditFlowApiUrl } from '@/api/urls/ECreditFlowApiUrl'
import { IFinancingLoanProducts, IRequestLoanProducts } from '@/logic/financing/interfaces'

export default class LoanProducts {
  create = async (body: IRequestLoanProducts) => {
    const response = await requestData<IFinancingLoanProducts>({
      url: ECreditFlowApiUrl.fundingLoanProducts,
      method: 'POST',
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response.data
  }
}
