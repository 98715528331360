import { useRef } from 'react'
import { isEqual } from '@/utils/isEqual'

export function useStableValue<T>(value: T): T {
  const stable = useRef(value)

  if (!isEqual(stable.current, value)) {
    stable.current = value
  }

  return stable.current
}
