import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EUserProfileRoute } from '@/types'
import { formatDate } from '@/utils/formatUtils'
import { Chip } from '../index'
import { statusesByParam } from './constants'
import { IAccreditationLabelLinkProps, TAccreditationStateName } from './interfaces'

const AccreditationLabelLink: React.FC<IAccreditationLabelLinkProps> = ({ accreditation, withLink }) => {
  if (!accreditation) return null
  const { validTo = '', status, id } = accreditation
  const { active, expired, processing, rejected } = status.params
  const params = {
    isExpired: expired,
    isProcessing: processing,
    isRejected: rejected,
    isValid: active,
  }
  const [[currentParam] = []] = Object.entries(params).filter(param => param[1])
  const labelModifier = statusesByParam[currentParam as TAccreditationStateName]
  const { textId } = labelModifier || {}

  return (
    <CommonLink url={withLink ? `${EUserProfileRoute.myAccreditation}/${id}` : undefined} spanIfEmpty>
      <Chip
        className="counterparty-info__valid-to text_super-small-sm"
        textId={`counterparty.accreditation.${textId}`}
        textValues={{ date: formatDate(validTo, 'dd.MM.yyyy') }}
      />
    </CommonLink>
  )
}

export default AccreditationLabelLink
