import React, { useMemo } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { RangeSlider as PoleUIRangeSlider, TOnChangeRange } from '@frontend/pole-ui/lib/components/RangeSlider'
import { combineParsers, maxNumberFormatter, minNumberFormatter } from '../TextInput/helpers'
import { IRangeSliderProps } from './interfaces'

const RangeSlider: React.FC<IRangeSliderProps> = props => {
  const { withInput, sliderProps } = props

  const parse = useMemo(() => {
    if (!withInput) return undefined

    const parsers: Array<(value: string) => string> = []

    if (sliderProps?.maxValue !== undefined) {
      parsers.push(maxNumberFormatter(sliderProps.maxValue))
    }

    const parser = combineParsers(...parsers)

    return (value: number) => Number(parser(String(value)))
  }, [withInput, sliderProps?.maxValue])

  const format = useMemo(() => {
    if (!withInput) return undefined

    const formater = minNumberFormatter(sliderProps?.minValue || 0)

    return (value: number) => formater(String(value))
  }, [withInput, sliderProps?.minValue])

  const initializeRangeSlider = (fieldProps: FieldRenderProps<number>) => {
    const { input } = fieldProps

    const onItemChange: TOnChangeRange = changedValue => {
      input.onChange(changedValue)
      input.onBlur()
    }

    return <PoleUIRangeSlider {...props} {...fieldProps} value={input.value} onChange={onItemChange} />
  }

  return (
    <Field<number> parse={parse} format={format} formatOnBlur {...props}>
      {initializeRangeSlider}
    </Field>
  )
}

export default RangeSlider
