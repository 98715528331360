import { TMessages } from '@/types/TMessages'

export const messages = {
  heading: 'Заказ №{id} {orderDate}',
  addressHeading: 'Адрес доставки: ',
  productsHeading: 'Товары:',
  buttonText: 'Открыть',
  offerValidTo: 'Оферта действительна до {date}',
  deliveryText: '{hasTypedDeliveryPrice, select, true {С учетом доставки, с} other {С}} учетом НДС',
  unitWeight: ' т',
  linkChatText: 'предложить свою цену в чате',
  multiOrderText: 'Мультизаявка',
  withPriceNegotiationNotice: 'В заказе есть товары с договорной ценой',
} satisfies TMessages
