import React, { FC, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useClickAway } from 'react-use'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { Button } from '@/components/ui'
import { Fading } from '@/components/ui/animations'
import { canUseChat, canUseNotifications } from '@/logic/app/reducer'
import { getNotificationsValues } from '@/logic/notifications/reducer'
import { injectMessages } from '@/logic/translation/utils'
import { RootState } from '@/redux/interfaces'
import { EUserProfileRoute } from '@/types'
import { useScrollDirection } from '@/utils/hooks'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  notifications: getNotificationsValues()(state),
  canUserUseChat: canUseChat()(state),
  canUserUseNotifications: canUseNotifications()(state),
})

const NoticesLink: FC = () => {
  const [isLinksShown, setLinksVisibility] = useState<boolean>(false)
  const wrapperRef = useRef(null)
  const { notifications, canUserUseChat, canUserUseNotifications } = useSelector(mapState)
  const { unreadChatMessagesCount, unreadNotificationsCount, totalUnreadNotificationsCount } = notifications
  const toggleLinksVisibility = (): void => setLinksVisibility(!isLinksShown)
  const { scrollClassName } = useScrollDirection({ className: 'notices' })

  useEffect(() => {
    if (scrollClassName === 'notices_down') setLinksVisibility(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollClassName])

  useClickAway(wrapperRef, () => {
    if (isLinksShown) setLinksVisibility(false)
  })

  return (
    <div className="notices text_small-from-sm">
      <Button className="notices__bell" modifiers={['clickable']} isFake isUnstyled onClick={toggleLinksVisibility}>
        <Icon name={EIconName.Bell} className="notices__bell__icon" size={EIconSize.S} />
        <span className="notices__total">{totalUnreadNotificationsCount}</span>
      </Button>
      <Fading isVisible={isLinksShown}>
        <div className="notices-links" ref={wrapperRef}>
          {canUserUseChat && (
            <CommonLink url={EUserProfileRoute.myChats}>
              <Button modifiers={['clickable', 'full-width', 'black']} className="notices-links__link" onClick={toggleLinksVisibility}>
                <FormattedMessage id="notices.link.myChats" /> {unreadChatMessagesCount}
              </Button>
            </CommonLink>
          )}
          {canUserUseNotifications && (
            <CommonLink url={EUserProfileRoute.notifications} className="notices-links__link">
              <Button modifiers={['clickable', 'full-width', 'black']} onClick={toggleLinksVisibility}>
                <FormattedMessage id="notices.link.notifications" /> {unreadNotificationsCount}
              </Button>
            </CommonLink>
          )}
        </div>
      </Fading>
    </div>
  )
}

export default NoticesLink
