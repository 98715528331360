import React from 'react'
import { FormattedMessage } from 'react-intl'
import { injectMessages } from '@/logic/translation/utils'
import { IToggleDisplayingItemsButtonProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const ToggleDisplayingItemsButton: React.FC<IToggleDisplayingItemsButtonProps> = ({ onActionClick, shouldExpand }) => (
  <label className="toggle-displaying-items-button">
    <input
      type="checkbox"
      className="toggle-displaying-items-button__button"
      checked={shouldExpand}
      onChange={event => onActionClick(event.target.checked)}
    />
    <span className="toggle-displaying-items-button__arrow" />
    <span className="toggle-displaying-items-button__text underline underline_black text_small color_pale-black">
      <FormattedMessage id={`toggleDisplayingItemsButton.${shouldExpand ? 'hide' : 'show'}`} />
    </span>
  </label>
)

export default ToggleDisplayingItemsButton
