import { requestData, requestDataWithRawResponse } from '@/api/request'
import { ELkApiUrl } from '@/api/urls'
import { IDocumentsResponse } from '@/services/documents/interfaces'
import { downloadFileFromResponse } from '@/utils/files'
import { getUrlWithQuery } from '@/utils/url'

interface IUploadDocumentOptions {
  file: File
  name: string
  type: string
}

interface IUpdateDocumentOptions {
  file: File
  name: string
  type: string
  documentId: string
}

interface IDeleteDocumentOptions {
  documentId: string
}

/**
 * Сервис работы с документами организации/пользователя
 */
export default class OrganizationDocumentsService {
  /**
   * Загрузка документа организации/пользователя
   * @param  {Object} options
   * @prop  {File} options.file - файл
   * @prop  {string} options.name - имя файла
   * @prop  {string} options.type - тип файла
   */
  uploadDocument = ({ file, name, type }: IUploadDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: ELkApiUrl.organizationDocuments,
      method: 'POST',
      body: formData,
      isJson: false,
    })
  }
  /**
   * Обновление документа организации/пользователя
   * @param  {Object} options
   * @prop  {File} options.file - файл
   * @prop  {string} options.name - имя файла
   * @prop  {string} options.type - тип файла
   * @prop {string} options.documentId - идентификатор документа
   */
  updateDocument = ({ file, name, type, documentId }: IUpdateDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: `${ELkApiUrl.organizationDocuments}/${documentId}`,
      method: 'PUT',
      body: formData,
      isJson: false,
    })
  }

  /**
   * Удаление документа организации/пользователя
   * @param  {Object} options
   * @prop {string} options.documentId - идентификатор документа
   */
  deleteDocument = ({ documentId }: IDeleteDocumentOptions) =>
    requestData({
      url: `${ELkApiUrl.organizationDocuments}/${documentId}`,
      method: 'DELETE',
    })

  /**
   * Скачивание документа организации/пользователя
   * @param  {string} url - версия запроса, поле updateDate организации/пользователя
   * @param {string} fileName - идентификатор документа
   */
  downloadDocument = (url: string, fileName: string) =>
    requestDataWithRawResponse<Blob>({ url, method: 'GET' }).then(response => {
      downloadFileFromResponse(response, fileName)
    })

  /**
   * Запрос документов организации/пользователя
   */
  fetchDocuments = () =>
    requestData<IDocumentsResponse>({
      url: getUrlWithQuery(ELkApiUrl.organizationDocuments, { limit: 100 }),
      method: 'GET',
    })
}
