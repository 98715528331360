/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LandingPageData = {
    id: number;
    identifier: string;
    skuId: string;
    offerId: string;
    skuName: string;
    image?: string;
    currentPrice: number;
    oldPrice: number;
    description: string;
    startPromotions: string;
    endPromotions: string;
    unitCode: string;
    status: string;
    regions: Array<string>;
    categoryId: number;
    packageTypeVolume: number;
    type: LandingPageData.type;
    supplier: string;
};

export namespace LandingPageData {

    export enum type {
        POOLING = 'POOLING',
        SUPPLIER_PROMO = 'SUPPLIER_PROMO',
    }


}

