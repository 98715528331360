import { RefObject } from 'react'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'

let scrollPosition = 0
let initialOverflow = ''
const instances = new Set<RefObject<HTMLDivElement>>()

// https://git.agro-it.team/marketplace/frontend/agromarket/-/merge_requests/1387#note_38802
export function useBodyScrollLock(instance: RefObject<HTMLDivElement>) {
  const lockScroll = () => {
    if (ExecutionEnvironment.isSsr) return

    const body = document.body

    scrollPosition = window.scrollY
    initialOverflow = body.style.overflow

    body.style.position = 'fixed'
    body.style.top = `-${scrollPosition}px`
    body.style.width = '100%'
  }

  const unlockScroll = () => {
    if (ExecutionEnvironment.isSsr) return

    const html = document.documentElement
    const body = document.body

    if (initialOverflow) body.style.overflow = initialOverflow
    else body.style.removeProperty('overflow')

    body.style.removeProperty('position')
    body.style.removeProperty('top')
    body.style.removeProperty('width')

    html.style.scrollBehavior = 'unset'
    window.scrollTo(0, scrollPosition)
    html.style.removeProperty('scroll-behavior')
  }

  const lock = () => {
    if (!instances.size) lockScroll()
    instances.add(instance)
  }

  const unlock = () => {
    instances.delete(instance)
    if (!instances.size) unlockScroll()
  }

  const forceUnlock = () => {
    instances.clear()
    unlockScroll()
  }

  return {
    lock,
    unlock,
    forceUnlock,
  }
}
