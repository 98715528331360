import { closeModal, openModal } from '@/components/composed/Modal/actions'
import { ICustomizedFormModalOptions } from '@/components/composed/modals/CustomizedFormModal/interfaces'
import { IMessageModalOptions } from '@/components/composed/modals/MessageModal/interfaces'
import { changeHarvestSaleRequest, changeHarvestSaleRequestStatus, rejectHarvestSaleRequest } from '@/logic/harvestSaleRequest/actions'
import { ERequestOfHarvestSaleRequestName } from '@/logic/harvestSaleRequest/constants'
import { TAppDispatch } from '@/redux/interfaces'
import { EUserProfileRoute } from '@/types'
import { IRequestQualityIndicator } from '@/types/HarvestSaleLots'
import { IHarvestSaleRequest } from '@/types/HarvestSaleRequest'
import { EHarvestSaleTitleType } from '@/types/HarvestSaleRequest/EHarvestSaleTitleType'
import { formatDate } from '@/utils/formatUtils'
import { getObjectKeys } from '@/utils/getObjectKeys'
import { scrollTo } from '@/utils/helpers'
import { IStatusCommentFormValues } from '../AgrarianOrder/interfaces'
import { IShipmentConfirmationModalOptions } from './components/modals/ShipmentConfirmationModal/types'
import {
  EDeliveryBasisName,
  IAgrarianSaleRequestFormValues,
  TAgrarianSaleRequestFormValues,
  TDeliveryBasisId,
  TTransportTypeOptions,
} from './interfaces'
import { messages } from './messages'

export const scrollToDetails = () => scrollTo('.tab-list')
export const scrollToDetailsForm = () => scrollTo('.agrarian-order-scroll-to-on-error')

export const getRejectingRequestCancellationModalSteps = (
  onBackButtonClick: () => void,
  titleType: EHarvestSaleTitleType = EHarvestSaleTitleType.REQUEST,
) => {
  const rejectFormMessages = {
    [EHarvestSaleTitleType.REQUEST]: {
      firstTitle: messages.agrarianSaleRequest.rejectingOrderModal.title.request,
      secondTitle: messages.agrarianSaleRequest.statusComment.title.request,
      buttonText: messages.agrarianSaleRequest.rejectButton.request,
    },
    [EHarvestSaleTitleType.DEAL]: {
      firstTitle: messages.agrarianSaleRequest.rejectingOrderModal.title.deal,
      secondTitle: messages.agrarianSaleRequest.statusComment.title.deal,
      buttonText: messages.agrarianSaleRequest.rejectButton.deal,
    },
    [EHarvestSaleTitleType.PROPOSAL]: {
      firstTitle: messages.agrarianSaleRequest.rejectingOrderModal.title.proposal,
      secondTitle: messages.agrarianSaleRequest.statusComment.title.proposal,
      buttonText: messages.agrarianSaleRequest.rejectButton.proposal,
    },
  }

  return [
    {
      titleId: rejectFormMessages[titleType].firstTitle,
      fields: [],
      shouldShowRequiringFieldsLabel: false,
      errorMessageId: messages.agrarianSaleRequest.rejectingOrderModal.error,
      submitButton: {
        textId: messages.agrarianSaleRequest.rejectingOrderModal.rejectButton.text,
        modifiers: ['full-width', 'red'],
      },
      buttons: [
        {
          textId: messages.agrarianSaleRequest.rejectingOrderModal.backButton.text,
          modifiers: ['full-width', 'outline-gray'],
          className: 'space-holder-top8',
          onClick: onBackButtonClick,
        },
      ],
    },
    {
      titleId: rejectFormMessages[titleType].secondTitle,
      fields: [
        {
          type: 'textArea',
          labelId: messages.agrarianSaleRequest.statusComment.comment,
          className: 'space-holder24',
          isRequired: true,
          name: 'statusComment',
        },
      ],
      errorMessageId: messages.agrarianSaleRequest.rejectingOrderModal.error,
      submitButton: {
        textId: rejectFormMessages[titleType].buttonText,
        modifiers: ['full-width', 'red'],
      },
    },
  ]
}

export const openRejectingOrderModal = (titleType: EHarvestSaleTitleType) => (dispatch: TAppDispatch) =>
  dispatch(
    openModal({
      options: {
        dialogId: 'CustomizedFormModal',
      },
      contentOptions: {
        customizedFormProps: {
          requestStatusName: ERequestOfHarvestSaleRequestName.REJECT,
          onSubmit: ({ statusComment }) => {
            dispatch(rejectHarvestSaleRequest({ redirectUrl: EUserProfileRoute.sales, statusComment }))
          },
          steps: getRejectingRequestCancellationModalSteps(() => dispatch(closeModal()), titleType),
        },
      } as ICustomizedFormModalOptions<IStatusCommentFormValues>,
    }),
  )

export const onSaleRequestChange = (
  formValues: IAgrarianSaleRequestFormValues,
  request: IHarvestSaleRequest,
  statusComment?: string,
  onSuccessChanging?: () => void,
) => {
  const {
    price,
    volume,
    quality,
    deliveryBasisId,
    elevatorId,
    storeId,
    deliveryRange,
    prepaymentPercentage,
    paymentType,
    discount,
    transportType,
    requestDiscountData,
  } = formValues
  const [startDate, endDate] = deliveryRange || []
  const deliveryOptions = {
    startDate: formatDate(startDate, 'yyyy-MM-dd'),
    endDate: formatDate(endDate, 'yyyy-MM-dd'),
    storeId,
    deliveryBasisId,
    elevatorId,
  }

  return changeHarvestSaleRequest({
    changedValues: {
      quality,
      price: {
        value: price ? Number(price) : 0,
        uom: request.price.uom,
      },
      volume: volume ? Number(volume) : 0,
      deliveryOptions,
      prepaymentPercentage,
      paymentType,
      discount,
      transportType,
      requestDiscountData,
    },
    statusComment,
    onSuccessChanging,
  })
}

export const openCommentModal = (formValues: IAgrarianSaleRequestFormValues, request: IHarvestSaleRequest) => (dispatch: TAppDispatch) =>
  dispatch(
    openModal({
      options: {
        dialogId: 'CustomizedFormModal',
      },
      contentOptions: {
        customizedFormProps: {
          onSubmit: ({ statusComment }) => {
            dispatch(onSaleRequestChange(formValues, request, statusComment))
            dispatch(closeModal())
            scrollToDetails()
          },
          steps: [
            {
              titleId: messages.agrarianSaleRequest.commentModal.title,
              fields: [
                {
                  type: 'textArea',
                  labelId: messages.agrarianSaleRequest.commentModal.placeholder.title,
                  className: 'space-holder24',
                  name: 'statusComment',
                },
              ],
              errorMessageId: messages.agrarianSaleRequest.changingOrder.failure,
              submitButton: {
                textId: messages.agrarianSaleRequest.commentModal.saveButton,
                modifiers: ['full-width', 'green'],
              },
              shouldShowRequiringFieldsLabel: false,
            },
          ],
        },
      } as ICustomizedFormModalOptions<IStatusCommentFormValues>,
    }),
  )

export const openMultirequestConfirmationModal = () => (dispatch: TAppDispatch) => {
  dispatch(
    openModal({
      options: {
        dialogId: 'MessageModal',
        headerId: messages.agrarianSaleRequest.multirequestConfirmationModal.title,
      },
      contentOptions: {
        exitIcon: { name: 'flat/exit', size: '24px' },
        messages: [{ id: messages.agrarianSaleRequest.multirequestConfirmationModal.description }],
        buttons: [
          {
            textId: messages.agrarianSaleRequest.multirequestConfirmationModal.button.confirm,
            modifiers: ['green', 'full-width-mobile'],
            className: 'message-modal-buttons__confirm text_body',
            onClick: () => {
              dispatch(changeHarvestSaleRequestStatus({}))
              dispatch(closeModal())
            },
          },
          {
            textId: messages.agrarianSaleRequest.multirequestConfirmationModal.button.cancel,
            modifiers: ['outline', 'full-width-mobile'],
            className: 'message-modal-buttons__cancel text_body',
          },
        ],
        className: 'multi-request-confirmation-modal__inner text_body',
        modalContentClassName: 'multi-request-confirmation-modal',
      } as IMessageModalOptions,
    }),
  )
}

export const openShipmentConfirmationModal = (id: number) => (dispatch: TAppDispatch) => {
  dispatch(
    openModal({
      options: {
        dialogId: 'ShipmentConfirmationModal',
      },
      contentOptions: {
        id,
      } as IShipmentConfirmationModalOptions,
    }),
  )
}

const getChanges = (
  newValue: IAgrarianSaleRequestFormValues,
  previousValues: IAgrarianSaleRequestFormValues,
  dispatch: TAppDispatch,
  qualityIndicatorsName?: IRequestQualityIndicator,
) => {
  const keys = getObjectKeys(newValue)
  const changedKeys = keys.filter(el => JSON.stringify(newValue[el]) !== JSON.stringify(previousValues[el]))
  const changedValues = changedKeys.map(el => {
    if (el === 'deliveryRange') {
      return {
        id: el,
        labelId: messages.deliveryRange,
        value: `${formatDate(newValue[el][0], 'yyyy-MM-dd')} - ${formatDate(newValue[el][1], 'yyyy-MM-dd')}`,
        previousValue: `${previousValues[el][0]} - ${previousValues[el][1]}`,
      }
    }

    if (el === 'deliveryBasisId') {
      return {
        id: el,
        labelId: messages.deliveryBasisId,
        value: EDeliveryBasisName[newValue[el] as TDeliveryBasisId],
        previousValue: EDeliveryBasisName[previousValues[el] as TDeliveryBasisId],
      }
    }

    if (el === 'paymentType') {
      return {
        id: el,
        labelId: messages.paymentType,
        value: newValue[el as TAgrarianSaleRequestFormValues],
        previousValue: previousValues[el as TAgrarianSaleRequestFormValues],
      }
    }

    if (el === 'requestDiscountData') {
      return undefined
    }

    if (el === 'discount') {
      return undefined
    }

    if (el === 'changeType') {
      return undefined
    }

    if (el === 'elevatorId') {
      return {
        id: el,
        labelId: messages.elevatorId,
        value: newValue[el as TAgrarianSaleRequestFormValues],
        previousValue: previousValues[el as TAgrarianSaleRequestFormValues],
      }
    }

    if (el === 'prepaymentPercentage') {
      return {
        id: el,
        labelId: messages.prepaymentPercentage,
        value: newValue[el as TAgrarianSaleRequestFormValues],
        previousValue: previousValues[el as TAgrarianSaleRequestFormValues],
      }
    }

    if (el === 'transportType') {
      const newVal = newValue[el as TAgrarianSaleRequestFormValues] as TTransportTypeOptions
      const prevVal = previousValues[el as TAgrarianSaleRequestFormValues] as TTransportTypeOptions

      return {
        id: el,
        labelId: messages.transportType,
        value: messages.transportTypeOptions[newVal],
        previousValue: messages.transportTypeOptions[prevVal],
      }
    }

    if (el === 'quality') {
      const qualityIndicators = getObjectKeys(previousValues[el])

      const qualityKeys = qualityIndicators.filter(
        indicator => JSON.stringify(newValue.quality[indicator]) !== JSON.stringify(previousValues.quality[indicator]),
      )
      const quality = qualityKeys.map(qualityName => {
        return {
          id: qualityName,
          labelId: qualityIndicatorsName?.indicators[qualityName]?.name,
          value: newValue.quality[qualityName],
          previousValue: previousValues.quality[qualityName],
        }
      })

      return quality
    }

    return {
      id: el,
      labelId: messages[el],
      value: newValue[el as TAgrarianSaleRequestFormValues],
      previousValue: previousValues[el as TAgrarianSaleRequestFormValues],
    }
  })

  return changedValues.flat()
}

export const openModalWithEdits =
  (
    formValues: IAgrarianSaleRequestFormValues,
    previousValues: IAgrarianSaleRequestFormValues,
    isDeviceMobile: boolean,
    qualityIndicators?: IRequestQualityIndicator,
  ) =>
  (dispatch: TAppDispatch) =>
    dispatch(
      openModal({
        options: {
          dialogId: 'EditedFormModal',
        },
        contentOptions: {
          values: getChanges(formValues, previousValues, dispatch, qualityIndicators),
          customizedFormProps: {
            onSubmit: ({ statusComment }) => {
              dispatch(changeHarvestSaleRequestStatus({ statusComment }))
              dispatch(closeModal())
              scrollToDetails()
            },
            steps: [
              {
                titleId: '',
                fields: [
                  {
                    type: 'textArea',
                    labelId: messages.agrarianSaleRequest.commentModal.placeholder.notNecessary,
                    className: 'space-holder24',
                    name: 'statusComment',
                  },
                ],
                backButton: {
                  textId: messages.agrarianSaleRequest.multirequestConfirmationModal.button.cancel,
                  className: 'detailsChangesButtonBack',
                  modifiers: ['outline-pale-black', 'full-width-mobile'],
                  onClick: () => dispatch(closeModal()),
                },
                submitButton: {
                  textId: messages.agrarianSaleRequest.multirequestConfirmationModal.button.confirm,
                  className: 'detailsChangesButtonSubmit',
                  modifiers: ['green', 'full-width-mobile'],
                },
                shouldShowRequiringFieldsLabel: false,
                isMobile: isDeviceMobile,
              },
            ],
          },
        } as ICustomizedFormModalOptions<IStatusCommentFormValues>,
      }),
    )
