
export enum CreateMobileBannerDataV1Action {
    "SEND_REQUEST" = "SEND_REQUEST",
    "GO_TO_DEEPLINK" = "GO_TO_DEEPLINK",
    "GO_TO_URL" = "GO_TO_URL"
}
export enum CreateMobileBannerDataV1Deeplink {
    "STATUS_REQUEST" = "STATUS_REQUEST",
    "PROFILE" = "PROFILE",
    "SEND_REQUEST" = "SEND_REQUEST",
    "AUTH" = "AUTH",
    "BLOG" = "BLOG"
}
export type CreateMobileBannerDataV1 = {
    /**
     * @type string
    */
    name: string;
    /**
     * @type string
    */
    action: CreateMobileBannerDataV1Action;
    /**
     * @type string | undefined
    */
    subject?: string;
    /**
     * @type array | undefined
    */
    emails?: string[];
    /**
     * @type string | undefined
    */
    url?: string;
    /**
     * @type string | undefined
    */
    deeplink?: CreateMobileBannerDataV1Deeplink;
    /**
     * @type string
    */
    imgUrl: string;
};
