/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuctionStoreCreateData } from './AuctionStoreCreateData';

export type UpdateAuctionData = {
    culture?: string;
    externalCultureId?: string;
    externalElevatorId?: string;
    externalStoreId?: string;
    store?: AuctionStoreCreateData;
    deliveryBasis?: UpdateAuctionData.deliveryBasis;
    amount?: number;
    startedPrice?: number;
    withNds?: boolean;
    unitOfMeasure?: string;
    minSaleAmount?: number;
    tenderStartDate?: string;
    tenderEndDate?: string;
    externalOrganizationId?: string;
    canIncreasePrice?: boolean;
    maxPriceProhibitions?: boolean;
    minStepValue?: number;
    maxStepValue?: number;
    payCondition?: string;
    payType?: UpdateAuctionData.payType;
    deliveryCondition?: string;
    deliveryAddress?: string;
    deliveryStartDate?: string;
    deliveryEndDate?: string;
    productRequirements?: string;
    requiredDocuments?: string;
    contactInfo?: string;
    comment?: string;
    type?: string;
    autoImprove?: boolean;
    autoPriceChangeFrom?: string;
    autoPriceChangeTo?: string;
    eachIntervalAmount?: number;
    maxPrice?: number;
    autoPriceChangeInterval?: number;
    displayPriceAndBid?: boolean;
    displayPrice?: boolean;
    limitPriceFrom?: number;
    limitPriceTo?: number;
    organizer?: string;
    qualityIndicators?: Record<string, string>;
};

export namespace UpdateAuctionData {

    export enum deliveryBasis {
        ELEVATOR = 'ELEVATOR',
        PICKUP = 'PICKUP',
        EXW = 'EXW',
        FOB = 'FOB',
        WAGON = 'WAGON',
    }

    export enum payType {
        PREPAYMENT = 'PREPAYMENT',
        POSTPAYMENT = 'POSTPAYMENT',
        STAGED = 'STAGED',
    }


}

