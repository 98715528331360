import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { openModal } from '@/components/composed/Modal/actions'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { BubblingTooltip, Button, ErrorMessage, Loader, UploadButton } from '@/components/ui'
import { IButtonProps } from '@/components/ui/Button/interfaces'
import { isUserAgrarian } from '@/logic/auth/reducer'
import { setInitialStatus } from '@/logic/requestStatus/actions'
import { getRequestState } from '@/logic/requestStatus/reducer'
import { injectMessages } from '@/logic/translation/utils'
import { RootState } from '@/redux/interfaces'
import { formatDate } from '@/utils/formatUtils'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { IRejectedEntityHintModalOptions } from './components/RejectedEntityHintModal/interfaces'
import { DocumentHint, DocumentStatus, RejectedEntityHintModal } from './components'
import { IDocumentCardMapStateOptions, IDocumentCardProps, TUpdateDocumentAction } from './interfaces'
import messages from './messages.json'
import './styles.scss'

addModalToWellKnown({ RejectedEntityHintModal })
injectMessages(messages)

const mapState = (options: IDocumentCardMapStateOptions) => (state: RootState) => {
  const { updateDocumentRequestName, deleteDocumentRequestName, downloadDocumentRequestName } = options
  const { isLoading: isDocumentUpdating, isFailure: isUpdatingDocumentFailed } = getRequestState(updateDocumentRequestName)(state)
  const { isLoading: isDocumentDownloading, isFailure: isDownloadingDocumentFailed } = getRequestState(downloadDocumentRequestName)(state)
  const { isLoading: isDocumentDeleting, isFailure: isDeletingDocumentFailed } = getRequestState(deleteDocumentRequestName)(state)

  return {
    isInProcess: isDocumentDownloading || isDocumentUpdating || isDocumentDeleting,
    isDownloadingDocumentFailed,
    isDocumentDownloading,
    isUpdatingDocumentFailed,
    isDeletingDocumentFailed,
    isAgrarian: isUserAgrarian()(state),
  }
}

const DocumentCard: React.FC<IDocumentCardProps> = props => {
  const {
    document,
    updateDocumentRequestName,
    deleteDocumentRequestName,
    downloadDocumentRequestName,
    onUpdateDocument,
    onDownloadDocument,
    rejectingDocumentButtonText,
    openRejectingDocumentModal,
    isDocumentRejected,
    canUpdateDocument,
    canDeleteDocument,
    documentStatus,
    isUpdatingButtonPlain,
    DocumentStatusElement,
    shouldHideUpdatingDocumentButton,
  } = props
  const { name, creationDate, type, comment } = document
  const dispatch = useDispatch()

  const updateDocument = (action: TUpdateDocumentAction, file?: File) => {
    const shouldDeleteDocument = action === 'delete'
    dispatch(setInitialStatus({ name: shouldDeleteDocument ? updateDocumentRequestName : deleteDocumentRequestName }))
    dispatch(setInitialStatus({ name: downloadDocumentRequestName }))
    onUpdateDocument(action, file)
  }

  const downloadDocument = () => {
    dispatch(setInitialStatus({ name: updateDocumentRequestName }))
    dispatch(setInitialStatus({ name: deleteDocumentRequestName }))
    onDownloadDocument()
  }

  const { isInProcess, isDownloadingDocumentFailed, isUpdatingDocumentFailed, isDeletingDocumentFailed, isAgrarian } = useSelector(
    mapState({ updateDocumentRequestName, deleteDocumentRequestName, downloadDocumentRequestName }),
  )
  const { isDesktop, isTablet, isMobile } = useDeviceType()

  const updatingButtonProps: IButtonProps = {
    iconProps: {
      name: EIconName.Update,
      size: isMobile ? EIconSize.XS : EIconSize.M,
      className: isDocumentRejected ? 'document-card-actions__button--iconColorRed' : 'document-card-actions__button--iconColorBlack',
    },
    className: classNames('document-card-actions__button', { 'document-card-actions__button_rejected': isDocumentRejected }),
    isUnstyled: true,
    isHidden: shouldHideUpdatingDocumentButton,
    modifiers: ['small'],
  }

  const rejectedDocumentHintOptions = {
    comment,
    entity: `document`,
    documentName: `document${type}`,
    isUpdatingButtonVisible: isAgrarian,
    onFileUpdate: file => updateDocument('update', file),
    modalContentClassName: 'document-card__rejecting-hint-modal',
  } as IRejectedEntityHintModalOptions

  const hint = isDocumentRejected && (
    <DocumentHint
      onClick={() => dispatch(openModal({ options: { dialogId: 'RejectedEntityHintModal' }, contentOptions: rejectedDocumentHintOptions }))}
      iconProps={{
        name: EIconName.InfoFill,
        size: isMobile ? EIconSize.XS : EIconSize.M,
        className: 'document-card-actions__button--iconColorRed',
      }}
      tooltipId="documentRejected"
      className="space-holder-top16-from-md document-card__rejecting-hint text_small-sm"
    />
  )
  const rejectingButton = (
    <Button
      className="space-holder-top16-from-md"
      onClick={openRejectingDocumentModal}
      modifiers={['small', 'outline']}
      isHidden={!rejectingDocumentButtonText || isDocumentRejected}
    >
      {rejectingDocumentButtonText}
    </Button>
  )
  const shouldShowRejectingButton: boolean = Boolean(rejectingDocumentButtonText) && !isDocumentRejected
  const shouldShowUploadingMobileButton: boolean = isDocumentRejected && canUpdateDocument && isMobile

  return (
    <div className="document-card">
      <Loader isVisible={isInProcess} />
      <div className="document-card-first-col">
        <p className="text_small-from-sm document-card__name">{name}</p>
        <p className="text_super-small color_pale-black space-holder-top8">{formatDate(creationDate, 'dd.MM.yyyy')}</p>
        {isDesktop && (
          <>
            {hint}
            {rejectingButton}
          </>
        )}
      </div>
      <div className="document-card-actions">
        <BubblingTooltip messageId="documentCard.hint.downloadButton" tooltipProps={{ interactive: true, touch: false }}>
          <span className="row">
            <Button
              onClick={downloadDocument}
              iconProps={{
                name: EIconName.Download,
                size: isMobile ? EIconSize.XS : EIconSize.M,
                className: 'document-card-actions__button--iconColorBlack',
              }}
              className="document-card-actions__button"
              isUnstyled
            />
          </span>
        </BubblingTooltip>
        {canUpdateDocument && (
          <BubblingTooltip messageId="documentCard.hint.updateButton" tooltipProps={{ touch: false }}>
            <span className="row">
              {isUpdatingButtonPlain ? (
                <Button {...updatingButtonProps} onClick={() => updateDocument('update')} />
              ) : (
                <UploadButton
                  name={`document${type}`}
                  buttonProps={updatingButtonProps}
                  onChange={file => updateDocument('update', file)}
                />
              )}
            </span>
          </BubblingTooltip>
        )}
        {canDeleteDocument && (
          <BubblingTooltip messageId="documentCard.hint.deleteButton" tooltipProps={{ touch: false }}>
            <span className="row">
              <Button
                onClick={() => updateDocument('delete')}
                iconProps={{
                  name: EIconName.Delete,
                  size: isMobile ? EIconSize.XS : EIconSize.M,
                  className: 'document-card-actions__button--iconColorBlack',
                }}
                className="document-card-actions__button"
                isUnstyled
              />
            </span>
          </BubblingTooltip>
        )}
      </div>

      {isTablet && hint}

      <div className="document-card-third-col">
        {DocumentStatusElement || <DocumentStatus status={documentStatus} />}
        <ErrorMessage
          isVisible={isUpdatingDocumentFailed}
          textId="documentCard.updateDocument.error"
          className="space-holder-top8 space-holder-top8-sm"
        />
        <ErrorMessage
          isVisible={isDownloadingDocumentFailed}
          textId="documentCard.downloadDocument.error"
          className="space-holder-top8 space-holder-top8-sm"
        />
        <ErrorMessage
          isVisible={isDeletingDocumentFailed}
          textId="documentCard.deleteDocument.error"
          className="space-holder-top8 space-holder-top8-sm"
        />
      </div>

      {!isDesktop && (shouldShowRejectingButton || shouldShowUploadingMobileButton) && (
        <div className="document-card-mobile-buttons space-holder-top8-sm">
          {rejectingButton}
          <UploadButton
            name={`document${type}`}
            buttonProps={{
              modifiers: ['small', 'outline'],
              textId: 'documentCard.updateDocumentButton',
              isHidden: !shouldShowUploadingMobileButton || shouldHideUpdatingDocumentButton,
            }}
            onChange={file => updateDocument('update', file)}
          />
        </div>
      )}
    </div>
  )
}

export default DocumentCard
