import classNames from 'classnames'
import { openModal } from '@/components/composed/Modal/actions'
import { IMessageModalOptions, IModalMessageProps } from '@/components/composed/modals/MessageModal/interfaces'
import { TOrderRequiredFields } from '@/services/OrderService/interfaces'
import { INotUploadedDocument } from './interface'

export const openNotUploadedDocumentsModal = (documents: INotUploadedDocument[][]) => {
  const modalMessages = documents.reduce((acc, documentList, index) => {
    const formattedDocuments = documentList.map(({ name }) => ({
      text: name,
      className: 'space-holder8',
    }))

    if (documents.length > 1 && index > 0) {
      return [
        ...acc,
        {
          id: 'messageModal.loadingClosingDocumentModal.or',
          className: 'space-holder16 space-holder-top16 list-with-marks__item_withoutMark',
        },
        ...formattedDocuments,
      ]
    }

    return [...acc, ...formattedDocuments]
  }, [] as IModalMessageProps[])

  return openModal({
    options: {
      dialogId: 'MessageModal',
      headerId: 'messageModal.loadingClosingDocumentModal.title',
    },
    contentOptions: {
      messages: modalMessages,
      className: 'list-with-marks',
    } as IMessageModalOptions,
  })
}

export const openRequiredFieldsModal = (requiredFields: TOrderRequiredFields, headerId?: string) => {
  let messages: IModalMessageProps[] = []
  const isArray = Array.isArray(requiredFields)

  if (isArray) {
    messages = requiredFields?.map(({ name }) => ({ text: name }))
  } else if (requiredFields.oneGroup && requiredFields.twoGroup) {
    messages = [
      {
        id: 'messageModal.loadingClosingDocumentModal.and',
        values: { first: requiredFields.oneGroup[0], second: requiredFields.oneGroup[1] },
      },
      {
        id: 'messageModal.loadingClosingDocumentModal.or',
        className: 'error-upload-documents__nomarks space-holder-top8',
      },
      { id: requiredFields.twoGroup[0], className: 'space-holder-top8' },
    ]
  }

  return openModal({
    options: {
      dialogId: 'MessageModal',
      headerId: headerId || 'messageModal.requiredFieldsErrorModal.title',
    },
    contentOptions: {
      messages,
      className: classNames('list-with-marks', { 'error-upload-documents': !isArray }),
    } as IMessageModalOptions,
  })
}
