/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateSkuPromotionFullRequestDataCC = {
    name: string;
    description: string;
    serviceName: string;
    startPromotion: string;
    endPromotion: string;
    type: CreateSkuPromotionFullRequestDataCC.type;
};

export namespace CreateSkuPromotionFullRequestDataCC {

    export enum type {
        ACTIVE = 'ACTIVE',
        DRAFT = 'DRAFT',
        ARCHIVE = 'ARCHIVE',
    }


}

