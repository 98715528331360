import { EffectCallback } from 'react'
import { useSelector } from 'react-redux'
import { isAuthenticated } from '@/logic/auth/reducer'
import { useEffectAfterMount } from './useEffectAfterMount'

export type TUseActionOnLoggedIn = (makeAction: EffectCallback, hasExtraCondition?: boolean) => void

export const useActionOnLoggedIn: TUseActionOnLoggedIn = (makeAction, hasExtraCondition = true) => {
  const isUserAuthenticated = useSelector(isAuthenticated())
  // eslint-disable-next-line consistent-return
  useEffectAfterMount(() => {
    if (isUserAuthenticated && hasExtraCondition) return makeAction()
  }, [isUserAuthenticated, hasExtraCondition])
}
