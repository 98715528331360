import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { EUserProfileRoute } from '@/types'
import * as messages from './messages'
import { TAdditionalSalesMenuSections } from './types'

export const sections: TAdditionalSalesMenuSections = [
  {
    title: messages.sections.buy.title,
    links: [
      {
        url: EUserProfileRoute.orders,
        icon: EIconName.Cart,
        text: messages.sections.buy.orders,
      },
      {
        url: EUserProfileRoute.tenders,
        icon: EIconName.Sell,
        text: messages.sections.buy.tenders,
      },
    ],
  },
  {
    title: messages.sections.sell.title,
    links: [
      {
        url: EUserProfileRoute.sales,
        icon: EIconName.Harvest,
        text: messages.sections.sell.orders,
      },
    ],
  },
  {
    title: messages.sections.another.title,
    links: [
      {
        url: EUserProfileRoute.fundingRequests,
        icon: EIconName.Wallet,
        text: messages.sections.another.funding,
      },
      {
        url: EUserProfileRoute.agroserviceRequests,
        icon: EIconName.Services,
        text: messages.sections.another.services,
      },
    ],
  },
]
