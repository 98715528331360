import sumBy from 'lodash/sumBy'
import { combineEpics, ofType } from 'redux-observable'
import { filter, race } from 'rxjs'
import { debounceTime, distinctUntilChanged, mergeMap, skip, take, tap } from 'rxjs/operators'
import { addProductToCart, changeCartItemQuantity, clearCart, deleteCartItem } from '@/logic/cart/actions'
import { ECartRequestName } from '@/logic/cart/constants'
import {
  IAddProductToCartPayload,
  IClearCartPayload,
  TChangeCartItemQuantityPayload,
  TDeleteICartItemPayload,
} from '@/logic/cart/interfaces'
import {
  getCartIdOfProduct,
  getCartItems,
  getCartItemsById,
  getCartItemsQuantityById,
  getCartPositionById,
  getStoreAddressByCardId,
} from '@/logic/cart/reducer'
import { Epic, TActionStream } from '@/logic/interfaces'
import { makeOrder } from '@/logic/order/actions'
import { EOrderRequestName } from '@/logic/order/constants'
import { IMakeOrderPayload } from '@/logic/order/interfaces'
import { requestFailed, requestFinished } from '@/logic/requestStatus/actions'
import { IRequestStatusPayload } from '@/logic/requestStatus/interfaces'
import { ECatalogProductOfferType } from '@/types/Catalog'
import { IAgrarianOrder } from '@/types/Order'
import { getErrorTextFromResponseMeta } from '@/utils/metrika/helpers'
import { hasRequestStatusName } from '@/utils/rxjs/filter'
import { pushProductsGtmEvent } from '../catalog/gtmEvents'
import { getMergedOfferWithSku, getProductFromCartForGtmEvent } from '../catalog/helpers'

const onAddProductToCartDoPushAddingProductGtmEvent: Epic<IAddProductToCartPayload, typeof addProductToCart> = (action$, state$) =>
  action$.pipe(
    filter(addProductToCart.match),
    mergeMap(({ payload }) =>
      race(
        (action$ as unknown as TActionStream<IRequestStatusPayload, typeof requestFinished>).pipe(
          ofType(requestFinished.type, requestFailed.type),
          hasRequestStatusName(ECartRequestName.ADD_PRODUCT_TO_CART),
          take(1),
          tap(({ type, payload: actionPayload }) => {
            const { blockName, itemPosition, offer, sku, quantity } = payload
            const { value: state } = state$
            const cartId = getCartIdOfProduct(state, offer.id)

            pushProductsGtmEvent({
              products: [{ ...getMergedOfferWithSku(offer, sku), quantity, storeAddress: offer.supplier?.address }],
              blockName,
              eventCategory: 'ecommerce',
              eventAction: 'click',
              eventLabel: 'buttonAddToCart',
              eventEcommerce: 'addToCart',
              ecommerceType: 'add',
              eventNonInteraction: '0',
              itemPosition,
              actionField: itemPosition ? { list: itemPosition } : undefined,
              orderPosition: getCartPositionById(state, cartId),
              cartId,
              eventStatus:
                type === requestFinished.type ? 'success' : (`err:${getErrorTextFromResponseMeta(actionPayload.meta)}` as `err:${string}`),
            })
          }),
          skip(1),
        ),
      ),
    ),
  )

const onChangeProductQuantityDoPushAddingProductGtmEvent: Epic<TChangeCartItemQuantityPayload, typeof changeCartItemQuantity> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(changeCartItemQuantity.match),
    debounceTime(500),
    distinctUntilChanged(),
    mergeMap(({ payload }) =>
      race(
        (action$ as unknown as TActionStream<IRequestStatusPayload, typeof requestFinished>).pipe(
          ofType(requestFinished.type, requestFailed.type),
          hasRequestStatusName(ECartRequestName.CHANGE_PRODUCT_QUANTITY),
          take(1),
          tap(({ payload: actionPayload, type }) => {
            const { blockName, itemPosition, quantity } = payload
            const { value: state } = state$
            const cartId = getCartIdOfProduct(state, payload.offer.id)
            const prevQuantity = getCartItemsQuantityById(state, payload.offer.id)
            const isRemove = prevQuantity > quantity
            const offer =
              payload.productType === ECatalogProductOfferType.CATALOG ? getMergedOfferWithSku(payload.offer, payload.sku) : payload.offer

            pushProductsGtmEvent({
              products: [
                {
                  ...offer,
                  quantity: Math.abs(quantity - prevQuantity),
                  storeAddress: getStoreAddressByCardId(state, cartId),
                },
              ],
              blockName,
              eventCategory: 'ecommerce',
              eventAction: 'click',
              eventLabel: isRemove ? 'buttonRemoveFromCart' : 'buttonAddToCart',
              eventEcommerce: isRemove ? 'removeFromCart' : 'addToCart',
              ecommerceType: isRemove ? 'remove' : 'add',
              eventNonInteraction: '0',
              itemPosition,
              cartId,
              productType: payload.productType,
              actionField: itemPosition ? { list: itemPosition } : undefined,
              orderPosition: getCartPositionById(state, cartId),
              eventStatus:
                type === requestFinished.type ? 'success' : (`err:${getErrorTextFromResponseMeta(actionPayload.meta)}` as `err:${string}`),
            })
          }),
          skip(1),
        ),
      ),
    ),
  )

const onDeleteProductFromCartDoPushDeletingProductGtmEvent: Epic<TDeleteICartItemPayload, typeof addProductToCart> = (action$, state$) =>
  action$.pipe(
    filter(deleteCartItem.match),
    mergeMap(({ payload }) =>
      race(
        (action$ as unknown as TActionStream<IRequestStatusPayload, typeof requestFinished>).pipe(
          ofType(requestFinished.type, requestFailed.type),
          hasRequestStatusName(ECartRequestName.DELETE_PRODUCT_FROM_CART),
          take(1),
          tap(({ payload: errorPayload, type }) => {
            const { value: state } = state$
            const { blockName, itemPosition, quantity = 0 } = payload
            const cartId = getCartIdOfProduct(state, payload.offer.id)
            const offer =
              payload.productType === ECatalogProductOfferType.CATALOG ? getMergedOfferWithSku(payload.offer, payload.sku) : payload.offer

            pushProductsGtmEvent({
              products: [
                {
                  ...offer,
                  quantity,
                  storeAddress: getStoreAddressByCardId(state, cartId),
                },
              ],
              blockName,
              eventCategory: 'ecommerce',
              eventAction: 'click',
              eventLabel: 'buttonRemoveFromCart',
              eventEcommerce: 'removeFromCart',
              ecommerceType: 'remove',
              eventNonInteraction: '0',
              itemPosition,
              cartId,
              actionField: itemPosition ? { list: itemPosition } : undefined,
              productType: payload.productType,
              orderPosition: getCartPositionById(state, cartId),
              eventStatus: requestFinished.type === type ? 'success' : (`err:${getErrorTextFromResponseMeta(errorPayload.meta)}` as const),
            })
          }),
          skip(1),
        ),
      ),
    ),
  )

const onMakeOrderDoPushGtmEvent: Epic<IMakeOrderPayload, typeof makeOrder> = (action$, state$) =>
  action$.pipe(
    filter(makeOrder.match),
    mergeMap(({ payload }) =>
      race(
        (action$ as unknown as TActionStream<IRequestStatusPayload, typeof requestFinished>).pipe(
          ofType(requestFinished.type, requestFailed.type),
          hasRequestStatusName(EOrderRequestName.MAKE_ORDER),
          take(1),
          tap(({ payload: actionPayload, type }) => {
            const { formValues } = payload
            const state = state$.value
            const order: IAgrarianOrder = actionPayload.data[0]
            const isSuccess = type === requestFinished.type
            const products = getCartItems(state)
            const cartId = getCartIdOfProduct(state, products[0]!.productOffer.id)

            pushProductsGtmEvent({
              products: products.map(getProductFromCartForGtmEvent(getStoreAddressByCardId(state, cartId))),
              pagePart: 'popUp',
              blockName: null,
              eventCategory: 'ecommerce',
              ecommerceType: 'purchase',
              eventAction: 'formSubmit',
              eventLabel: 'purchaseInputs',
              eventEcommerce: 'purchase',
              eventNonInteraction: '0',
              productType: ECatalogProductOfferType.CART,
              eventStatus: isSuccess ? 'success' : (`err:${getErrorTextFromResponseMeta(actionPayload.meta)}` as const),
              orderPosition: getCartPositionById(state, cartId),
              cartId,
              actionField: {
                id: order?.id?.toString(),
                revenue: order.sumPrice?.value.toString(),
                option: formValues.deliveryType,
                shipping: sumBy(order.items, 'deliveryPrice.value'),
                checkoutOption: order.paymentType?.toLocaleLowerCase(),
              },
            })
          }),
          skip(1),
        ),
      ),
    ),
  )

const onClearCartDoPushClearingCartEcommerceGtmEvent: Epic<IClearCartPayload, typeof clearCart> = (action$, state$) =>
  action$.pipe(
    filter(clearCart.match),
    mergeMap(({ payload }) => {
      const { value: state } = state$
      const { id: cartId, blockName, itemPosition } = payload
      const products = getCartItemsById(state, cartId)!.items.map(getProductFromCartForGtmEvent(getStoreAddressByCardId(state, cartId)))

      return race(
        (action$ as unknown as TActionStream<IRequestStatusPayload, typeof requestFinished>).pipe(
          ofType(requestFinished.type, requestFailed.type),
          hasRequestStatusName(ECartRequestName.CLEAR_CART),
          take(1),
          tap(({ payload: actionPayload, type }) => {
            pushProductsGtmEvent({
              products,
              blockName,
              eventCategory: 'ecommerce',
              eventAction: 'click',
              eventLabel: 'buttonRemoveOrder',
              eventEcommerce: 'removeOrder',
              ecommerceType: 'remove',
              eventNonInteraction: '0',
              productType: ECatalogProductOfferType.CART,
              cartId,
              orderPosition: getCartPositionById(state, cartId),
              eventStatus: type === requestFinished.type ? 'success' : (`err:${getErrorTextFromResponseMeta(actionPayload.meta)}` as const),
              itemPosition,
              actionField: { list: 'cartListing' },
            })
          }),
          skip(1),
        ),
      )
    }),
  )

export default (<any>combineEpics)(
  onAddProductToCartDoPushAddingProductGtmEvent,
  onDeleteProductFromCartDoPushDeletingProductGtmEvent,
  onMakeOrderDoPushGtmEvent,
  onChangeProductQuantityDoPushAddingProductGtmEvent,
  onClearCartDoPushClearingCartEcommerceGtmEvent,
)
