import React from 'react'
import { FormattedMessage } from 'react-intl'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { injectMessages } from '@/logic/translation/utils'
import { Icon } from '..'
import { IFileNameInfoProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const FileNameInfo: React.FC<IFileNameInfoProps> = props => {
  const { file, fileName, label, labelId = 'file.name.info.label', className, icon } = props

  if (!file && !fileName) return null

  return (
    <div className={classNames(className, 'file-name-info row row_column')}>
      <p className="space-holder8 text_small color_pale-black">{label || <FormattedMessage id={labelId} />}</p>
      <div className="row row_nowrap col-12">
        <span className="row">
          <Icon name={icon || EIconName.OkFill} className="file-name-info__icon" />
        </span>
        <span className="file-name-info__name space-holder-left8">{file?.name || fileName}</span>
      </div>
    </div>
  )
}

export default FileNameInfo
