import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { isAnyPermissionIntersected } from '@/logic/auth/helpers'
import { getUserPermissions } from '@/logic/auth/reducer'
import { injectMessages } from '@/logic/translation/utils'
import { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { links, mobileLinks } from './constants'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  userPermissions: getUserPermissions(state),
})

const NavLinks: React.FC<{ classname?: string }> = ({ classname }) => {
  const { userPermissions } = useSelector(mapState)

  const { isMobile, isTablet } = useDeviceType()
  const currentLinks = isMobile || isTablet ? mobileLinks : links

  return (
    <ul className={classNames('nav-list', classname)}>
      {currentLinks.map(({ textId, url, icon, permissions }) => {
        if (Array.isArray(permissions) && !isAnyPermissionIntersected(permissions, userPermissions)) return null

        return (
          <li key={textId} className="nav-list-item">
            <CommonLink url={url} spanIfEmpty className="nav-list-item-link" activeClassName="nav-list-item-link_active">
              <span className="nav-list-item-link__icon">
                <Icon name={icon} size={isMobile ? EIconSize.XS : EIconSize.M} />
              </span>
              <span className="nav-list-item-link__text">
                <FormattedMessage id={`header.menu.link.${textId}`} />
              </span>
            </CommonLink>
          </li>
        )
      })}
    </ul>
  )
}

export default NavLinks
