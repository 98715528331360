import type { TemplateDataDocumentClauseDto } from "./TemplateDataDocumentClauseDto";

export enum PurchaseOfferTemplateDataRequestStatus {
    "DRAFT" = "DRAFT",
    "ON_APPROVAL" = "ON_APPROVAL",
    "PROPOSED_CHANGES" = "PROPOSED_CHANGES",
    "UNDER_REVIEW" = "UNDER_REVIEW",
    "PUBLISHED" = "PUBLISHED"
}
export type PurchaseOfferTemplateDataRequest = {
    /**
     * @type string
    */
    name: string;
    /**
     * @type array
    */
    clauses: TemplateDataDocumentClauseDto[];
    /**
     * @type integer | undefined int64
    */
    id?: number;
    /**
     * @type string | undefined
    */
    contractorInn?: string;
    /**
     * @type string
    */
    status: PurchaseOfferTemplateDataRequestStatus;
};
