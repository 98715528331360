import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, IIconProps } from '@frontend/pole-ui/lib/components/Icon'
import { ISorterButton } from '@/components/ui/SorterButtons/interfaces'
import { TAccreditationMainStatus } from '@/logic/accreditation/interfaces'
import {
  AccreditationStatusId,
  ELogisticsAccreditationStatus,
  EOrderAccreditationStatus,
  ERequestAccreditationStatus,
} from '@/types/Accreditation'
import { AccreditationLinkConfig, IMyAccreditationContext, TAccreditationSorterId, TAccreditationSubStatus } from './interfaces'

export const MyAccreditationContext = React.createContext<IMyAccreditationContext>({} as IMyAccreditationContext)

export const subStatuses = ['needUpdate', 'renew'] as const // TODO 'hasUpdated' - пока что этот статус не используем

export const expiringDateVisibilityStatuses = ['expires', 'expired'] as const

export const shownDocumentsStatuses = ['new', 'waiting', 'active', 'expires'] as const

export const unpackingArchiveStatuses: AccreditationStatusId[] = [
  EOrderAccreditationStatus.ARCHIVE_UNPACKING,
  ERequestAccreditationStatus.ARCHIVE_UNPACKING,
]

export const accreditationDocumentsStatuses = [
  ERequestAccreditationStatus.DOCUMENTS_RESUBMISSION,
  ERequestAccreditationStatus.DOCUMENTS_SUBMISSION,
  ERequestAccreditationStatus.DOCUMENTS_CHECK,
  EOrderAccreditationStatus.DOCUMENTS_RESUBMISSION,
  EOrderAccreditationStatus.DOCUMENTS_SUBMISSION,
  EOrderAccreditationStatus.DOCUMENTS_CHECK,
  ...unpackingArchiveStatuses,
]

export const accreditationWaitingStatuses = [
  EOrderAccreditationStatus.DOCUMENTS_RESUBMISSION,
  EOrderAccreditationStatus.DOCUMENTS_SUBMISSION,
  ERequestAccreditationStatus.DOCUMENTS_RESUBMISSION,
  ERequestAccreditationStatus.DOCUMENTS_SUBMISSION,
  ...unpackingArchiveStatuses,
]

export const initialLogisticsAccreditationStatuses = [
  ELogisticsAccreditationStatus.NOT_STARTED,
  ELogisticsAccreditationStatus.ADMIN_APPROVES,
]

export const successLogisticsAccreditationStatuses = [ELogisticsAccreditationStatus.APPROVED]

export const newAccreditationStatuses = [
  EOrderAccreditationStatus.NOT_STARTED,
  ERequestAccreditationStatus.NOT_STARTED,
  ELogisticsAccreditationStatus.NOT_STARTED,
]

export const accreditationSigningStatuses = [
  ERequestAccreditationStatus.BUYER_SIGNING,
  ERequestAccreditationStatus.AGRARIAN_SIGNING,
  ERequestAccreditationStatus.BUYER_SIGNING_CRYPTO,
  ERequestAccreditationStatus.AGRARIAN_SIGNING_CRYPTO,
  ELogisticsAccreditationStatus.BUYER_SIGNING,
  ELogisticsAccreditationStatus.LOGISTICS_OPERATOR_SIGNING,
]

export const AccreditationLink: AccreditationLinkConfig = {
  new: {
    name: 'confirm',
    modifier: 'green',
    type: 'button',
  },
  waiting: {
    name: 'continue',
    type: 'link',
  },
  processing: {
    name: 'lookAt',
    type: 'link',
  },
  sign: {
    name: 'sign',
    type: 'link',
  },
  expires: {
    name: 'update',
    type: 'button',
  },
  expired: {
    name: 'update',
    type: 'button',
  },
  rejected: {
    name: 'lookAt',
    type: 'link',
  },
  active: {
    name: 'open',
    type: 'link',
  },
  adminApproves: {
    name: 'open',
    type: 'link',
  },
  approved: {
    name: 'open',
    type: 'link',
  },
}

export const AccreditationStatusIcon: Record<TAccreditationMainStatus | TAccreditationSubStatus, IIconProps | undefined> = {
  new: {
    name: EIconName.Empty,
    style: { color: EColor.LIGHT_GRAY },
  },
  active: {
    name: EIconName.Active,
    style: { color: EColor.GREEN },
  },
  adminApproves: {
    name: EIconName.FileCheck,
    style: { color: EColor.GREEN },
  },
  approved: {
    name: EIconName.OkFill,
    style: { color: EColor.GREEN },
  },
  rejected: {
    name: EIconName.Error,
    style: { color: EColor.RED },
  },
  waiting: {
    name: EIconName.Services,
    style: { color: EColor.ORANGE },
  },
  expired: {
    name: EIconName.Warning,
    style: { color: EColor.RED },
  },
  expires: {
    name: EIconName.PendingFill,
    style: { color: EColor.ORANGE },
  },
  processing: {
    name: EIconName.Verificationofdoc2,
    style: { color: EColor.ORANGE },
  },
  sign: {
    name: EIconName.Edit,
    style: { color: EColor.ORANGE },
  },
  needUpdate: {
    name: EIconName.Warning,
    style: { color: EColor.RED },
  },
  renew: {
    name: EIconName.Renewalofold1Fill,
    style: { color: EColor.ORANGE },
  },
}

export const AccreditationSorterButtons: Record<TAccreditationSorterId, ISorterButton<TAccreditationSorterId>> = {
  processing: {
    id: 'processing',
    textId: 'myAccreditation.sorterButton.processing',
  },
  logistics: {
    id: 'logistics',
    textId: 'myAccreditation.sorterButton.logistics',
  },
  active: {
    id: 'active',
    textId: 'myAccreditation.sorterButton.active',
  },
  archive: {
    id: 'archive',
    textId: 'myAccreditation.sorterButton.archive',
  },
}

export const accreditationSorterButtons: ISorterButton<TAccreditationSorterId>[] = [
  AccreditationSorterButtons.processing,
  AccreditationSorterButtons.logistics,
  AccreditationSorterButtons.active,
  AccreditationSorterButtons.archive,
]

export const accreditationsPerPage = 10
