import React from 'react'
import ReactHintFactory from 'react-hint'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import merge from 'lodash/merge'
import { IntlProvider } from 'rsuite'
import ru from 'rsuite/lib/IntlProvider/locales/ru_RU'
import { useOnAppMount } from '@/App/hooks/useOnAppMount'
import { Redirect404 } from '@/router/components'
import { useLocation } from '@/router/hooks'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import 'cross-fetch/polyfill'
import fieldErrorsMessages from '../commonMessages/fieldErrorsMessages.json'
import messages from '../commonMessages/messages.json'
import { CookieAgreement, Footer, Header, MobileNavBar, Modal, UserRegionDefiner } from '../components/composed'
import { Breadcrumbs } from '../components/ui'
import { hasWebpSupport, is404Page } from '../logic/app/reducer'
import { getCurrentLocale } from '../logic/translation/selectors'
import { injectMessages } from '../logic/translation/utils'
import { RootState } from '../redux/interfaces'
import { routes } from '../routes'
import { useFormatDate } from '../utils/hooks/format/useFormatDate'
import { AppRoute } from './components'
import 'swiper/swiper.scss'
import 'keen-slider/keen-slider.min.css'
import 'rsuite/dist/styles/rsuite-default.min.css'
import 'react-hint/css/index.css'
import './app.scss'

const ReactHint = ReactHintFactory(React)
const Routes = React.memo(() => (
  <Switch>
    {routes.map(routeProps => (
      <AppRoute {...routeProps} />
    ))}
    <Route key="404" component={() => <Redirect404 />} />
  </Switch>
))

const RsuiteLocale: Record<string, Object> = {
  ru,
}

injectMessages(merge(messages, fieldErrorsMessages))

const mapState = (state: RootState) => ({
  currentLanguage: getCurrentLocale()(state),
  withWebp: hasWebpSupport()(state),
  is404: is404Page()(state),
})

const App: React.FC = () => {
  const { pathname } = useLocation()
  const { currentLanguage, withWebp, is404 } = useSelector(mapState)
  const { formatDate } = useFormatDate()

  useOnAppMount()

  const { isDesktop, isMobile } = useDeviceType()

  return (
    <IntlProvider locale={RsuiteLocale[currentLanguage]} formatDate={(date, dateFormat) => formatDate(date, dateFormat)}>
      <Modal />
      <ReactHint autoPosition events />
      <div className={`app app_${withWebp ? 'with-webp' : 'no-webp'}`}>
        <UserRegionDefiner />
        <CookieAgreement />
        <Header />
        {!isDesktop && <MobileNavBar />}
        <main data-page={pathname}>
          {!is404 && !isMobile && (
            <div className="page-wrapper">
              <Breadcrumbs />
            </div>
          )}
          <Routes />
        </main>
        {!is404 && <Footer />}
      </div>
    </IntlProvider>
  )
}

export default App
