import omit from 'lodash/omit'
import { ICartItem } from '@/types/Cart'
import { ISku, ISkuOffer } from '@/types/Catalog'
import { getTranslitedTextInCamelCase } from '@/utils/helpers'
import {
  IGtmEventCatalogProduct,
  TCatalogProductDimension14,
  TFormattedCartItem,
  TFormattedCatalogProduct,
  TMergedOfferWithSku,
  TProductItemPosition,
} from './interfaces'

export const getFormattedProduct = (
  product: TFormattedCatalogProduct,
  orderPosition?: number,
  categoryList?: TProductItemPosition,
): IGtmEventCatalogProduct => {
  const { quantity, id, name, price, supplier, category, availableVolume, packageType, store } = product
  const priceValue = price?.value ?? 0

  return {
    id,
    name: getTranslitedTextInCamelCase(name),
    price: priceValue,
    quantity,
    brand: supplier ? getTranslitedTextInCamelCase(supplier.name) : null,
    category: category.id,
    list: categoryList,
    position: orderPosition,
    variant: getTranslitedTextInCamelCase(packageType.name),
    metric1: quantity ? priceValue * quantity : priceValue,
    dimension14: `avl:${availableVolume > 0 ? 'available' : 'notAvailable'}&prvId:${
      supplier ? supplier.id : ''
    }&adr:${getTranslitedTextInCamelCase(store?.location?.address || '')}${
      orderPosition ? `&orderId:${orderPosition}` : ''
    }` as TCatalogProductDimension14,
  }
}

export const getFormattedCartItem = (
  product: TFormattedCartItem,
  orderPosition?: number,
  categoryList?: TProductItemPosition,
): IGtmEventCatalogProduct => {
  const { id, name, price, supplier, category, availability, packageType, quantity, storeAddress, discountPrice } = product
  const { value: priceValue } = price || {}
  const { value: discountPriceValue } = discountPrice || {}
  const actualPrice = discountPriceValue || priceValue

  return {
    id,
    name: getTranslitedTextInCamelCase(name),
    price: actualPrice || null,
    quantity,
    brand: supplier ? getTranslitedTextInCamelCase(supplier.name) : null,
    category: category.id,
    list: categoryList,
    position: orderPosition,
    variant: getTranslitedTextInCamelCase(packageType.name),
    metric1: (quantity ? actualPrice * quantity : actualPrice) || null,
    dimension14: `avl:${availability ? 'available' : 'notAvailable'}&prvId:${
      supplier ? supplier.id : ''
    }&adr:${getTranslitedTextInCamelCase(storeAddress)}${orderPosition ? `&orderId:${orderPosition}` : ''}` as TCatalogProductDimension14,
  }
}

export const getProductFromCartForGtmEvent =
  (storeAddress: string) =>
  ({ quantity, productOffer }: ICartItem): TFormattedCartItem => ({
    ...productOffer,
    quantity,
    storeAddress,
  })

export const getMergedOfferWithSku = (offer: ISkuOffer, sku: ISku): TMergedOfferWithSku => ({ ...offer, ...omit(sku, ['offers']) })
