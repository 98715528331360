import { ResponseDocumentListResponse } from '@/api/generated/documents'
import { isStatusOk } from '@/api/helpers'
import { requestData, requestDataWithRawResponse } from '@/api/request'
import { EDocumentServiceApiUrl } from '@/api/urls/EDocumentServiceApiUrl'
import { downloadFileFromResponse } from '@/utils/files'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'
import {
  IRequestBodyAddSealToDocument,
  IRequestBodyCreateAccessionDocument,
  IRequestBodyCreateAssignmentDocument,
  IRequestBodyCreatePowerAttorneyDocument,
  IRequestBodyCreateRegistrationDocuments,
  IRequestBodyGetFertilizerOfferDocument,
  IRequestBodyUploadDocumentsForRegistrationPartner,
  IRequestBodyUploadDocumentsSigned,
  IRequestBodyUploadFertilizerDocumentsSigned,
  IRequestBodyUploadFertilizerOfferDocumentsSigned,
  IResponseCreateDocument,
  IResponseDataCreateRegistrationDocuments,
} from './interfaces'

export default class DocumentService {
  createAccessionDocument = async (body: IRequestBodyCreateAccessionDocument): Promise<IResponseCreateDocument> => {
    const response = await requestData<IResponseCreateDocument>({
      method: 'POST',
      url: EDocumentServiceApiUrl.CREATE_ACCESSION_DOCUMENT,
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response.data
  }

  createPowerAttorneyDocument = async (body: IRequestBodyCreatePowerAttorneyDocument): Promise<IResponseCreateDocument> => {
    const response = await requestData<IResponseCreateDocument>({
      method: 'POST',
      url: EDocumentServiceApiUrl.CREATE_POWER_ATTORNEY,
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response.data
  }

  createAssignmentDocument = async (body: IRequestBodyCreateAssignmentDocument): Promise<string> => {
    const response = await requestData<string>({
      method: 'POST',
      url: EDocumentServiceApiUrl.CREATE_ASSIGNMENT_DOCUMENT,
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response.data
  }

  uploadSignedDocument = async (body: IRequestBodyUploadDocumentsSigned): Promise<any> => {
    const accreditationId: number = Array.isArray(body.accreditationId) ? 0 : body.accreditationId

    const response = await requestData({
      method: 'POST',
      url: EDocumentServiceApiUrl.UPLOAD_ACCESSION_DOCUMENT_CRYPTO_SIGNED,
      body: {
        ...body,
        accreditationId,
      },
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response
  }

  uploadFertilizerOfferSignedDocument = async (body: IRequestBodyUploadFertilizerOfferDocumentsSigned): Promise<any> => {
    const response = await requestData({
      method: 'POST',
      url: EDocumentServiceApiUrl.UPLOAD_FERTILIZER_OFFER_DOCUMENT_CRYPTO_SIGNED,
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response
  }

  uploadFertilizerSignedDocument = async (body: IRequestBodyUploadFertilizerDocumentsSigned): Promise<any> => {
    const response = await requestData({
      method: 'POST',
      url: EDocumentServiceApiUrl.UPLOAD_FERTILIZER_DOCUMENT_CRYPTO_SIGNED,
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response
  }

  addSealToDocument = async (body: IRequestBodyAddSealToDocument): Promise<IResponseCreateDocument> => {
    const response = await requestData<IResponseCreateDocument>({
      method: 'POST',
      url: EDocumentServiceApiUrl.ADD_SEAL_TO_DOCUMENT,
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response.data
  }

  getFertilizerOfferDocument = async (body: IRequestBodyGetFertilizerOfferDocument) => {
    const response = await requestData<IResponseCreateDocument>({
      method: 'GET',
      url: getUrlWithQuery(EDocumentServiceApiUrl.GET_FERTILIZER_OFFER_DOCUMENT_TEMPLATE, { orderId: body.orderId }),
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response.data
  }

  getFertilizerOfferDocumentSigned = async (body: IRequestBodyGetFertilizerOfferDocument) => {
    const response = await requestData<IResponseCreateDocument>({
      method: 'GET',
      url: getUrlWithQuery(EDocumentServiceApiUrl.GET_FERTILIZER_OFFER_DOCUMENT_SIGNED, { orderId: body.orderId }),
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response.data
  }

  uploadDocumentForRegistrationPartner = async (body: IRequestBodyUploadDocumentsForRegistrationPartner): Promise<any> => {
    const response = await requestData({
      method: 'POST',
      url: EDocumentServiceApiUrl.UPLOAD_DOCUMENT_FOR_REGISTRATION_PARTNER,
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response
  }

  createRegistrationDocuments = async (body: IRequestBodyCreateRegistrationDocuments) => {
    const response = await requestData<IResponseDataCreateRegistrationDocuments>({
      method: 'POST',
      url: EDocumentServiceApiUrl.CREATE_REGISTRATION_DOCUMENTS,
      body,
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response.data
  }

  getPartnerDocuments = async (userId: string): Promise<ResponseDocumentListResponse> => {
    const response = await requestData<ResponseDocumentListResponse>({
      method: 'GET',
      url: createRouterLink(EDocumentServiceApiUrl.GET_PARTNER_DOCUMENTS, { userId }),
    })

    if (!isStatusOk(response)) {
      return Promise.reject(response)
    }

    return response.data
  }

  downloadPartnerDocument = async (documentId: string, archive: boolean) => {
    const response = await requestDataWithRawResponse({
      method: 'GET',
      url: getUrlWithQuery(createRouterLink(EDocumentServiceApiUrl.DOWNLOAD_DOCUMENT_BY_ID, { documentId }), { archive }),
    })

    await downloadFileFromResponse(response, 'document')
  }
}
