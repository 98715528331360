import { useSelector } from 'react-redux'
import { getCurrentCartStoreAddress, isCartEmpty as hasNoItems } from '@/logic/cart/reducer'
import { useData } from '@/logic/data'
import { RootState } from '@/redux/interfaces'
import { useParams } from '@/router/hooks'
import { ICart } from '@/types/Cart'
import { IUseCartOptions } from './interfaces'

const mapState = (state: RootState) => ({
  isCartEmpty: hasNoItems(state),
  storeAddress: getCurrentCartStoreAddress(state),
})

export const useCartParams = () => useParams<{ cartId: string }>()

const defaultOptions: IUseCartOptions = {
  shouldLoadForcibly: true,
}

export const useCart = ({ shouldLoadForcibly = true }: IUseCartOptions = defaultOptions) => ({
  ...useData<ICart>('cart', { shouldLoadForcibly, params: useCartParams() }),
  ...useSelector(mapState),
})
