import React, { memo, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useRegions } from '@/api/kubik/credit'
import { Dropdown } from '@/components/ui/fields'
import { transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import { EDirectoryRegions } from '@/components/ui/fields/RegionSelect/constants'
import { IRegionSelectProps } from '@/components/ui/fields/RegionSelect/interfaces'
import { injectMessages } from '@/logic/translation/utils'
import { getRegions } from '@/logic/userRegion'
import { RootState } from '@/redux/interfaces'
import { IRegion } from '@/types'
import messages from './messages.json'

injectMessages(messages)

const mapState = (state: RootState) => ({
  regions: getRegions(state),
})

const RegionSelect: React.FC<IRegionSelectProps> = props => {
  const {
    name = 'regions',
    isHidden,
    containerClassName,
    modifiers = ['white'],
    placeholderId = 'regionDropdown.placeholder',
    customValidator,
    directoryRegions = EDirectoryRegions.POLE,
  } = props
  const { regions: directoryRegionsPole } = useSelector(mapState)
  const [regions, setRegions] = useState<readonly IRegion[]>()

  const { data: directoryRegionsSber = [] } = useRegions({ query: { enabled: directoryRegions === EDirectoryRegions.SBER } })

  const setDirectoryRegionsSber = async () => {
    if (directoryRegionsSber.length) {
      setRegions(directoryRegionsSber.map(region => ({ code: region.id, name: region.name })))
    }
  }

  if (!regions?.length) {
    switch (directoryRegions) {
      case EDirectoryRegions.SBER:
        setDirectoryRegionsSber()
        break
      default:
        if (directoryRegionsPole) setRegions(directoryRegionsPole)
    }
  }

  const regionOptions = useMemo(() => transformOptions<IRegion, number>({ options: regions, valueKey: 'code' }), [regions])

  if (isHidden) return null

  return (
    <Dropdown<number>
      {...props}
      containerClassName={classNames('dropdown-user-region-chooser', containerClassName)}
      name={name}
      options={regionOptions}
      modifiers={modifiers}
      placeholderId={placeholderId}
      customValidator={customValidator}
    />
  )
}

export default memo(RegionSelect)
