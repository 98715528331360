import { BeforeMaskedStateChangeStates, InputState } from 'react-input-mask'
import { IntlShape } from 'react-intl'
import { getFloatFromString, getNumberFromString, getNumericValueFromString, removeSpaces } from '@/utils/formatUtils'
import { formatMoney } from '@/utils/price'

export const moneyFormatter = (value: string, intl: IntlShape) => (value ? formatMoney(getNumberFromString(value), intl) : '')

export const moneyFloatFormatter =
  (digitsAfterDot = 2) =>
  (value: string, intl: IntlShape) => {
    if (!value) return ''

    const matchedFloat = String(value).match(new RegExp(`(\\d*)(!?[,.]\\d{0,${digitsAfterDot}})*`))
    const integer = formatMoney(Number(matchedFloat?.[1] || ''), intl)
    const decimal = matchedFloat?.[2]?.replace('.', ',') || ''

    return `${integer}${decimal}`
  }

export const moneyFloatParser =
  (digitsAfterDot = 2) =>
  (value: string) => {
    if (!value) return ''

    const matchedFloat = new RegExp(`(\\d+)?([,.]?\\d{0,${digitsAfterDot}})`).exec(String(value).replace(/\s/g, ''))
    const integer = matchedFloat?.[1]
    const decimal = matchedFloat?.[2]?.replace(',', '.') || ''

    return `${integer}${decimal}`
  }

export const percentFormatter = (value: string) => {
  if (!value) return ''
  const percent = getNumberFromString(value)

  return `${percent > 100 ? 100 : percent}%`
}

export const minNumberFormatter =
  (min: number, isFloat: boolean = false) =>
  (value: string): string => {
    const valueWithoutSpaces = removeSpaces(value)
    const number = isFloat ? parseFloat(valueWithoutSpaces) : parseInt(valueWithoutSpaces, 10)

    return Number.isNaN(number) ? String(min) : String(Math.max(number, min))
  }

export const maxNumberFormatter =
  <GValue = string>(max: number, isFloat: boolean = false) =>
  (value: GValue): string => {
    const valueWithoutSpaces = removeSpaces(String(value))
    const number = isFloat ? parseFloat(valueWithoutSpaces) : parseInt(valueWithoutSpaces, 10)

    return Number.isNaN(number) ? '' : String(Math.min(number, max))
  }

export const numberParser = (value: string) => (!Number.isNaN(parseInt(value, 10)) ? String(getNumericValueFromString(value)) : '')

export const numberParserWithFloat = (value: string) => getFloatFromString(value)?.replace(',', '.') || ''

export const numericValueParser = (value: string) => (!Number.isNaN(parseInt(value, 10)) ? String(getNumericValueFromString(value)) : '')

export const combineParsers =
  (...parsers: Array<(value: string) => string>) =>
  (value: string): string =>
    parsers.reduce((result, parser) => parser(result), value)

export const digitsFormatter = (value: string) => (!Number.isNaN(parseInt(value, 10)) ? value?.replace(/\D/g, '') : '')

export const formatUserPhoneEntering = ({ nextState }: BeforeMaskedStateChangeStates): InputState => {
  const { enteredString } = nextState as any
  if (!enteredString || enteredString.length < 11) return nextState

  return {
    ...nextState,
    value: enteredString.slice(enteredString.length === 11 ? 1 : 0),
  }
}

export const normalizeFloatPrice = (value: string) => parseFloat(value.replace(/\s|([,.]$)/g, '').replace(',', '.'))
