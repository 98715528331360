/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateMainPageSliderBannerData = {
    url?: string;
    specialAction?: CreateMainPageSliderBannerData.specialAction;
    name: string;
    regions: Array<number>;
    webImage: string;
    mobileImage: string;
};

export namespace CreateMainPageSliderBannerData {

    export enum specialAction {
        REGISTRATION_FORM = 'REGISTRATION_FORM',
        URL = 'URL',
    }


}

