import React, { FC } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Image, Skeleton } from '@/components/ui'
import { getJournalPublicationUrl } from '@/utils/infoportal/helpers'
import { articlePreviewBannerSkeletons } from './constants'
import { IArticlePreviewBannerProps } from './interfaces'
import './styles.scss'

const ArticlePreviewBanner: FC<IArticlePreviewBannerProps> = props => {
  const { className, article, isLoading, isImageInStatic, onClick } = props

  if ((!article && !isLoading) || (article && !article.coverImage?.rectangleCover)) return null

  return (
    <div className="page-wrapper page-wrapper_no-mobile" onClick={onClick}>
      <article className={classNames('articlePreviewBanner', className)}>
        <Skeleton skeletons={articlePreviewBannerSkeletons} isLoading={isLoading}>
          {article && (
            <CommonLink url={getJournalPublicationUrl(article.slug)} className="articlePreviewBanner__link">
              <Image src={article.coverImage?.rectangleCover} className="articlePreviewBanner__image" isInStatic={isImageInStatic} />
              <h4 className="articlePreviewBanner__title">{article.title}</h4>
            </CommonLink>
          )}
        </Skeleton>
      </article>
    </div>
  )
}

export default ArticlePreviewBanner
