import React from 'react'
import { Button } from '@/components/ui'
import { IAppErrorProps } from '../../types'
import { messages } from './messages'
import './styles.scss'

export const AppErrorProduction: React.FC<IAppErrorProps> = () => {
  const onClick = () => {
    window.location.reload()
  }

  return (
    <div className="appErrorProduction">
      <h1 className="h5">{messages.title}</h1>
      <Button className="appErrorProduction__button" onClick={onClick} modifiers={['green']}>
        {messages.submit}
      </Button>
    </div>
  )
}
