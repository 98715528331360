import { RootState } from '@/redux/interfaces'
import { getUserGtmInfo, pushFormattedGtmEvent } from '../metrika/helpers'

export const sendRegistrationSuccessfullEvent = (globalState: RootState) => {
  pushFormattedGtmEvent({
    ...getUserGtmInfo()(globalState),
    event: 'userEvent',
    regionSelected: globalState.userRegion.region.name,
    pagePart: 'popUp',
    blockName: 'formRegistration',
    eventCategory: 'interactions',
    eventAction: 'click',
    eventLabel: 'formRegistrationStep3',
    eventContext: null,
    eventStatus: 'success',
  })
}

export const sendRegistrationErrorEvent = (textId: string) => {
  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart: 'popUp',
    blockName: 'formRegistrationStep2',
    eventCategory: 'interactions',
    eventAction: 'click',
    eventLabel: 'messageError',
    eventContext: `err:${textId}`,
    eventStatus: 'unsuccess',
    eventEcommerce: null,
    eventNonInteraction: '0',
    firingOptions: 'oncePerEvent',
    ecommerce: null,
  })
}

export const sendRegistrationWrongPhone = () => {
  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart: 'popUp',
    blockName: 'formRegistrationStep1',
    eventCategory: 'interactions',
    eventAction: 'click',
    eventLabel: 'messageError',
    eventContext: 'err: wrong phone',
    eventStatus: 'unsuccess',
  })
}

export const sendConfirmNumberEvent = (status: boolean) => {
  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart: 'popUp',
    blockName: 'formAuthorization',
    eventCategory: 'conversions',
    eventAction: 'backgroundProcess',
    eventLabel: status ? 'responseSuccessAuth' : 'responseErrorAuth',
    eventContext: null,
    eventStatus: status ? 'success' : 'unsuccess',
    eventEcommerce: null,
    eventNonInteraction: '0',
    firingOptions: 'oncePerEvent',
    ecommerce: null,
  })
}
