import { ICatalogResponse } from '@/services/CatalogService'
import { isNonNullable } from '@/typeguards/common'
import {
  EAgriculturalProductCategoryId,
  IAgriculturalProductFilter,
  IAsyncThunkFetchProductCatalogPayload,
  TAgriculturalProductCharacteristicsFilter,
  TAgriculturalProductFilterName,
} from '@/types/Catalog'
import { ESkuCharacteristicType } from '@/types/Catalog/ESkuCharacteristicType'
import { getObjectKeys } from '@/utils/getObjectKeys'
import type {
  TAgricultureCatalogFilter,
  TAgricultureCatalogFilterMultiSelectValue,
  TAgricultureCatalogFilterNumberValue,
  TAgricultureCatalogFilterSingleSelectValue,
  TAgricultureCatalogFilterValue,
} from '../../components/AgricultureCatalogFilter'
import { DONT_TOUCH_FILTER_NAMES_IN_QS } from '../../constants'
import { IAgriculturalProductCatalogFiltersForm, IAsyncThunkFetchProductCatalogResponse } from '../../types'
import { getEmptyFiltersForm } from '../form'
import { isAgriculturalProductFilterName } from '../typeguards'

export function mapFetchRequestResponseToStoreState(
  response: ICatalogResponse,
  payload: IAsyncThunkFetchProductCatalogPayload,
): IAsyncThunkFetchProductCatalogResponse {
  const { promotions, products, categories: categoriesTree, ...data } = response

  const filters = [...mapFiltersResponse(response.filters), ...mapCharacteristicsFiltersResponse(response.characteristicsFilters)]
  const form = getForm(payload, filters)

  return {
    ...data,
    form,
    filters,
    categoriesTree,
    products,
    promotions: promotions.filter(promotion => products.length >= (promotion.bannerSettings?.minOfferCount ?? 0)),
  }
}

function mapFiltersResponse(filters: IAgriculturalProductFilter[]): TAgricultureCatalogFilter[] {
  return filters
    .filter(filter => !!filter.options.length)
    .map(filter => ({
      title: filter.name,
      name: filter.value,
      type: ESkuCharacteristicType.MULTI_SELECT,
      options: filter.options.map(({ name, value }) => ({
        title: name,
        value: String(value),
      })),
    }))
}

function mapCharacteristicsFiltersResponse(filters: TAgriculturalProductCharacteristicsFilter[]): TAgricultureCatalogFilter[] {
  return filters
    .map(filter => {
      const { id: name, name: title, type } = filter

      switch (type) {
        case ESkuCharacteristicType.SINGLE_SELECT:
        case ESkuCharacteristicType.MULTI_SELECT: {
          const { items } = filter.value

          return {
            title,
            name,
            type,
            options: items.map(option => ({
              title: option,
              value: option,
            })),
          }
        }
        case ESkuCharacteristicType.NUMBER: {
          const { units, interval } = filter.value
          if (!interval) return null

          return {
            title,
            name,
            type,
            unit: units?.[0] ?? null,
            interval,
          }
        }
        default: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const exhaustiveCheck: never = type

          return null
        }
      }
    })
    .filter(isNonNullable)
}

function getForm(
  { params, search }: IAsyncThunkFetchProductCatalogPayload,
  filters: TAgricultureCatalogFilter[],
): IAgriculturalProductCatalogFiltersForm {
  const payload = { ...params, ...search }

  return getObjectKeys(payload).reduce((acc, key) => {
    if (isAgriculturalProductFilterName(key)) {
      acc.filters[key] = getFilterValue(key, payload[key], filters)

      return acc
    }

    switch (key) {
      case 'cfilters': {
        let val: IAgriculturalProductCatalogFiltersForm['filters'] = {}

        try {
          const rawVal = payload[key]
          val = rawVal ? JSON.parse(decodeURIComponent(rawVal)) ?? {} : val
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }

        // prettier-ignore
        const cfilters = Object.fromEntries(
          Object
            .entries(val)
            .map(([k, v]) => [k, getFilterValue(k, v, filters)])
        )

        Object.assign(acc.filters, cfilters)
        break
      }

      case 'categoryId':
        acc[key] = Number(payload[key]) || EAgriculturalProductCategoryId.SEARCH
        break

      case 'pageNumber':
        acc[key] = Number(payload[key]) || 1
        break

      case 'searchText':
        acc[key] = payload[key]?.trim() || null
        break

      case 'promotionId':
        acc[key] = payload[key]?.trim() || null
        break

      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const exhaustiveCheck: never = key
      }
    }

    return acc
  }, getEmptyFiltersForm())
}

function getFilterValue(key: string, rawValue: any, filters: TAgricultureCatalogFilter[]): TAgricultureCatalogFilterValue {
  const filter = filters.find(f => f.name === key)

  // prettier-ignore
  const type = filter?.type ?? (
    DONT_TOUCH_FILTER_NAMES_IN_QS.includes(key as TAgriculturalProductFilterName)
      ? ESkuCharacteristicType.MULTI_SELECT
      : null
  )

  if (!type) return null

  switch (type) {
    case ESkuCharacteristicType.MULTI_SELECT: {
      const values: TAgricultureCatalogFilterMultiSelectValue = [rawValue].flat().filter(isNonNullable).sort()

      return values.length ? values : null
    }

    case ESkuCharacteristicType.SINGLE_SELECT: {
      const value: Maybe<TAgricultureCatalogFilterSingleSelectValue> = [rawValue].flat().filter(isNonNullable).sort()[0] ?? null

      return value
    }

    case ESkuCharacteristicType.NUMBER: {
      const from = Number(rawValue?.from) || 0
      const to = Number(rawValue?.to) || Infinity

      const value: TAgricultureCatalogFilterNumberValue = { from, to }

      return value
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = type
    }
  }

  return null
}
