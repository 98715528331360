import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { PriceInfo } from '@/components/composed'
import { DotSpacer } from '..'
import { ITotalPrice } from './interfaces'

const TotalPrice: FC<ITotalPrice> = ({ totalPrice, shouldShowEmptyPrice, className, priceLabelId, isDiscount = false }) => {
  const priceColor = isDiscount ? 'red' : 'green'
  const price = isDiscount ? { value: -totalPrice.value, uom: totalPrice.uom } : totalPrice

  return (
    <div className={classNames(className, 'row row_nowrap text_nowrap row-align_end')}>
      <span className="text_small-from-md">
        <FormattedMessage id={priceLabelId} />
      </span>
      <DotSpacer />
      <h5 className={`color_${priceColor} text_zero-height`}>
        <PriceInfo price={price} shouldShowEmptyPrice={shouldShowEmptyPrice} />
      </h5>
    </div>
  )
}

export default TotalPrice
