import { TMessages } from '@/types/TMessages'

export const messages = {
  header: {
    title: 'Лизинг новой и б/у сельхозтехники и оборудования, автотранспорта',
    description: 'Отраслевые решения для сельхозтоваропроизводителей: Сезонные графики, Программа «Плати раз в год», Субсидия 1432',
  },
  benefits: {
    description: {
      first: 'Индивидуальные графики платежей (равномерный, убывающий, сезонный)',
      second: 'Программа без оценки финансового состояния',
      third: 'Авансовый платеж ниже рыночного',
    },
    banner: {
      title: 'Лизинг сельхозтехники на ваших условиях!',
      benefits: {
        machines: 'сельхозтехника марок РСМ, ПТЗ, БТЗ, NEW HOLAND, CLASS, JOHN DEERE',
        prepaidExpense: 'аванс от 5%',
        noRating: 'лизинг без оценки финансового состояния',
        term: 'срок лизинга от 12 до 60 месяцев',
      },
    },
  },
  interleasing: { form: { title: 'Оставьте заявку на лизинг от «Интерлизинг» уже сейчас!' } },
  affiliation: {
    description:
      '<ul class="list-with-marks"><li>Производители сельскохозяйственной продукции*</li><li>ИП и Юридические лица, осуществляющие деятельность <br/>в смежных отраслях (транспортировка, переработка сельхозпродукции, производство и реализация пищевых продуктов и т.д.)</li><li>Прочие юридические лица и ИП, отвечающие требованиям «Интерлизинга»</li></ul>',
    requirements: {
      text: '<ul class="list-with-marks"><li>Срок деятельности от 6 месяцев</li><li> Стоимость предмета лизинга: <ul class="list-with-marks space-holder-top16 space-holder-left8"><li>Спецтехника, грузовые автомобили, коммерческая техника - от 1,5 млн.</li><li>Оборудование (прицепная техника) - от 2 млн.</li><li>Легковые автомобили - от 1 млн.</li></ul></li><li>Региональные ограничения: Республика Крым, Севастополь, Республика Дагестан, Республика Ингушетия, Чеченская Республика</li></ul>',
      alert:
        'Вы можете стать лизингополучателем, если у вас отсутствует просроченная задолженность по налогам, сборам, взносам, обязательным платежам и кредитам в финансовых организациях',
    },
    law: '* В соответствии с Федеральным законом от 29.12.2006 № 264-ФЗ (ред. 25.12.2018) «О развитии сельского хозяйства»',
  },
  steps: {
    '1': 'Выбор техники / оборудования у поставщика',
    '2': 'Оформление заявки на поле.рф',
    '3': 'Одобрение заявки и согласование условий',
    '4': 'Заключение договора',
    '5': 'Получение техники / оборудования',
    title: 'Этапы получения средств',
    button: 'Подать заявку',
  },
  financingOffer: {
    hint: {
      forAgrariansOnly: 'Доступно только для аграриев',
    },
    details: {
      advancePayment: {
        value: "от <span class='h2 h3-sm with-counter-animation' data-value='5'>0%</span>",
        sign: 'аванс',
      },
      solution: {
        value: "<span class='h2 h3-sm with-counter-animation' data-value='1'>0</span> час",
        sign: 'предварительное решение',
      },
      deadline: {
        value: 'до 5 лет',
        sign: 'срок лизинга',
      },
      sale: {
        value: "до <span class='h2 h3-sm with-counter-animation' data-value='15'>0%</span>",
        sign: 'скидка',
      },
    },
  },
  leaveRequest: 'Подать заявку',
  breadcrumb: 'Лизинг «Интерлизинг»',
} satisfies TMessages
