import { requestData } from '@/api/request'
import { ECommonApiUrl } from '@/api/urls'
import { getUrlWithQuery } from '@/utils/url'
import { TMarketSearchSuggestions, TMarketSearchSuggestionsParameters } from './interfaces'

export default class MarketSearchService {
  /**
   * Получает список подсказок по всем сущностям маркетплейса
   */
  getSuggestions = (query: string, parameters: TMarketSearchSuggestionsParameters) =>
    requestData<TMarketSearchSuggestions>({
      method: 'GET',
      url: getUrlWithQuery(ECommonApiUrl.searchSuggestions, { ...parameters, query }),
    }).then(response => response.data)

  getAgrariansFromBuyersLk = (query: string) =>
    requestData<TMarketSearchSuggestions>({
      method: 'GET',
      url: getUrlWithQuery(ECommonApiUrl.agrariansSearch, { searchField: query }),
    }).then(response => response.data)

  getAgrariansOrganizationsFromAccreditation = (query?: { statuses?: string; searchField: string }) =>
    requestData<TMarketSearchSuggestions>({
      method: 'GET',
      url: getUrlWithQuery(ECommonApiUrl.searchaArariansOrganizations, { ...query }),
    }).then(response => response.data)
}
