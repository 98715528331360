import React from 'react'
import { FormattedMessage } from 'react-intl'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { injectMessages } from '@/logic/translation/utils'
import { IAgriculturalProductAvailableVolumeProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const AgriculturalProductAvailableVolume: React.FC<IAgriculturalProductAvailableVolumeProps> = props => {
  const { availableVolume, className, labelId, withIcon } = props
  const volume = typeof availableVolume === 'number' ? availableVolume : availableVolume.value
  const isInStock = volume > 0

  if (!isInStock) return null

  const text = (
    <span>
      <FormattedMessage tagName="span" id="agriculturalProductAvailableVolume.inStock" />
    </span>
  )

  return (
    <div className={classNames('agricultural-product-available-volume', className)}>
      {labelId && (
        <span className="agricultural-product-available-volume__label text_super-small space-holder2">
          <FormattedMessage id={labelId} />
        </span>
      )}
      <span className="text_small">
        {withIcon ? (
          <div className="agricultural-product-available-volume__with-icon">
            <span className="row">
              <Icon name={EIconName.OkFill} />
            </span>
            <span className="space-holder-left4 color_pale-black">{text}</span>
          </div>
        ) : (
          text
        )}
      </span>
    </div>
  )
}

export default AgriculturalProductAvailableVolume
