import { TFormattedCartItem, TFormattedCatalogProduct } from '@/logic/metrika/catalog/interfaces'

export const isFormattedCartItem = (cartItem: unknown): cartItem is TFormattedCartItem =>
  typeof (cartItem as TFormattedCartItem)?.id === 'string' && Array.isArray((cartItem as TFormattedCartItem)?.deliveryRegions)

export const isFormattedCartItems = (cartItems: unknown[]): cartItems is TFormattedCartItem[] => isFormattedCartItem(cartItems[0])

export const isFormattedCatalogProduct = (catalogProduct: unknown): catalogProduct is TFormattedCatalogProduct =>
  typeof (catalogProduct as TFormattedCatalogProduct)?.id === 'string' &&
  Array.isArray((catalogProduct as TFormattedCatalogProduct)?.deliveryRegions) &&
  typeof (catalogProduct as TFormattedCatalogProduct)?.postponement === 'boolean'
export const isFormattedCatalogProducts = (catalogProducts: unknown[]): catalogProducts is TFormattedCatalogProduct[] =>
  isFormattedCatalogProduct(catalogProducts[0])
