import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useGetUserJwt } from '@/api/kubik/mobile'
import { Loader } from '@/components/ui'
import { setJwt } from '@/logic/auth/actions'
import { isAuthenticated } from '@/logic/auth/reducer'
import { useConvertToRelativeUrl } from '@/pages/MobileRedirect/useConvertToRelativeUrl'
import { RootState } from '@/redux/interfaces'
import { useSearchQuery } from '@/router/hooks/useSearchQuery'
import { AuthCookiesService } from '@/services'
import './styles.scss'

const mapState = (state: RootState) => ({
  isUserAuthenticated: isAuthenticated()(state),
})

const MobileRedirect = () => {
  const { isUserAuthenticated } = useSelector(mapState)
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useSearchQuery()
  const convertToRelativeUrl = useConvertToRelativeUrl()

  const code = query.get('code')
  const redirectLink = query.get('link')

  const { data, isSuccess } = useGetUserJwt(
    { code: code || '' },
    {
      query: {
        enabled: !!code && !isUserAuthenticated,
      },
    },
  )

  const navigate = () => {
    if (redirectLink && convertToRelativeUrl(redirectLink)) {
      history.replace(convertToRelativeUrl(redirectLink))
    } else {
      history.replace('/')
    }
  }

  useEffect(() => {
    if (isUserAuthenticated) {
      navigate()
    } else {
      const jtwToken = data?.data

      if (jtwToken && isSuccess) {
        dispatch(setJwt({ jwt: jtwToken }))
        AuthCookiesService.setJwt(jtwToken)

        navigate()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess, isUserAuthenticated])

  return <Loader isVisible isFixed className="mobileRedirect__loader" />
}

export default MobileRedirect
