
export enum UpdatePurchaseOfferTemplateStatusRequestNewStatus {
    "DRAFT" = "DRAFT",
    "ON_APPROVAL" = "ON_APPROVAL",
    "PROPOSED_CHANGES" = "PROPOSED_CHANGES",
    "UNDER_REVIEW" = "UNDER_REVIEW",
    "PUBLISHED" = "PUBLISHED"
}
export type UpdatePurchaseOfferTemplateStatusRequest = {
    /**
     * @type integer int64
    */
    templateId: number;
    /**
     * @type string
    */
    newStatus: UpdatePurchaseOfferTemplateStatusRequestNewStatus;
};
