import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { FindForVitrinaMutationRequest, FindForVitrinaMutationResponse, FindForVitrinaQueryParams } from "../../types/FindForVitrina";

/**
     * @description Метод предназначен для получения списка аукционов с возможностью фильтрации. https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425480271
     * @link /v1/auctions/view_for-vitrina
     */
export async function findForVitrina (data?: FindForVitrinaMutationRequest, params?: FindForVitrinaQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindForVitrinaMutationResponse>["data"]> {
    const { data: resData } = await client<FindForVitrinaMutationResponse, FindForVitrinaMutationRequest>({
        method: "post",
        url: `/v1/auctions/view_for-vitrina`,
        params,
        data,
        ...options
    });
    
    return resData;
};