import { createAction } from '@reduxjs/toolkit'
import { IModalOptions } from './interfaces'

export declare namespace NAction {
  export interface IOpenModalPayload {
    options: IModalOptions
    contentOptions?: Record<string, any>
  }
}

export const openModal = createAction<NAction.IOpenModalPayload>('@@modal/OPEN-MODAL')

export const clearModalOptions = createAction('@@modal/CLEAR-MODAL-OPTIONS')

export const closeModal = createAction('@@modal/CLOSE-MODAL')
