import { createReducer } from '@reduxjs/toolkit'
import { getInitialState } from '@/redux/helpers'
import { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { clearMaxShownItemsCount, setMaxShownItemsCount } from './actions'
import { IPaginationState } from './interfaces'

const initialState: IPaginationState = {
  maxShownItemsCount: 0,
}

const reducerName = 'pagination'
const reducer = createReducer(getInitialState(reducerName, initialState), {
  [setMaxShownItemsCount.type]: (state, action) => {
    state.maxShownItemsCount = action.payload
  },
  [clearMaxShownItemsCount.type]: state => {
    state.maxShownItemsCount = 0
  },
})

reducerManager.add(reducerName, reducer, true)

export const getMaxShownItemsCount = () => (state: RootState) => state.pagination.maxShownItemsCount
