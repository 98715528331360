import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import TextWithIcon from '../TextWithIcon/TextWithIcon'
import { IStatusCommentProps } from './interfaces'
import './styles.scss'

const StatusComment: React.FC<IStatusCommentProps> = props => {
  const { text, color, iconProps, className, textModifier = 'super-small', commentTextClassName = 'color_pale-black', textValues } = props

  return text ? (
    <TextWithIcon
      className={classNames('status-comment color_pale-black', className)}
      iconProps={{ name: EIconName.Chat, style: { color: color || EColor.LIGHT_GRAY }, ...iconProps }}
      text={text}
      textModifier={textModifier}
      textClassName={classNames('status-comment__text', commentTextClassName)}
      textValues={textValues}
    />
  ) : null
}

export default StatusComment
