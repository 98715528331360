import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IFinancingState, IInstallmentCalculatorPayload, TSetCalculatorStateProps } from '@/logic/financing/interfaces'
import { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { InstallmentCalculator } from '@/services/FinancingService'
import './styles.scss'

const reducerName = 'financing'

const installmentCalculator = new InstallmentCalculator()

const initialState: IFinancingState = {
  isFetching: false,
  calculatorResults: {},
}

export const financingProductsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setCalculatorState: (state, { payload }: TSetCalculatorStateProps) => {
      const { id, isFetching, products, calculateSberPaymentAmount } = payload

      state.calculatorResults = {
        ...state.calculatorResults,
        [id]: {
          ...state.calculatorResults[id],
          ...(calculateSberPaymentAmount !== undefined && { calculateSberPaymentAmount }),
          ...(isFetching !== undefined && { isFetching }),
          ...(products !== undefined && { products }),
        },
      }
    },
  },
})

export const { setCalculatorState } = financingProductsSlice.actions

export const requestInstallmentCalculator = createAsyncThunk<void, IInstallmentCalculatorPayload>(
  '@@financing/INSTALLMENT_CALCULATOR_REQUEST',
  async ({ id, payload }, { dispatch }) => {
    dispatch(setCalculatorState({ id, isFetching: true }))

    try {
      const result = await installmentCalculator.create(payload)

      dispatch(
        setCalculatorState({
          id,
          isFetching: false,
          products: result.data.calculatePaymentAmountList,
          calculateSberPaymentAmount: result.data.calculatePaymentAmount,
        }),
      )
    } catch (err) {
      dispatch(setCalculatorState({ id, isFetching: false }))
    }
  },
)

export const getIsFetching = () => (state: RootState) => state[reducerName].isFetching
export const getCalculatorResults = () => (state: RootState) => state[reducerName].calculatorResults

reducerManager.add(reducerName, financingProductsSlice.reducer, true)
