import React, { memo, useCallback } from 'react'
import { useField } from 'react-final-form'
import RadioButtonBase from '../RadioButtonBase'
import { TFinalFormRadioButtonProps } from './interfaces'

const FinalFormRadioButton: React.FC<TFinalFormRadioButtonProps> = props => {
  const { name, value, onChange, ...radioProps } = props

  const { input } = useField<string>(name, { type: 'radio', value })

  const onRadioChange: TFinalFormRadioButtonProps['onChange'] = useCallback(
    event => {
      input.onChange(event)
      onChange?.(event)
    },
    [input, onChange],
  )

  return <RadioButtonBase {...input} {...radioProps} onChange={onRadioChange} />
}

export default memo(FinalFormRadioButton)
