import React from 'react'
import { Image, InjectHtml } from '@/components/ui'
import { IExactFarmingScreenSectionProps } from './interfaces'
import './styles.scss'

const ExactFarmingScreenSection: React.FC<IExactFarmingScreenSectionProps> = ({ titleId, listItemTextIds, imageSrc, blockId }) => {
  return (
    <section className="exact-farming-screen-section">
      <div className="page-wrapper">
        <InjectHtml textId={titleId} TagName="h2" className="space-holder24-from-sm" />

        <div className="exact-farming-screen-section-text-row">
          {listItemTextIds.map((listItemTextId, i) => (
            <InjectHtml key={i} textId={listItemTextId} className="exact-farming-screen-section-text-row__item" />
          ))}
        </div>

        <div className="exact-farming-screen-section__image-wrapper" id={blockId}>
          <Image src={imageSrc} isInStatic />
        </div>
      </div>
    </section>
  )
}

export default ExactFarmingScreenSection
