import React, { ElementRef, FC, FocusEvent, forwardRef, KeyboardEvent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextInputView as TextInput, TOnTextInputChange } from '@frontend/pole-ui/lib/components/TextInput'
import { injectMessages } from '@/logic/translation/utils'
import { EPriceUom } from '@/types'
import { formatUserPhoneEntering, normalizeFloatPrice } from './helpers'
import { IPriceCurrencyProps, TTextInputViewProps } from './interfaces'
import messages from './messages.json'

injectMessages(messages)

const PriceCurrency: FC<IPriceCurrencyProps> = ({ priceUom, unitOfMeasurement }) => (
  <span className="text-input-view__currency">
    <FormattedMessage
      id={`textInput.priceCurrency.${!priceUom || priceUom === EPriceUom.RUB ? 'rub' : 'conditionalCurrency'}`}
      values={{ unitOfMeasurement }}
    />
  </span>
)

const TextInputView = forwardRef<ElementRef<typeof TextInput>, TTextInputViewProps>((props, ref) => {
  const {
    input,
    onChange,
    placeholder,
    placeholderId,
    comment,
    commentId,
    label,
    labelId,
    onBlur,
    onFocus,
    onKeyDown,
    errorValues,
    priceUom,
    unitOfMeasurement,
    hideCurrency,
    ...otherProps
  } = props

  const { formatMessage } = useIntl()
  let placeholderResult = placeholder
  if (placeholderId) placeholderResult = formatMessage({ id: placeholderId })
  let commentResult = comment
  if (commentId) commentResult = formatMessage({ id: commentId })
  let labelResult = label
  if (labelId) labelResult = formatMessage({ id: labelId })

  const onInputChange: TOnTextInputChange = event => {
    onChange?.(event)

    input?.onChange?.(event.target.value)
  }

  const onInputBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onBlur?.(event)
    input?.onBlur?.(event)

    if (otherProps.type === 'floatPrice') input?.onChange?.(normalizeFloatPrice(input.value))
  }

  const onInputFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onFocus?.(event)

    input?.onFocus?.(event)
  }

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onKeyDown?.(event)

    if (otherProps.type === 'floatPrice' && event.code === '13') input?.onChange?.(normalizeFloatPrice(input.value))
  }

  const { minThreshold, minLength, maxLength, minValue, maxValue, meta, customErrorText } = otherProps

  const { error, data } = meta || {}
  const errorText = customErrorText ?? (
    <FormattedMessage
      id={`field.error.${error || data?.warning}`}
      values={{ minThreshold, minLength, maxLength, minValue, maxValue, ...errorValues }}
    />
  )

  return (
    <TextInput
      ref={ref}
      value={input?.value}
      onKeyDown={onInputKeyDown}
      onBlur={onInputBlur}
      onChange={onInputChange}
      onFocus={onInputFocus}
      placeholder={placeholderResult}
      label={labelResult}
      comment={commentResult}
      priceCurrency={hideCurrency ? null : <PriceCurrency priceUom={priceUom} unitOfMeasurement={unitOfMeasurement} />}
      errorText={errorText}
      beforeMaskedStateChange={formatUserPhoneEntering}
      {...otherProps}
    />
  )
})

export default TextInputView
