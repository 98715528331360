import React from 'react'
import { CheckboxButtons, TOnCheckboxesChange } from '@frontend/pole-ui/lib/components/CheckboxButtons'
import { useMount } from '@/utils/hooks'
import { TCheckboxButtonsGroupProps } from './interfaces'

const CheckboxButtonsGroupView: React.FC<TCheckboxButtonsGroupProps> = props => {
  const { onChange, input, ...otherProps } = props

  const setValues: TOnCheckboxesChange = newValues => {
    if (Array.isArray(newValues)) {
      input.onChange(newValues)
      onChange?.(newValues)
    }
  }

  useMount(undefined, () => setValues([]))

  return (
    <CheckboxButtons
      {...otherProps}
      multiple
      value={typeof input.value === 'string' && input.value ? [input.value] : input.value || []}
      onChange={setValues}
    />
  )
}

export default CheckboxButtonsGroupView
