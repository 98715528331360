import React, { memo, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { useEffectOnMissingClickByBlock } from '@/utils/hooks/event'
import { sections } from './constants'
import * as messages from './messages'
import { IAdditionalSalesMenuProps } from './types'
import './AdditionalSalesMenu.scss'

const AdditionalSalesMenu: React.FC<IAdditionalSalesMenuProps> = props => {
  const { onClose } = props
  const isFirstClickOutside = useRef(true)
  const [isActive, setIsActive] = useState(true)

  const closeMenu = () => setIsActive(false)

  useEffectOnMissingClickByBlock('additionalSalesMenu', () => {
    if (isFirstClickOutside.current) {
      isFirstClickOutside.current = false

      return
    }
    closeMenu()
  })

  return (
    <CSSTransition appear in={isActive} timeout={160} onExited={onClose} classNames="additionalSalesMenu-translate" unmountOnExit>
      <div className="additionalSalesMenu">
        <section className="additionalSalesMenuHeader">
          <h3>{messages.title}</h3>

          <div className="additionalSalesMenuHeader__closeBtn" onClick={closeMenu}>
            <Icon name={EIconName.Exit} />
          </div>
        </section>

        <div className="additionalSalesMenuSections">
          {sections.map(({ title, links }) => (
            <section className="additionalSalesMenuSection" key={title}>
              <p className="additionalSalesMenuSectionTitle text_super-small">{title}</p>

              <div className="additionalSalesMenuSectionLinks">
                {links.map(link => (
                  <CommonLink
                    key={link.text}
                    className="additionalSalesMenuSectionLink text_small"
                    url={link.url}
                    activeClassName=""
                    onClick={closeMenu}
                  >
                    <Icon className="additionalSalesMenuSectionLink__icon" name={link.icon} />
                    <span>{link.text}</span>
                    <Icon className="additionalSalesMenuSectionLink__arrow" name={EIconName.ShevronRight} />
                  </CommonLink>
                ))}
              </div>
            </section>
          ))}
        </div>
      </div>
    </CSSTransition>
  )
}

export default memo(AdditionalSalesMenu)
