import { useCallback, useRef } from 'react'
import { SwiperRefNode } from 'react-id-swiper/lib/types'

export const useSwiper = () => {
  const swiperRef = useRef<SwiperRefNode>(null)

  const slideNext = useCallback(() => {
    swiperRef.current?.swiper?.slideNext()
  }, [])

  const slidePrevious = useCallback(() => {
    swiperRef.current?.swiper?.slidePrev()
  }, [])

  const slideTo = useCallback((index: number) => {
    swiperRef.current?.swiper?.slideTo(index)
  }, [])

  const slideToLoop = useCallback((index: number) => {
    swiperRef.current?.swiper?.slideToLoop(index)
  }, [])

  return {
    swiperRef,
    slideNext,
    slidePrevious,
    slideTo,
    slideToLoop,
  }
}
