import { DocumentInfoResponse, DocumentInfoResponseStatus } from '@/api/kubik/documents'
import { IUser } from '@/logic/auth/interfaces'

export type TContractPermissionsContract = Maybe<Pick<DocumentInfoResponse, 'status' | 'organizationId' | 'contractorInn'>>

export interface IContractPermissionsConfig {
  user: IUser | undefined
  isAgrarian: boolean
  isSupplier: boolean
  isImpersonated: boolean
}

export function getContractPermissions(contract: TContractPermissionsContract, config: IContractPermissionsConfig) {
  const { user, isAgrarian, isSupplier, isImpersonated } = config

  const create = isSupplier

  const view = (() => {
    if (!contract) return false

    if (isAgrarian) {
      return user?.organizationInn === contract.contractorInn && contract.status === DocumentInfoResponseStatus.ON_APPROVAL
    }

    return isSupplier
  })()

  const edit = (() => {
    if (!contract || !view) return false

    if (isSupplier) {
      // prettier-ignore
      const allowedStatuses = isImpersonated
        // eslint-disable-next-line max-len
        ? [DocumentInfoResponseStatus.DRAFT, DocumentInfoResponseStatus.PROPOSED_CHANGES, DocumentInfoResponseStatus.UNDER_REVIEW]
        : [DocumentInfoResponseStatus.DRAFT, DocumentInfoResponseStatus.PROPOSED_CHANGES]

      return allowedStatuses.includes(contract.status)
    }

    return false
  })()

  const accept = (() => {
    if (!contract || !view) return false

    if (isAgrarian) {
      return user?.organizationInn === contract.contractorInn && contract.status === DocumentInfoResponseStatus.ON_APPROVAL
    }

    if (isSupplier) {
      return isImpersonated && contract.status === DocumentInfoResponseStatus.UNDER_REVIEW
    }

    return false
  })()

  const duplicate = (() => {
    if (!contract || !view) return false

    return isSupplier
  })()

  const makeProposals = (() => {
    if (!contract || !view) return false

    if (isAgrarian) {
      return user?.organizationInn === contract.contractorInn && contract.status === DocumentInfoResponseStatus.ON_APPROVAL
    }

    if (isSupplier) {
      return isImpersonated && contract.status === DocumentInfoResponseStatus.UNDER_REVIEW
    }

    return false
  })()

  const viewProposals = (() => {
    if (!contract || !view) return false

    return isSupplier
  })()

  return {
    create,
    view,
    edit,
    accept,
    duplicate,
    makeProposals,
    viewProposals,
  }
}
