/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MonetaryAmountData } from './MonetaryAmountData';
import type { SupplyActionDto } from './SupplyActionDto';
import type { SupplyItemDto } from './SupplyItemDto';

export type OrderSupplyDto = {
    id: number;
    startDate: string;
    endDate: string;
    status: OrderSupplyDto.status;
    statusName: string;
    sum: MonetaryAmountData;
    items: Array<SupplyItemDto>;
    actions?: Array<SupplyActionDto>;
};

export namespace OrderSupplyDto {

    export enum status {
        SCHEDULED = 'SCHEDULED',
        COMPLETED = 'COMPLETED',
        PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
    }


}

