import { Dispatch, SetStateAction, useState } from 'react'
import { useIsomorphicLayoutEffect } from '@frontend/pole-ui/lib/hooks/useIsomorphicLayoutEffect'

interface IUseIsLoadingDebouncedProps {
  delay: number
}

export function useIsLoadingDebounced({
  delay,
}: IUseIsLoadingDebouncedProps): [isLoadingDebounced: boolean, setIsLoading: Dispatch<SetStateAction<boolean>>, isLoading: boolean] {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDebounced, setIsLoadingDebounced] = useState(false)

  useIsomorphicLayoutEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (isLoading) {
      timer = setTimeout(() => setIsLoadingDebounced(isLoading), delay)
    } else {
      setIsLoadingDebounced(false)
    }

    return () => clearInterval(timer)
  }, [isLoading])

  return [isLoadingDebounced, setIsLoading, isLoading]
}
