import isNil from 'lodash/isNil'
import { EAgriculturalProductCategoryId, TAgriculturalProductFilterName } from '@/types/Catalog'
import { getObjectKeys } from '@/utils/getObjectKeys'
import { normalizeInterval } from '@/utils/normalizeInterval'
import type { TAgricultureCatalogFilter, TAgricultureCatalogFilterValue } from '../components/AgricultureCatalogFilter'
import { TAgricultureCatalogFilterMultiSelectValue } from '../components/AgricultureCatalogFilter'
import {
  isMultiSelectFilter,
  isMultiSelectValue,
  isNumberFilter,
  isNumberValue,
  isSingleSelectFilter,
  isSingleSelectValue,
} from '../components/AgricultureCatalogFilter/typeguards'
import { DONT_TOUCH_FILTER_NAMES_IN_QS } from '../constants'
import { IAgriculturalProductCatalogFiltersForm } from '../types'

export const getEmptyFiltersForm = (): IAgriculturalProductCatalogFiltersForm => ({
  categoryId: EAgriculturalProductCategoryId.SEARCH,
  searchText: null,
  // regions: null,
  promotionId: null,
  filters: {},
  pageNumber: 1,
})

export function normalizeFiltersFormValues(
  form: IAgriculturalProductCatalogFiltersForm,
  filters: TAgricultureCatalogFilter[],
): IAgriculturalProductCatalogFiltersForm {
  return {
    ...form,
    filters: getActualFiltersValues(form.filters, filters),
  }
}

function getActualFiltersValues(
  formFilters: IAgriculturalProductCatalogFiltersForm['filters'],
  filters: TAgricultureCatalogFilter[],
): IAgriculturalProductCatalogFiltersForm['filters'] {
  return getObjectKeys(formFilters).reduce((acc, filterName) => {
    const filter = filters.find(f => f.name === filterName)

    if (!filter) {
      // костыль для `supplierIds`
      if (DONT_TOUCH_FILTER_NAMES_IN_QS.includes(filterName as TAgriculturalProductFilterName)) {
        const newValue = (formFilters[filterName] ?? null) as TAgricultureCatalogFilterMultiSelectValue

        if (newValue?.length) {
          acc[filterName] = newValue
        }
      }

      return acc
    }

    const value = formFilters[filterName]
    let newValue: Maybe<TAgricultureCatalogFilterValue> = null

    if (!isNil(value)) {
      if (isMultiSelectFilter(filter) && isMultiSelectValue(filter, value)) {
        const { options } = filter
        newValue = value.filter(v => options.find(opt => opt.value === v))
        newValue = newValue.length ? newValue : null
      } else if (isSingleSelectFilter(filter) && isSingleSelectValue(filter, value)) {
        const { options } = filter
        newValue = options.find(opt => opt.value === value)?.value ?? null
      } else if (isNumberFilter(filter) && isNumberValue(filter, value)) {
        acc[filterName] = normalizeInterval(value, filter.interval)
      }
    }

    if (!isNil(newValue)) {
      acc[filterName] = newValue
    }

    return acc
  }, {} as IAgriculturalProductCatalogFiltersForm['filters'])
}
