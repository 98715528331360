import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ShortFaqArticleData } from '@/api/generated/base'
import { useData } from '@/logic/data'
import { loadData } from '@/logic/data/actions'
import { FaqContext } from '../../constants'
import { TFaqQuestions } from '../../types'

export const useGetSpecializationQuestions = () => {
  const { specializationId: initialSpecializationId } = useContext(FaqContext).params
  const categoryQuestionsResponse = useData<ShortFaqArticleData[]>('faqCategoryQuestions', {
    shouldNotLoadDataOnMount: !initialSpecializationId,
    params: { id: initialSpecializationId },
  })
  const { data: categoryQuestions = [] } = categoryQuestionsResponse
  const dispatch = useDispatch()
  const [specializationId, setSpecializationId] = useState<string | undefined>(initialSpecializationId)
  const [questions, setQuestions] = useState<TFaqQuestions>(
    categoryQuestions && specializationId ? { [specializationId!]: categoryQuestions } : {},
  )

  const loadQuestions = (id: string) => {
    dispatch(loadData({ name: 'faqCategoryQuestions', shouldLoadForcibly: true, params: { specializationId: id } }))
    setSpecializationId(id)
  }

  useEffect(() => {
    if (specializationId && categoryQuestions.length) setQuestions({ ...questions, [specializationId]: categoryQuestions })
    //eslint-disable-next-line
  }, [categoryQuestions])

  return { questions, loadQuestions }
}
