import React from 'react'
import { useSelector } from 'react-redux'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import { BubblingTooltip, Button, ErrorMessage, Loader, UploadButton } from '@/components/ui'
import { getRequestState } from '@/logic/requestStatus/reducer'
import { injectMessages } from '@/logic/translation/utils'
import { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { DocumentHint, DocumentStatus } from '../DocumentCard/components'
import { ITypedDocumentCardProps } from './interfaces'
import messages from './messages.json'

injectMessages(messages)

const mapState = (uploadDocumentRequestName: string) => (state: RootState) => {
  const { isLoading: isDocumentUploading, isFailure: isUploadingDocumentFailed } = getRequestState(uploadDocumentRequestName)(state)

  return { isDocumentUploading, isUploadingDocumentFailed }
}

const TypedDocumentCard: React.FC<ITypedDocumentCardProps> = props => {
  const {
    typedDocument,
    onDownloadDocument,
    canAddDocument,
    uploadDocumentRequestName,
    onUploadDocument,
    DocumentStatusElement,
    isUploadingButtonPlain,
    shouldUploadTemplateAsAction,
    shouldHideUpdatingDocumentButton,
  } = props
  const { name, id: type, templateUrl } = typedDocument
  const { isDocumentUploading, isUploadingDocumentFailed } = useSelector(mapState(uploadDocumentRequestName))

  const { isMobile } = useDeviceType()

  const uploadingButtonProps = {
    iconProps: {
      name: EIconName.Upload,
      size: isMobile ? EIconSize.XS : EIconSize.M,
      className: 'typedDocumentCard--iconColorBlack"',
    },
    className: 'document-card-actions__button',
    isUnstyled: true,
    isHidden: !canAddDocument || shouldHideUpdatingDocumentButton,
  }

  const uploadingButton = (
    <BubblingTooltip messageId="typedDocumentCard.hint.uploadButton" tooltipProps={{ touch: false }}>
      <span className="row">
        {isUploadingButtonPlain ? (
          <Button {...uploadingButtonProps} onClick={onUploadDocument} />
        ) : (
          <UploadButton name={`document${type}`} buttonProps={uploadingButtonProps} onChange={onUploadDocument} />
        )}
      </span>
    </BubblingTooltip>
  )

  return (
    <div className="document-card">
      <Loader isVisible={isDocumentUploading} />
      <div className="document-card-first-col">
        <p className="text_small-from-sm document-card__name">{name}</p>
        {templateUrl && (!shouldHideUpdatingDocumentButton || shouldUploadTemplateAsAction) && (
          <DocumentHint
            iconProps={{
              name: EIconName.InfoFill,
              size: isMobile ? EIconSize.XS : EIconSize.M,
              className: 'typedDocumentCard--opacityIcon',
            }}
            tooltipId="template"
            className="space-holder-top16"
            // shouldUploadTemplateAsAction задаёт инверсию действиям скачивания и загрузки шаблона
            onClick={shouldUploadTemplateAsAction ? onDownloadDocument : file => onUploadDocument(file)}
            shouldUploadFile={!shouldUploadTemplateAsAction}
          />
        )}
      </div>
      <div className="document-card-actions">
        {templateUrl ? (
          <>
            {shouldUploadTemplateAsAction ? (
              uploadingButton
            ) : (
              <BubblingTooltip messageId="typedDocumentCard.hint.downloadButton" tooltipProps={{ touch: false }}>
                <span className="row">
                  <Button
                    onClick={onDownloadDocument}
                    iconProps={{
                      name: EIconName.Download,
                      size: isMobile ? EIconSize.XS : EIconSize.M,
                      className: 'typedDocumentCard--iconColorBlack',
                    }}
                    className="document-card-actions__button"
                    isUnstyled
                  />
                </span>
              </BubblingTooltip>
            )}
          </>
        ) : (
          uploadingButton
        )}
      </div>

      <div className="document-card-third-col">
        {DocumentStatusElement || <DocumentStatus />}
        <ErrorMessage
          isVisible={isUploadingDocumentFailed}
          textId="typedDocumentCard.uploadDocument.error"
          className="space-holder-top8 space-holder-top8-sm"
        />
      </div>
    </div>
  )
}

export default TypedDocumentCard
