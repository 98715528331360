import { IUserGtmInfoPayload } from '@/interfaces'
import { ILocation } from '@/logic/app/interfaces'
import { RootState } from '@/redux/interfaces'
import { EUserPermission } from '@/types'
import { getAnalyticsClientId, is404Page } from '../app/reducer'
import {
  getUser,
  isAuthenticated,
  isUserAdmin,
  isUserAgrarian,
  isUserBuyer,
  isUserContentManager,
  isUserLogisticsOperator,
  isUserSupplier,
} from '../auth/reducer'
import { baseScrollGtmEvent } from './constants'
import {
  IFormattedGtmEventPayload,
  IFormattedPageViewGtmEventPayload,
  IGetEcommerceObjectProps,
  IPushPageViewGtmEventParams,
  TEcommerceObject,
} from './interfaces'

export const pushGtmEvent = <GPayload>(payload: GPayload) => {
  return window.dataLayer?.push(payload)
}
export const pushFormattedGtmEvent = <GPayload extends IFormattedGtmEventPayload = IFormattedGtmEventPayload>(payload: GPayload) => {
  return pushGtmEvent<IFormattedGtmEventPayload>(payload)
}
export const getGtmScrollEventPayloadMap = (elementIds: string[], blockId: string | null = null) =>
  elementIds.reduce(
    (result, id: string) => ({
      ...result,
      [id]: { ...baseScrollGtmEvent, eventLabel: `scroll${id}`, blockId },
    }),
    {},
  )

export const pushPageViewGtmEvent = (params: IPushPageViewGtmEventParams) => {
  const { eventContext, eventStatus, userAuth, uid, memberID } = params

  pushGtmEvent<IFormattedPageViewGtmEventPayload>({
    event: 'pageViewEvent',
    eventContext,
    eventStatus,
    eventEcommerce: null,
    userSegment: null,
    userAuth,
    uid,
    memberID,
    ecommerce: null,
  })
}

export const getEventContextGtm = (path: string, prevLocation?: ILocation) => {
  const previousPagePath = prevLocation?.pathname !== '/' ? prevLocation?.pathname : document.referrer

  return `pp:${path};oldpp:${previousPagePath}`
}

const getUserAuth = (state: RootState) => {
  if (!isAuthenticated()(state)) return null
  if (isUserAdmin()(state)) return EUserPermission.ADMIN.toLowerCase()
  if (isUserBuyer()(state)) return EUserPermission.BUYER.toLowerCase()
  if (isUserSupplier()(state)) return EUserPermission.SUPPLIER.toLowerCase()
  if (isUserAgrarian()(state)) return EUserPermission.AGRARIAN.toLowerCase()
  if (isUserContentManager()(state)) return EUserPermission.CONTENT_MANAGER.toLowerCase()
  if (isUserLogisticsOperator()(state)) return EUserPermission.LOGISTICS_OPERATOR.toLowerCase()

  return null
}

export const getUserGtmInfo =
  () =>
  (state: RootState): IUserGtmInfoPayload => ({
    eventStatus: is404Page()(state) ? 'err:404error' : 'success',
    userAuth: getUserAuth(state),
    memberID: getUser()(state)?.userId || null,
    userImpersonalization: state.auth.isImpersonationUser ? '1' : '0',
    uid: getAnalyticsClientId()(state),
  })

export const getEcommerceObject = ({ ecommerceType, items, actionField, itemContext }: IGetEcommerceObjectProps): TEcommerceObject => {
  if (ecommerceType === 'impressions') {
    return {
      currencyCode: 'RUB',
      impressions: itemContext ? items?.map(item => ({ ...item, list: itemContext })) : items,
    }
  }

  const ecommerce: TEcommerceObject = {
    currencyCode: 'RUB',
    [ecommerceType]: {
      products: items,
    },
  }

  if (actionField) ecommerce[ecommerceType]!.actionField = actionField

  return ecommerce
}

export const pushGtmEventOfSendingDocumentsToVerification = () => {
  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart: 'content',
    blockName: 'orderDetailsSeller',
    eventCategory: 'conversions',
    eventStatus: 'success',
    eventEcommerce: null,
    eventNonInteraction: '0',
    firingOptions: 'oncePerEvent',
    ecommerce: null,
    eventAction: 'click',
    eventLabel: 'documentVerificationSend',
    eventContext: null,
  })
}
