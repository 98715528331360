import React, { memo } from 'react'
import { EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { AgriculturalProductPromoLabel } from '@/components/composed/catalog/promo'
import { EAgriculturalProductPromoLabelSize } from '@/components/composed/catalog/promo/AgriculturalProductPromoLabel/interfaces'
import { InjectHtml } from '@/components/ui'
import { ILabelListProps, IProductCardLabel } from './interfaces'
import './OfferLabels.scss'

const DEFAULT_LIST: IProductCardLabel[] = []

const OfferLabels: React.FC<ILabelListProps> = ({ list = DEFAULT_LIST, isMobileDevice, className }) => {
  if (!list.length) return null

  return (
    <div className={classNames('product-card-labels', className)}>
      {list.map(label => {
        const { id, name, nameId, nameValues, color, description, descriptionId, iconName } = label

        return (
          <AgriculturalProductPromoLabel
            className="product-card-labels__chip"
            shouldShowLabel
            key={id}
            textId={nameId}
            text={name}
            textValues={nameValues}
            color={color}
            size={isMobileDevice ? EAgriculturalProductPromoLabelSize.SMALL : EAgriculturalProductPromoLabelSize.MEDIUM}
            promoExplanationMessageId={descriptionId}
            promoExplanationMessage={description}
          >
            {iconName && (
              <>
                <Icon className="space-holder-right8" name={iconName} size={EIconSize.XS} />
                <InjectHtml TagName="div" textId={nameId} />
              </>
            )}
          </AgriculturalProductPromoLabel>
        )
      })}
    </div>
  )
}

export default memo(OfferLabels)
