import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddCommentForTemplateByIdMutationRequest, AddCommentForTemplateByIdMutationResponse, AddCommentForTemplateByIdHeaderParams } from "../../types/AddCommentForTemplateById";

/**
     * @link /api/document/purchase-offer/v1/add_comment-for-template-by-id
     */
export async function addCommentForTemplateById (data: AddCommentForTemplateByIdMutationRequest, headers?: AddCommentForTemplateByIdHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<AddCommentForTemplateByIdMutationResponse>["data"]> {
    const { data: resData } = await client<AddCommentForTemplateByIdMutationResponse, AddCommentForTemplateByIdMutationRequest>({
        method: "post",
        url: `/api/document/purchase-offer/v1/add_comment-for-template-by-id`,
        data,
        headers: { ...headers, ...options.headers },
        ...options
    });
    
    return resData;
};