import { ILotsFilter, ITemplatesFilter } from '@/types/HarvestSaleLots'

export const transformTemplatesFiltersFromResponse = (filters: ITemplatesFilter[]): ILotsFilter[] =>
  filters.map(({ name: title, options, value: filterName }) => ({
    category: filterName,
    title,
    options: options.map(({ name: label, value: checkboxName, dependencies }) => ({
      name: `${filterName}.${checkboxName}`,
      id: String(checkboxName),
      label,
      dependencies,
    })),
  }))
