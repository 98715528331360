import React from 'react'
import Loadable, { OptionsWithoutResolver } from '@loadable/component'
import { PageLoader } from '@/components/ui'

const pageOptions: OptionsWithoutResolver<any> = { fallback: <PageLoader className="app-page-loader" /> }

export const AgriculturalProductCatalog = Loadable(
  /* webpackChunkName: "AgriculturalProductCatalog" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./AgriculturalProductCatalog'),
  pageOptions,
)

export const HarvestSaleLots = Loadable(
  /* webpackChunkName: "HarvestSaleLots" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./HarvestSaleLots'),
  pageOptions,
)
export const CulturesSaleLots = Loadable(
  /* webpackChunkName: "CulturesSaleLots" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./CulturesSaleLots'),
  pageOptions,
)
export const HarvestSale = Loadable(
  /* webpackChunkName: "HarvestSale" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./HarvestSale'),
  pageOptions,
)

export const OfferRequests = Loadable(
  /* webpackChunkName: "OfferRequests" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./OfferRequests'),
  pageOptions,
)

export const QrCode = Loadable(
  /* webpackChunkName: "QrCode" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./QrCode'),
  pageOptions,
)

export const PayOnline = Loadable(
  /* webpackChunkName: "PayOnline" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./PayOnline'),
  pageOptions,
)

export const Error500Page = Loadable(
  /* webpackChunkName: "Error500Page" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./errors/500'),
  pageOptions,
)

export const Error404Page = Loadable(
  /* webpackChunkName: "Error404Page" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./errors/404'),
  pageOptions,
)

export const AgriculturalProduct = Loadable(
  /* webpackChunkName: "AgriculturalProduct" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./AgriculturalProduct'),
  pageOptions,
)

export const UserProfile = Loadable(
  /* webpackChunkName: "UserProfile" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./Profiles/UserProfile'),
  pageOptions,
)

export const MakingOrderDetails = Loadable(
  /* webpackChunkName: "MakingOrderDetails" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./MakingOrderDetails'),
  pageOptions,
)

export const Impersonation = Loadable(
  /* webpackChunkName: "Impersonation" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./Impersonation'),
  pageOptions,
)

export const IndexPage = Loadable(
  /* webpackChunkName: "IndexPage" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./IndexPage'),
  pageOptions,
)

export const TemporaryBasicAuthPage = Loadable(
  /* webpackChunkName: "TemporaryBasicAuthPage" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./TemporaryBasicAuthPage'),
  pageOptions,
)

export const Financing = Loadable(
  /* webpackChunkName: "Financing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Financing'),
  pageOptions,
)

export const ExperimentalFinancing = Loadable(
  /* webpackChunkName: "ExperimentalFinancing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Financing/ExperimentalFinancing'),
  pageOptions,
)

export const FinancingRedirect = Loadable(
  /* webpackChunkName: "FinancingRedirect" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FinancingRedirect'),
)

export const Agrocalc = Loadable(
  /* webpackChunkName: "Agrocalc" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Agrocalc'),
  pageOptions,
)

export const Agroservices = Loadable(
  /* webpackChunkName: "Agroservices" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Agroservices'),
  pageOptions,
)

export const AgriculturalProducts = Loadable(
  /* webpackChunkName: "AgriculturalProducts" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AgriculturalProducts'),
  pageOptions,
)

export const Feedback = Loadable(
  /* webpackChunkName: "Feedback" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Feedback'),
  pageOptions,
)

export const QuestionsAndAnswers = Loadable(
  /* webpackChunkName: "QuestionsAndAnswers" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./QuestionsAndAnswers'),
  pageOptions,
)

export const FaqSearch = Loadable(
  /* webpackChunkName: "FaqSearch" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FaqSearch'),
  pageOptions,
)

export const Faq = Loadable(
  /* webpackChunkName: "Faq" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Faq'),
  pageOptions,
)

export const Loyalty = Loadable(
  /* webpackChunkName: "Loyalty" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Loyalty'),
)

export const SeedingRateCalc = Loadable(
  /* webpackChunkName: "SeedingRateCalc" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SeedingRateCalc'),
  pageOptions,
)
export const FgisZerno = Loadable(
  /* webpackChunkName: "FgisZerno" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FgisZerno'),
  pageOptions,
)

export const PoleExpert = Loadable(
  /* webpackChunkName: "PoleExpert" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PoleExpert'),
  pageOptions,
)

export const PoleScouting = Loadable(
  /* webpackChunkName: "PoleScouting" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PoleScouting'),
  pageOptions,
)

export const PoleDiff = Loadable(
  /* webpackChunkName: "PoleDiff" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PoleDiff'),
)

export const PolePlus = Loadable(
  /* webpackChunkName: "PolePlus" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PolePlus'),
  pageOptions,
)

export const FarmlandCropValuation = Loadable(
  /* webpackChunkName: "FarmlandCropValuation" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FarmlandCropValuation'),
  pageOptions,
)

export const ExpressCredit = Loadable(
  /* webpackChunkName: "ExpressCredit" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ExpressCredit'),
  pageOptions,
)

export const ExpressCreditAlfaBank = Loadable(
  /* webpackChunkName: "ExpressCreditAlfaBank" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ExpressCreditAlfaBank'),
  pageOptions,
)

export const SberRassrochka = Loadable(
  /* webpackChunkName: "SberRassrochka" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SberRassrochka'),
  pageOptions,
)

export const InvestmentCredit = Loadable(
  /* webpackChunkName: "InvestmentCredit" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./InvestmentCredit'),
  pageOptions,
)

export const InvestSber = Loadable(
  /* webpackChunkName: "InvestSber" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./InvestSber'),
  pageOptions,
)

export const AdvancePaymentOnFutureHarvest = Loadable(
  /* webpackChunkName: "AdvancePaymentOnFutureHarvest" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AdvancePaymentOnFutureHarvest'),
)

export const AdvancePaymentOnFutureHarvestWithCalculator = Loadable(
  /* webpackChunkName: "AdvancePaymentOnFutureHarvestWithCalculator" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AdvancePaymentOnFutureHarvestWithCalculator'),
)

export const AgriculturalMachinesAndEquipmentLeasing = Loadable(
  /* webpackChunkName: "AgriculturalMachinesAndEquipmentLeasing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AgriculturalMachinesAndEquipmentLeasing'),
  pageOptions,
)

export const AlfaLeasing = Loadable(
  /* webpackChunkName: "AlfaLeasing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AlfaLeasing/AlfaLeasing'),
  pageOptions,
)

export const LombardCreditAlfaBank = Loadable(
  /* webpackChunkName: "LombardCreditAlfaBank" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./LombardCreditAlfaBank'),
  pageOptions,
)

export const Interleasing = Loadable(
  /* webpackChunkName: "Interleasing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Interleasing'),
  pageOptions,
)

export const SberLeasing = Loadable(
  /* webpackChunkName: "SberLeasing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SberLeasing'),
  pageOptions,
)

export const BaltiyskiyLeasing = Loadable(
  /* webpackChunkName: "BaltiyskiyLeasing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./BaltiyskiyLeasing'),
  pageOptions,
)

export const StoneXxiLeasing = Loadable(
  /* webpackChunkName: "StoneXxiLeasing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./StoneXxiLeasing'),
  pageOptions,
)

export const Agroconsulting = Loadable(
  /* webpackChunkName: "Agroconsulting" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Agroconsulting'),
  pageOptions,
)

export const Revolving = Loadable(
  /* webpackChunkName: "Revolving" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Revolving'),
  pageOptions,
)

export const OborotSber = Loadable(
  /* webpackChunkName: "OborotSber" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./OborotSber'),
  pageOptions,
)

export const RevolvingTinkoff = Loadable(
  /* webpackChunkName: "RevolvingTinkoff" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./RevolvingTinkoff'),
  pageOptions,
)

export const MFOPage = Loadable(
  /* webpackChunkName: "MFOPage" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./MFOPage'),
  pageOptions,
)

export const TransportAndSpecialEquipmentLeasing = Loadable(
  /* webpackChunkName: "TransportAndSpecialEquipmentLeasing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./TransportAndSpecialEquipmentLeasing'),
  pageOptions,
)

export const FleetFinanceLeasing = Loadable(
  /* webpackChunkName: "FleetFinanceLeasing" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FleetFinanceLeasing'),
  pageOptions,
)

export const InfoportalAdministrationPage = Loadable(
  /* webpackChunkName: "InfoportalAdministrationPage" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./InfoportalAdministrationPage'),
  pageOptions,
)

export const Requisites = Loadable(
  /* webpackChunkName: "Requisites" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Requisites'),
  pageOptions,
)

export const Journal = Loadable(
  /* webpackChunkName: "Journal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Journal/Journal'),
  pageOptions,
)

export const JournalNews = Loadable(
  /* webpackChunkName: "JournalNews" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./JournalNews'),
  pageOptions,
)

export const JournalRedirectToLatestNews = Loadable(
  /* webpackChunkName: "JournalRedirectToLatestNews" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./JournalRedirectToLatestNews'),
  pageOptions,
)

export const JournalPublication = Loadable(
  /* webpackChunkName: "JournalPublication" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./JournalPublication'),
  pageOptions,
)

export const LegalDocumentsPage = Loadable(
  /* webpackChunkName: "LegalDocumentsPage" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./LegalDocumentsPage'),
  pageOptions,
)

export const PartnerLanding = Loadable(
  /* webpackChunkName: "PartnerLanding" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PartnerLanding'),
  pageOptions,
)

export const PartnerLandingRedirect = Loadable(
  /* webpackChunkName: "PartnerLandingRedirect" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PartnerLandingRedirect'),
  pageOptions,
)

export const MultiCart = Loadable(
  /* webpackChunkName: "MultiCart" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./MultiCart'),
  pageOptions,
)

export const BusinessProcess = Loadable(
  /* webpackChunkName: "BusinessProcess" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./BusinessProcess'),
)

export const Pooling = Loadable(
  /* webpackChunkName: "Pooling" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Pooling'),
)

export const SberAuthRedirect = Loadable(
  /* webpackChunkName: "SberAuthRedirect" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SberAuthRedirect'),
)

export const AboutUs = Loadable(
  /* webpackChunkName: "AboutUs" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AboutUs'),
)

export const YouDo = Loadable(
  /* webpackChunkName: "YouDo" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./YouDo'),
)

export const CenterInvestLoan = Loadable(
  /* webpackChunkName: "CenterInvestLoan" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./CenterInvestLoan'),
)

export const EfisZsn = Loadable(
  /* webpackChunkName: "EfisZsn" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./EfisZsn'),
)

export const TenderPublic = Loadable(
  /* webpackChunkName: "TenderPublic" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./TenderPublic'),
)

export const TenderPublicOfferForm = Loadable(
  /* webpackChunkName: "TenderPublic" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./TenderPublicOfferForm'),
)

export const TenderComparativeTable = Loadable(
  /* webpackChunkName: "TenderPublic" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./TenderComparativeTable'),
)
