import { useCallback } from 'react'
import { requestDataWithRawResponse } from '@/api/request'
import { downloadFileFromResponse } from '@/utils/files'

export function useContractTemplateDownload(contractId: Maybe<number>, name?: string) {
  const download = useCallback(async () => {
    // todo: это урлы из `api.getFertilizerOfferExampleContent()` и `api.getFertilizerOfferExampleMockContent()`.
    //  сгенерированный api-клиент не поддерживает blob/binary ответы
    //  и принудительно выполняет `response.text()` (если в ответе не json),
    //  тем самым ломая возвращаемый pdf-файл
    // prettier-ignore
    const url = contractId
      ? `/api/orders/sign/content/example/${contractId}`
      : '/api/orders/sign/content/example'

    const res = await requestDataWithRawResponse({ url, method: 'GET' })
    await downloadFileFromResponse(res, `${name || 'doc'}.pdf`)
  }, [contractId, name])

  return {
    download,
  }
}
