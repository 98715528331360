import { createAction } from '@reduxjs/toolkit'
import { EOrderRequestName } from '@/logic/order/constants'
import {
  IAddImpersonalProductToExistingOrderPayload,
  IAddProductToExistingOrderPayload,
  IChangeOrderPayload,
  IChangeOrderStatusPayload,
  IDeleteProductFromOrderPayload,
  IFetchAgrarianOrderPayload,
  IMakeMultiOrderPayload,
  IMakeOrderPayload,
  IRecalculateAgrarianOrderPayload,
  IRejectOrderPayload,
  ISetAgrarianOrderPayload,
} from '@/logic/order/interfaces'
import { setInitialStatus } from '@/logic/requestStatus/actions'

export const makeOrder = createAction<IMakeOrderPayload>('@@order/MAKE_ORDER')
export const makeMultiOrder = createAction<IMakeMultiOrderPayload>('@@order/MAKE_MULTI_ORDER')
export const addProductToExistingOrder = createAction<IAddProductToExistingOrderPayload>('@@order/ADD_PRODUCT_TO_EXISTING_ORDER')
export const addImpersonalProductToExistedOrder = createAction<IAddImpersonalProductToExistingOrderPayload>(
  '@@order/ADD_IMPERSONAL_PRODUCT_TO_EXISTING_ORDER',
)
export const deleteProductFromOrder = createAction<IDeleteProductFromOrderPayload>('@@order/DELETE_PRODUCT_FROM_ORDER')
export const clearMakingOrderRequestErrors = () => setInitialStatus({ name: EOrderRequestName.MAKE_ORDER })
export const recalculateAgrarianOrder = createAction<IRecalculateAgrarianOrderPayload>('@@order/RECALCULATE_AGRARIAN_ORDER')
export const fetchAgrarianOrder = createAction<IFetchAgrarianOrderPayload>('@@order/FETCH_AGRARIAN_ORDER')
export const setAgrarianOrder = createAction<ISetAgrarianOrderPayload>('@@order/SET_AGRARIAN_ORDER')
export const setEditorModeState = createAction<boolean>('@@order/SET_EDITOR_MODE_STATE')
export const clearOrderState = createAction('@@order/CLEAR_ORDER_STATE')
export const changeOrderStatus = createAction<IChangeOrderStatusPayload>('@@order/CHANGE_ORDER_STATUS')
export const changeOrder = createAction<IChangeOrderPayload>('@@order/CHANGE_ORDER')
export const rejectOrder = createAction<IRejectOrderPayload>('@@order/REJECT_ORDER')
