import React, { memo, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { generateMenu } from '@/components/composed/MobileNavBar/helpers'
import Overlay from '@/components/composed/Overlay'
import Portal from '@/components/composed/Portal'
import { InjectHtml } from '@/components/ui'
import { isAuthenticated as isUserAuthenticated, isUserBuyer, isUserLogisticsOperator, isUserSupplier } from '@/logic/auth/reducer'
import { getNotificationsValues } from '@/logic/notifications/reducer'
import { injectMessages } from '@/logic/translation/utils'
import { RootState } from '@/redux/interfaces'
import AdditionalSalesMenu from './components/AdditionalSalesMenu'
import { IMobileNavBarProps } from './interfaces'
import messages from './messages.json'
import './MobileNavBar.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  isBuyer: isUserBuyer()(state),
  isAuthenticated: isUserAuthenticated()(state),
  isSupplier: isUserSupplier()(state),
  isLogisticsOperator: isUserLogisticsOperator()(state),
  notificationsCount: getNotificationsValues()(state),
})

const MobileNavBar: React.FC<IMobileNavBarProps> = props => {
  const { className } = props
  const { isAuthenticated, isBuyer, isSupplier, isLogisticsOperator, notificationsCount } = useSelector(mapState)
  const [isAdditionalMenuOpen, setIstAdditionalMenuOpen] = useState(false)

  const linkList = useMemo(() => {
    return generateMenu({ isAuthenticated, isBuyer, isSupplier, isLogisticsOperator, notificationsCount })
  }, [notificationsCount, isAuthenticated, isBuyer, isSupplier, isLogisticsOperator])

  if (linkList.length === 0) return null

  const onOpenAdditionalMenu = () => setIstAdditionalMenuOpen(true)
  const onCloseAdditionalMenu = () => setIstAdditionalMenuOpen(false)

  return (
    <div className={classNames(className, 'mobileNavBar')}>
      {linkList.map(({ textId, icon, url, additional, counter }, idx) => (
        <CommonLink
          key={`${idx}_${textId}`}
          className="mobileNavBarLink"
          activeClassName="mobileNavBarLink--active"
          url={url}
          onClick={() => additional && onOpenAdditionalMenu()}
        >
          <Icon name={icon} />
          <InjectHtml className="text_super-small space-holder-top2 noWrap" textId={`MobileNavBar.link.${textId}.text`} />
          {Boolean(counter) && <div className="mobileNavBarLink__counter text_label">{counter}</div>}
        </CommonLink>
      ))}

      {isAdditionalMenuOpen && (
        <Portal>
          <Overlay>
            <AdditionalSalesMenu onClose={onCloseAdditionalMenu} />
          </Overlay>
        </Portal>
      )}
    </div>
  )
}

export default memo(MobileNavBar)
