import { generatePath } from 'react-router-dom'
import { createAction } from '@reduxjs/toolkit'
import { IResponse } from '@/api/interfaces'
import { requestFailed, requestFinished, requestStarted } from '@/logic/requestStatus/actions'
import { EPoolingLandingRequestNamePart } from './enums'
import { IMakePoolingLandingOrderPayload, IPoolingLandingResponse } from './interfaces'

export const poolingLandingRequestFinished = (data: IPoolingLandingResponse) =>
  requestFinished({
    name: generatePath(EPoolingLandingRequestNamePart.FETCH, { id: data.identifier }),
    data,
  })
export const poolingLandingRequestFailed = ({ meta }: IResponse<IPoolingLandingResponse>, identifier: string) =>
  requestFailed({ name: generatePath(EPoolingLandingRequestNamePart.FETCH, { id: identifier }), meta })
export const poolingLandingRequestStarted = (identifier: string) =>
  requestStarted({ name: generatePath(EPoolingLandingRequestNamePart.FETCH, { id: identifier }) })

export const saveOrderValues = createAction<IMakePoolingLandingOrderPayload | null>('@@pooling/SAVE_ORDER_VALUES')
