/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Метаинформация об ответе
 */
export type Meta = {
    /**
     * Статус результата вызова метода
     */
    status?: Meta.status;
    /**
     * Сообщение
     */
    message?: string;
    /**
     * Локализованное сообщение
     */
    description?: string;
};

export namespace Meta {

    /**
     * Статус результата вызова метода
     */
    export enum status {
        OK = 'OK',
        ERROR = 'ERROR',
    }


}

