import { useEffect, useState } from 'react'
import { IApplyingFinancingOfferProps } from '@/logic/financing/interfaces'
import { MicrocreditOrganizationsService } from '@/services/FinancingService'
import { EFinancingProductId } from '@/types'

const microcreditOrganizationsService = new MicrocreditOrganizationsService()

export const useRegionsMicrocreditOrganizations = (props: IApplyingFinancingOfferProps) => {
  const { productId } = props
  const [regionsAvailableExpressMFO, setRegionsAvailableExpressMFO] = useState<number[]>([])
  const isMFOProduct = productId === EFinancingProductId.EXPRESS_MFO

  useEffect(() => {
    if (isMFOProduct) {
      microcreditOrganizationsService.getRegionsForMicrocreditOrganization(EFinancingProductId.EXPRESS_MFO).then(regions => {
        setRegionsAvailableExpressMFO(regions)
      })
    }
  }, [isMFOProduct])

  return { regionsAvailableExpressMFO, isMFOProduct }
}
