import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { ILabeledDetailProps } from './interfaces'

const LabeledDetail: React.FC<ILabeledDetailProps> = props => {
  const { titleId, value, className, valueClass, labelClass = 'color_pale-black', children, animation } = props

  return (
    <div className={className} {...animation}>
      <p className={classNames('text_small space-holder8 space-holder4-sm', labelClass)}>
        <FormattedMessage id={titleId} />
      </p>
      <h5 className={classNames('space-holder2-sm', valueClass)}>{value}</h5>
      {children}
    </div>
  )
}

export default LabeledDetail
