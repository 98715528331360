import get from 'lodash/get'
import { combineEpics } from 'redux-observable'
import { concat, from, of, throwError, timer } from 'rxjs'
import { catchError, delayWhen, filter, mergeMap, switchMap } from 'rxjs/operators'
import { isStatusOk } from '@/api/helpers'
import { getLocation, getLocationQuery } from '@/logic/app/reducer'
import { Epic } from '@/logic/interfaces'
import { OrderHistoryService, SaleRequestHistoryService } from '@/services/history'
import SaleMultiRequestHistoryService from '@/services/history/SaleMultiRequestHistoryService'
import { EHistoryEntityType } from '@/types/History'
import { fetchHistory, onHistoryRequestFailed, onHistoryRequestFinished, reloadHistory } from './actions'
import { IFetchHistoryPayload } from './interfaces'

const HistoryService = {
  [EHistoryEntityType.ORDERS]: new OrderHistoryService(),
  [EHistoryEntityType.REQUESTS]: new SaleRequestHistoryService(),
  [EHistoryEntityType.MULTIREQUEST]: new SaleMultiRequestHistoryService(),
}

const onFetchHistoryDoMakeRequest: Epic<IFetchHistoryPayload, typeof fetchHistory> = action$ =>
  action$.pipe(
    filter(fetchHistory.match),
    mergeMap(({ payload }) => {
      const { entityId, entity } = payload

      return concat(
        from(HistoryService[entity].getHistory(entityId)).pipe(
          mergeMap(response => {
            if (isStatusOk(response)) {
              return of(onHistoryRequestFinished({ history: response.data }))
            }

            return throwError(response)
          }),
          catchError(response => of(onHistoryRequestFailed(get(response, 'meta.message')))),
        ),
      )
    }),
  )

const onReloadHistoryDoSetReloadingByInterval: Epic<IFetchHistoryPayload, typeof reloadHistory> = (action$, state$) =>
  action$.pipe(
    filter(reloadHistory.match),
    delayWhen(() => timer(10000)),
    filter(({ payload }) => {
      const { value: state } = state$

      return (getLocation()(state$.value).pathname || '').includes(String(payload.entityId)) && getLocationQuery(state)?.tab === 'history'
    }),
    switchMap(({ payload }) => [fetchHistory({ ...payload, shouldNotShowLoadingState: true }), reloadHistory(payload)]),
  )

export default (<any>combineEpics)(onFetchHistoryDoMakeRequest, onReloadHistoryDoSetReloadingByInterval)
