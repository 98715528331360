import { useCallback } from 'react'
import { useLatestValue } from './useLatestValue'

export function useEvent<Fn extends (...args: any[]) => any, Args extends any[] = Parameters<Fn>, RetVal = ReturnType<Fn>>(
  cb: (...args: Args) => RetVal,
): (...args: Args) => RetVal {
  const cache = useLatestValue(cb)

  return useCallback((...args: Args) => cache.current(...args), [cache])
}
