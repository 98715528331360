import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetCategoryFiltersQueryResponse } from "../../types/GetCategoryFilters";

/**
     * @summary Получение фильтров по категории
     * @link /api/catalog/categories/filters
     */
export async function getCategoryFilters (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetCategoryFiltersQueryResponse>["data"]> {
    const { data: resData } = await client<GetCategoryFiltersQueryResponse>({
        method: "get",
        url: `/api/catalog/categories/filters`,
        ...options
    });
    
    return resData;
};