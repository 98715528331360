import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UploadDocument1MutationRequest, UploadDocument1MutationResponse } from "../types/UploadDocument1";

/**
 * @link /api/document/v1/upload_documents-for-registration-partner
 */

export function useUploadDocument1 <TData = UploadDocument1MutationResponse, TError = unknown, TVariables = UploadDocument1MutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/document/v1/upload_documents-for-registration-partner`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
