import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { AuthButton, BubblingTooltip, Button, GlassMorphIcon, InjectHtml } from '@/components/ui'
import { isAuthenticated as isUserAuthenticated, isUserAgrarian } from '@/logic/auth/reducer'
import { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { IStepsProps, IStepSubmitButtonProps } from './interfaces'
import './styles.scss'

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  isAuthenticated: isUserAuthenticated()(state),
})

const StepSubmitButton: React.FC<IStepSubmitButtonProps> = ({ buttonTooltipMessage, shouldDisableButton, ...props }) => {
  const submitButton = <Button {...props} isDisabled={Boolean(buttonTooltipMessage) && shouldDisableButton} />

  if (buttonTooltipMessage && shouldDisableButton)
    return (
      <BubblingTooltip content={buttonTooltipMessage}>
        <span className="ui-step-submit-button-wrapper">{submitButton}</span>
      </BubblingTooltip>
    )

  return submitButton
}

const Steps: React.FC<IStepsProps> = props => {
  const {
    isButtonPositionEnd,
    steps,
    onButtonClick,
    withSign,
    title,
    signTextId,
    blockId,
    backgroundColor = 'white',
    buttonText,
    buttonTextId,
    sectionId,
    buttonTooltipMessage,
    gridColsNumber,
    submitButtonClassName,
    isAuthButton = false,
    isVerticalMobile,
    children,
  } = props
  const { isAgrarian, isAuthenticated } = useSelector(mapState)

  const { isMobile } = useDeviceType()

  const { formatMessage } = useIntl()
  const shouldDisableButton = isAuthenticated && !isAgrarian
  const hasButtonText = buttonTextId || buttonText
  const shouldShownButton = (!isMobile && isVerticalMobile && hasButtonText) || (hasButtonText && !isVerticalMobile)

  if (!steps) return null

  const gridColsClassName = gridColsNumber ? `grid-col-${gridColsNumber}` : `grid-col-${steps.length !== 5 ? '4' : '3'}`

  return (
    <section className={`ui-steps-container background_${backgroundColor}`} id={sectionId}>
      <div className="page-wrapper" id={blockId}>
        <h2 className="ui-steps__title space-holder24 space-holder32-from-md">{title}</h2>

        {children}

        <ul
          className={
            isMobile && isVerticalMobile
              ? `ui-steps-vertival-mobile ui-steps_${gridColsClassName}`
              : `ui-steps ui-steps_${gridColsClassName}`
          }
        >
          {steps.map((step, index) => {
            const stepItemTextId = typeof step === 'string' ? step : step.title
            const stepItemDescriptionId = typeof step === 'string' ? null : step.description
            const stepNumber = index + 1
            const isLastStep = stepNumber === steps.length

            return (
              <li
                className={classNames('ui-step', `ui-step_${gridColsClassName}`, {
                  'ui-step_last': isLastStep,
                })}
                key={stepNumber}
              >
                <span className={isMobile && isVerticalMobile ? 'ui-step-stroke-vertical-mobile' : 'ui-step-stroke'} />
                <h5
                  className={`ui-step__step-label background_${backgroundColor === 'white' ? 'light-gray' : 'white'}`}
                >{`0${stepNumber}`}</h5>
                <InjectHtml
                  textId={stepItemTextId}
                  className={isMobile && isVerticalMobile ? 'ui-step__title-vertical-mobile' : 'ui-step__title'}
                  TagName={isMobile && !isVerticalMobile ? 'p' : 'h4'}
                />
                {stepItemDescriptionId && (
                  <p className={`${isMobile && isVerticalMobile ? 'ui-step__description-vertical-mobile' : 'ui-step__title'}`}>
                    {formatMessage({ id: stepItemDescriptionId })}
                  </p>
                )}
                {stepNumber === 1 && isMobile && isAuthButton && isVerticalMobile && (
                  <div className="auth-button button_small space-holder24-sm button">
                    <AuthButton
                      buttonProps={{
                        isUnstyled: false,
                        className: submitButtonClassName,
                        textId: buttonTextId,
                        modifiers: ['green'],
                      }}
                      shouldHideForAuthenticatedUser
                    >
                      {buttonText}
                    </AuthButton>
                  </div>
                )}
              </li>
            )
          })}
          {shouldShownButton && (
            <li
              className={classNames('ui-step__button', `ui-step_${gridColsClassName}`, {
                'ui-step_button-position-end': isButtonPositionEnd,
              })}
            >
              {isAuthButton && onButtonClick === undefined ? (
                <AuthButton
                  buttonProps={{
                    isUnstyled: false,
                    className: submitButtonClassName,
                    textId: buttonTextId,
                    modifiers: ['green'],
                  }}
                  shouldHideForAuthenticatedUser
                >
                  {buttonText}
                </AuthButton>
              ) : (
                <StepSubmitButton
                  shouldDisableButton={shouldDisableButton}
                  buttonTooltipMessage={buttonTooltipMessage}
                  modifiers={['green']}
                  textId={buttonTextId}
                  onClick={onButtonClick}
                >
                  {buttonText}
                </StepSubmitButton>
              )}
            </li>
          )}
        </ul>

        {withSign && (
          <div className="ui-steps-sign space-holder-top64">
            <GlassMorphIcon image="black/flag" className="shrink_no" />
            <h5 className="ui-steps-sign__content">{formatMessage({ id: signTextId })}</h5>
          </div>
        )}
      </div>
    </section>
  )
}

export default Steps
