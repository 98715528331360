import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useData } from '@/logic/data'
import { loadData } from '@/logic/data/actions'
import { FaqContext } from './constants'

export const useGetFaqEntity = <T>(name: 'faqCategory' | 'faqArticle', entityIdName: 'categoryId' | 'questionId') => {
  const { params } = useContext(FaqContext)
  const dispatch = useDispatch()
  const id = params[entityIdName]
  useEffect(() => {
    if (id) dispatch(loadData({ name, params, shouldLoadForcibly: true }))
    //eslint-disable-next-line
  }, [id])

  return useData<T>(name, { shouldNotLoadDataOnMount: true })
}
