import React, { FC, memo } from 'react'
import { EIconName, EIconSize, IIconProps } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { TextWithIcon } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import { IProductPostpaidNoticeProps } from './interfaces'
import messages from './messages.json'
import './ProductPostpaidNotice.scss'

injectMessages(messages)

const blackInfoIcon: IIconProps = { name: EIconName.InfoFill, size: EIconSize.XS }

const ProductPostpaidNotice: FC<IProductPostpaidNoticeProps> = props => {
  const { isProductSupportingPostpaid, className, customNoticeId = 'productPostpaidNotice.notice' } = props

  if (!isProductSupportingPostpaid) return null

  return (
    <TextWithIcon
      iconProps={blackInfoIcon}
      textId={customNoticeId}
      className={classNames(className, 'row-align_center color_pale-black')}
      textClassName="text_wrap"
      iconClassName="product-postpaid-notice-icon"
    />
  )
}

export default memo(ProductPostpaidNotice)
