import { useMemo } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { isNonNullable } from '@/typeguards/common'
import { formatLocalizedString } from '@/utils/formatUtils'
import { NUMBER_FLOAT_PRECISION_DEFAULT, PRICE_MIN_VALUE, PRICE_PRECISION_DEFAULT } from './constants'
import { messages } from './messages'
import { ITextInputProps } from './types'
import { composeRules, getRule, isNumberInput, isPriceInput } from './utils'
import {
  isDigits,
  isEmail,
  isIntegerNumber,
  isNumber,
  isNumberGreaterThen,
  isNumberLessThen,
  isPhoneNumber,
  isValidFloatPrecision,
  isValidNumber,
} from './validators'

const emptyValidator = () => true

export function useTextInputRules<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: ITextInputProps<TFieldValues, TName>) {
  const { type, minValue, maxValue, greaterThenValue, lessThenValue, digitsAfterDot, rules } = props

  const validateByInputType = useMemo(() => {
    if (!isNumberInput(type) && !isPriceInput(type)) {
      switch (type) {
        case 'digits':
          return getRule(isDigits, messages.shouldBeInDigitsOnlyNumberFormat)
        case 'tel':
          return getRule(isPhoneNumber, messages.shouldBeInPhoneNumberFormat)
        case 'email':
          return getRule(isEmail, messages.shouldBeInEmailFormat)
        default:
          return emptyValidator
      }
    } else {
      const min = Math.max(minValue ?? -Infinity, isPriceInput(type) ? PRICE_MIN_VALUE : -Infinity)
      const max = maxValue ?? Infinity

      const numberRules = [
        getRule(isNumber, messages.shouldBeInNumberFormat),
        getRule(isValidNumber, messages.shouldBeInValidNumberFormat),
        getRule(isNumberGreaterThen(Number.MIN_SAFE_INTEGER, true), messages.shouldBeInValidNumberFormat),
        getRule(isNumberLessThen(Number.MAX_SAFE_INTEGER, true), messages.shouldBeInValidNumberFormat),
        Number.isFinite(min) && getRule(isNumberGreaterThen(min, true), formatLocalizedString(messages.shouldBeMinOrEqual, { min })),
        Number.isFinite(max) && getRule(isNumberLessThen(max, true), formatLocalizedString(messages.shouldBeMaxOrEqual, { max })),
        isNonNullable(greaterThenValue) &&
          getRule(isNumberGreaterThen(greaterThenValue), formatLocalizedString(messages.shouldBeGreaterThen, { min: greaterThenValue })),
        isNonNullable(lessThenValue) &&
          getRule(isNumberLessThen(lessThenValue), formatLocalizedString(messages.shouldBeLessThen, { max: lessThenValue })),
      ]

      const precision = (() => {
        const initialPrecision = isNonNullable(digitsAfterDot) && digitsAfterDot >= 0 ? digitsAfterDot : null

        switch (type) {
          case 'num':
            return initialPrecision
          case 'numberFloat':
            return initialPrecision ?? NUMBER_FLOAT_PRECISION_DEFAULT
          case 'price':
            return initialPrecision
          case 'floatPrice':
            return initialPrecision ?? PRICE_PRECISION_DEFAULT
          default: {
            // eslint-disable-next-line unused-imports/no-unused-vars
            const exhaustive: never = type

            return null
          }
        }
      })()

      if (!precision) {
        return composeRules(...numberRules, getRule(isIntegerNumber, messages.shouldBeInIntegerNumberFormat))
      }

      return composeRules(
        ...numberRules,
        getRule(
          isValidFloatPrecision(precision),
          formatLocalizedString(messages.shouldBeInValidFloatNumberFormat, { digitsAfterDot: precision }),
        ),
      )
    }
  }, [type, minValue, maxValue, greaterThenValue, lessThenValue, digitsAfterDot])

  const { validate } = rules ?? {}
  const alreadyDefinedValidateRules = typeof validate === 'function' ? { validate } : validate

  return {
    ...rules,
    validate: {
      validateByInputType,
      ...alreadyDefinedValidateRules,
    },
  }
}
