import React, { FC } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import format from 'date-fns/format'
import { Image } from '@/components/ui'
import { useFormatPublicationDate } from '@/utils/hooks/format/useFormatPublicationDate'
import { getJournalPublicationUrl } from '@/utils/infoportal/helpers'
import { IWideArticlePreviewProps } from './interfaces'
import './styles.scss'

export const WideArticlePreview: FC<IWideArticlePreviewProps> = ({ className, article, isImageInStatic }) => {
  const { coverImage, title, subtitle, showCover: shouldShowCover, slug, publicationDate, type } = article
  const { formatPublicationDate } = useFormatPublicationDate(type)

  return (
    <article className={classNames(className, 'wide-article-preview')}>
      <CommonLink className="wide-article-preview-link" url={getJournalPublicationUrl(slug)}>
        {shouldShowCover && (
          <Image
            src={coverImage?.squareCover}
            isBackground
            isInStatic={isImageInStatic}
            className="wide-article-preview__image space-holder24-sm"
          />
        )}
        <div className="wide-article-preview-content row row_column row-justify_between-from-sm">
          <div className="space-holder16-sm">
            <h4 className="space-holder16">{title}</h4>
            <p>{subtitle}</p>
          </div>

          {publicationDate && (
            <div>
              <p className="text_super-small color_pale-black">
                <time itemProp="publishedDate" dateTime={format(new Date(publicationDate), 'yyyy-MM-dd')}>
                  {formatPublicationDate(publicationDate)}
                </time>
              </p>
            </div>
          )}
        </div>
      </CommonLink>
    </article>
  )
}
