import React, { FC } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { useData } from '@/logic/data'
import { getLegalDocumentUrl } from '@/logic/legalDocuments/helpers'
import { ELegalDocumentType, TLegalDocuments } from '@/types/legalDocuments'
import { formatLocalizedString } from '@/utils/formatUtils'
import { IPolicyAgreementProps } from './types'
import './styles.scss'

const PolicyAgreement: FC<IPolicyAgreementProps> = props => {
  const { className, linkToAgreement = {}, textClassName, text, icon } = props

  const { data: documents = [] } = useData<TLegalDocuments>('legalDocuments')

  return (
    <div className={classNames('policyAgreement', className)}>
      {icon && <Icon className={icon.className} name={icon.name} style={icon.style} />}
      <div className={textClassName}>
        {formatLocalizedString(text, {
          LinkToAgreement: chunks => (
            <CommonLink
              className={classNames('policyAgreement__link', linkToAgreement.className)}
              url={linkToAgreement.url ?? getLegalDocumentUrl(documents, ELegalDocumentType.registrationRules)}
              shouldOpenInNewWindow
            >
              {chunks}
            </CommonLink>
          ),
        })}
      </div>
    </div>
  )
}

export default PolicyAgreement
