import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import get from 'lodash/get'
import { CheckboxesSearchInput } from '@/components/composed/CheckboxesFilter/components/index'
import { ICheckboxesFilterSearchableProps, IFilterCheckbox, IFilterCheckboxMapped } from '@/components/composed/CheckboxesFilter/interfaces'
import { InjectHtml } from '@/components/ui'
import { Checkbox } from '@/components/ui/fields'
import { useDeviceType } from '@/utils/hooks/useDeviceType'

const CheckboxesFilterSearchable: React.FC<ICheckboxesFilterSearchableProps> = props => {
  const { checkboxes, checkboxProps, controlStyle, onChange, isPopupOpened, clearExpanded, isExpanded } = props

  const intl = useIntl()
  const { values } = useFormState()
  const { isDesktop } = useDeviceType()
  const [searchQuery, setSearchQuery] = useState('')
  const [checked, setChecked] = useState<IFilterCheckbox[]>([])
  const [unchecked, setUnchecked] = useState<IFilterCheckboxMapped[]>([])
  const [wasExpanded, setExpanded] = useState(false)

  useEffect(() => {
    if (isExpanded === wasExpanded) return
    setExpanded(Boolean(isExpanded))
    if (!isExpanded) return

    const rawChecked = checkboxes.filter(checkbox => get(values, checkbox.name))
    const map = new Map<string, IFilterCheckbox[]>()

    checkboxes.forEach(checkbox => {
      const char = checkbox.label?.substr(0, 1).toUpperCase() || ''

      if (map.has(char)) map.get(char)!.push(checkbox)
      else map.set(char, [checkbox])
    })

    const mappedUnchecked = Array.from(map).map(([key, value]) => {
      let isGroupVisible = false
      const elements = value.map(checkbox => {
        const isVisible = !get(values, checkbox.name)
        if (isVisible) isGroupVisible = true

        return { checkbox, isVisible }
      })

      return { key, elements, isVisible: isGroupVisible }
    })

    setChecked(rawChecked)
    setUnchecked(mappedUnchecked)
  }, [isExpanded, wasExpanded, checkboxes, values])

  const filteredCheckboxes = useMemo(
    () =>
      searchQuery !== '' ? checkboxes.filter(checkbox => checkbox.label?.toLowerCase().includes(searchQuery.toLowerCase())) : checkboxes,
    [searchQuery, checkboxes],
  )

  const onChangeCommon = (event: ChangeEvent) => {
    onChange?.(event)
    if (!isPopupOpened) {
      setSearchQuery(() => '')
      clearExpanded?.()
    }
  }

  const onChangeCheckedGroup = (event: ChangeEvent) => {
    if (isDesktop) onChangeCommon?.(event)
    else {
      const id = event.target.id
      const newUnchecked = [...unchecked]

      onChange?.(event)
      setChecked(checked.filter(checkbox => id !== checkbox.id))
      newUnchecked.some(group =>
        group.elements.some(groupElement => {
          if (groupElement.checkbox.id !== id) return false
          group.isVisible = true
          groupElement.isVisible = true

          return true
        }),
      )
      setUnchecked(newUnchecked)
    }
  }

  const OnChangeUncheckedGroup = (event: ChangeEvent) => {
    if (isDesktop) onChangeCommon?.(event)
    else onChange?.(event)
  }

  return (
    <>
      <CheckboxesSearchInput value={searchQuery} onChange={setSearchQuery} placeholder={intl.formatMessage({ id: 'global.search' })} />

      <div
        className={classNames('checkboxes-filter-search-char-container', {
          'checkboxes-filter-search-char-container_in-modal': isPopupOpened,
        })}
      >
        {searchQuery === '' && (
          <>
            {checked.length !== 0 && (
              <>
                <InjectHtml
                  textId="filter.search.selected"
                  TagName="div"
                  className="checkboxes-filter-search-char-label text_small color_pale-black"
                />

                {checked.map(checkbox => (
                  <Checkbox
                    key={`checked-${checkbox.id}`}
                    onChange={onChangeCheckedGroup}
                    {...checkbox}
                    {...checkboxProps}
                    controlStyle={controlStyle}
                  />
                ))}
              </>
            )}

            {unchecked
              .filter(el => el.isVisible)
              .map(({ key, elements }) => (
                <React.Fragment key={`uncheckedGroup-${key}`}>
                  <div className="checkboxes-filter-search-char-label text_small color_pale-black">{key}</div>

                  {elements
                    .filter(element => element.isVisible)
                    .map(({ checkbox }) => (
                      <Checkbox
                        key={`unchecked-${checkbox.id}`}
                        onChange={OnChangeUncheckedGroup}
                        {...checkbox}
                        {...checkboxProps}
                        controlStyle={controlStyle}
                      />
                    ))}
                </React.Fragment>
              ))}
          </>
        )}
        {searchQuery !== '' && (
          <>
            {filteredCheckboxes.map(checkbox => (
              <Checkbox
                key={`filteredCheckboxes-${checkbox.id}`}
                onChange={onChangeCommon}
                {...checkbox}
                {...checkboxProps}
                controlStyle={controlStyle}
              />
            ))}

            {filteredCheckboxes.length === 0 && (
              <InjectHtml textId="filter.search.noItems" TagName="div" className="checkboxes-filter-search-no-results" />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default memo(CheckboxesFilterSearchable)
