import {
  getClientPublications,
  getClientPublicationsQueryKey,
  GetClientPublicationsQueryParamsType,
  getMainPublication,
  getMainPublicationQueryKey,
  getPublicationById,
  getPublicationByIdQueryKey,
  getRubrics,
  getRubricsQueryKey,
  getTags,
  getTagsQueryKey,
} from '@/api/kubik/infoportal'
import { TSsrPrefetchQueries } from '@/interfaces'

export const journalPublicationSsrQueries: TSsrPrefetchQueries = (params: Record<'publicationSlug', string | undefined>) => {
  return [
    { queryFn: () => getRubrics({ showInMenu: true }), queryKey: getRubricsQueryKey({ showInMenu: true }) },
    {
      queryFn: () => getClientPublications({ type: GetClientPublicationsQueryParamsType.NEWS, includeMain: true }),
      queryKey: getClientPublicationsQueryKey({ type: GetClientPublicationsQueryParamsType.NEWS, includeMain: true }),
    },
    {
      queryFn: () => getPublicationById(params.publicationSlug ?? ''),
      queryKey: getPublicationByIdQueryKey(params.publicationSlug ?? ''),
    },
  ]
}
//
export const journalByRubricSsrQueries: TSsrPrefetchQueries = (params: Record<'rubricId', string | undefined>) => [
  { queryFn: () => getRubrics({ showInMenu: true }), queryKey: getRubricsQueryKey({ showInMenu: true }) },
  {
    infiniteQueryFn: () =>
      getClientPublications({
        type: GetClientPublicationsQueryParamsType.ARTICLE,
        limit: 13,
        rubricId: params?.rubricId ? Number(params?.rubricId) : undefined,
      }),
    queryKey: [
      ...getClientPublicationsQueryKey({
        rubricId: params?.rubricId ? Number(params?.rubricId) : undefined,
        tagId: undefined,
        type: GetClientPublicationsQueryParamsType.ARTICLE,
      }),
      'infiniteArticles',
      'simpleArticles',
    ],
  },
  {
    queryFn: () =>
      getClientPublications({
        type: GetClientPublicationsQueryParamsType.NEWS,
        limit: 5,
        includeMain: true,
        rubricId: params?.rubricId ? Number(params?.rubricId) : undefined,
      }),
    queryKey: getClientPublicationsQueryKey({
      type: GetClientPublicationsQueryParamsType.NEWS,
      limit: 5,
      includeMain: true,
      rubricId: params?.rubricId ? Number(params?.rubricId) : undefined,
    }),
  },
]

export const journalByTagSsrQueries: TSsrPrefetchQueries = (params: Record<'tagId', string | undefined>) => [
  {
    queryFn: () =>
      getClientPublications({
        type: GetClientPublicationsQueryParamsType.NEWS,
        limit: 4,
        tagId: params.tagId ? Number(params?.tagId) : undefined,
      }),
    queryKey: getClientPublicationsQueryKey({
      type: GetClientPublicationsQueryParamsType.NEWS,
      limit: 4,
      tagId: params.tagId ? Number(params?.tagId) : undefined,
    }),
  },
  {
    queryFn: () =>
      getClientPublications({
        type: GetClientPublicationsQueryParamsType.ARTICLE,
        limit: 4,
        tagId: params.tagId ? Number(params?.tagId) : undefined,
      }),
    queryKey: getClientPublicationsQueryKey({
      type: GetClientPublicationsQueryParamsType.ARTICLE,
      limit: 4,
      tagId: params.tagId ? Number(params?.tagId) : undefined,
    }),
  },
  { queryFn: () => getRubrics({ showInMenu: true }), queryKey: getRubricsQueryKey({ showInMenu: true }) },
]

export const journalNewsSsrQueries: TSsrPrefetchQueries = (params: Record<'rubricId', string | undefined>) => [
  { queryFn: () => getRubrics({ showInMenu: true }), queryKey: getRubricsQueryKey({ showInMenu: true }) },
  {
    infiniteQueryFn: () =>
      getClientPublications({
        type: GetClientPublicationsQueryParamsType.NEWS,
        limit: 13,
        rubricId: params?.rubricId ? Number(params?.rubricId) : undefined,
      }),
    queryKey: [
      ...getClientPublicationsQueryKey({
        rubricId: params?.rubricId ? Number(params?.rubricId) : undefined,
        tagId: undefined,
        type: GetClientPublicationsQueryParamsType.NEWS,
      }),
      'infiniteArticles',
      'simpleArticles',
    ],
  },
]

export const journalIndexSsrQueries: TSsrPrefetchQueries = () => [
  { queryFn: () => getMainPublication(), queryKey: getMainPublicationQueryKey() },
  {
    infiniteQueryFn: () =>
      getClientPublications({
        type: GetClientPublicationsQueryParamsType.ARTICLE,
        limit: 13,
      }),
    queryKey: [
      ...getClientPublicationsQueryKey({
        rubricId: undefined,
        tagId: undefined,
        type: GetClientPublicationsQueryParamsType.ARTICLE,
      }),
      'infiniteArticles',
      'simpleArticles',
    ],
  },
  {
    queryFn: () =>
      getClientPublications({
        type: GetClientPublicationsQueryParamsType.NEWS,
        limit: 5,
        includeMain: true,
      }),
    queryKey: getClientPublicationsQueryKey({
      type: GetClientPublicationsQueryParamsType.NEWS,
      limit: 5,
      includeMain: true,
    }),
  },
  { queryFn: () => getRubrics({ showInMenu: true }), queryKey: getRubricsQueryKey({ showInMenu: true }) },
  { queryFn: () => getTags({ showInMenu: true }), queryKey: getTagsQueryKey({ showInMenu: true }) },
]
