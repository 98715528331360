import { createReducer } from '@reduxjs/toolkit'
import { getInitialState } from '@/redux/helpers'
import { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { EUserPermission } from '@/types'
import {
  clearProfileState,
  clearUserProfile,
  profileRequestFailed,
  profileRequestFinished,
  profileRequestStarted,
  setUserProfile,
} from './actions'
import { IProfileState } from './interface'

export const initialState: IProfileState = {
  isFetching: false,
  isFetchingFailed: false,
  isFailure: false,
  profile: {
    phone: '',
    photo: '',
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    birthDate: '',
    updateDate: '',
    userCanChangeRole: false,
    role: EUserPermission.AGRARIAN,
  },
  notificationSettings: {
    deals: {
      sms: false,
      email: false,
    },
    newMessages: {
      sms: false,
      email: false,
    },
    specialOffers: {
      sms: false,
      email: false,
    },
  },
}

const reducerName = 'profile'
export const reducer = createReducer(getInitialState(reducerName, initialState), {
  [profileRequestStarted.type]: state => {
    state.isFetching = true
  },
  [profileRequestFinished.type]: state => {
    state.isFetching = false
  },
  [profileRequestFailed.type]: state => {
    state.isFetching = false
    state.isFailure = true
  },
  [clearProfileState.type]: state => ({
    ...initialState,
    profile: state.profile,
  }),
  [setUserProfile.type]: (state, { payload: { profile, deals, newMessages, specialOffers } }) => {
    state.profile = profile
    state.notificationSettings = { ...state.notificationSettings, deals, newMessages, specialOffers }
  },
  [clearUserProfile.type]: () => initialState,
})

reducerManager.add(reducerName, reducer, true)

export const isProfileFetching = () => (state: RootState) => state.profile.isFetching
export const isProfileRequestFailed = () => (state: RootState) => state.profile.isFailure
export const getProfile = () => (state: RootState) => state.profile.profile
export const getNotificationSettings = () => (state: RootState) => state.profile.notificationSettings
