import React, { FC } from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { InjectHtml } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import { IInteractiveStepsProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const InteractiveSteps: FC<IInteractiveStepsProps> = ({ containerClassName, completedSteps, steps }) => {
  const currentStep = completedSteps.length + 1

  return (
    <div
      className={classNames('col-12 row-from-sm row-justify_between-only-md space-holder16-from-sm space-holder32-sm', containerClassName)}
    >
      {steps.map(({ stepNumber, descriptionId }) => {
        const isCompleted = completedSteps.includes(stepNumber)

        return (
          <li className="interactive-step col-3-from-md col-4-only-md space-holder32-sm" key={stepNumber}>
            <div
              className={classNames('interactive-step__label', {
                'interactive-step__label_active': stepNumber === currentStep && stepNumber !== 1,
              })}
            >
              {isCompleted ? <Icon name={EIconName.Check} style={{ color: EColor.GREEN }} size={EIconSize.S} /> : <h5>0{stepNumber}</h5>}
            </div>
            <div>
              <InjectHtml
                textId="interactiveSteps.description.heading"
                textValues={{ stepNumber }}
                TagName="h4"
                className={classNames('interactive-step__description-heading space-holder4', { 'interactive-step_completed': isCompleted })}
              />
              <InjectHtml
                textId={descriptionId}
                className={classNames('interactive-step__description', { 'interactive-step_completed': isCompleted })}
                TagName="p"
              />
            </div>
          </li>
        )
      })}
    </div>
  )
}

export default InteractiveSteps
