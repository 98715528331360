import React, { memo, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { PriceUom } from '@/components//ui'
import {
  AgroserviceGeneralCard,
  AgroserviceSmallCard,
  AgroserviceWideCard,
} from '@/components/resolvers/AgroServices/components/AgroservicesCards'
import { EAgroservicesTypes } from '@/components/resolvers/AgroServices/enums'
import { useAgroserviceProducts } from '@/logic/agroservicesProducts/hooks'
import { injectMessages } from '@/logic/translation/utils'
import { EPriceUom } from '@/types'
import { useWebpExtension } from '@/utils/hooks/image'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { formatMoney } from '@/utils/price'
import { AGROSERVICES_DESKTOP, AGROSERVICES_MOBILE, AGROSERVICES_TABLET } from './constants'
import { IAgroServicesProps, TPartnerServiceName } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const AgroServices: React.FC<IAgroServicesProps> = props => {
  const { withoutLink } = props

  const { isDesktop, isMobile, isTablet } = useDeviceType()
  const imageExtension = useWebpExtension()
  const intl = useIntl()
  const { agroserviceProducts } = useAgroserviceProducts()
  const { formatMessage } = useIntl()

  const getServiceItemBackgroundImage = (productId: string): string => {
    let imagePostfix: string = ''
    if (isMobile) imagePostfix = 'Mobile'
    if (isTablet) imagePostfix = 'Tablet'

    return `index/agroServices${imagePostfix}/${productId}.${imageExtension}`
  }

  const getPrice = (priceValue: string | number) => {
    if (typeof priceValue === 'string') return priceValue

    return formatMoney(priceValue, intl)
  }

  const agroServices = useMemo(() => {
    if (isMobile) return AGROSERVICES_MOBILE
    if (isDesktop) return AGROSERVICES_DESKTOP

    return AGROSERVICES_TABLET
  }, [isMobile, isDesktop])

  return (
    <div
      className="
        page-wrapper
        index-agroservices-container
        space-holder96-from-sm
        space-holder40-sm
        page-wrapper_no-mobile
        space-holder-top40-sm
      "
    >
      <span id="services" className="index-agroservices-intersection-element" />

      <div className="index-agroservices">
        {agroServices.map(agroService => {
          const { productId, url, blockType, initialPrice, isHideDescriptionText } = agroService
          if (!url) return null
          const price = agroService?.price || agroserviceProducts[productId as TPartnerServiceName]?.price

          const backgroundImageUrl = getServiceItemBackgroundImage(productId)

          const buttonText = formatMessage({ id: `agroServicesBlock.link.${productId}` })
          const idSuffix = isDesktop && (productId === 'ef_plus' || productId === 'ef_expert') ? '.alt' : ''
          const titleText = formatMessage({ id: `agroServicesBlock.title.${productId}` })
          const descriptionText = formatMessage({ id: `agroServicesBlock.text.${productId}${idSuffix}` })
          const paymentDescription = formatMessage({ id: agroService?.paymentDescription || 'agroServicesBlock.paymentPeriod' })
          const priceFormat = price && getPrice(price?.value)
          const initialPriceFormat = initialPrice && getPrice(initialPrice)

          switch (blockType) {
            case EAgroservicesTypes.WIDE:
              return (
                <AgroserviceWideCard
                  {...agroService}
                  className="index-agroservices__wide-card--size"
                  key={productId}
                  isDesktopDevice={isDesktop}
                  backgroundImageUrl={backgroundImageUrl}
                  buttonText={buttonText}
                  titleText={titleText}
                  descriptionText={descriptionText}
                  logoUrl={agroService.logo && `logos/${agroService.logo}.${imageExtension}`}
                  isHideDescriptionText={isHideDescriptionText}
                  price={priceFormat}
                  initialPrice={initialPriceFormat}
                  paymentDescription={paymentDescription}
                />
              )
            case EAgroservicesTypes.SMALL:
              return (
                <AgroserviceSmallCard
                  {...agroService}
                  className="index-agroservices__small-card--size"
                  paymentPeriod={paymentDescription}
                  initialPrice={
                    initialPrice && (
                      <>
                        {formatMoney(initialPrice, intl)} <PriceUom uom={EPriceUom.RUB} />
                      </>
                    )
                  }
                  buttonText={buttonText}
                  titleText={titleText}
                  descriptionText={descriptionText}
                  key={productId}
                  price={
                    price && (
                      <>
                        {formatMoney(price.value, intl)} <PriceUom uom={EPriceUom.RUB} />
                      </>
                    )
                  }
                  backgroundImageUrl={backgroundImageUrl}
                  logoUrl={agroService.logo && `logos/${agroService.logo}.${imageExtension}`}
                />
              )
            default:
              return (
                <AgroserviceGeneralCard
                  {...agroService}
                  className="index-agroservices__general-card--size"
                  key={productId}
                  backgroundImageUrl={backgroundImageUrl}
                  buttonText={buttonText}
                  titleText={titleText}
                  descriptionText={descriptionText}
                  isHideDescriptionText={isHideDescriptionText}
                  logoUrl={agroService.logo && `logos/${agroService.logo}.${imageExtension}`}
                />
              )
          }
        })}
      </div>

      {!withoutLink && (
        <span className="index-agroservices__link-container">
          <CommonLink className="space-holder-top56 button button_white" url="/agroservices">
            <FormattedMessage id="agroServicesBlock.link" />
          </CommonLink>
        </span>
      )}
    </div>
  )
}

export default memo(AgroServices)
