import React, { memo } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Button, Image, PriceUom } from '@/components/ui'
import { EPriceUom } from '@/types'
import { IAgroserviceWideCardProps } from './interfaces'
import './styles.scss'

const AgroserviceWideCard: React.FC<IAgroserviceWideCardProps> = props => {
  const {
    url,
    logoUrl,
    logoClassName,
    onClick,
    className,
    backgroundImageUrl = '',
    isBackgroundImageInStatic = true,
    isDesktopDevice,
    buttonText,
    titleText,
    descriptionText,
    isLogoInStatic = true,
    isHideDescriptionText = false,
    price,
    initialPrice,
    paymentDescription,
  } = props

  return (
    <div className={classNames('agroserviceWideCard agroservice-card-wrapper', className)}>
      <section
        className={classNames(
          'with-blurred-bottom-block agroserviceWideCardItem',
          'agroservice-card-item-wrapper with-blurred-bottom-block_visible',
        )}
      >
        {isDesktopDevice ? (
          <h3 className="agroserviceWideCardItem__title">{titleText}</h3>
        ) : (
          <h4 className="agroserviceWideCardItem__title">{titleText}</h4>
        )}

        {!isHideDescriptionText && <p className="agroserviceWideCardItem__text">{descriptionText}</p>}

        <div className="row row-align_end row_nowrap">
          <CommonLink className="agroserviceWideCardItem__bottom" url={url}>
            <Button onClick={onClick} modifiers={['white', 'small']}>
              {buttonText}
            </Button>
          </CommonLink>

          {!!price && (
            <div className="agroserviceWideCardItemPrice">
              {initialPrice && (
                <h5 className="agroserviceWideCardItemPrice__initial">
                  {initialPrice} <PriceUom uom={EPriceUom.RUB} />
                </h5>
              )}
              <div className="agroserviceWideCardItemPrice__actual row">
                <span className="h4">
                  {price} <PriceUom uom={EPriceUom.RUB} />
                </span>
                &nbsp;
                <span className="agroserviceWideCardItemPrice__period">{paymentDescription}</span>
              </div>
            </div>
          )}
        </div>

        {logoUrl && <Image src={logoUrl} isInStatic={isLogoInStatic} className={logoClassName || 'agroservice-card-item-wrapper__logo'} />}
      </section>
      <Image src={backgroundImageUrl} className="agroservice-card-background-image" isInStatic={isBackgroundImageInStatic} />
    </div>
  )
}

export default memo(AgroserviceWideCard)
