import { createAsyncThunk } from '@reduxjs/toolkit'
import { getBadgesV2, NotificationTypeDataType } from '@/api/kubik/monolith'
import { isAuthenticated } from '@/logic/auth/reducer'
import { INITIAL_BADGES, POLLING_INTERVAL, STATE_KEY_BY_NOTIFICATION_DATA_TYPE } from '@/logic/notifications/constants'
import { setNotifications, setNotificationsIntervalId } from '@/logic/notifications/reducer'
import { IThunkProps } from '@/redux/interfaces'

export const startNotificationsPolling = createAsyncThunk<void, void, IThunkProps>(
  'notifications/startNotificationsPolling',
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI
    const intervalId = getState().notifications.intervalId

    const getNotifications = async () => {
      const currentInterval = getState().notifications.intervalId

      if (!isAuthenticated()(getState())) {
        if (currentInterval) clearInterval(currentInterval)

        return
      }

      try {
        const response = await getBadgesV2({
          type: [
            NotificationTypeDataType.UNREAD_FUNDING_MESSAGES_COUNT,
            NotificationTypeDataType.UNREAD_MESSAGES_COUNT,
            NotificationTypeDataType.UNREAD_WEB_NOTIFICATION_COUNT,
          ],
        })
        const notificationsCount =
          response?.data?.reduce((result, notificationCount) => {
            const { type, count = 0 } = notificationCount
            const total = result.totalUnreadNotificationsCount + count

            const newResult = {
              ...result,
              totalUnreadNotificationsCount: total,
            }

            if (type) {
              const unreadNotificationCountKey = STATE_KEY_BY_NOTIFICATION_DATA_TYPE[type]

              newResult[unreadNotificationCountKey] = count
            }

            return newResult
          }, INITIAL_BADGES) || INITIAL_BADGES

        dispatch(setNotifications(notificationsCount))
      } catch (error) {
        if (currentInterval) clearInterval(currentInterval)
      }
    }

    if (intervalId) clearInterval(intervalId)

    getNotifications()
    const newIntervalId = setInterval(getNotifications, POLLING_INTERVAL)
    dispatch(setNotificationsIntervalId(newIntervalId))
  },
)
