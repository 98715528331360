import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/redux/interfaces'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { clearData, loadingDataFailed, loadingDataFinished, loadingDataStarted, NDataAction } from './actions'
import { getDataByName, setDataByName } from './helpers'
import { IDataState, INamedDataState, INamedDataStateWithoutNullData, TApiUrlName } from './interfaces'
import { responseAccumulator } from './responseAccumulator'
import { fetchData } from './thunks'

const initialState: IDataState = {
  loadedData: {},
}

const initialNamedDataState: INamedDataState = {
  data: null,
  meta: null,
  isLoading: false,
  isLoaded: false,
  isFailure: false,
  isLoadedWithSsr: false,
}

const onDataLoadingStarted = (state: IDataState, { payload }: PayloadAction<NDataAction.ILoadData>) => {
  const { name } = payload

  setDataByName(name, {
    ...initialNamedDataState,
    data: getDataByName(name)(state)?.data || null,
    isLoading: true,
  })(state)
}

const onDataLoaded = (state: IDataState, { payload }: PayloadAction<NDataAction.ILoadingDataFinished>) => {
  const { name, data = null, shouldAccumulate } = payload

  setDataByName(name, {
    ...initialNamedDataState,
    data: shouldAccumulate ? responseAccumulator[name]?.(data, state) : data,
    isLoaded: true,
    isLoadedWithSsr: ExecutionEnvironment.isSsr,
  })(state)
}

const onDataFailure = (state: IDataState, { payload }: PayloadAction<NDataAction.ILoadingDataFailed>) => {
  const { name, meta = null } = payload || {}

  setDataByName(name, {
    ...initialNamedDataState,
    isFailure: true,
    meta,
  })(state)
}

const onClearData = (state: IDataState, { payload }: PayloadAction<NDataAction.IClearData>) => {
  const { name } = payload

  setDataByName(name, initialNamedDataState)(state)
}

const reducer = createReducer(initialState, {
  [loadingDataStarted.type]: onDataLoadingStarted,
  [loadingDataFinished.type]: onDataLoaded,
  [loadingDataFailed.type]: onDataFailure,
  [fetchData.fulfilled.type]: onDataLoaded,
  [fetchData.rejected.type]: onDataFailure,
  [clearData.type]: onClearData,
})

const reducerName = 'data'

export const getRequestData =
  <T>(name: TApiUrlName) =>
  (state: RootState): INamedDataStateWithoutNullData<T> => {
    const requestState = getDataByName<T>(name)(state[reducerName])
    const data = requestState?.data

    return { ...requestState, data: data === null ? undefined : data }
  }

export default reducer
