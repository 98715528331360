import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Skeleton } from '@/components/ui'
import { EJournalRoute } from '@/types'
import { createRouterLink } from '@/utils/url'
import { tagsMenuSkeletons } from './constants'
import { ITagsMenuProps } from './types'
import './styles.scss'

const TagsMenu: React.FC<ITagsMenuProps> = props => {
  const { tags, className, isLoading } = props

  if (tags?.length === 0) return null

  return (
    <div className={classNames('journalTags__wrapper', className)}>
      <Skeleton isLoading={isLoading} skeletons={tagsMenuSkeletons}>
        {tags?.map(({ id, name, countOfPublications }) => (
          <>
            {countOfPublications > 0 ? (
              <CommonLink
                key={id}
                className="color_green underline underline_green text_body text_small-sm journalTags__tag"
                url={createRouterLink(EJournalRoute.TAG, { tagId: id })}
              >
                <span>{name}</span>
              </CommonLink>
            ) : null}
          </>
        ))}
      </Skeleton>
    </div>
  )
}

export default TagsMenu
