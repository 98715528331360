import { useSelector } from 'react-redux'
import { isUserAgrarian } from '@/logic/auth/reducer'
import { RootState } from '@/redux/interfaces'
import { EAgrarianOrderStatus } from '@/types/Order'

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
})

export function useIsBargainInfoVisible(statusId?: EAgrarianOrderStatus) {
  const { isAgrarian } = useSelector(mapState)

  return isAgrarian && statusId && [EAgrarianOrderStatus.AGRARIAN_PROPOSAL, EAgrarianOrderStatus.SUPPLIER_PROPOSAL].includes(statusId)
}
