import { FieldPath, FieldPathValue, FieldValues, ValidateResult } from 'react-hook-form'
import { TTextInputType } from '@frontend/pole-ui/lib/components/TextInput'
import { TValidate } from '../types'
import { isValidValidatorResult } from '../utils'
import { NUMBER_INPUT_TYPES, PRICE_INPUT_TYPES } from './constants'
import { TTextInputInternalValidator } from './types'

export const isPriceInput = <T extends (typeof PRICE_INPUT_TYPES)[number]>(type?: TTextInputType): type is T =>
  !!type && PRICE_INPUT_TYPES.indexOf(type as T) >= 0

export const isNumberInput = <T extends (typeof NUMBER_INPUT_TYPES)[number]>(type?: TTextInputType): type is T =>
  !!type && NUMBER_INPUT_TYPES.indexOf(type as T) >= 0

export const getRule = <
  TFieldValue extends FieldPathValue<TFieldValues, TFieldName>,
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  validator: TTextInputInternalValidator<TFieldValue, TFieldValues, TFieldName>,
  message: string,
): TValidate<TFieldValue, TFieldValues, TFieldName> => {
  return (value, formValues) => isValidValidatorResult(validator(value, formValues)) || message
}

export const composeRules = <
  TFieldValue extends FieldPathValue<TFieldValues, TFieldName>,
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  ...validators: (TValidate<TFieldValue, TFieldValues, TFieldName> | false | null)[]
): TValidate<TFieldValue, TFieldValues, TFieldName> => {
  return (value, formValues) => {
    return validators
      .filter(Boolean)
      .reduce((result, validator) => (!isValidValidatorResult(result) ? result : validator(value, formValues)), true as ValidateResult)
  }
}
