import { IUserGtmInfoPayload } from '@/interfaces'
import { EOrderType } from '@/types/HarvestSaleLots'
import { getEcommerceObject, pushFormattedGtmEvent, pushGtmEvent } from '../helpers'
import { getFormattedFormStep1, getFormattedRequestSale, getFormattedTemplate } from './helpers'
import {
  IBtnBannerGtmEventOptions,
  IHarvestSaleRequestEventOptions,
  IModalFirstStepGtmEventOptions,
  IModalThirdStepGtmEventOptions,
  IOfferHarvestGtmEventOptions,
  ITemplateGtmEventOptions,
} from './interfaces'

export const pushHarvestGtmEvent = (options: ITemplateGtmEventOptions, userRegion?: string, userGtmInfo?: IUserGtmInfoPayload) => {
  const {
    eventNonInteraction,
    pagePart = 'content',
    blockName,
    items,
    itemContext,
    eventStatus,
    eventAction,
    eventLabel,
    ecommerceType,
    eventEcommerce,
    eventCategory,
    trueEventContext,
    actionField,
  } = options

  let resultItems = items.map(getFormattedTemplate)
  if (resultItems.length > 1) resultItems = resultItems.map((item, position) => ({ ...item, position }))

  const eventContext = `prd:${items?.reduce((result: string, { id }, index) => `${result}${index !== 0 ? ';' : ''}${id}`, '')}${
    itemContext ? `&lst:${itemContext}` : ''
  }`

  const harvestSale = items?.map(el => {
    return {
      items: {
        item_id: el.id,
        item_buyer: el.buyer.id,
        item_category: el.culture.id,
        item_list_name: 'harvestSale',
      },
    }
  })

  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart,
    blockName,
    eventCategory,
    eventAction,
    harvestSale,
    eventLabel,
    eventContext: trueEventContext || eventContext,
    eventStatus,
    eventEcommerce,
    eventNonInteraction,
    firingOptions: 'oncePerEvent',
    ecommerce: getEcommerceObject({ ecommerceType, items: resultItems, actionField, itemContext }),
    regionSelected: userRegion,
    ...userGtmInfo,
  })
}

export const pushHarvestSaleRequestGtmEvent = (options: IHarvestSaleRequestEventOptions) => {
  const {
    eventNonInteraction,
    pagePart = 'content',
    blockName,
    templateId,
    isMultiRequest,
    items,
    eventStatus,
    eventAction,
    eventLabel,
    eventEcommerce,
    ecommerceType,
    eventCategory,
    trueEventContext,
    actionField,
  } = options

  const resultItems = items.map(item => getFormattedRequestSale(item, templateId, isMultiRequest))

  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart,
    blockName,
    eventCategory,
    eventAction,
    eventLabel,
    eventContext: trueEventContext || '',
    eventStatus,
    eventEcommerce,
    eventNonInteraction,
    firingOptions: 'oncePerEvent',
    ecommerce: getEcommerceObject({ ecommerceType, items: resultItems, actionField }),
  })
}

export const pushSubmitModalFirstStepHarvestGtmEvent = (options: IModalFirstStepGtmEventOptions) => {
  const {
    eventNonInteraction,
    pagePart = 'content',
    blockName,
    items,
    eventStatus,
    eventAction,
    eventLabel,
    eventEcommerce,
    ecommerceType,
    eventCategory,
    trueEventContext,
    userGtmInfo,
    userRegion,
    targetRequestID,
    requestID,
    actionField,
  } = options

  const resultItems = (items || []).map(getFormattedFormStep1)

  pushFormattedGtmEvent({
    event: 'userEvent',
    ...userGtmInfo,
    regionSelected: userRegion.name,
    pagePart,
    blockName,
    eventCategory,
    eventAction,
    targetRequestID,
    requestID,
    eventLabel,
    eventContext: trueEventContext,
    eventStatus,
    eventEcommerce,
    eventNonInteraction,
    firingOptions: 'oncePerEvent',
    ecommerce: getEcommerceObject({ ecommerceType, items: resultItems, actionField }),
  })
}

export const pushSubmitModalThirdStepHarvestGtmEvent = (options: IModalThirdStepGtmEventOptions) => {
  const { templateId, sourceOfOpening, values, withTemplate } = options

  let eventLabel = values.orderType === EOrderType.multiple ? 'multiFormStep2' : 'singleFormStep2'
  if (withTemplate) eventLabel = 'submitFormStep2'

  const trueEventContext = `${withTemplate ? `prd:${templateId}&` : ''}Card&lst:${sourceOfOpening}`

  const items = [
    {
      ...values,
      id: templateId ?? null,
    },
  ]
  const actionField = {
    step: 2,
    list: sourceOfOpening,
  }

  const resultItems = (items || []).map(getFormattedFormStep1)

  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart: 'popUp',
    blockName: 'applicationOutputsStep2',
    eventCategory: 'ecommerce',
    eventAction: 'click',
    eventLabel,
    eventContext: trueEventContext,
    eventStatus: 'success',
    eventEcommerce: 'checkout',
    eventNonInteraction: '0',
    firingOptions: 'oncePerEvent',
    ecommerce: getEcommerceObject({ ecommerceType: 'checkout', items: resultItems, actionField }),
  })
}

export const pushBtnBannerHarvestGtmEvent = (
  options: IBtnBannerGtmEventOptions,
  regionSelected?: string,
  userGtmInfo?: IUserGtmInfoPayload,
) => {
  const {
    eventNonInteraction,
    pagePart = 'content',
    blockName,
    items,
    eventStatus,
    eventAction,
    eventLabel,
    eventEcommerce,
    ecommerceType,
    eventCategory,
    trueEventContext,
    actionField,
  } = options

  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart,
    blockName,
    eventCategory,
    eventAction,
    eventLabel,
    eventContext: trueEventContext,
    eventStatus,
    eventEcommerce,
    eventNonInteraction,
    firingOptions: 'oncePerEvent',
    ecommerce: ecommerceType ? getEcommerceObject({ ecommerceType, items, actionField }) : null,
    regionSelected,
    ...userGtmInfo,
  })
}

export const pushHarvestNameClickGtmEvent = (
  options: IBtnBannerGtmEventOptions,
  regionSelected?: string,
  userGtmInfo?: IUserGtmInfoPayload,
) => {
  const {
    eventNonInteraction,
    pagePart = 'content',
    blockName,
    eventStatus,
    eventAction,
    eventLabel,
    eventEcommerce,
    eventCategory,
    trueEventContext,
  } = options

  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart,
    blockName,
    eventCategory,
    eventAction,
    eventLabel,
    eventContext: trueEventContext,
    eventStatus,
    eventEcommerce,
    eventNonInteraction,
    firingOptions: 'oncePerEvent',
    regionSelected,
    ...userGtmInfo,
  })
}

export const pushOfferHarvestGtmEvent = (options: IOfferHarvestGtmEventOptions) => {
  const {
    eventNonInteraction,
    pagePart = 'content',
    blockName,
    eventStatus,
    eventAction,
    eventLabel,
    eventEcommerce,
    eventCategory,
    trueEventContext,
  } = options

  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart,
    blockName,
    eventCategory,
    eventAction,
    eventLabel,
    eventContext: trueEventContext,
    eventStatus,
    eventEcommerce,
    eventNonInteraction,
    firingOptions: 'oncePerEvent',
    ecommerce: null,
  })
}

export const getGtmSmartSeedsLogistics = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  eventLabel: null | number,
  eventContext?: string | number,
) => {
  pushGtmEvent({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 1,
    blockName: 'harvestSaleRequest',
    eventLabel: eventLabel ? eventLabel.toString() : '',
    eventContext: eventContext ? eventContext.toString() : '',
    ...userGtmInfo,
  })
}

export const getGtmLogisticsForm = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  eventLabel: null | number,
  eventContext?: string | number,
) => {
  pushGtmEvent({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'formSend',
    eventNonInteraction: 1,
    blockName: 'harvestSaleRequest',
    eventLabel: eventLabel ? eventLabel.toString() : '',
    eventContext: eventContext ? eventContext.toString() : '',
    ...userGtmInfo,
  })
}

export const getGtmLogisticsInfoForm = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  blockName: string,
  eventLabel: string,
  eventContext?: string | number,
) => {
  pushGtmEvent({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 1,
    blockName,
    eventLabel,
    eventContext: eventContext ? eventContext.toString() : '',
    ...userGtmInfo,
  })
}
