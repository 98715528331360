import React, { FC, memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Image, InjectHtml } from '@/components//ui'
import { injectMessages } from '@/logic/translation/utils'
import { useWebpExtension } from '@/utils/hooks/image'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { EBannerContext } from './enums'
import { IBannerForComplexOrderProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const BannerForComplexOrder: FC<IBannerForComplexOrderProps> = ({ context, linkToForm, onButtonClick, className }) => {
  const { isMobile } = useDeviceType()

  const imageExtension = useWebpExtension()

  return (
    <div className={classNames('banner-for-complex-order', className)}>
      <Image
        src={`agriculturalProducts/productsListBanner${isMobile ? 'Mobile' : ''}-${context}.${imageExtension}`}
        isInStatic
        className="banner-for-complex-order__image"
      />
      <section className="banner-for-complex-order__right">
        {context === EBannerContext.COMMON && (
          <ul className="banner-for-complex-order__benefits text_label color_pale-black space-holder16 row">
            <InjectHtml textId="bannerForComplexOrder.benefits.quantity" TagName="li" />
            <InjectHtml textId="bannerForComplexOrder.benefits.discount" TagName="li" />
            <InjectHtml textId="bannerForComplexOrder.benefits.delivery" TagName="li" />
          </ul>
        )}

        <InjectHtml
          TagName="p"
          className="banner-for-complex-order__title text_small space-holder8 font-weight_bold"
          textId={`bannerForComplexOrder.title.${context}`}
        />

        <InjectHtml TagName="p" className="text_small" textId={`bannerForComplexOrder.text.${context}`} />

        <div className="banner-for-complex-order__button">
          <CommonLink
            className="button button_small button_white-darkGreen button_full-width-mobile button_outline"
            url={linkToForm}
            onClick={onButtonClick}
          >
            <FormattedMessage id="bannerForComplexOrder.actions.leaveRequest" />
          </CommonLink>
        </div>
      </section>
    </div>
  )
}

export default memo(BannerForComplexOrder)
