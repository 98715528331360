import { AgroserviceCreateRequestData, AgroserviceRequestResult } from '@/api/generated/base'
import { requestData } from '@/api/request'
import { EAgroservicesApiUrl } from '@/api/urls'

class AgroservicesService {
  createAgroserviceRequest = (payload: AgroserviceCreateRequestData): Promise<AgroserviceRequestResult> =>
    requestData({
      url: EAgroservicesApiUrl.AGROSERVICE_REQUESTS,
      method: 'POST',
      body: payload,
    })
}

export default AgroservicesService
