import { requestData } from '@/api/request'
import { ECartApiUrl } from '@/api/urls'
import { ICart } from '@/types/Cart'
import { IAddProductOptions, IChangeProductQuantityOptions, IClearCartOptions, IDeleteProductOptions } from './interfaces'

/** Сервис работы с корзиной */
export default class CartService {
  /**
   * Удаляет продукт из корзины
   * @param {Object} options
   * @prop {string} options.productId - идентификатор товара
   */
  deleteProduct = ({ productId }: IDeleteProductOptions) =>
    requestData<ICart>({ url: `${ECartApiUrl.cartItem}/${productId}`, method: 'DELETE' })

  /**
   * Очищает корзину
   * @param {Object} options
   * @prop {string} options.cartId - идентификатор корзины
   */
  clearCart = ({ cartId }: IClearCartOptions) => requestData<undefined>({ url: `${ECartApiUrl.cart}/${cartId}`, method: 'DELETE' })

  /**
   * Добавляет новый товар в корзину
   * @param {Object} options
   * @prop {string} options.quantity - количество добавляемого товара
   * @prop {string} options.productId - идентификатор товара
   */
  addProduct = ({ productId, quantity }: IAddProductOptions) =>
    requestData<ICart>({
      url: `${ECartApiUrl.cartItem}/${productId}`,
      method: 'POST',
      body: { quantity },
    })

  /**
   * Изменяет количество существующего в корзине товара
   * @param {Object} options
   * @prop {string} options.quantity - количество товара
   * @prop {string} options.productId - идентификатор товара
   */
  changeProductQuantity = ({ productId, quantity }: IChangeProductQuantityOptions) =>
    requestData<ICart>({
      url: `${ECartApiUrl.cartItem}/${productId}`,
      method: 'PUT',
      body: { quantity },
    })
}
