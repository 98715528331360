import { ExecutionEnvironment } from './ExecutionEnvironment'

const epicManagerModule = ExecutionEnvironment.isSsr ? undefined : require('@/redux/rootEpic')

const addNewEpicsLazily = (epicsArr: any[]) => {
  if (epicManagerModule) {
    const { epicRegistry, epic$ } = epicManagerModule
    epicsArr.forEach(epic => {
      if (epicRegistry.indexOf(epic) === -1) {
        epicRegistry.push(epic)
        epic$.next(epic)
      }
    })
  }
}

export { addNewEpicsLazily }
