/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AuctionDeliveryBasisData = {
    code: AuctionDeliveryBasisData.code;
    name: string;
};

export namespace AuctionDeliveryBasisData {

    export enum code {
        ELEVATOR = 'ELEVATOR',
        PICKUP = 'PICKUP',
        EXW = 'EXW',
        FOB = 'FOB',
        WAGON = 'WAGON',
    }


}

