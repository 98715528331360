import React, { FC, memo, useMemo } from 'react'
import { Accordion } from 'react-accessible-accordion'
import { FormattedMessage } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import { AccordionItem } from '@/components/ui'
import { THeaderComponent } from '@/components/ui/AccordionItem/interfaces'
import { injectMessages } from '@/logic/translation/utils'
import { IAgriculturalProductTreeCategory } from '@/types/Catalog'
import { IRecursiveCategoriesTreeProps } from './interfaces'
import messages from './messages.json'
import './RecursiveCategoriesTree.scss'

injectMessages(messages)

type TSeparatedCategories = [IAgriculturalProductTreeCategory[], IAgriculturalProductTreeCategory[]]
const defaultChildCategories: IAgriculturalProductTreeCategory[] = []

const AccordionHeader: THeaderComponent = ({ expanded }) => (
  <div className="text_small color_pale-black underline underline_black">
    <FormattedMessage id={`recursiveCategoriesTree.accordionHeader.${expanded ? 'expanded' : 'hidden'}`} />
  </div>
)

interface IAgriculturalProductCategoriesTreeCategoryProps {
  category: IAgriculturalProductTreeCategory
  getUrl: (id: number) => string
  shouldUseAccordion?: boolean
  shouldHideChildren?: boolean
  className?: string
}

const displayingCategoriesByDefaultQuantity = 6

const AgriculturalProductCategoriesTreeCategory: FC<IAgriculturalProductCategoriesTreeCategoryProps> = props => {
  const { getUrl, shouldUseAccordion, category, shouldHideChildren, className } = props
  const { id, name, active: isChosenCategory } = category
  const children = category.children ?? defaultChildCategories
  const [childCategories, childCategoriesInAccordion]: TSeparatedCategories = useMemo(() => {
    if (shouldHideChildren) return [[], []]

    if (shouldUseAccordion) {
      return [children.slice(0, displayingCategoriesByDefaultQuantity), children.slice(displayingCategoriesByDefaultQuantity)]
    }

    return [children, []]
  }, [shouldUseAccordion, children, shouldHideChildren])
  const isCategoryWithoutLink = isChosenCategory && !shouldHideChildren

  return (
    <li className={classNames('agricultural-product-categories-tree-category', className)}>
      <CommonLink
        activeClassName=""
        className={classNames('agricultural-product-categories-tree-category__link text_small link-no-hover', {
          'agricultural-product-categories-tree-category__link_active font-weight_light-bold': isCategoryWithoutLink,
        })}
        url={isCategoryWithoutLink ? undefined : getUrl(id)}
        spanIfEmpty
      >
        {name}
      </CommonLink>

      {childCategories.map((childCategory, index) => (
        //eslint-disable-next-line @typescript-eslint/no-use-before-define
        <RecursiveCategoriesTree
          key={`child-${childCategory.id}_${index}`}
          categories={[childCategory]}
          className="agricultural-product-categories-tree-category__children"
          getUrl={getUrl}
          shouldUseAccordion={shouldUseAccordion}
        />
      ))}

      {childCategoriesInAccordion.length > 0 && (
        <Accordion allowZeroExpanded className="agricultural-product-categories-tree-category__accordion">
          <AccordionItem
            Header={AccordionHeader}
            headingPosition="bottom"
            withoutChildOffset
            initialIconRotationDegree={0}
            iconRotationDegree={180}
          >
            <div className="agricultural-product-categories-tree-category space-holder8-from-sm space-holder16-sm">
              {childCategoriesInAccordion.map((childCategory, index) => (
                //eslint-disable-next-line @typescript-eslint/no-use-before-define
                <RecursiveCategoriesTree
                  key={`accordion-child-${childCategory.id}_${index}`}
                  categories={[childCategory]}
                  className="agricultural-product-categories-tree-category__children"
                  getUrl={getUrl}
                  shouldUseAccordion={shouldUseAccordion}
                />
              ))}
            </div>
          </AccordionItem>
        </Accordion>
      )}
    </li>
  )
}

const RecursiveCategoriesTree = memo((props: IRecursiveCategoriesTreeProps) => {
  const { categories, isRoot, className, rootIconName, getUrl, shouldUseAccordion, shouldHideChildren } = props

  return (
    <ul className={classNames(className, 'agricultural-product-categories-tree', { 'agricultural-product-categories-tree_root': isRoot })}>
      {isRoot && rootIconName && <Icon name={rootIconName} size={EIconSize.L} className="agricultural-product-categories-tree__icon" />}
      {categories.map((category, index) => (
        <AgriculturalProductCategoriesTreeCategory
          key={`${category.id}-${index}`}
          category={category}
          getUrl={getUrl}
          shouldUseAccordion={shouldUseAccordion}
          shouldHideChildren={shouldHideChildren}
          className={classNames({ 'space-holder8': isRoot })}
        />
      ))}
    </ul>
  )
}, isEqual)

export default RecursiveCategoriesTree
