import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'urijs'
import { isStatusOk } from '@/api/helpers'
import { requestData } from '@/api/request'
import { EHarvestSaleLotsApiUrl } from '@/api/urls'
import { TFormatOption, transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import { getTemplateRequestBody } from '@/pages/HarvestSaleLots/helpers'
import { IThunkProps, RootState } from '@/redux/interfaces'
import { IFilterOption, ITemplatesFilter, ITemplatesResponse, TTemplatesFilterName } from '@/types/HarvestSaleLots'
import { formatLocalizedString } from '@/utils/formatUtils'
import { transformTemplatesFiltersFromResponse } from '@/utils/responseTransformers'
import { availableFilters, includedFiltersFromResponse, notIndicatorsName, TemplatesFilterName } from './constants'
import { IFetchTemplatesPayload, IFilterBasedOnIndicators, ISetTemplatesPayload, TFilterOptions } from './interfaces'
import { messages } from './messages'

const formatProteinOption =
  (state: RootState) =>
  ({ value, dependencies }: IFilterOption): IFilterOption => ({
    value,
    name: formatLocalizedString(messages.proteinOption, { value }),
    dependencies,
  })

const FilterOptionFormatter: Partial<Record<TTemplatesFilterName, TFormatOption<IFilterOption, number>>> = {
  protein: ({ value }) => ({
    label: `Не менее ${value}%`,
    value: Number(value),
  }),
}

const createBody = (payload: IFetchTemplatesPayload, proteinFrom: string[] | string) => {
  if (!proteinFrom) return getTemplateRequestBody(payload)

  const body = getTemplateRequestBody(payload)
  const possibleValues: string[] = []

  if (!Array.isArray(proteinFrom)) {
    possibleValues.push(`${proteinFrom}`)
  } else {
    proteinFrom.forEach(item => {
      possibleValues.push(`${item}`)
    })
  }

  body.indicatorsData.indicators.proteinFrom = {
    name: 'Не менее',
    possibleValues,
  }

  return body
}

export const fetchTemplates = createAsyncThunk<ISetTemplatesPayload, IFetchTemplatesPayload, IThunkProps>(
  '@@harvestSaleLots/FETCH-TEMPLATES',
  async (payload, thunkAPI) => {
    const { itemsPerPage = 6, filterValues = {}, pageNumber = 1, apiUrl = '', maxShownItemsCount } = payload
    const store = filterValues[TemplatesFilterName.STORE]

    const isStoreSelected = store && store !== 'placeholder'
    const sortBy = filterValues[TemplatesFilterName.SORT_BY]

    const query = {
      limit: maxShownItemsCount || itemsPerPage,
      offset: Number.isInteger(maxShownItemsCount) ? 0 : itemsPerPage * (pageNumber - 1),
      store: isStoreSelected ? store : undefined,
      sortBy,
    }
    const url = new URI(`${apiUrl}${EHarvestSaleLotsApiUrl.templates}`).addQuery(query)
    const { getState, rejectWithValue } = thunkAPI
    const state = getState()

    const response = await requestData<ITemplatesResponse>({
      url: url.toString(),
      method: 'POST',
      state,
      body: createBody(payload, state.app.location.query?.proteinFrom),
    })

    if (!isStatusOk(response)) return rejectWithValue(response.meta)

    const { data } = response
    const { filters, deliveryBasisVariants } = data
    const filterOptions = filters.reduce((result, { value: filterName, options }) => {
      return {
        ...result,
        [filterName]: transformOptions({ options, formatOption: FilterOptionFormatter[filterName] }),
      }
    }, {}) as TFilterOptions
    filterOptions.deliveryBasis = transformOptions({ options: deliveryBasisVariants, valueKey: 'id' })

    return {
      ...data,
      asideFilters: transformTemplatesFiltersFromResponse(filters.filter(({ value }) => includedFiltersFromResponse.includes(value))),
      topFilters: filters.reduce((result: ITemplatesFilter[], filter) => {
        if (!availableFilters.includes(filter.value)) return result

        const { options } = filter

        return [
          ...result,
          {
            ...filter,
            options: filter.value === TemplatesFilterName.PROTEIN ? options.map(formatProteinOption(state)) : options,
          },
        ]
      }, []),
      indicators: filters
        .reduce((result: IFilterBasedOnIndicators[], filter) => {
          if (notIndicatorsName.includes(filter.value)) return result

          return [...result, filter as IFilterBasedOnIndicators]
        }, [])
        .sort((a, b) => a.options.length - b.options.length),
      filterOptions,
    }
  },
)
