import React from 'react'
import { Button, InjectHtml } from '@/components/ui'
import { IExactFarmingTextPhoneSectionProps } from './interfaces'
import './styles.scss'

const ExactFarmingTextPhoneSection: React.FC<IExactFarmingTextPhoneSectionProps> = ({ titleId, textId, onActionClick }) => {
  return (
    <section className="exact-farming-text-phone-section">
      <div className="page-wrapper">
        <InjectHtml TagName="h2" textId={titleId} className="exact-farming-text-phone-section__title space-holder16" />
        <InjectHtml TagName="p" textId={textId} className="exact-farming-text-phone-section__text" />

        <div className="exact-farming-text-phone-section__button-wrapper">
          <Button
            onClick={onActionClick}
            modifiers={['green']}
            textId="global.orderRequest"
            className="exact-farming-text-phone-section__button"
          />
        </div>
      </div>
    </section>
  )
}

export default ExactFarmingTextPhoneSection
