/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OrderSupplyUpdateStatusRequest = {
    nextStatus: OrderSupplyUpdateStatusRequest.nextStatus;
};

export namespace OrderSupplyUpdateStatusRequest {

    export enum nextStatus {
        SCHEDULED = 'SCHEDULED',
        COMPLETED = 'COMPLETED',
        PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
    }


}

