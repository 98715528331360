import { useState } from 'react'
import { QueryObserverBaseResult } from '@tanstack/query-core'

export type TQueriesStatusesKeys = {
  [k in keyof QueryObserverBaseResult]: k extends `is${string}` ? (QueryObserverBaseResult[k] extends boolean ? k : never) : never
}[keyof QueryObserverBaseResult]

export type TQueriesStatuses = Pick<QueryObserverBaseResult, TQueriesStatusesKeys>

// for debug use the spread syntax: `{ ...statuses }`
export function useQueriesStatuses(queries: TQueriesStatuses[]): TQueriesStatuses {
  const [statuses] = useState<TQueriesStatuses>(() => ({
    isInitialLoading: false,
    isError: false,
    isFetched: false,
    isLoading: false,
    isStale: false,
    isFetchedAfterMount: false,
    isFetching: false,
    isLoadingError: false,
    isPaused: false,
    isPlaceholderData: false,
    isPreviousData: false,
    isRefetchError: false,
    isRefetching: false,
    isSuccess: false,
  }))

  // https://tkdodo.eu/blog/react-query-render-optimizations#tracked-queries
  return new Proxy(statuses, {
    get(target, key: TQueriesStatusesKeys) {
      return queries.some(q => q[key] ?? false)
    },
  })
}
