import React from 'react'
import { captureException } from '@sentry/react'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { CHUNK_LOAD_ERROR_NAME } from './constants'
import { isAlreadyReloadedFlagUp, setAlreadyReloadedFlag } from './helpers'
import { IErrorBoundaryProps, IErrorBoundaryState } from './interfaces'
import SsrErrorBoundary from './SsrErrorBoundary'

class ErrorBoundary extends React.PureComponent<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      error: null,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch(error: Error): void {
    captureException(error)

    if (error instanceof Error && error.name === CHUNK_LOAD_ERROR_NAME && !isAlreadyReloadedFlagUp()) {
      setAlreadyReloadedFlag()
      window.location.reload()
    }
  }

  render() {
    if (ExecutionEnvironment.isSsr) return <SsrErrorBoundary {...this.props} />

    const { error, hasError } = this.state
    const { fallBack, children } = this.props
    if (hasError) return fallBack(error as Error)

    return children
  }
}

export default ErrorBoundary
