import React, { ChangeEventHandler, useCallback, useMemo, useState } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { TextInputView, TTextInputType } from '@frontend/pole-ui/lib/components/TextInput'
import { getMappedFieldMetaState, splitHookFormControllerProps } from '@/utils/fields'
import { TRANSFORMERS_BY_TEXT_INPUT_TYPE } from './constants'
import { ITextInputProps } from './types'
import { useTextInputRules } from './useTextInputRules'

export function HookFormTextInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: ITextInputProps<TFieldValues, TName>) {
  const [controllerProps, { inputRef, isDisabled, customTransformValue, onFocus, onBlur, onChange, ...componentProps }] =
    splitHookFormControllerProps(props)

  const disabled = controllerProps.disabled || isDisabled
  const inputType: TTextInputType = componentProps.type ?? 'text'
  const rules = useTextInputRules(props)

  const { field, fieldState } = useController({ ...controllerProps, disabled, rules })

  /* TODO: active state пока недоступен из коробки
     Можно будет удалить, как только будет залит этот pr: https://github.com/react-hook-form/react-hook-form/pull/11024
     https://jira.poleinvest.ru/browse/IFA-856
   */
  const [active, setActive] = useState(false)
  const meta = useMemo(() => getMappedFieldMetaState(fieldState, { active }), [fieldState, active])

  const onInputFocus: NonNullable<typeof onFocus> = useCallback(
    event => {
      onFocus?.(event)
      setActive(true)
    },
    [onFocus],
  )

  const onInputBlur: NonNullable<typeof onBlur> = useCallback(
    event => {
      field.onBlur()
      onBlur?.(event)
      setActive(false)
    },
    [field, onBlur],
  )

  const onInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    event => {
      const eventValue = event.target.value
      const fieldValue = TRANSFORMERS_BY_TEXT_INPUT_TYPE[inputType]?.(eventValue) ?? eventValue

      field.onChange(customTransformValue ? customTransformValue(fieldValue) : fieldValue)

      onChange?.(event)
    },
    [customTransformValue, field, inputType, onChange],
  )

  return (
    <TextInputView
      errorText={fieldState.error?.message}
      {...componentProps}
      {...field}
      meta={meta}
      isDisabled={field.disabled}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      onChange={onInputChange}
      ref={e => {
        if (!e) return

        field.ref(e)
        if (inputRef) inputRef.current = e
      }}
    />
  )
}
