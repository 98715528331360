import React, { memo, useEffect, useRef } from 'react'
import { useBodyScrollLock } from '@/utils/hooks/scroll/useBodyScrollLock'
import { IOverlayProps } from './interfaces'
import './styles.scss'

const Overlay: React.FC<IOverlayProps> = ({ onClick, children }) => {
  const instanceRef = useRef<HTMLDivElement>(null)
  const { lock, unlock } = useBodyScrollLock(instanceRef)

  useEffect(() => {
    lock()

    return () => unlock()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={instanceRef} className="overlay" onClick={onClick}>
      {children}
    </div>
  )
}

export default memo(Overlay)
