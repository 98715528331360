/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OnboardingStepDataCC } from './OnboardingStepDataCC';

export type OnboardingParamsDataCC = {
    name: string;
    condition: OnboardingParamsDataCC.condition;
    status: OnboardingParamsDataCC.status;
    steps: Array<OnboardingStepDataCC>;
};

export namespace OnboardingParamsDataCC {

    export enum condition {
        FIRST_START = 'FIRST_START',
        SECOND_START = 'SECOND_START',
    }

    export enum status {
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
        DELETED = 'DELETED',
    }


}

