import React from 'react'
import { GlassMorphIcon } from '@/components/ui'
import accreditationAsyncEpic from '@/logic/accreditation/epics'
import { addNewEpicsLazily } from '@/utils/addNewEpicsLazily'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import dealAgreementFormAsyncEpic from '../forms/DealAgreementForm/epics'
import Buttons from './components/Buttons'
import { IStatusModelActionsProps } from './interfaces'
import './styles.scss'

addNewEpicsLazily([accreditationAsyncEpic, dealAgreementFormAsyncEpic])

const StatusModelActions: React.FC<IStatusModelActionsProps> = props => {
  const { status, isOrderClosed } = props

  const { isMobile } = useDeviceType()

  if (isOrderClosed || !status?.bannerText) return null

  const { bannerText } = status || {}
  const buttons = <Buttons {...props} />

  return (
    <div className="order-details-notification-container">
      <div className="order-details-notification">
        <div className="row row_nowrap order-details-notification-banner-text">
          <GlassMorphIcon image="black/docs" className="order-details-notification__image" />

          <div className="space-holder-top8 space-holder-top0-sm">
            <h5 className="color_white order-details-notification__title">{bannerText}</h5>
            {!isMobile && buttons}
          </div>
        </div>
        {isMobile && buttons}
      </div>
    </div>
  )
}

export default StatusModelActions
