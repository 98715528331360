import { useDispatch, useSelector } from 'react-redux'
import { useMount } from '@/utils/hooks'
import { clearData, loadData } from './actions'
import { IUseDataOptions, TApiUrlName } from './interfaces'
import { getRequestData } from './reducer'

export const useData = <T>(name: TApiUrlName, options: IUseDataOptions = {}) => {
  const { shouldLoadForcibly, params, shouldNotLoadDataOnMount, shouldClearDataOnMount, shouldAccumulate, shouldClearDataOnUnmount } =
    options
  const dispatch = useDispatch()
  useMount(
    () => {
      if (!shouldNotLoadDataOnMount) dispatch(loadData({ name, shouldLoadForcibly, params, isLoadingOnMount: true, shouldAccumulate }))
      if (shouldClearDataOnMount) dispatch(clearData({ name }))
    },
    () => {
      if (shouldClearDataOnUnmount) dispatch(clearData({ name }))
    },
  )

  return useSelector(getRequestData<T>(name))
}
