import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { injectMessages } from '@/logic/translation/utils'
import { ITemporaryUnavailableTagProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const TemporaryUnavailableTag: FC<ITemporaryUnavailableTagProps> = ({ className = '' }) => {
  return (
    <div className={`temporary-unavailable-tag ${className}`}>
      <FormattedMessage id="temporaryUnavailableTag.label" />
    </div>
  )
}

export default TemporaryUnavailableTag
