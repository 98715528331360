import React from 'react'
import classNames from 'classnames'
import { HangingMarkerProps } from './interfaces'
import './styles.scss'

const HangingMarker: React.FC<HangingMarkerProps> = ({ modifier, children, className }) => (
  <span className={classNames(`hanging-marker hanging-marker_${modifier}`, className)}>{children}</span>
)

export default HangingMarker
