import { ISkuCharacteristic } from '@/types/Catalog/ISkuCharacteristic'
import { IProductInfo } from '@/types/Order/IProductInfo'
import {
  isMultiSelectCharacteristicValue,
  isNumberCharacteristicValue,
  isSelectCharacteristicValue,
  isTextCharacteristicValue,
} from './typeguards'

export const prepareCharacteristics = (characteristics: ISkuCharacteristic[]) => {
  return characteristics.map(({ value, name }) => {
    let list: string[] = []

    if (isNumberCharacteristicValue(value)) {
      const dataArr = typeof value.data === 'string' ? [value.data] : [value.data.value, ...(value.data.unit ? [value.data.unit] : [])]

      list = [dataArr.join(' ')]
    }

    if (isTextCharacteristicValue(value) || isSelectCharacteristicValue(value)) {
      list = [value.data]
    }

    if (isMultiSelectCharacteristicValue(value)) {
      list = [value.data].flat()
    }

    return {
      name,
      list,
    }
  })
}

export const getProductInfo = (productInfoData?: IProductInfo) => {
  if (!productInfoData) return null

  return {
    id: productInfoData.skuId,
    name: productInfoData.skuName,
    quantity: productInfoData.quantity,
    packageType: {
      unitCode: productInfoData.packageType.unitCode,
      volume: productInfoData.packageType.volume,
    },
  }
}
