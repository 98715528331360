import React from 'react'
import { renderToString } from 'react-dom/server'
import { Provider } from 'react-intl-redux'
import { useSelector, useStore } from 'react-redux'
import { isServerRenderError } from '@/logic/app/reducer'
import { ISsrErrorBoundaryProps } from './interfaces'

const SsrErrorBoundary: React.FC<ISsrErrorBoundaryProps> = ({ fallBack, children }) => {
  const store = useStore()
  const isNeedCheckRender = useSelector(isServerRenderError())

  if (isNeedCheckRender) {
    try {
      renderToString(<Provider store={store}>{children}</Provider>)

      return <>{children}</>
    } catch (error) {
      return fallBack(error as Error)
    }
  }

  return <>{children}</>
}

export default SsrErrorBoundary
