import React, { FC, memo } from 'react'
import { Logo } from '@/components/ui'
import { AdditionalMenuLinks, HeaderActions, NavLinks, RegionChooserTop, ScrollableHeader } from './components'
import './styles.scss'

const HeaderContent: FC = memo(() => (
  <>
    <div className="header-top text_small">
      <div className="page-wrapper">
        <RegionChooserTop />
        <AdditionalMenuLinks />
        <HeaderActions />
      </div>
    </div>
    <div className="header-bottom">
      <div className="page-wrapper">
        <nav className="header-bottom-navigation">
          <Logo url="/" className="header-logo" />
          <NavLinks />
        </nav>
      </div>
    </div>
  </>
))

const Header: FC = () => (
  <>
    <div className="header-tablet-litter" />
    <ScrollableHeader>
      <HeaderContent />
    </ScrollableHeader>
  </>
)

export default Header
