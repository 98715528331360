import React, { FC, memo } from 'react'
import { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Chip, HintWrapper } from '@/components/ui'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { EAgriculturalProductPromoLabelSize, IAgriculturalProductPromoLabelProps } from './interfaces'
import './styles.scss'

const AgriculturalProductPromoLabel: FC<IAgriculturalProductPromoLabelProps> = props => {
  const {
    className = '',
    size = EAgriculturalProductPromoLabelSize.BIG,
    color = EChipModifier.ATTENTION_LIGHT,
    shouldShowLabel,
    textId,
    text,
    textValues,
    promoExplanationMessageId,
    promoExplanationMessage,
    children,
    url,
  } = props
  const { isDesktop } = useDeviceType()
  if (!shouldShowLabel) return null
  const sizeClassName = `agricultural-product-promo-label_${size}`

  return (
    <HintWrapper
      messageId={promoExplanationMessageId}
      message={promoExplanationMessage}
      shouldBeHintInModal={!isDesktop}
      shouldDisplayHint={Boolean(promoExplanationMessageId || promoExplanationMessage)}
      className={className}
    >
      <CommonLink url={url} spanIfEmpty>
        <Chip
          textId={textId}
          text={text}
          textValues={textValues}
          color={color}
          className={classNames(`font_base font-weight_light-bold agricultural-product-promo-label ${sizeClassName}`, {
            [className]: !(promoExplanationMessageId || promoExplanationMessage),
          })}
          modifiers={[EChipModifier.RECT]}
          fontStyle="small"
        >
          {children}
        </Chip>
      </CommonLink>
    </HintWrapper>
  )
}

export default memo(AgriculturalProductPromoLabel)
