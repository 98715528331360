
export enum DocumentInfoResponseStatus {
    "DRAFT" = "DRAFT",
    "ON_APPROVAL" = "ON_APPROVAL",
    "PROPOSED_CHANGES" = "PROPOSED_CHANGES",
    "UNDER_REVIEW" = "UNDER_REVIEW",
    "PUBLISHED" = "PUBLISHED"
}
export type DocumentInfoResponse = {
    /**
     * @type integer int64
    */
    id: number;
    /**
     * @type string
    */
    name: string;
    /**
     * @type string
    */
    status: DocumentInfoResponseStatus;
    /**
     * @type string date-time
    */
    updateDate: string;
    /**
     * @type string
    */
    organizationId: string;
    /**
     * @type string | undefined
    */
    organizationName?: string;
    /**
     * @type string | undefined
    */
    contractorInn?: string;
};
