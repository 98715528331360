import { reducerManager } from '@/redux/reducerManager'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { setMessages } from './actions'
import { TLocaleMessages } from './interfaces'

export const injectMessages = (locales: TLocaleMessages) => {
  if (!ExecutionEnvironment.isSsr) {
    if (reducerManager.store) reducerManager.store.dispatch(setMessages(locales))
    else setTimeout(() => injectMessages(locales), 100)
  }
}
