import { ReactNode } from 'react'
import { TResponsiveValue } from '@/utils/hooks/responsive/interfaces'

export interface IUserRegionSelectProps {
  className?: Maybe<string>
  label?: Maybe<string | ReactNode>
  picker: TUserRegionSelectResponsiveConfig
  confirm: TUserRegionSelectResponsiveConfig
  selector: TUserRegionSelectResponsiveConfig
  onClickPicker?: () => void
  showSelector?: boolean
  genitiveCase?: boolean
}

export type TUserRegionSelectResponsiveConfig = TResponsiveValue<EUserRegionSelectVariation | false> | false

export enum EUserRegionSelectVariation {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}
