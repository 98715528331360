import React, { useRef } from 'react'
import { LoadableLibrary } from '@loadable/component'
import { ILightboxProps } from '@/components/ui/Lightbox/types'
import { LightboxContext } from './constants'
import { useLightbox } from './useLightbox'
import { ReactLightbox, ReactLightboxThumbnailsPlugin } from './vendor'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

const Lightbox = (props: ILightboxProps) => {
  const { children } = props
  const thumbnailsPlugin = useRef<LoadableLibrary<typeof import('yet-another-react-lightbox/plugins/thumbnails')>>(null)

  const { addSlide, closeLightbox, isLightboxOpen, openLightbox, slides, reset, currentIndex } = useLightbox()

  return (
    <LightboxContext.Provider value={{ addSlide, openLightbox, reset }}>
      <ReactLightbox>
        {({ default: YARLightbox }) => (
          <YARLightbox
            index={currentIndex}
            close={closeLightbox}
            open={isLightboxOpen}
            slides={slides}
            controller={{ closeOnBackdropClick: true }}
            plugins={thumbnailsPlugin.current ? [thumbnailsPlugin.current.default] : undefined}
          />
        )}
      </ReactLightbox>
      <ReactLightboxThumbnailsPlugin ref={thumbnailsPlugin} />
      {children}
    </LightboxContext.Provider>
  )
}

export default Lightbox
