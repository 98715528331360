import React from 'react'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { Avatar, BuyerBlackLabel } from '@/components/ui'
import { isUserSupplier } from '@/logic/auth/reducer'
import { RootState } from '@/redux/interfaces'
import { EUserProfileRoute } from '@/types'
import { formatDate } from '@/utils/formatUtils'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { IClientProps } from './interfaces'
import './style.scss'

const mapState = (state: RootState) => ({
  isSupplier: isUserSupplier()(state),
})

const ClientCard: React.FC<IClientProps> = ({ client }) => {
  const { user, lastOrder, ordersQuantity } = client
  const { id, firstName, lastName, photo, organization, marks } = user
  const { changedPriceMark: isUserLabeled } = marks || {}
  const { isSupplier } = useSelector(mapState)

  const isMobile = useDeviceType()

  const clientUrl = `${EUserProfileRoute.clients}/${id}`
  const innText = `${isMobile ? 'ИНН: ' : ''}${organization.inn}`

  return (
    <div className="client-card space-holder-top4-from-sm space-holder-top2-sm">
      <CommonLink url={clientUrl} className="row row-align_center row_nowrap">
        <Avatar className="hidden-sm space-holder-right16" size="40px" avatar={photo} />
        <h5 className="client-card__title">
          {firstName} {lastName} <BuyerBlackLabel isAgrarianLabeled={isUserLabeled} className="client-card__mark" />
        </h5>
      </CommonLink>
      <p className="color_pale-black hidden-sm text_super-small-from-sm">{organization.name}</p>
      <p className="client-card__inn color_pale-black space-holder-top4-sm text_super-small-from-sm">{innText}</p>
      <p className="hidden-sm text_super-small-from-sm underline underline_pale-black">{ordersQuantity}</p>
      <div className="client-card__last-request text_super-small-from-sm">
        <CommonLink
          className="underline underline_pale-black"
          url={`${isSupplier ? EUserProfileRoute.orders : EUserProfileRoute.sales}/${lastOrder.id}`}
        >
          №{lastOrder.id}
        </CommonLink>
        <p className="color_pale-black space-holder-top4">{formatDate(lastOrder.creationDate, 'dd.MM.yyyy')}</p>
      </div>
      <CommonLink url={clientUrl} className="client-card__button-icon">
        <Icon name={EIconName.ArrowRight2} />
      </CommonLink>
    </div>
  )
}

export default ClientCard
