import React, { FC } from 'react'
import classNames from 'classnames'
import { Button } from '..'
import { messages } from './messages'
import { IPaginationMoreButtonProps } from './types'
import './styles.scss'

const PaginationMoreButton: FC<IPaginationMoreButtonProps> = props => {
  const { isLoading, className, hasNextPage, onClick, text } = props

  if (!hasNextPage) return null

  return (
    <Button modifiers={['white']} className={classNames({ button_disabled: isLoading }, className)} onClick={onClick}>
      {text || messages.loadMoreDefaultText}
    </Button>
  )
}

export default PaginationMoreButton
