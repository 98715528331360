import { ISkeletonLoaderProps } from '@frontend/pole-ui/lib/components/Skeleton'

export const articlePreviewSkeleton: ISkeletonLoaderProps = {
  className: 'journal-articles-item article-preview',
  width: null,
  height: null,
  styleName: 'white',
  skeletons: [
    {
      className: 'article-preview__image space-holder32-from-md space-holder24-md',
      height: null,
      width: null,
    },
    { className: 'text_large article-preview__title' },
  ],
}
