import React from 'react'
import { useIntl } from 'react-intl'
import { InjectHtml as InjectHtmlView } from '@frontend/pole-ui/lib/components/InjectHtml'
import { IInjectHtmlProps } from './interfaces'

const InjectHtml: React.FC<IInjectHtmlProps> = ({ html, textId, textValues, ...otherProps }) => {
  const { formatMessage } = useIntl()
  let text
  if (textId) text = formatMessage({ id: textId }, textValues)

  return <InjectHtmlView html={text || html} {...otherProps} />
}

export default InjectHtml
