/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LandingPageShortData = {
    identifier: string;
    startPromotions: string;
    endPromotions: string;
    type: LandingPageShortData.type;
};

export namespace LandingPageShortData {

    export enum type {
        POOLING = 'POOLING',
        SUPPLIER_PROMO = 'SUPPLIER_PROMO',
    }


}

