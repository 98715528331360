import {
  getMainPagePublication,
  getMainPagePublicationQueryKey,
  getTopNewsHarvest,
  getTopNewsHarvestQueryKey,
  getTopNewsProducts,
  getTopNewsProductsQueryKey,
} from '@/api/kubik/infoportal'
import {
  getAboutBanner,
  getAboutBannerQueryKey,
  getBrandsList,
  getBrandsListQueryKey,
  getCategoryProducts,
  getCategoryProductsQueryKey,
  getConsultBanner,
  getConsultBannerQueryKey,
  getLegalDocuments,
  getLegalDocumentsQueryKey,
  getMainPageSaleTemplatesCulturesInfo,
  getMainPageSaleTemplatesCulturesInfoQueryKey,
  getMenuItemsList,
  getMenuItemsListQueryKey,
  getSaleTemplatesBestOffers,
  getSaleTemplatesBestOffersQueryKey,
  getSaleTemplatesForEachCultures,
  getSaleTemplatesForEachCulturesQueryKey,
  getSaleTemplatesOffersList,
  getSaleTemplatesOffersListQueryKey,
  getTopBanner,
  getTopBannerQueryKey,
} from '@/api/kubik/monolith'
import { TSsrPrefetchQueries } from '@/interfaces'
import { PRODUCT_CATEGORIES } from '@/logic/index/constants'

export const ssrPrefetchQueries: TSsrPrefetchQueries = () => [
  // TODO MOB-589: пофиксить после починки SSR для МС
  { queryFn: getMainPagePublication, queryKey: getMainPagePublicationQueryKey() },
  { queryFn: getTopNewsProducts, queryKey: getTopNewsProductsQueryKey() },
  { queryFn: getTopNewsHarvest, queryKey: getTopNewsHarvestQueryKey() },
  { queryFn: getAboutBanner, queryKey: getAboutBannerQueryKey() },
  { queryFn: () => getSaleTemplatesBestOffers(), queryKey: getSaleTemplatesBestOffersQueryKey() },

  // Каталог Категории
  {
    queryFn: () => getCategoryProducts({ categories: PRODUCT_CATEGORIES.machinery }, { paramsSerializer: { indexes: null } }),
    queryKey: getCategoryProductsQueryKey({ categories: PRODUCT_CATEGORIES.machinery }),
  },
  {
    queryFn: () => getCategoryProducts({ categories: PRODUCT_CATEGORIES.agroproducts }, { paramsSerializer: { indexes: null } }),
    queryKey: getCategoryProductsQueryKey({ categories: PRODUCT_CATEGORIES.agroproducts }),
  },

  { queryFn: getBrandsList, queryKey: getBrandsListQueryKey() },

  { queryFn: () => getSaleTemplatesForEachCultures({ limit: 1 }), queryKey: getSaleTemplatesForEachCulturesQueryKey({ limit: 1 }) },

  { queryFn: getSaleTemplatesOffersList, queryKey: getSaleTemplatesOffersListQueryKey() },
  {
    queryFn: getMainPageSaleTemplatesCulturesInfo,
    queryKey: getMainPageSaleTemplatesCulturesInfoQueryKey(),
  },

  { queryFn: getLegalDocuments, queryKey: getLegalDocumentsQueryKey() },
  { queryFn: getTopBanner, queryKey: getTopBannerQueryKey() },
  { queryFn: getConsultBanner, queryKey: getConsultBannerQueryKey() },
  { queryFn: getMenuItemsList, queryKey: getMenuItemsListQueryKey() },
]
