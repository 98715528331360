/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuctionRegionData } from './AuctionRegionData';

export type AuctionStoreData = {
    externalStoreId: string;
    name: string;
    address: string;
    region: AuctionRegionData;
    type?: AuctionStoreData.type;
};

export namespace AuctionStoreData {

    export enum type {
        WAREHOUSE = 'WAREHOUSE',
        STATION = 'STATION',
    }


}

