import React from 'react'
import classNames from 'classnames'
import { transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import { useData } from '@/logic/data/useData'
import { injectMessages } from '@/logic/translation/utils'
import { IRegion } from '@/types'
import DropdownWithTags from '../DropdownWithTags'
import { IRegionMultiSelectProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const RegionMultiSelect: React.FC<IRegionMultiSelectProps<number>> = props => {
  const { name = 'regions', isHidden, containerClassName, modifiers = ['white'], placeholderId = 'regionMultiSelect.placeholder' } = props
  const { data: regions } = useData<IRegion[]>('regions')
  if (isHidden) return null

  const regionOptions = transformOptions<IRegion, number>({ options: regions, valueKey: 'code' })

  return (
    <DropdownWithTags<number>
      {...props}
      containerClassName={classNames('dropdown-user-region-multi-select', containerClassName)}
      name={name}
      options={regionOptions}
      modifiers={modifiers}
      placeholderId={placeholderId}
    />
  )
}

export default RegionMultiSelect
