import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import keyBy from 'lodash/keyBy'
import { TypedDocumentCard } from '@/components/resolvers'
import { TUpdateDocumentAction } from '@/components/resolvers/DocumentCard/interfaces'
import { Button, Loader } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import { RejectingDocumentModal } from '@/pages/Profiles/UserProfile/components/Accreditation/components'
import { RootState } from '@/redux/interfaces'
import { EDocumentCategory, IDocument, IDocumentType } from '@/types/Documents'
import { addNewEpicsLazily } from '@/utils/addNewEpicsLazily'
import { useMount } from '@/utils/hooks'
import { openModal } from '../Modal/actions'
import { addModalToWellKnown } from '../Modal/constants'
import { IUploadDocumentModalOptions } from './components/UploadDocumentModal/interfaces'
import { deleteDocument, downloadDocument, fetchDocuments, reloadDocuments, updateDocument, uploadDocument } from './actions'
import { DocumentStatus, DocumentView, UploadDocumentModal } from './components'
import { categoryGroups, CommonDocumentType } from './constants'
import asyncEpics from './epics'
import { canMakeAction } from './helpers'
import { useGetDocumentsByType } from './hooks'
import { IDocumentPermissions, IDocumentsProps, IFetchDocumentsPayload, TFilterDocumentTypes } from './interfaces'
import messages from './messages.json'
import { getDocuments, getTypes, isFetching } from './reducer'
import './styles.scss'

addModalToWellKnown({
  UploadDocumentModal,
  RejectingDocumentModal,
})
addNewEpicsLazily([asyncEpics])
injectMessages(messages)

const getDefaultFilteredTypes: TFilterDocumentTypes = types => types

const mapState = (state: RootState) => ({
  documents: getDocuments()(state),
  types: getTypes()(state),
  isLoading: isFetching()(state),
})

const getDefaultSeparatedTypesByGroup: IDocumentsProps['separateTypesByGroup'] = types => [{ title: '', types }]

const Documents: FC<IDocumentsProps> = props => {
  const {
    entity,
    entityId,
    className,
    actions,
    shouldHideUpdatingDocumentButton = false,
    shouldDisplayDocumentStatusElement = false,
    shouldHideAddingDocumentButton = false,
    filterTypes = getDefaultFilteredTypes,
    separateTypesByGroup = getDefaultSeparatedTypesByGroup,
    openRejectingDocumentModal,
    orderIdForUpdateOrderAfterUpdateDoc,
    shouldCheckPermissionsFromActionsByCategories,
    uploadingThroughModalDocumentType,
  } = props
  const dispatch = useDispatch()
  useMount(() => {
    const fetchingDocumentPayload: IFetchDocumentsPayload = { entity, entityId }
    dispatch(fetchDocuments(fetchingDocumentPayload))
    dispatch(reloadDocuments(fetchingDocumentPayload))
  })
  const { documents, types, isLoading } = useSelector(mapState)
  const documentsByType = useGetDocumentsByType(documents)

  const { typesByGroup, typesById, otherDocuments } = useMemo(() => {
    const typesMap = keyBy(types, 'id')

    return {
      typesByGroup: separateTypesByGroup(filterTypes(types)),
      typesById: typesMap,
      otherDocuments: documents.filter(({ type }) => !typesMap[type] && !CommonDocumentType[type]),
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types, documents, filterTypes])

  const openUploadingDocumentModal = () => {
    dispatch(
      openModal({
        options: { dialogId: 'UploadDocumentModal' },
        contentOptions: { entity, entityId, documentType: uploadingThroughModalDocumentType } as IUploadDocumentModalOptions,
      }),
    )
  }

  const uploadDoc = (documentFile: File, typedDocument: IDocumentType) => {
    const { id: documentType, name: documentName } = typedDocument

    dispatch(uploadDocument({ documentFile, documentType, entity, entityId, documentName }))
  }

  const updateDoc = (document: IDocument, documentFile: File) =>
    dispatch(
      updateDocument({
        documentFile,
        document,
        entity,
        entityId,
        documentName: document?.name,
        orderId: orderIdForUpdateOrderAfterUpdateDoc,
      }),
    )
  const deleteDoc = (document: IDocument) => dispatch(deleteDocument({ ...document, entity, entityId }))
  const downloadDoc = (document: IDocument) => dispatch(downloadDocument({ ...document, entity }))
  const downloadDocTemplate = ({ templateUrl, fileName, id }: IDocumentType) =>
    dispatch(downloadDocument({ url: templateUrl as string, fileName: fileName as string, type: id, id, entity }))

  const onUpdateDocument = (action: TUpdateDocumentAction, document: IDocument, file?: File) => {
    switch (action) {
      case 'delete':
        deleteDoc(document)
        break
      case 'update':
        updateDoc(document, file as File)
        break
      default:
    }
  }

  const documentPermissions = useMemo(() => {
    const memoDocumentPermissions: Record<string, IDocumentPermissions> = {}

    return (id: string, categories: EDocumentCategory[]): IDocumentPermissions => {
      if (memoDocumentPermissions[id]) return memoDocumentPermissions[id]!

      const memoConditions = {
        canUpdateDoc: canMakeAction('updateDocument', categories, actions!),
        canDeleteDoc: canMakeAction('deleteDocument', categories, actions!),
        canRejectDoc: canMakeAction('rejectDocument', categories, actions!),
        canAddDoc: canMakeAction('addDocument', categories, actions!),
      }
      memoDocumentPermissions[id] = memoConditions

      return memoConditions
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(actions)])

  const actionPermissions = {
    canAddDoc: true,
    canDeleteDoc: true,
    canRejectDoc: true,
    canUpdateDoc: true,
  }

  if (actions) {
    actionPermissions.canUpdateDoc = Boolean(actions.updateDocument)
    actionPermissions.canDeleteDoc = Boolean(actions.deleteDocument)
    actionPermissions.canAddDoc = Boolean(actions.addDocument)
    actionPermissions.canRejectDoc = Boolean(actions?.rejectDocument)
  }

  return (
    <div className={classNames('documents', className)}>
      <Loader isVisible={isLoading} />

      {actionPermissions.canAddDoc && !shouldHideAddingDocumentButton && (
        <div className="documents-upload-button-container">
          <Button
            onClick={openUploadingDocumentModal}
            modifiers={['outline', 'small']}
            textId="documents.button.upload"
            className="text_small-from-sm"
          />
        </div>
      )}
      {typesByGroup.map(({ types: visibleTypes, title }, index) => (
        <section key={`${title}_${index}`} className="documents-group">
          {title && <h2 className="documents-group__title space-holder-top8 h4">{title}</h2>}

          {visibleTypes.map(typedDocument => {
            const { id, categories } = typedDocument
            const shownDocuments = documentsByType[id]
            const { canUpdateDoc, canDeleteDoc, canRejectDoc, canAddDoc } = shouldCheckPermissionsFromActionsByCategories
              ? documentPermissions(id, categories)
              : actionPermissions

            if (shownDocuments && shownDocuments.length > 0) {
              return shownDocuments.map(document => (
                <DocumentView
                  document={document}
                  downloadDoc={downloadDoc}
                  onUpdateDocument={onUpdateDocument}
                  key={id}
                  canUpdateDocument={canUpdateDoc}
                  canDeleteDocument={canDeleteDoc}
                  shouldDisplayDocumentStatusElement={shouldDisplayDocumentStatusElement}
                  shouldHideUpdatingDocumentButton={shouldHideUpdatingDocumentButton}
                  typesById={typesById}
                  rejectingDocumentButtonName={canRejectDoc ? actions?.rejectDocument?.name : undefined}
                  onRejectDocument={openRejectingDocumentModal ? openRejectingDocumentModal(document.id) : undefined}
                />
              ))
            }

            if (CommonDocumentType[id]) return null

            const DocumentStatusElement = shouldDisplayDocumentStatusElement ? (
              <DocumentStatus categoryGroups={categoryGroups} typedCategories={typedDocument.categories} />
            ) : undefined

            return (
              <TypedDocumentCard
                onUploadDocument={file => file && uploadDoc(file, typedDocument)}
                key={id}
                onDownloadDocument={() => downloadDocTemplate(typedDocument)}
                typedDocument={typedDocument}
                canAddDocument={canAddDoc}
                uploadDocumentRequestName={`uploadDocument_${id}`}
                DocumentStatusElement={DocumentStatusElement}
                shouldUploadTemplateAsAction
                shouldHideUpdatingDocumentButton={shouldHideUpdatingDocumentButton}
              />
            )
          })}
        </section>
      ))}
      {otherDocuments?.length > 0 && (
        <div className="documents__otherDocumentsGroup">
          {otherDocuments.map(document => (
            <DocumentView
              document={document}
              downloadDoc={downloadDoc}
              onUpdateDocument={onUpdateDocument}
              key={document.id}
              canUpdateDocument={actionPermissions.canUpdateDoc}
              canDeleteDocument={actionPermissions.canDeleteDoc}
              shouldDisplayDocumentStatusElement={shouldDisplayDocumentStatusElement}
              typesById={typesById}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Documents
