import { createReducer, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { Details } from 'express-useragent'
import { IApplicationTokens } from '@/interfaces'
import { RootState } from '@/redux/interfaces'
import { detectDevice } from '@/utils/detectDevice'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { getLocationPayload } from '@/utils/url'
import { isUserAgrarian, isUserLogisticsOperator } from '../auth/reducer'
import {
  onAppMountWithEpics,
  onResize,
  setAnalyticsInformation,
  setApiUrl,
  setAppEnv,
  setAppTokens,
  setLocationInfo,
  setServerRenderError,
  setUserAgentPayload,
  setUserLastActivityTime,
} from './actions'
import { AppState, IAnalyticsInformation, IAppEnv, TDeviceContext, ToDoNoticesAny } from './interfaces'

const defaultHeaderHidenPages = ['partners', 'editor']
const defaultDevice = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
}

const initialState: AppState = {
  isAppMounted: false,
  userLastActivityTime: null,
  device: defaultDevice,
  featureSupports: {
    hasWebpSupport: true,
  },
  location: {},
  isFirstRendering: true,
  isServerRenderError: false,
  prevLocation: {},
  notices: [],
  apiUrl: {},
  env: {
    isDev: false,
    isPole: false,
  },
  applicationTokens: {
    gtm: {
      id: '',
      testId: '',
    },
    dadata: {
      token: '',
    },
  },
  analyticsInformation: {
    clientId: '',
  },
}

export default createReducer(initialState, {
  [onAppMountWithEpics.type]: state => {
    state.isAppMounted = true
  },
  [setUserLastActivityTime.type]: (state, { payload }) => {
    state.userLastActivityTime = payload.userLastActivityTime
  },
  [onResize.type]: state => {
    state.device = {
      ...state.device,
      ...detectDevice(),
    }
  },
  [setUserAgentPayload.type]: (state, { payload }: PayloadAction<Details | undefined>) => {
    state.device = payload || defaultDevice
    const { version, isSafari, isIE, isAndroid, isiPhone, browser, isiPad, isiPod } = payload ?? {}

    const majorVersion = Number(version?.toString().split('.')[0]) ?? 0

    const hasWebpSupport = !(
      (isSafari && majorVersion <= 13) ||
      isIE ||
      (isAndroid && majorVersion <= 3) ||
      ((isiPad || isiPod || isiPhone) && browser === 'Chrome')
    )

    state.featureSupports = {
      hasWebpSupport,
    }
  },
  [setLocationInfo.type]: (state, { payload: location }) => {
    state.location = getLocationPayload(location)
  },
  [LOCATION_CHANGE]: (state, { payload }: LocationChangeAction) => {
    const { href = '' } = ExecutionEnvironment.isSsr ? {} : window.location

    state.prevLocation = state.location
    state.location = getLocationPayload(href)
    state.isFirstRendering = payload.isFirstRendering
  },
  [setServerRenderError.type]: state => {
    state.isServerRenderError = true
  },
  [setAppTokens.type]: (state, { payload: tokens }: PayloadAction<IApplicationTokens>) => {
    state.applicationTokens = tokens
  },
  [setApiUrl.type]: (state, { payload: apiUrl }) => {
    state.apiUrl = getLocationPayload(apiUrl)
  },
  [setAnalyticsInformation.type]: (state, { payload }: PayloadAction<IAnalyticsInformation>) => {
    state.analyticsInformation = payload
  },
  [setAppEnv.type]: (state, { payload }: PayloadAction<IAppEnv>) => {
    state.env = payload
  },
})

export const getGtmId = () => (state: RootState) => state.app.applicationTokens.gtm[state.app.env.isPole ? 'id' : 'testId']
export const isAppMounted = () => (state: RootState) => state.app.isAppMounted
export const userLastActivityTime = () => (state: RootState) => state.app.userLastActivityTime
export const getDevice = () => (state: RootState) => state.app.device
export const isSmallMobile = () => (state: RootState) => !!state.app.device?.isSmallMobile
export const isMobile = () => (state: RootState) => !!state.app.device?.isMobile
export const isTablet = () => (state: RootState) => !!state.app.device?.isTablet
export const isDesktop = () => (state: RootState) => !!state.app.device?.isDesktop
export const getLocation = () => (state: RootState) => state.app.location
export const getPrevLocation = () => (state: RootState) => state.app.prevLocation
export const isServerRenderError =
  () =>
  (state: RootState): boolean =>
    state.app.isServerRenderError
export const getUserNotices =
  () =>
  (state: RootState): ToDoNoticesAny =>
    state.app.notices
export const getLocationQuery = createSelector(getLocation(), ({ query }) => query)
export const getPageNumberInQuery = createSelector(getLocation(), location => {
  if (location.query?.pageNumber) return Number(location.query.pageNumber)
  if (location.hash?.startsWith('#page')) return Number(location.hash.substr(5))

  return 1
})
export const getDadataToken = () => (state: RootState) => state.app.applicationTokens.dadata.token
export const hasWebpSupport = () => (state: RootState) => state.app.featureSupports.hasWebpSupport
export const getApiUrl = () => (state: RootState) => state.app.apiUrl
export const is404Page = () => (state: RootState) => state.app.location.is404Page
export const isDefaultHeaderShown = () => (state: RootState) =>
  defaultHeaderHidenPages.some(el => state.app.location.pathname?.split('/').includes(el))
export const shouldHideHeader = () => (state: RootState) => is404Page()(state) || isDefaultHeaderShown()(state)
export const getAnalyticsClientId = () => (state: RootState) => state.app.analyticsInformation.clientId
export const getAppEnv = () => (state: RootState) => state.app.env
export const getDeviceContext = createSelector(isTablet(), isMobile(), (isTabletDevice, isMobileDevice): TDeviceContext => {
  if (isMobileDevice) return 'mobile'
  if (isTabletDevice) return 'tablet'

  return 'desktop'
})
export const canUseCart = createSelector(isUserAgrarian(), (...conditions) => conditions.every(Boolean))
export const canUseChat = () => (state: RootState) => !isUserLogisticsOperator()(state)
export const canUseNotifications = () => (state: RootState) => !isUserLogisticsOperator()(state)
export const getIsFirstRendering = () => (state: RootState) => state.app.isFirstRendering
