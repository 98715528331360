import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateTemplateStatusMutationRequest, UpdateTemplateStatusMutationResponse, UpdateTemplateStatusHeaderParams } from "../../types/UpdateTemplateStatus";

/**
     * @link /api/document/purchase-offer/v1/update_template-status
     */
export async function updateTemplateStatus (data: UpdateTemplateStatusMutationRequest, headers?: UpdateTemplateStatusHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateTemplateStatusMutationResponse>["data"]> {
    const { data: resData } = await client<UpdateTemplateStatusMutationResponse, UpdateTemplateStatusMutationRequest>({
        method: "post",
        url: `/api/document/purchase-offer/v1/update_template-status`,
        data,
        headers: { ...headers, ...options.headers },
        ...options
    });
    
    return resData;
};