import get from 'lodash/get'
import { RootState } from '@/redux/interfaces'
import { IDataState, IFetchDataPayload, INamedDataState, TApiUrlName } from './interfaces'
import { responseMapper } from './responseMapper'

export const hasParamInUrl =
  (paramName: string) =>
  ({ params }: Partial<IFetchDataPayload>): boolean =>
    Boolean(params?.[paramName])

export const getDataByName =
  <T = any>(name: TApiUrlName) =>
  (state: IDataState): INamedDataState<T> =>
    get(state, `loadedData.${name}`)

export const setDataByName = (name: TApiUrlName, payload: INamedDataState) => (state: IDataState) => {
  state.loadedData[name] = { ...getDataByName(name), ...payload }
}

export const getTransformedResponse = (name: TApiUrlName, data: any) => (state: RootState) => {
  const mapResponse = responseMapper[name]
  if (mapResponse) data = mapResponse(data, state)

  return data
}
